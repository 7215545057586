import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
    IconButton,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon, Male as MaleIcon, Female as FemaleIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import BooleanIcon from 'components/BooleanIcon';
import DrawerHeaderTitle from 'components/DrawerHeaderTitle';
import OverviewTabPanel from './OverviewTabPanel';

// Apollo
import { useQuery, useLazyQuery, from } from '@apollo/client';
import { GET_CLINIC } from './apollo-queries';

// Redux
import {} from 'redux';

// Utils
import { formatNumber, formatPhoneNumber, capitalize } from 'utils';

// Constants
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import BillingTabPanel from './BillingTabPanel';
import EditTabPanel from './EditTabPanel';
import SexIcon from 'components/SexIcon';

interface EditDrawerProps {
    clinicId: number | null;
    handleCloseEditDrawer: () => void;
}

interface EditDrawerContentProps {
    clinic: any;
    handleCloseEditDrawer: () => void;
}

export default function EditDrawer({ clinicId, handleCloseEditDrawer }: EditDrawerProps) {
    // Apollo queries
    const {
        loading: clinicLoading,
        error: clinicError,
        data: clinicData,
    } = useQuery(GET_CLINIC, {
        variables: { id: clinicId },
        skip: !clinicId,
    });

    const clinic = clinicData?.clinic;

    const isDrawerOpen = clinicId ? true : false;

    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseEditDrawer}
            PaperProps={{ sx: { mt: '64px', height: 'calc(100vh - 64px) !important', p: 3 } }}
        >
            {clinicLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                    <Loading />
                </Box>
            ) : (
                <EditDrawerContent clinic={clinic} handleCloseEditDrawer={handleCloseEditDrawer} />
            )}
        </Drawer>
    );
}

export const EditDrawerContent = ({ clinic, handleCloseEditDrawer }: EditDrawerContentProps) => {
    const [tabIndex, setTabIndex] = React.useState('facturation-interne');

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue.toString());
    };

    if (!clinic) return <></>;

    const { pst, name } = clinic;

    return (
        <Grid container spacing={2} width="60vw">
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} container spacing={2}>
                    <Grid item>
                        <Avatar
                            src={
                                ''
                                // 'https://storage.cloud.google.com/fmparadis-public-bucket-staging/admin-avatars/grogu.jpeg?authuser=2'
                            }
                            sx={{ width: 140, height: 140 }}
                        >
                            <Typography variant="h2" fontWeight="bold">
                                {pst}
                            </Typography>
                        </Avatar>
                    </Grid>

                    <Grid item xs ml={2}>
                        <Grid item xs={12} container alignItems="center">
                            <Grid item xs={9}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h3">{name}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3} display="flex" justifyContent="flex-end">
                                <Typography variant="h4">{clinic.grp}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 1, mb: 3 }}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} container spacing={1} columnSpacing={6}>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Groupe:
                                </Typography>
                                <Typography variant="body1">{clinic.grp}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Courriel contact:
                                </Typography>
                                <Typography variant="body1">{clinic.contact_email}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Balance:
                                </Typography>
                                <Typography variant="body1">{formatNumber(clinic.balance / 100)} $</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Prénom contact:
                                </Typography>
                                <Typography variant="body1">{formatPhoneNumber(clinic.contact_first_name)}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Nom contact:
                                </Typography>
                                <Typography variant="body1">{formatPhoneNumber(clinic.contact_last_name)}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Tel. contact:
                                </Typography>
                                <Typography variant="body1">{formatPhoneNumber(clinic.contact_phone)}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Rejoint le:
                                </Typography>
                                <Typography variant="body1">
                                    {clinic.joined_on ? moment(clinic.joined_on).format(SQL_DATE) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Parti le:
                                </Typography>
                                <Typography variant="body1">
                                    {clinic.left_on ? moment(clinic.left_on).format(SQL_DATE) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" fontWeight={500}>
                                        Encore client:{' '}
                                    </Typography>
                                    <BooleanIcon value={clinic.is_still_client} sx={{ ml: 1 }} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
                <TabContext value={tabIndex}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={onTabChange}
                            variant="scrollable"
                            // scrollButtons
                            allowScrollButtonsMobile
                        >
                            <Tab label="Overview" value="overview" />
                            <Tab label="Edit" value="clinic-edit" />
                            <Tab label="Facturation" value="facturation-interne" />
                        </TabList>
                    </Box>
                    <>
                        <OverviewTabPanel tabIndexAsString="overview" clinic={clinic} />
                        <EditTabPanel tabIndexAsString="clinic-edit" clinic={clinic} />
                        <BillingTabPanel tabIndexAsString="facturation-interne" clinic={clinic} />
                    </>
                </TabContext>
            </Grid>
        </Grid>
    );
};
