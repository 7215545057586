import React, { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';

// MUI Components
import {
    Avatar,
    AppBar as MuiAppBar,
    AppBarProps as MuiAppBarProps,
    Box,
    Drawer as MuiDrawer,
    Badge,
    Chip,
    ChipProps,
    ListSubheader,
    Menu,
    MenuItem,
    Toolbar,
    List,
    CssBaseline,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    useMediaQuery,
    ListItemSecondaryAction,
} from '@mui/material';

// MUI Icons
import {
    Analytics as AnalyticsIcon,
    Mail as MailIcon,
    Inbox as InboxIcon,
    DashboardOutlined as DashboardOutlinedIcon,
    Storage as StorageIcon,
    Notifications as NotificationsIcon,
    BarChartOutlined as BarChartOutlinedIcon,
    BusinessOutlined as BusinessOutlinedIcon,
    Person as PersonIcon,
    PieChart as PieChartIcon,
    TimelineOutlined as TimelineOutlinedIcon,
    Receipt as ReceiptIcon,
    Chat as ChatIcon,
    DashboardCustomize as DashboardCustomizeIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    FormatListNumbered as FormatListNumberedIcon,
    ContentPaste as ContentPasteIcon,
    Brightness4 as Brightness4Icon,
    Brightness7 as Brightness7Icon,
    AccountCircle as AccountCircleIcon,
    More as MoreIcon,
    ReplyAll as ReplyAllIcon,
    DocumentScanner as DocumentScannerIcon,
    Summarize as SummarizeIcon,
    Event as EventIcon,
    GroupsOutlined as GroupsOutlinedIcon,
    DateRange as DateRangeIcon,
    AttachMoney as AttachMoneyIcon,
    ReceiptLongOutlined as ReceiptLongOutlinedIcon,
    ManageAccountsOutlined as ManageAccountsOutlinedIcon,
    LocalHospital as LocalHospitalIcon,
    TopicOutlined as TopicOutlinedIcon,
    RequestQuoteOutlined as RequestQuoteOutlinedIcon,
    CalendarMonthOutlined as CalendarMonthOutlinedIcon,
    Work as WorkIcon,
    CasesOutlined as CasesOutlinedIcon,
    HeartBrokenOutlined as HeartBrokenOutlinedIcon,
    DescriptionOutlined as DescriptionOutlinedIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Business as BusinessIcon,
    Groups as GroupsIcon,
    CalculateOutlined as CalculateOutlinedIcon,
    ReceiptLong as ReceiptLongIcon,
    PaymentsOutlined as PaymentsOutlinedIcon,
    StackedLineChart as StackedLineChartIcon,
    AccountBalanceOutlined as AccountBalanceOutlinedIcon,
    NearMe as NearMeIcon,
} from '@mui/icons-material';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { LOGOUT_ADMIN } from './apollo-queries';

// Redux
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { setIsThemeDark } from 'redux/features/general/generalSlice';

// Services
import { Auth } from 'services';
import { useAuth } from 'providers/AuthProvider/context';
import { LOGIN_ROUTE } from 'router';

// Utils
import { capitalize } from 'utils';

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    paddingLeft: theme.spacing(0.5),
    [theme.breakpoints.down('tablet')]: {
        width: `0px`,
        padding: '0px',
    },
    [theme.breakpoints.up('tablet')]: {
        // width: `calc(${theme.spacing(9.5)} + 1px)`,
        width: `62px`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
            ...openedMixin(theme),
            msOverflowStyle: 'none' /* for Internet Explorer, Edge */,
            scrollbarWidth: 'none !important' /* for Firefox */,
            '::-webkit-scrollbar': {
                display: 'none' /* for Chrome, Safari, and Opera */,
            },
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': {
            ...closedMixin(theme),
            msOverflowStyle: 'none' /* for Internet Explorer, Edge */,
            scrollbarWidth: 'none !important' /* for Firefox */,
            '::-webkit-scrollbar': {
                display: 'none' /* for Chrome, Safari, and Opera */,
            },
        },
    }),
}));

const SUPER_ADMIN_IDS = [1, 2, 3, 4, 6];

const drawerItems = [
    {
        subheader: {
            title_fr: 'Statistiques',
            title_en: 'Statistiques',
        },
        items: [
            {
                title_fr: 'Performance générale',
                title_en: 'Performance générale',
                icon: <BarChartOutlinedIcon />,
                navigateTo: 'stats/general-performance',
                status: 'v1',
                hideFromEmployee: true,
            },
            {
                title_fr: 'Performance analyste',
                title_en: 'Performance analyste',
                icon: <StackedLineChartIcon />,
                navigateTo: 'stats/analyst-performance',
                status: 'v1',
                hideFromEmployee: true,
            },
            {
                title_fr: 'Revenus',
                title_en: 'Revenus',
                icon: <AccountBalanceOutlinedIcon />,
                navigateTo: 'stats/billing-performance',
                status: 'v1',
                hideFromEmployee: true,
            },
        ],
        hideFromEmployee: true,
    },
    {
        subheader: {
            title_fr: 'Gestion des médecins',
            title_en: 'Gestion des médecins',
        },
        items: [
            {
                title_fr: 'Médecins',
                title_en: 'Médecins',
                icon: <PersonIcon />,
                navigateTo: 'mds',
                status: 'v1',
            },
            {
                title_fr: 'Factures',
                title_en: 'Factures',
                icon: <AttachMoneyIcon />,
                navigateTo: 'mds/bills',
                status: 'v2',
            },
            {
                title_fr: 'Hors RAMQ',
                title_en: 'Hors RAMQ',
                icon: <NearMeIcon />,
                navigateTo: 'mds/hors-ramq',
                status: 'v1',
            },
            {
                title_fr: 'Grand livre',
                title_en: 'Grand livre',
                icon: <ReceiptLongIcon />,
                navigateTo: 'mds/transactions',
                status: 'v2',
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Gestion des cliniques',
            title_en: 'Gestion des cliniques',
        },
        items: [
            {
                title_fr: 'Cliniques',
                title_en: 'Cliniques',
                icon: <BusinessIcon />,
                navigateTo: 'clinics',
                status: 'v1',
            },
            {
                title_fr: 'Factures',
                title_en: 'Factures',
                icon: <AttachMoneyIcon />,
                navigateTo: 'clinics/bills',
                status: 'v2',
            },
            {
                title_fr: 'Grand livre',
                title_en: 'Grand livre',
                icon: <ReceiptLongIcon />,
                navigateTo: 'clinics/transactions',
                status: 'v2',
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Comptabilité',
            title_en: 'Comptabilité',
        },
        items: [
            {
                title_fr: 'Paiements',
                title_en: 'Paiements',
                icon: <PaymentsOutlinedIcon />,
                navigateTo: 'accounting/payments',
                status: 'v1',
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Utilitaires',
            title_en: 'Utilitaires',
        },
        items: [
            {
                title_fr: 'Calc. de matrice',
                title_en: 'Calc. de matrice',
                icon: <CalculateOutlinedIcon />,
                navigateTo: 'brackets/bracket-sheet-function-calculator',
                status: 'v1',
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Reconstitution balances SIPT',
            title_en: 'Reconstitution balances SIPT',
        },
        items: [
            {
                title_fr: 'Historique Mds',
                title_en: 'Historique Mds',
                icon: <ReceiptLongIcon />,
                navigateTo: 'mds/historical-transactions',
                status: 'v1',
            },
            {
                title_fr: 'Historique Clins.',
                title_en: 'Historique Clins.',
                icon: <ReceiptLongIcon />,
                navigateTo: 'clinics/historical-transactions',
                status: 'v1',
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Objets',
            title_en: 'Objets',
        },
        items: [
            {
                title_fr: 'Groupes',
                title_en: 'Groupes',
                icon: <GroupsIcon />,
                navigateTo: 'home',
                status: 'dv',
            },
            {
                title_fr: 'Postes',
                title_en: 'Postes',
                icon: <BusinessIcon />,
                navigateTo: 'postes',
                status: 'v1',
            },
            {
                title_fr: 'Diagnostiques',
                title_en: 'Diagnostiques',
                icon: <ContentPasteIcon />,
                navigateTo: 'home',
                status: 'dv',
            },
            {
                title_fr: 'Actes',
                title_en: 'Acts',
                icon: <ContentPasteIcon />,
                navigateTo: 'home',
                status: 'dv',
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Facturation RAMQ',
            title_en: 'Facturation RAMQ',
        },
        items: [
            {
                title_fr: 'États de compte',
                title_en: 'États de compte',
                icon: <ReceiptIcon />,
                // icon: <Typography>FMP</Typography>,
                navigateTo: 'ec',
                status: 'v1',
                disabled: true,
            },
            {
                title_fr: 'Lots',
                title_en: 'Lots',
                icon: <ReceiptIcon />,
                // icon: <Typography>FMP</Typography>,
                navigateTo: 'lots',
                status: 'v1',
                disabled: true,
            },
            {
                title_fr: 'DPs',
                title_en: 'DPs',
                icon: <ReceiptIcon />,
                // icon: <Typography>FMP</Typography>,
                navigateTo: 'factures',
                status: 'v1',
                disabled: true,
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Scans',
            title_en: 'Scans',
        },
        items: [
            {
                title_fr: 'Scan overview',
                title_en: 'Scan overview',
                icon: <DateRangeIcon />,
                navigateTo: 'scan-overview',
                status: 'v1',
                disabled: true,
            },
            {
                title_fr: 'Scan dispatcher',
                title_en: 'Scan dispatcher',
                icon: <ReplyAllIcon />,
                navigateTo: 'scan-dispatcher',
                status: 'v1',
                disabled: true,
            },
            {
                title_fr: 'Mes scans',
                title_en: 'Mes scans',
                icon: <DocumentScannerIcon />,
                navigateTo: 'scans/2/2022-02-04',
                status: 'v1',
                disabled: true,
            },
        ],
    },
    // {
    //     subheader: {
    //         title_fr: 'Gestion',
    //         title_en: 'Gestion',
    //     },
    //     items: [
    //         {
    //             title_fr: 'Dashboard',
    //             title_en: 'Dashboard',
    //             icon: <DashboardOutlinedIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //         {
    //             title_fr: 'Rapports',
    //             title_en: 'Rapports',
    //             icon: <TimelineOutlinedIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //         {
    //             title_fr: 'Activités',
    //             title_en: 'Activités',
    //             icon: <NotificationsIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //         {
    //             title_fr: 'Finances',
    //             title_en: 'Finances',
    //             icon: <AccountBalanceOutlinedIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //         {
    //             title_fr: 'Stats MD',
    //             title_en: 'Stats MD',
    //             icon: <TimelineOutlinedIcon />,
    //             navigateTo: 'stats/md/1070739',
    //             status: 'dv',
    //         },
    //         {
    //             title_fr: 'Calendrier',
    //             title_en: 'Calendrier',
    //             icon: <EventIcon />,
    //             navigateTo: 'home',
    //             status: 'dv',
    //         },
    //     ],
    // },
    // {
    //     subheader: {
    //         title_fr: 'Utils',
    //         title_en: 'Utils',
    //     },
    //     items: [
    //         {
    //             title_fr: 'Kanban',
    //             title_en: 'Kanban',
    //             icon: <DashboardCustomizeIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //         {
    //             title_fr: 'Tâches',
    //             title_en: 'Tâches',
    //             icon: <AssignmentTurnedInIcon />,
    //             navigateTo: 'home',
    //             status: 'dv',
    //         },
    //         {
    //             title_fr: 'Processus',
    //             title_en: 'Processus',
    //             icon: <SummarizeIcon />,
    //             navigateTo: 'home',
    //             status: 'dv',
    //         },
    //         {
    //             title_fr: 'Chat',
    //             title_en: 'Chat',
    //             icon: <ChatIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //     ],
    // },
    // {
    //     subheader: {
    //         title_fr: 'Administration',
    //         title_en: 'Administration',
    //     },
    //     items: [
    //         {
    //             title_fr: 'Admins',
    //             title_en: 'Admins',
    //             icon: <StorageIcon />,
    //             navigateTo: 'admin',
    //             status: 'dv',
    //         },
    //         {
    //             title_fr: 'Rôles & perms.',
    //             title_en: 'Roles & perms.',
    //             icon: <StorageIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //         {
    //             title_fr: 'Logs',
    //             title_en: 'Acts wrappers',
    //             icon: <StorageIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //         {
    //             title_fr: 'Monitoring',
    //             title_en: 'Monitoring',
    //             icon: <StorageIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //         {
    //             title_fr: 'Storage',
    //             title_en: 'Storage',
    //             icon: <StorageIcon />,
    //             navigateTo: 'home',
    //             status: 'td',
    //         },
    //     ],
    // },
];

export default function MiniDrawer() {
    const auth = useAuth();
    const theme = useTheme();
    let navigate = useNavigate();
    const location = useLocation();

    const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(isLaptop);
    }, [isLaptop]);

    const general = useAppSelector((state) => state.general);
    const dispatch = useAppDispatch();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleLogout = () => {
        logoutAdmin();
    };

    const [logoutAdmin] = useLazyQuery(LOGOUT_ADMIN, {
        onCompleted: async () => {
            await auth.logout();
            // navigate(LOGIN_ROUTE);
        },
    });

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose} disabled>
                <Avatar sx={{ width: 30, height: 30, mr: 2 }} />
                Mon compte
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Déconnexion
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleMenuClose} disabled>
                <Avatar sx={{ width: 30, height: 30, mr: 2 }} />
                Mon compte
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Déconnexion
            </MenuItem>
        </Menu>
    );

    useEffect(() => {
        const { pathname } = location;

        if (pathname && pathname.split('/')[1] === 'stats') {
            setOpen(false);
        }
    }, [location]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Admin FMParadis
                    </Typography>
                    {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                    </Search> */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            sx={{ ml: 1 }}
                            onClick={() => dispatch(setIsThemeDark(!general.isThemeDark))}
                            color="inherit"
                        >
                            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircleIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}

            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                {drawerItems
                    .filter((item) => (item.hideFromEmployee ? SUPER_ADMIN_IDS.includes(auth.admin!.id) : true))
                    .map((drawerItem, drawerItemIdx) => {
                        const {
                            subheader: { title_fr },
                            items,
                        } = drawerItem;

                        return (
                            <List key={drawerItemIdx} sx={{ mt: 0 }}>
                                {open && <ListSubheader>{title_fr}</ListSubheader>}
                                {items
                                    .filter((item) =>
                                        (item as any).hideFromEmployee
                                            ? SUPER_ADMIN_IDS.includes(auth.admin!.id)
                                            : true,
                                    )
                                    .map((item, itemIdx) => {
                                        const { title_fr, icon, navigateTo, status, disabled } = item as any;

                                        let statusChipColor = 'primary' as ChipProps['color'];
                                        switch (status) {
                                            case 'td':
                                                statusChipColor = 'error';
                                                break;
                                            case 'dv':
                                                statusChipColor = 'warning';
                                                break;
                                            default:
                                                break;
                                        }

                                        const isSelected =
                                            location.pathname.split('/').splice(1).join('/') === navigateTo;

                                        return (
                                            <ListItemButton
                                                key={itemIdx}
                                                component={NavLink}
                                                to={navigateTo}
                                                selected={isSelected}
                                                disabled={
                                                    process.env.NODE_ENV !== 'development'
                                                        ? disabled === undefined
                                                            ? ['td', 'dv'].includes(status)
                                                            : disabled
                                                        : false
                                                }
                                            >
                                                <ListItemIcon sx={{ minWidth: 50 }}>{icon}</ListItemIcon>
                                                <ListItemText primary={title_fr} />
                                                {(process.env.NODE_ENV as unknown) === 'staging' && false && (
                                                    <ListItemSecondaryAction>
                                                        {open && (
                                                            <Chip
                                                                label={
                                                                    <Typography
                                                                        variant="overline"
                                                                        sx={{ fontSize: '11px' }}
                                                                    >
                                                                        {capitalize(status)}
                                                                    </Typography>
                                                                }
                                                                color={statusChipColor}
                                                                size="small"
                                                                sx={{ px: 0, py: 0, borderRadius: 1 }}
                                                            />
                                                        )}
                                                    </ListItemSecondaryAction>
                                                )}
                                            </ListItemButton>
                                        );
                                    })}
                                <Divider />
                            </List>
                        );
                    })}
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
                <DrawerHeader />

                <Outlet />

                {/* <Typography paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
                    enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
                    imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
                    Convallis convallis tellus id interdum velit laoreet id donec ultrices.
                    Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                    adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
                    nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
                    leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
                    feugiat vivamus at augue. At augue eget arcu dictum varius duis at
                    consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
                    sapien faucibus et molestie ac.
                </Typography>
                <Typography paragraph>
                    Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                    eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                    neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                    tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                    sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                    tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                    gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                    et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                    tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                    eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                    posuere sollicitudin aliquam ultrices sagittis orci a.
                </Typography> */}
            </Box>
        </Box>
    );
}
