import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { ClinicFields } from 'apollo/fragments';

export const GET_CLINIC = gql`
    ${ClinicFields}

    query GetClinic($id: Int!) {
        clinic(id: $id) {
            ...ClinicFields
        }
    }
`;

export const UPDATE_CLINIC = gql`
    mutation UpdateClinic(
        $id: Int
        $is_still_client: Boolean
        $left_on: Date
        $can_login: Boolean
        $qb_customer_id: Int
        $qb_customer_fully_qualified_name: String
        $is_billable: Boolean
        $is_billable_until: Date
        $should_receive_md_reports: Boolean
        $should_receive_md_reports_until: Date
        $should_receive_md_ec_reports: Boolean
        $should_receive_md_df_reports: Boolean
        $should_receive_md_custom_reports: Boolean
    ) {
        updateClinic(
            id: $id
            is_still_client: $is_still_client
            left_on: $left_on
            can_login: $can_login
            qb_customer_id: $qb_customer_id
            qb_customer_fully_qualified_name: $qb_customer_fully_qualified_name
            is_billable: $is_billable
            is_billable_until: $is_billable_until
            should_receive_md_reports: $should_receive_md_reports
            should_receive_md_reports_until: $should_receive_md_reports_until
            should_receive_md_ec_reports: $should_receive_md_ec_reports
            should_receive_md_df_reports: $should_receive_md_df_reports
            should_receive_md_custom_reports: $should_receive_md_custom_reports
        ) {
            id
        }
    }
`;
