import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';

// MUI Components
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { Save as SaveIcon } from '@mui/icons-material';

// Components
import { AutocompleteControl, DateControl, NumberControl, SelectControl, TextControl } from 'components/inputs';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
    GET_HORS_RAMQ_CLAIM_TYPES,
    GET_HORS_RAMQ_CLAIM_SUBTYPES,
    GET_MDS,
    CREATE_MD_HORS_RAMQ_CLAIM,
} from './apollo-queries';
import { GET_MD_HORS_RAMQ_CLAIMS } from 'views/hors-ramq/MdHorsRamqClaimList/apollo-queries';

// Redux
import {} from 'redux';

// Utils
import { getErrorMsg } from 'utils';

// Constants
import { HORS_RAMQ_STATUSES } from 'constants/DbConstants';
import { SQL_DATE } from 'constants/Moment';

interface MdHorsRamqClaimCreateDialogProps {
    isMdHorsRamqClaimCreateDialogOpen: boolean;
    handleCloseMdHorsRamqClaimCreateDialog: () => void;
}

interface MdHorsRamqClaimCreateDialogContentProps {
    isMdHorsRamqClaimCreateDialogOpen: boolean;
    handleCloseMdHorsRamqClaimCreateDialog: () => void;
}

export function MdHorsRamqClaimCreateDialog({
    isMdHorsRamqClaimCreateDialogOpen,
    handleCloseMdHorsRamqClaimCreateDialog,
}: MdHorsRamqClaimCreateDialogProps) {
    const isDialogOpen = isMdHorsRamqClaimCreateDialogOpen ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={handleCloseMdHorsRamqClaimCreateDialog}>
            <MdHorsRamqClaimCreateDialogContent
                isMdHorsRamqClaimCreateDialogOpen={isMdHorsRamqClaimCreateDialogOpen}
                handleCloseMdHorsRamqClaimCreateDialog={handleCloseMdHorsRamqClaimCreateDialog}
            />
        </Dialog>
    );
}

const MdHorsRamqClaimCreateDialogContent = ({
    isMdHorsRamqClaimCreateDialogOpen,
    handleCloseMdHorsRamqClaimCreateDialog,
}: MdHorsRamqClaimCreateDialogContentProps) => {
    const theme = useTheme();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
        setValue,
    } = useForm({
        defaultValues: {
            md_id: null,
            received_on: moment().format(SQL_DATE),
            patient_name: '',
            consulted_on: moment().format(SQL_DATE),
            hors_ramq_claim_type_id: null,
            hors_ramq_claim_subtype_id: null,
            amt_asked: 0,
            amt_paid: 0,
            check_no: null,
            check_date: moment().format(SQL_DATE),
            status: null,
        },
    });

    const horsRamqClaimTypeId = watch('hors_ramq_claim_type_id');

    const {
        loading: horsRamqClaimTypesLoading,
        error: horsRamqClaimTypesError,
        data: horsRamqClaimTypesData,
    } = useQuery(GET_HORS_RAMQ_CLAIM_TYPES, {
        skip: !isMdHorsRamqClaimCreateDialogOpen,
    });
    const {
        loading: horsRamqClaimSubtypesLoading,
        error: horsRamqClaimSubtypesError,
        data: horsRamqClaimSubtypesData,
    } = useQuery(GET_HORS_RAMQ_CLAIM_SUBTYPES, {
        variables: {
            hors_ramq_claim_type_id: horsRamqClaimTypeId,
        },
        skip: !isMdHorsRamqClaimCreateDialogOpen || horsRamqClaimTypeId === null,
    });
    const { loading: mdsLoading, error: mdsError, data: mdsData } = useQuery(GET_MDS);

    const [
        createMdHorsRamqClaim,
        { loading: createMdHorsRamqClaimLoading, error: createMdHorsRamqClaimError, data: createMdHorsRamqClaimData },
    ] = useMutation(CREATE_MD_HORS_RAMQ_CLAIM, {
        refetchQueries: [GET_MD_HORS_RAMQ_CLAIMS],
    });

    const onSubmit = async (data) => {
        await createMdHorsRamqClaim({
            variables: {
                md_id: data.md_id,
                received_on: data.received_on,
                patient_name: data.patient_name,
                consulted_on: data.consulted_on,
                hors_ramq_claim_type_id: data.hors_ramq_claim_type_id,
                hors_ramq_claim_subtype_id: data.hors_ramq_claim_subtype_id,
                amt_asked: data.amt_asked,
                amt_paid: data.amt_paid,
                check_no: data.check_no,
                check_date: data.check_date,
                status: data.status,
            },
        });

        handleCloseMdHorsRamqClaimCreateDialog();
    };

    // Error snackbar
    const [isErrorSnackbarOpened, setIsErrorSnackbarOpened] = React.useState(false);
    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpened(false);
    };
    useEffect(() => setIsErrorSnackbarOpened(createMdHorsRamqClaimError ? true : false), [createMdHorsRamqClaimError]);

    // Show subselect logic
    const [showSubtypeSelect, setShowSubtypeSelect] = useState(false);

    useEffect(() => {
        if ((horsRamqClaimSubtypesData?.horsRamqClaimSubtypes || []).length > 0) {
            setShowSubtypeSelect(true);
        } else {
            if (showSubtypeSelect === true && horsRamqClaimSubtypesLoading) {
                return;
            }
            setShowSubtypeSelect(false);
        }
    }, [showSubtypeSelect, horsRamqClaimSubtypesData, horsRamqClaimSubtypesLoading, setShowSubtypeSelect]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <DialogTitle>Enregistrer une demande Hors-RAMQ</DialogTitle>

                <DialogContent>
                    <Grid container spacing={3} pt={1}>
                        <Grid item xs={12}>
                            <AutocompleteControl
                                name="md_id"
                                control={control as any}
                                options={mdsData?.mds || []}
                                valuePropertyName="id"
                                loading={mdsLoading}
                                getOptionLabel={(option: any) => {
                                    const { id, first_name, last_name } = option;

                                    if (option === getValues('md_id')) {
                                        const selectedMd = (mdsData?.mds || []).find((md) => md.id === option);

                                        return selectedMd
                                            ? `${selectedMd.last_name}, ${selectedMd.first_name} (${selectedMd.id})`
                                            : '';
                                    } else {
                                        return `${last_name}, ${first_name} (${id})`;
                                    }
                                }}
                                isOptionEqualToValue={(option: any, value) => option?.id === value}
                                textFieldOptions={{ label: 'Médecin' }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DateControl
                                name={`received_on`}
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Date de réception' }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextControl
                                name="patient_name"
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Nom du patient' }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DateControl
                                name={`consulted_on`}
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Date de consultation' }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SelectControl
                                name="hors_ramq_claim_type_id"
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Type' }}
                                dropdownOptions={[...(horsRamqClaimTypesData?.horsRamqClaimTypes || [])]
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((status) => (
                                        <MenuItem key={status.id} value={status.id}>
                                            {status.name}
                                        </MenuItem>
                                    ))}
                                onChangeAddon={(newValue) => {
                                    setValue('hors_ramq_claim_subtype_id', null);
                                }}
                            />
                        </Grid>

                        {showSubtypeSelect && (
                            <Grid item xs={12}>
                                <SelectControl
                                    name="hors_ramq_claim_subtype_id"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Sous-type', disabled: !showSubtypeSelect }}
                                    dropdownOptions={[...(horsRamqClaimSubtypesData?.horsRamqClaimSubtypes || [])]
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((status) => (
                                            <MenuItem key={status.id} value={status.id}>
                                                {status.name}
                                            </MenuItem>
                                        ))}
                                />
                            </Grid>
                        )}

                        <Grid item xs={6}>
                            <NumberControl
                                name={`amt_asked`}
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Montant demandé' }}
                                money
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <NumberControl
                                name={`amt_paid`}
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Montant payé' }}
                                money
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextControl
                                name="check_no"
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'No du chèque' }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DateControl
                                name={`check_date`}
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Date du chèque' }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SelectControl
                                name="status"
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Statut' }}
                                dropdownOptions={HORS_RAMQ_STATUSES.sort((a, b) => a.name.localeCompare(b.name)).map(
                                    (status) => (
                                        <MenuItem key={status.name} value={status.name}>
                                            {status.name}
                                        </MenuItem>
                                    ),
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        variant="text"
                        onClick={handleSubmit(onSubmit)}
                        endIcon={<SaveIcon />}
                        loading={createMdHorsRamqClaimLoading}
                        loadingPosition="end"
                        type="submit"
                    >
                        Sauvegarder
                    </LoadingButton>
                </DialogActions>
            </form>

            <Snackbar
                open={isErrorSnackbarOpened}
                autoHideDuration={6000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {getErrorMsg(createMdHorsRamqClaimError)}
                </Alert>
            </Snackbar>
        </>
    );
};
