import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getBillLineActeDetailsColumns, getBillLineLigneDetailsColumns } from './grid-columns';

// MUI Icons
import {} from '@mui/icons-material';

// Components
import BracketDetailsDialog from '../BracketDetailsDialog';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils

// Constants

interface BillLineDetailsTabPanelProps {
    tabIndexAsString: string;
    billLine: any;
}

export default function BillLineDetailsTabPanel({ tabIndexAsString, billLine }: BillLineDetailsTabPanelProps) {
    // Json details Dialog
    const [selectedDetails, setSelectedDetails] = useState<any | null>(null);
    const handleCloseBracketDetailsDialog = () => {
        setSelectedDetails(null);
    };

    // Columns
    const billLineTagId = billLine.bill_line_tag_id;
    const billingMethod: 'acte' | 'ligne' | null = billLineTagId === 1 ? 'acte' : billLineTagId === 2 ? 'ligne' : null;
    const columns = useMemo(() => {
        switch (billingMethod) {
            case 'acte':
                return getBillLineActeDetailsColumns(setSelectedDetails);
            case 'ligne':
                return getBillLineLigneDetailsColumns(setSelectedDetails);
            default:
                return [];
        }
    }, [billingMethod]);

    return (
        <TabPanel value={tabIndexAsString}>
            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={billLine.json_details}
                columns={columns}
                // pagination
                pageSize={200}
                // rowsPerPageOptions={[10, 25, 50, 75, 100]}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: { left: ['billing_sheet_bracket_id'], right: ['actions'] },
                    sorting: {
                        sortModel: [{ field: 'from', sort: 'asc' }],
                    },
                }}
            />
            <BracketDetailsDialog
                selectedDetails={selectedDetails}
                handleCloseBracketDetailsDialog={handleCloseBracketDetailsDialog}
                billingMethod={billingMethod}
            />
        </TabPanel>
    );
}
