import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { ClinicFields } from 'apollo/fragments';

export const GET_CLINICS = gql`
    ${ClinicFields}

    query GetClinics($id: Int, $name: String) {
        clinics(id: $id, name: $name) {
            ...ClinicFields

            ClinicBillingInfo {
                is_approved
                billing_method
                billing_sheet_id
            }
        }
    }
`;
