import { gql } from '@apollo/client';
import { FmpUserFields } from 'apollo/fragments';

export const GET_ADMIN_GENERAL_PERF_PAGE = gql`
    query getAdminGeneralPerfPage($start_date: Date!, $end_date: Date!, $group_by: String!, $fmp_user_ids: [Int]!) {
        adminGeneralPerfPage(
            start_date: $start_date
            end_date: $end_date
            group_by: $group_by
            fmp_user_ids: $fmp_user_ids
        ) {
            dps_total

            total_prelim
            total_final

            currentWeekStats {
                count_new_paper
                count_new_dme
                total_prelim
                count_new_factligne
            }

            dpGraphPoints {
                label

                count
                count_new
                count_rev

                count_new_paper
                count_new_dme

                count_new_sm
                count_new_fd
                count_new_rmx
                count_new_vac
                count_new_sal

                count_new_myle
                count_new_kinlogix
                count_new_omnimed

                total_prelim
                total_final
                total_diff

                avg_total_prelim
                avg_total_final
                avg_total_diff

                count_new_factligne
                avg_new_factligne
            }

            actGraphPoints {
                codefact

                count
                count_new
                count_rev

                total_prelim
                total_final
                total_diff

                percentage_count
                percentage_total_prelim
                percentage_total_final
            }
        }
    }
`;

export const GET_FMP_USERS = gql`
    ${FmpUserFields}

    query GetFmpUsers {
        fmpUsers {
            ...FmpUserFields
        }
    }
`;
