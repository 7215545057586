import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdFields } from 'apollo/fragments';

export const GET_MDS = gql`
    ${MdFields}

    query GetMds($id: Int, $first_name: String, $last_name: String) {
        mds(id: $id, first_name: $first_name, last_name: $last_name) {
            ...MdFields

            MdBillingInfo {
                is_approved
                billing_method
                billing_sheet_id
            }
        }
    }
`;

export const GET_FACTLIGNE_SUM = gql`
    query GetFactligneTotal($licence: Int!, $datefactFrom: String!, $datefactTo: String!) {
        factligneSum(licence: $licence, datefactFrom: $datefactFrom, datefactTo: $datefactTo) {
            monthName
            year
            total_billed
        }
    }
`;
