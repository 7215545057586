import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams, useParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import FileSaver from 'file-saver';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import { Autocomplete, Breadcrumbs, Button, Grid, TextField, Typography, IconButton } from '@mui/material';
import { DatePicker } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getMdHistoricalTransactionsTableColumns } from './grid-columns';

// MUI Icons
import { NavigateNext as NavigateNextIcon, Clear as ClearIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_MD_HISTORICAL_TRANSACTIONS, GET_MDS } from './apollo-queries';
import { Api } from 'services';

// Redux

// Utils

// Constants
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Reconstitution balances SIPT
    </Typography>,
    <Typography key="1" fontSize={15}>
        Historique médecins
    </Typography>,
];

type RouteParams = 'id';

const DEBOUNCE_DELAY = 500;

export default function MdHistoricalTransactionList() {
    const { id } = useParams<RouteParams>();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter - Start date
    const search_start_date = searchParams.get('start_date');
    const [startDateFilter, setStartDateFilter] = useState<Moment | null>(
        search_start_date ? moment(search_start_date) : null,
    );
    const handleChangeStartDate = (value) => {
        setStartDateFilter(value);
    };
    const handleClearStartDate = () => {
        setStartDateFilter(null);
    };

    // Filter - End date
    const search_end_date = searchParams.get('end_date');
    const [endDateFilter, setEndDateFilter] = useState<Moment | null>(search_end_date ? moment(search_end_date) : null);
    const handleChangeEndDate = (value) => {
        setEndDateFilter(value);
    };
    const handleClearEndDate = () => {
        setEndDateFilter(null);
    };

    // Filter - Md ID
    const search_md_id = searchParams.get('md_id');
    const [mdIdFilter, setMdIdFilter] = useState<number | null>(search_md_id ? parseInt(search_md_id) : null);
    const handleChangeMdId = (e, value: any) => {
        const mdId = value?.id;
        setMdIdFilter(mdId || null);
    };

    // Apollo queries
    const { loading: mdsLoading, error: mdsError, data: mdsData } = useQuery(GET_MDS);
    const {
        loading: mdHistoricalTransactionsLoading,
        error: mdHistoricalTransactionsError,
        data: mdHistoricalTransactionsData,
    } = useQuery(GET_MD_HISTORICAL_TRANSACTIONS, {
        variables: {
            md_id: mdIdFilter,
            start_date: startDateFilter,
            end_date: endDateFilter,
        },
        skip: !mdIdFilter,
    });

    // Md bill file download
    const [mdHistoricalTransactionIdsOfCurrentFileDownloads, setMdBillIdsOfCurrentFileDownloads] = useState<number[]>(
        [],
    );
    const handleDownloadMdBill = useCallback(
        async (md_bill_id: number) => {
            setMdBillIdsOfCurrentFileDownloads([...mdHistoricalTransactionIdsOfCurrentFileDownloads, md_bill_id]);

            try {
                const res = await Api.get('storage/md-bill', {
                    responseType: 'blob',
                    timeout: 1000 * 20, // 20 seconds
                    params: {
                        md_bill_id: md_bill_id,
                    },
                });

                const filename = `${md_bill_id}.pdf`;
                FileSaver.saveAs(res.data, filename);
            } catch (e) {
            } finally {
                setMdBillIdsOfCurrentFileDownloads(
                    mdHistoricalTransactionIdsOfCurrentFileDownloads.filter((item) => item === md_bill_id),
                );
            }
        },
        [setMdBillIdsOfCurrentFileDownloads, mdHistoricalTransactionIdsOfCurrentFileDownloads],
    );

    // Columns
    const mdHistoricalTransactionsColumns = useMemo(
        () =>
            getMdHistoricalTransactionsTableColumns(
                handleDownloadMdBill,
                mdHistoricalTransactionIdsOfCurrentFileDownloads,
            ),
        [handleDownloadMdBill, mdHistoricalTransactionIdsOfCurrentFileDownloads],
    );
    const [pageSize, setPageSize] = React.useState<number>(2500);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Historique médecins
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container spacing={3} mb={2.5}>
                <Grid item xs={2.5}>
                    <Autocomplete
                        // disablePortal
                        options={mdsData?.mds || []}
                        loading={mdsLoading}
                        getOptionLabel={(option: any) => {
                            const { first_name, last_name, id } = option;

                            if (option === mdIdFilter) {
                                const selectedMd = (mdsData?.mds || []).find((md) => md.id === option);

                                return selectedMd
                                    ? `${selectedMd.last_name}, ${selectedMd.first_name} (${selectedMd.id})`
                                    : '';
                            } else {
                                return `${last_name}, ${first_name} (${id})`;
                            }
                        }}
                        value={mdIdFilter}
                        onChange={handleChangeMdId}
                        isOptionEqualToValue={(option: any, value) => option?.id === value}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth label="Médecin" />}
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <DatePicker
                        label={'Date de début'}
                        value={startDateFilter}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: startDateFilter && (
                                <IconButton onClick={handleClearStartDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <DatePicker
                        label={'Date de fin'}
                        value={endDateFilter}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: endDateFilter && (
                                <IconButton onClick={handleClearEndDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>
            </Grid>

            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={mdHistoricalTransactionsData?.mdHistoricalTransactions || []}
                columns={mdHistoricalTransactionsColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: {
                        right: ['actions'],
                    },
                    sorting: {
                        // sortModel: [{ field: 'txn_recorded_at', sort: 'desc' }],
                    },
                }}
                loading={mdHistoricalTransactionsLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />
        </>
    );
}
