import React from 'react';

// Components
import { Grid, Paper, Typography } from '@mui/material';

export default function Dashboard() {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Paper
                        sx={{
                            px: 2,
                            py: 2,
                        }}
                    >
                        <Typography variant="h6">Claims received today</Typography>
                        <Typography variant="h5">220</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper
                        sx={{
                            px: 2,
                            py: 2,
                        }}
                    >
                        <Typography variant="h6">Claims processed today</Typography>
                        <Typography variant="h5">220</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper
                        sx={{
                            px: 2,
                            py: 2,
                        }}
                    >
                        <Typography variant="h6">Claims drafted</Typography>
                        <Typography variant="h5">220</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper
                        sx={{
                            px: 2,
                            py: 2,
                        }}
                    >
                        <Typography variant="h6">Problematic claims</Typography>
                        <Typography variant="h5">220</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
