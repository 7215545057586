import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import ReactJson from 'react-json-view';

// MUI Components
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// MUI Grid

// MUI Icons

// Components

// Apollo

// Redux

// Utils
import { formatNumber } from 'utils';

// Constants
interface BracketDetailsDialogProps {
    selectedDetails: any;
    handleCloseBracketDetailsDialog: () => void;
    billingMethod: BillingMethods;
}

interface BracketDetailsDialogContentProps {
    selectedDetails: any;
    handleCloseBracketDetailsDialog: () => void;
    billingMethod: BillingMethods;
}

type BillingMethods = 'acte' | 'ligne' | null;

export default function BracketDetailsDialog({
    selectedDetails,
    handleCloseBracketDetailsDialog,
    billingMethod,
}: BracketDetailsDialogProps) {
    const isDialogOpen = selectedDetails ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'lg'} open={isDialogOpen} onClose={handleCloseBracketDetailsDialog}>
            <BracketDetailsDialogContent
                selectedDetails={selectedDetails}
                handleCloseBracketDetailsDialog={handleCloseBracketDetailsDialog}
                billingMethod={billingMethod}
            />
        </Dialog>
    );
}

const BracketDetailsDialogContent = ({
    selectedDetails,
    handleCloseBracketDetailsDialog,
    billingMethod,
}: BracketDetailsDialogContentProps) => {
    const theme = useTheme();

    if (!selectedDetails) return <></>;

    let title = '';
    if (billingMethod === 'acte') {
        const { from, to } = selectedDetails;
        title = `Bracket de ${formatNumber(from, { cents: true })} $ à ${formatNumber(to, {
            cents: true,
        })} - Détaillé du calcul du nombre de demandes`;
    } else if (billingMethod === 'ligne') {
        const { lot_id } = selectedDetails;
        title = `Lot #${lot_id} - Détaillé du calcul du nombre de demandes/lignes`;
    }

    return (
        <>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                <ReactJson
                    src={selectedDetails}
                    theme={theme.palette.mode === 'light' ? 'rjv-default' : 'railscasts'}
                    collapsed={2}
                    indentWidth={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseBracketDetailsDialog}>Fermer</Button>
            </DialogActions>
        </>
    );
};
