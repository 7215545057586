import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { PosteFields } from 'apollo/fragments';

export const GET_POSTES = gql`
    ${PosteFields}

    query GetPostes {
        postes {
            ...PosteFields
        }
    }
`;
