import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getPratiqueGroupeMdTableColumns } from './grid-columns';

// MUI Icons
import {} from '@mui/icons-material';

// Components
import EtablissementDialog from '../EtablissementDialog';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils

// Constants

interface PratiqueGroupeMdTabPanelProps {
    tabIndexAsString: string;
    medecin: any;
}

export default function PratiqueGroupeMdTabPanel({ tabIndexAsString, medecin }: PratiqueGroupeMdTabPanelProps) {
    // Etablissement Dialog
    const [selectedEtablissement, setSelectedEtablissement] = useState<any | null>(null);
    const handleOpenEtablissementDialog = (activiteContexte) => {
        setSelectedEtablissement(activiteContexte);
    };
    const handleCloseEtablissementDialog = () => {
        setSelectedEtablissement(null);
    };

    // Columns
    const columns = useMemo(() => getPratiqueGroupeMdTableColumns(handleOpenEtablissementDialog), []);

    return (
        <TabPanel value={tabIndexAsString}>
            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={medecin.PratiqueGroupeMd}
                columns={columns}
                pagination
                pageSize={25}
                rowsPerPageOptions={[25, 50, 75, 100]}
                disableSelectionOnClick
                autoHeight
                onRowClick={(params, event, details) => {}}
            />
            <EtablissementDialog
                selectedEtablissement={selectedEtablissement}
                handleCloseEtablissementDialog={handleCloseEtablissementDialog}
            />
        </TabPanel>
    );
}
