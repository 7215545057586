import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    POSTE_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
    ACTIVE_COLUMN_WIDTH,
    LONG_STRING_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getGrpTableColumns = (): GridColDefPro[] => [
    {
        field: 'numero',
        headerName: '# Groupe',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'actif',
        headerName: 'Actif',
        width: ACTIVE_COLUMN_WIDTH,
        valueGetter: (params) => {
            return params.row.AgenceMd?.actif;
        },
        renderCell: ({ value }) => {
            return value === undefined ? <Typography variant="body2">NULL</Typography> : <BooleanIcon value={value} />;
        },
    },
    {
        field: 'denomination',
        headerName: 'Dénomination',
        width: LONG_STRING_COLUMN_WIDTH,
    },
    {
        field: 'incorp',
        headerName: 'Incorporé',
        width: 120,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'sm',
        headerName: 'SM',
        width: BOOLEAN_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.AgenceMd?.sm,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'rmx',
        headerName: 'RMX',
        width: BOOLEAN_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.AgenceMd?.rmx,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'vac',
        headerName: 'VAC',
        width: BOOLEAN_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.AgenceMd?.vac,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'sal',
        headerName: 'SAL',
        width: BOOLEAN_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.AgenceMd?.sal,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'debut',
        headerName: 'Début',
        width: 200,
        valueFormatter: ({ value }) => moment(value as any, 'x').format(SQL_DATETIME),
    },
    {
        field: 'fin',
        headerName: 'Fin',
        width: 200,
        valueFormatter: ({ value }) => moment(value as any, 'x').format(SQL_DATETIME),
    },
];
