import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    Container,
    useTheme,
    lighten,
    Alert,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { useForm, Controller, useController } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

// Services
import { Auth } from 'services';

// Providers
import { useAuth } from 'providers/AuthProvider/context';

// Router
import { LOGIN_ROUTE, MAIN_PAGE_WHEN_AUTHENTICATED } from 'router';

// Components
import GuestLayout from 'components/GuestLayout';

const LOGIN_ADMIN = gql`
    mutation LoginAdmin($email: String!, $password: String!) {
        loginAdmin(email: $email, password: $password) {
            type
            token
            expiresAt
            expiresIn
        }
    }
`;

export default function Login() {
    const navigate = useNavigate();
    const auth = useAuth();
    const theme = useTheme();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const [loginAdmin, { loading: loginAdminLoading, error: loginAdminError, data: loginAdminData }] = useMutation(
        LOGIN_ADMIN,
        {
            onCompleted: async ({ loginAdmin }) => {
                if (loginAdmin?.token) {
                    await auth.login(loginAdmin.token);
                    // navigate(LOGIN_ROUTE);
                }
            },
        },
    );

    if (loginAdminError) {
        console.log(loginAdminError);
        console.log(loginAdminError.graphQLErrors);
        console.log(loginAdminError.graphQLErrors[0].extensions.code);
    }

    const onSubmit = async (data) => {
        loginAdmin({
            variables: {
                email: data.email,
                password: data.password,
            },
        });
    };

    return (
        <GuestLayout>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Box display="flex" justifyContent="center">
                            <img
                                src="/img/logo-noir.jpg"
                                alt=""
                                style={{
                                    display: 'block',
                                    height: '70px',
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} mb={4}>
                        <Typography variant="h6" textAlign="center">
                            Bienvenue sur le portail!
                        </Typography>

                        <Typography variant="body1" textAlign="center" mt={1}>
                            Veuillez vous connecter pour continuer.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <TextField {...field} label="Courriel" size="small" fullWidth type="email" />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <TextField label="Mot de passe" size="small" fullWidth {...field} type="password" />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox />} label="Se souvenir de moi" />
                    </Grid>

                    {loginAdminError && (
                        <Grid item xs={12} mt={2}>
                            <Typography color="red" textAlign="center">
                                {loginAdminError.message}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} mt={2}>
                        <Button type="submit" variant="contained" fullWidth disabled={loginAdminLoading}>
                            Connexion
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </GuestLayout>
    );
}
