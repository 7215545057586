import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider/context';
import { LOGIN_ROUTE, MAIN_PAGE_WHEN_AUTHENTICATED } from 'router';

export default function NotFoundPage(props) {
    const auth = useAuth();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100vw"
            height="100vh"
        >
            <Typography variant="h5" fontSize={25} mb={2}>
                404 - Page introuvable
            </Typography>

            {auth.admin ? (
                <Button
                    size="small"
                    variant="text"
                    color="primary"
                    component={RouterLink}
                    to={MAIN_PAGE_WHEN_AUTHENTICATED}
                >
                    Retourner à mon tableau de bord
                </Button>
            ) : (
                <Button size="small" variant="text" color="primary" component={RouterLink} to={LOGIN_ROUTE}>
                    Me connecter
                </Button>
            )}
        </Box>
    );
}
