import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { SizeMe } from 'react-sizeme';

// MUI Components
import {
    Avatar,
    Box,
    Breadcrumbs,
    ButtonBase,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Link,
    Paper,
    Typography,
    Button,
    Chip,
    Theme,
    Drawer,
    Tab,
    TextField,
    Autocomplete,
    Select,
    MenuItem,
} from '@mui/material';
import { DateTimePicker } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { NavigateNext as NavigateNextIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperLib, Navigation, Pagination, Scrollbar, A11y, Mousewheel, Keyboard } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Components

// React PDF
import { Document, LoadingProcessData, Outline, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

// Apollo
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';

// Redux
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { red } from '@mui/material/colors';

// Utils
import { capitalize, getScanStatusText } from 'utils/index';
import { getScanStatusColor } from 'utils';

// Constants
import { DATETIME_COLUMN_WIDTH, HALF_NAME_COLUMN_WIDTH } from 'constants/DataGrid';
import { SQL_DATETIME } from 'constants/Moment';
import { SMALL_STRING_COLUMN_WIDTH } from 'constants/DataGrid';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFDocumentWrapper = styled('div')({
    '> div': {
        width: '100% !important',
        height: 'auto !important',
    },
});

const INITIAL_LOADING_DOCUMENT_PROGRESS = {
    loaded: 0,
    total: 0,
};

const GET_SCANS = gql`
    query GetScans {
        scans {
            id
            created_at
            updated_at
            gdrive_file_created_at
            gdrive_file_updated_at
            file_path
            nb_pages
            medecin_licence
            period_start
            period_end
            admin_id
            status
            Medecin {
                licence
                prenom
                nom
                male
                specialiste
                lieutra
                retraite
            }
            Admin {
                id
                first_name
                last_name
                avatar_url
            }
        }
    }
`;

const GET_SCAN = gql`
    query GetScan($id: Int!) {
        scan(id: $id) {
            id
            created_at
            updated_at
            gdrive_file_created_at
            gdrive_file_updated_at
            file_path
            nb_pages
            medecin_licence
            period_start
            period_end
            admin_id
            status
            Medecin {
                licence
                prenom
                nom
                male
                specialiste
                lieutra
                retraite
            }
            Admin {
                id
                first_name
                last_name
                avatar_url
            }
        }
    }
`;

const UPDATE_SCAN = gql`
    mutation UpdateScan(
        $id: Int
        $medecin_licence: Int
        $period_start: String
        $period_end: String
        $admin_id: Int
        $status: String
    ) {
        updateScan(
            id: $id
            medecin_licence: $medecin_licence
            period_start: $period_start
            period_end: $period_end
            admin_id: $admin_id
            status: $status
        ) {
            id
            # created_at
            # updated_at
            # gdrive_file_created_at
            # gdrive_file_updated_at
            # file_path
            # nb_pages
            # medecin_licence
            # period_start
            # period_end
            # admin_id
            # status
            # Medecin {
            #     licence
            #     prenom
            #     nom
            #     male
            #     specialiste
            #     lieutra
            #     retraite
            # }
        }
    }
`;

const GET_MEDECINS = gql`
    query GetMedecins {
        medecins {
            licence
            prenom
            nom
        }
    }
`;

const GET_ADMINS = gql`
    query GetAdmins {
        admins {
            id
            first_name
            last_name
        }
    }
`;

// mutation {
//     deleteCategory(id: 2) {
//         name
//     }
// }

// type RouteParams = '' | '';

export default function ScanList() {
    const { pathname } = useLocation();
    // const {} = useParams<RouteParams>();
    const theme = useTheme();
    let navigate = useNavigate();

    const { loading, error, data } = useQuery(GET_SCANS);

    const [getScan, { loading: scanLoading, error: scanError, data: scan }] = useLazyQuery(GET_SCAN, {
        onCompleted: ({ scan }) => {
            setSelectedScan(scan);
        },
        notifyOnNetworkStatusChange: true, // Needs to be set to true. If not set to true, clicking on a scan, closing the drawer and then reclicking on the same scan will not trigger the drawer open
    });

    const [selectedScan, setSelectedScan] = useState<any | null>(null);

    const handleKeyDown = useCallback((event) => {
        switch (event.keyCode) {
            case 37:
                // * Now handled directly by the Swiper Keyboard module
                // Left arrow
                // selectPrevScan();
                break;
            case 38:
                // Up arrow
                event.preventDefault();
                break;
            case 39:
                // * Now handled directly by the Swiper Keyboard module
                // Right arrow
                // selectNextScan();
                break;
            case 40:
                // Down arrow
                event.preventDefault();
                break;
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const breadcrumbs = [
        <Typography key="1" fontSize={15}>
            Scan dispatcher
        </Typography>,
    ];

    const columns = useMemo(() => getColumns(theme, navigate), [theme, navigate]);

    if (loading) return <Typography>Chargement...</Typography>;
    if (error) return <Typography>Error! {error.message}</Typography>;

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Scan dispatcher
            </Typography>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 0, mb: 5 }}>
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataGridPro
                        getRowId={(row) => row.id}
                        rows={data.scans || []}
                        columns={columns}
                        pageSize={25}
                        rowsPerPageOptions={[25, 50, 75, 100]}
                        checkboxSelection
                        disableSelectionOnClick
                        // disableColumnMenu
                        // rowHeight={100}
                        // density="comfortable"
                        // showColumnRightBorder
                        // showCellRightBorder
                        autoHeight
                        // hideFooter
                        onRowClick={(params, event, details) => {
                            getScan({ variables: { id: params.row.id } });
                        }}
                    />
                </Grid>
            </Grid>

            <EditDrawer selectedScan={selectedScan} onClose={() => setSelectedScan(null)} />
        </>
    );
}

const EditDrawer = ({ selectedScan, onClose }: { selectedScan: any | null; onClose: () => void }) => {
    const [selectedScanPageCount, setSelectedScanPageCount] = useState(0);
    const [selectedScanPageNumber, setSelectedScanPageNumber] = useState(1);
    const [loadingDocumentProgress, setLoadingDocumentProgress] = useState<{ loaded: number; total: number }>(
        INITIAL_LOADING_DOCUMENT_PROGRESS,
    );

    const pagesRef = useRef<any[]>([]);

    const selectPage = useCallback(
        (wantedPage) => {
            if (wantedPage >= 1 && wantedPage <= selectedScanPageCount) {
                setSelectedScanPageNumber(wantedPage);
            }
        },
        [setSelectedScanPageNumber, selectedScanPageCount],
    );

    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        console.log('loading stopped');
        setSelectedScanPageCount(numPages);
        setSelectedScanPageNumber(1);
        setLoadingDocumentProgress(INITIAL_LOADING_DOCUMENT_PROGRESS);
    };
    const onDocumentLoadProgress = (data: LoadingProcessData) => {
        setLoadingDocumentProgress(data);
    };

    const { loading: getMedecinsLoading, error: getMedecinsError, data: medecinsData } = useQuery(GET_MEDECINS);

    const { loading: getAdminsLoading, error: getAdminsError, data: adminsData } = useQuery(GET_ADMINS);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({});

    useEffect(() => {
        console.log('form prep');

        const selectedMedecin = (medecinsData?.medecins || []).find(
            (medecin) => medecin.licence === selectedScan?.medecin_licence,
        );

        const selectedAdmin = (adminsData?.admins || []).find((admin) => admin.id === selectedScan?.admin_id);

        setValue('medecin', selectedMedecin || null);
        setValue('period_start', selectedScan?.period_start ? moment(selectedScan.period_start, 'x').toDate() : null);
        setValue('period_end', selectedScan?.period_end ? moment(selectedScan.period_end, 'x').toDate() : null);
        setValue('status', selectedScan?.status);
        setValue('admin', selectedAdmin || null);
    }, [selectedScan, medecinsData, adminsData, setValue]);

    const [updateScan, { loading: updateScanLoading, error: updateScanError, data: updateScanData }] = useMutation(
        UPDATE_SCAN,
        {
            refetchQueries: [GET_SCANS],
        },
    );

    const onSubmit = (data) => {
        console.log(data);

        // const formData = new FormData();

        // for (const name in data) {
        //     formData.append(name, data[name]);
        // }

        updateScan({
            variables: {
                id: selectedScan.id,
                medecin_licence: data.medecin?.licence || null,
                period_start: data.period_start,
                period_end: data.period_end,
                admin_id: data.admin?.id || null,
                status: data.status,
            },
        });

        onClose();
    };

    return (
        <Drawer
            anchor="right"
            open={selectedScan ? true : false}
            onClose={onClose}
            PaperProps={{ sx: { mt: '64px', height: 'calc(100vh - 64px) !important', p: 3 } }}
        >
            <Grid container spacing={2} width="20vw">
                <Grid item xs={12}>
                    {updateScanError && <Typography>Erreur lors de l'envoi! {updateScanError.message}</Typography>}

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="medecin"
                                    control={control}
                                    render={({ field, ...props }) => {
                                        console.log(field);
                                        console.log(props);
                                        return (
                                            <Autocomplete
                                                disablePortal
                                                options={medecinsData.medecins || []}
                                                getOptionLabel={(option: any) =>
                                                    `${option.nom}, ${option.prenom} (${option.licence})`
                                                }
                                                {...field}
                                                {...props}
                                                // onChange={(e, value: any) => field.onChange(value.licence)}
                                                onChange={(e, value: any) => field.onChange(value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" fullWidth label="Médecin" />
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="period_start"
                                    control={control}
                                    render={({ field }) => (
                                        <DateTimePicker
                                            label="Premier patient vu le"
                                            {...field}
                                            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="period_end"
                                    control={control}
                                    render={({ field }) => (
                                        <DateTimePicker
                                            label="Dernier patient vu le"
                                            {...field}
                                            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="admin"
                                    control={control}
                                    render={({ field, ...props }) => {
                                        return (
                                            <Autocomplete
                                                disablePortal
                                                options={adminsData.admins || []}
                                                getOptionLabel={(option: any) =>
                                                    `${option.last_name}, ${option.first_name}`
                                                }
                                                {...field}
                                                {...props}
                                                onChange={(e, value: any) => field.onChange(value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" fullWidth label="Assigné à" />
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField label="Statut" size="small" fullWidth select {...field}>
                                            <MenuItem value="todo">À faire</MenuItem>
                                            <MenuItem value="done">Facturé</MenuItem>
                                        </TextField>
                                    )}
                                />
                            </Grid>
                        </Grid>

                        {/* <TextField label="Assigné à" size="small"></TextField>
                        <TextField label="À faire le" size="small"></TextField> */}
                        <Grid item xs={12} container justifyContent="flex-end" mt={4}>
                            <Button type="submit" variant="contained" disabled={updateScanLoading}>
                                Sauvegarder
                            </Button>
                        </Grid>
                    </form>

                    <br />
                    <Divider />
                    <br />

                    {selectedScan && (
                        <Document
                            file={selectedScan.file_path}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadProgress={onDocumentLoadProgress}
                            loading={() => {
                                return (
                                    <Typography>
                                        Chargement des thumbnails du scan...{' '}
                                        {loadingDocumentProgress.loaded == 0
                                            ? '0'
                                            : (
                                                  (loadingDocumentProgress.loaded / loadingDocumentProgress.total) *
                                                  100
                                              ).toFixed(2)}
                                        %
                                    </Typography>
                                );
                            }}
                        >
                            <Grid item xs={12} container spacing={2}>
                                {Array.from(new Array(selectedScanPageCount), (el, index) => (
                                    <Grid item xs={12} key={index} ref={(ref) => (pagesRef.current[index] = ref)}>
                                        <ButtonBase
                                            onClick={() => selectPage(index + 1)}
                                            sx={{ display: 'block', width: '100%' }}
                                        >
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    border: '2px',
                                                    borderColor:
                                                        selectedScanPageNumber == index + 1 ? 'transparent' : 'black',
                                                }}
                                            >
                                                <SizeMe>
                                                    {({ size }) => (
                                                        <Page
                                                            pageNumber={index + 1}
                                                            renderAnnotationLayer={false}
                                                            renderInteractiveForms={false}
                                                            renderTextLayer={false}
                                                            // canvasRef={(ref) => (selectedScanPagesRefs.current[index] = ref)}
                                                            width={size.width ? size.width : 1}
                                                            loading="Chargement de la page..."
                                                        />
                                                    )}
                                                </SizeMe>
                                            </Paper>
                                        </ButtonBase>
                                        <Typography variant="subtitle2" textAlign="center" mt={1}>
                                            Page {index + 1}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Document>
                    )}
                </Grid>
            </Grid>
        </Drawer>
    );
};

const getColumns = (theme: Theme, navigate: NavigateFunction): GridColDefPro[] => [
    {
        field: 'id',
        headerName: '# Scan',
        width: 85,
    },
    {
        field: 'gdrive_file_created_at',
        headerName: 'Scanné le',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params) => {
            const scannedOn = params.value as any;

            return moment(scannedOn, 'x').format(SQL_DATETIME);
        },
    },
    {
        field: 'nb_pages',
        headerName: 'Nb. pages',
        width: 100,
    },
    {
        field: 'period_start',
        headerName: 'Début période',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params) => {
            const periodStart = params.value as any;

            return moment(periodStart, 'x').format(SQL_DATETIME);
        },
    },
    {
        field: 'period_end',
        headerName: 'Fin période',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params) => {
            const periodEnd = params.value as any;

            return moment(periodEnd, 'x').format(SQL_DATETIME);
        },
    },
    {
        field: 'medecin_licence',
        headerName: 'MD Licence',
        width: 120,
    },
    {
        field: 'medecinPrenom',
        headerName: 'MD Prénom',
        width: HALF_NAME_COLUMN_WIDTH,
        valueGetter: (params) => {
            return params.row.Medecin?.prenom;
        },
    },
    {
        field: 'medecinNom',
        headerName: 'MD Nom',
        width: HALF_NAME_COLUMN_WIDTH,
        valueGetter: (params) => {
            return params.row.Medecin?.nom;
        },
    },
    {
        field: 'status',
        headerName: 'Statut',
        renderCell: (params) => {
            const { status } = params.row as any;

            return (
                <>
                    <Chip
                        label={getScanStatusText(status)}
                        size="small"
                        sx={{
                            bgcolor: getScanStatusColor(theme, status).bgcolor,
                            color: getScanStatusColor(theme, status).color,
                        }}
                    />
                </>
            );
        },
    },
    {
        field: 'admin_id',
        headerName: 'Assigné à',
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => {
            return params.row.Admin?.first_name;
        },
        renderCell: (params) => {
            const { Admin } = params.row as any;

            if (!Admin) return <></>;
            else
                return (
                    <>
                        <Avatar src={Admin.avatar_url} sx={{ width: 28, height: 28, mr: 1 }} />
                        <Typography variant="body2">
                            {Admin.first_name} {Admin.last_name.charAt(0)}.
                        </Typography>
                    </>
                );

            // return (
            // <AvatarGroup
            //     max={4}
            //     sx={{
            //         '& .MuiAvatarGroup-avatar': {
            //             height: '27px',
            //             width: '27px',
            //             typography: 'body1',
            //         },
            //     }}
            // >
            // {assigned_to.map((user, idx) => {
            //     const { image_url } = user;

            //     return <Avatar src={image_url} />;
            // })}
            // </AvatarGroup>
            // );
        },
    },
    // {
    //     field: 'status',
    //     headerName: 'Statut',
    //     width: 150,
    //     renderCell: (params) => {
    //         const { status } = params.row as RootState['doctors'][0];

    //         let backgroundColor = getBadgeBackgroundColorFromDoctorStatus(theme, status);
    //         let textColor = getBadgeTextColorFromDoctorStatus(theme, status);

    //         return (
    //             <>
    //                 <Chip
    //                     label={getDoctorStatusName(status, 'fr')}
    //                     size="small"
    //                     sx={{ backgroundColor: backgroundColor, color: textColor }}
    //                 />
    //             </>
    //         );
    //     },
    // },
    // {
    //     field: 'total_claims_ramq',
    //     headerName: 'Demandes RAMQ',
    //     width: 150,
    //     valueGetter: (params) => {
    //         return getRandomNumberBetween(1000, 10000);
    //     },
    //     valueFormatter: (params) => {
    //         const { value } = params;

    //         return `${formatNumber(value as number)}`;
    //     },
    // },
    // {
    //     field: 'total_ramq',
    //     headerName: 'Facturé RAMQ',
    //     width: 150,
    //     valueGetter: (params) => {
    //         return getRandomNumberBetween(30000, 1000000, 2);
    //     },
    //     valueFormatter: (params) => {
    //         const { value } = params;

    //         return `${formatNumber(value as number)}$`;
    //     },
    // },
    // {
    //     field: 'total_fmp',
    //     headerName: 'Revenu FMP',
    //     width: 150,
    //     valueGetter: (params) => {
    //         return getRandomNumberBetween(30000, 1000000, 2) * 0.01;
    //     },
    //     valueFormatter: (params) => {
    //         const { value } = params;

    //         return `${formatNumber(value as number)}$`;
    //     },
    // },
    // {
    //     field: 'actions',
    //     headerName: 'Actions',
    //     width: 150,
    //     sortable: false,
    //     filterable: false,
    //     renderCell: (params) => {
    //         const { id: doctorId } = params.row as RootState['doctors'][0];

    //         return (
    //             <>
    //                 <IconButton size="small" onClick={() => navigate(`${doctorId}/edit/overview`)}>
    //                     <EditIcon fontSize="small" />
    //                 </IconButton>
    //                 <IconButton size="small" onClick={() => navigate(`${doctorId}/edit/overview`)}>
    //                     <DeleteIcon fontSize="small" />
    //                 </IconButton>
    //             </>
    //         );
    //     },
    // },
];
