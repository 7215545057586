export const CHART_COLORS = [
    '#3F51B5',
    '#03A9F4',
    '#4CAF50',
    '#F9CE1D',
    '#FF9800',
    '#9C27B0',
    '#F44336',
    '#4ECDC4',
    '#00E396',
    // '#E91E63',
    // '#F86624',
    // '#5A2A27',
    // '#2B908F',
    // '#546E7A',
];

export const COLORS = {
    VIOLET: '#3F51B5',
    LIGHT_BLUE: '#03A9F4',
    GREEN: '#4CAF50',
    YELLOW: '#F9CE1D',
    ORANGE: '#FF9800',
    PINK: '#9C27B0',
    ORANGE_RED: '#F44336',
    TURQUOIZE: '#4ECDC4',
    LIME: '#00E396',
};
