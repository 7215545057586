import moment from 'moment';

// MUI Components
import { Box, IconButton, Tooltip, CircularProgress, Typography, Link, Chip } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    MenuOpen as MenuOpenIcon,
    Download as DownloadIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    DATETIME_COL_DEFAULTS,
    AMT_COL_DEFAULTS,
    ID_COL_DEFAULTS,
    LICENCE_COL_DEFAULTS,
    BOOLEAN_COL_DEFAULTS,
    DATE_COL_DEFAULTS,
    ENTITY_NAME_COL_DEFAULTS,
    CENTERED_COL_DEFAULTS,
    BANK_ACCOUNT_NAME_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';
import ColoredNumber from 'components/ColoredNumber';

export const getPaymentsTableColumns = (): GridColDefPro[] => [
    {
        ...ID_COL_DEFAULTS,
        field: 'id',
        headerName: 'ID Paiement',
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'created_at',
        headerName: 'Créé le',
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'updated_at',
        headerName: 'Modifié le',
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'deleted_at',
        headerName: 'Supprimé le',
    },
    {
        ...LICENCE_COL_DEFAULTS,
        field: 'entity_id',
        headerName: 'ID client',
    },
    {
        ...ENTITY_NAME_COL_DEFAULTS,
        field: 'entity_name',
        headerName: 'Nom client',
    },
    {
        ...CENTERED_COL_DEFAULTS,
        field: 'entity_type',
        headerName: 'Type client',
        renderCell: ({ value }) => (
            <Chip
                label={value === 'md' ? 'Médecin' : 'Clinique'}
                color={value === 'md' ? 'primary' : 'info'}
                variant="outlined"
                size="small"
            />
        ),
    },

    {
        ...DATETIME_COL_DEFAULTS,
        field: 'txn_recorded_at',
        headerName: 'Enregistré le',
    },

    // {
    //     ...DATETIME_COL_DEFAULTS,
    //     field: 'method',
    //     headerName: 'Méthode paiement',
    // },
    {
        ...CENTERED_COL_DEFAULTS,
        field: 'METHOD_NAME',
        headerName: 'Méthode',
    },

    {
        ...CENTERED_COL_DEFAULTS,
        field: 'ref_no',
        headerName: 'No ref.',
    },

    {
        ...AMT_COL_DEFAULTS,
        field: 'amt',
        headerName: 'Montant',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
        renderCell: ({ value, row }) => <ColoredNumber value={value} />,
    },

    {
        ...DATE_COL_DEFAULTS,
        field: 'payment_date',
        headerName: 'Date paiement',
        width: 120,
    },

    {
        ...ID_COL_DEFAULTS,
        field: 'bank_account_id',
        headerName: 'ID banque',
    },
    {
        ...BANK_ACCOUNT_NAME_COL_DEFAULTS,
        field: 'bank_account_name',
        headerName: 'Compte bancaire',
    },

    {
        ...CENTERED_COL_DEFAULTS,
        field: 'qb_payment_id',
        headerName: 'Paiement QB',
        width: 125,
        renderCell: ({ value }) => (
            <IconButton
                href={`https://app.qbo.intuit.com/app/recvpayment?txnId=${value}`}
                target="_blank"
                size="small"
                color="primary"
            >
                <OpenInNewIcon fontSize="inherit" />
            </IconButton>
        ),
    },

    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'status',
        headerName: 'Statut',
        valueGetter: ({ row }) => (row.deleted_at ? false : true),
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...ID_COL_DEFAULTS,
        field: 'new_payment_id',
        headerName: 'ID nouveau paiement',
        width: 150,
    },

    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'email_sent',
        headerName: 'Email envoyé',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'last_email_sent_at',
        headerName: 'Dernier email',
        valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATETIME) : null),
    },

    {
        field: 'private_note',
        headerName: 'Note privée',
        width: 200,
    },
];
