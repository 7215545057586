import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdHorsRamqClaimFields, MdFields, HorsRamqClaimTypeFields, HorsRamqClaimSubtypeFields } from 'apollo/fragments';

export const GET_MD_HORS_RAMQ_CLAIMS = gql`
    ${MdHorsRamqClaimFields}
    ${MdFields}
    ${HorsRamqClaimTypeFields}
    ${HorsRamqClaimSubtypeFields}

    query GetMdHorsRamqClaims($md_id: Int, $start_date: DateTime, $end_date: DateTime) {
        mdHorsRamqClaims(md_id: $md_id, start_date: $start_date, end_date: $end_date) {
            ...MdHorsRamqClaimFields

            md {
                ...MdFields
            }

            horsRamqClaimType {
                ...HorsRamqClaimTypeFields
            }

            horsRamqClaimSubtype {
                ...HorsRamqClaimSubtypeFields
            }
        }
    }
`;

export const GET_MDS = gql`
    ${MdFields}

    query GetMds {
        mds {
            ...MdFields
        }
    }
`;
