import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';

// MUI Components
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography, Box } from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import MdBillingEdit from './MdBillingEdit';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import {} from './apollo-queries';

// Redux

// Utils

// Constants

interface BillingTabPanelProps {
    tabIndexAsString: string;
    medecin: any;
}

export default function BillingTabPanel({ tabIndexAsString, medecin }: BillingTabPanelProps) {
    return (
        <TabPanel value={tabIndexAsString}>
            <Box mb={6}>
                <MdBillingEdit mdId={medecin.id} />
            </Box>
        </TabPanel>
    );
}
