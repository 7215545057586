import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    POSTE_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
    DESCRIPTION_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getIdentifiantUtilProfTableColumns = (): GridColDefPro[] => [
    {
        field: 'idutilno',
        headerName: 'idutilno',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'idUtilDescription',
        headerName: 'Nom',
        width: DESCRIPTION_COLUMN_WIDTH,
        valueGetter: (params: any) => params.row.IdentifiantUtil?.description,
    },
    {
        field: 'idUtilNoGmf',
        headerName: '# GMF',
        width: ID_COLUMN_WIDTH,
        valueGetter: (params: any) => params.row.IdentifiantUtil?.nogmf,
    },
];
