import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { HorsRamqClaimTypeFields, HorsRamqClaimSubtypeFields, MdFields } from 'apollo/fragments';

export const GET_HORS_RAMQ_CLAIM_TYPES = gql`
    ${HorsRamqClaimTypeFields}

    query GetHorsRamqClaimTypes {
        horsRamqClaimTypes {
            ...HorsRamqClaimTypeFields
        }
    }
`;

export const GET_HORS_RAMQ_CLAIM_SUBTYPES = gql`
    ${HorsRamqClaimSubtypeFields}

    query GetHorsRamqClaimSubtypes($hors_ramq_claim_type_id: Int) {
        horsRamqClaimSubtypes(hors_ramq_claim_type_id: $hors_ramq_claim_type_id) {
            ...HorsRamqClaimSubtypeFields
        }
    }
`;

export const GET_MDS = gql`
    ${MdFields}

    query GetMds {
        mds {
            ...MdFields
        }
    }
`;

export const CREATE_MD_HORS_RAMQ_CLAIM = gql`
    mutation CreateMdHorsRamqClaim(
        $md_id: Int
        $received_on: Date
        $patient_name: String
        $consulted_on: Date
        $hors_ramq_claim_type_id: Int
        $hors_ramq_claim_type_name: String
        $hors_ramq_claim_subtype_id: Int
        $hors_ramq_claim_subtype_name: String
        $amt_asked: Int
        $amt_paid: Int
        $check_no: String
        $check_date: Date
        $is_billed: Boolean
        $md_bill_id: Int
        $status: String
        $status_date: Date
        $is_sipt: Boolean
        $sipt_admin_name: String
    ) {
        createMdHorsRamqClaim(
            md_id: $md_id
            received_on: $received_on
            patient_name: $patient_name
            consulted_on: $consulted_on
            hors_ramq_claim_type_id: $hors_ramq_claim_type_id
            hors_ramq_claim_type_name: $hors_ramq_claim_type_name
            hors_ramq_claim_subtype_id: $hors_ramq_claim_subtype_id
            hors_ramq_claim_subtype_name: $hors_ramq_claim_subtype_name
            amt_asked: $amt_asked
            amt_paid: $amt_paid
            check_no: $check_no
            check_date: $check_date
            is_billed: $is_billed
            md_bill_id: $md_bill_id
            status: $status
            status_date: $status_date
            is_sipt: $is_sipt
            sipt_admin_name: $sipt_admin_name
        ) {
            md_id
            received_on
            patient_name
            consulted_on
            hors_ramq_claim_type_id
            hors_ramq_claim_type_name
            hors_ramq_claim_subtype_id
            hors_ramq_claim_subtype_name
            amt_asked
            amt_paid
            check_no
            check_date
            is_billed
            md_bill_id
            status
            status_date
            is_sipt
            sipt_admin_name
        }
    }
`;
