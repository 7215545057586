import React from 'react';
import { TextField } from '@mui/material';
import Decimal from 'decimal.js';
import { useState, useEffect, useCallback } from 'react';
import { Control, FormState, useController } from 'react-hook-form';
import NumberFormat from 'react-number-format';

interface MoneyControlProps {
    name: string;
    control: Control<any, any>;
    errors?: FormState<any>['errors'];
    options?: { label?: string; suffix?: string; disabled?: boolean };
}

export function MoneyControl({ name, control, errors, options = {} }: MoneyControlProps) {
    const { label, suffix } = options;

    const {
        field: { onChange, value },
    } = useController({ name, control });
    const [formattedValue, setFormattedValue] = useState((value || 0) / 100);

    useEffect(() => {
        setFormattedValue((value || 0) / 100);
    }, [value]);

    const onValueChange = useCallback(
        ({ value, floatValue, formattedValue }) => {
            onChange(new Decimal(value || 0).mul(100).toNumber());
            setFormattedValue(formattedValue);
        },
        [onChange],
    );

    return (
        <>
            <NumberFormat
                customInput={TextField}
                value={formattedValue}
                onValueChange={onValueChange}
                size="small"
                fullWidth
                thousandSeparator=" "
                suffix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                {...options}
            />
        </>
    );
}
