import React, { useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as ReTooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

// MUI Components
import { Box, Grid, Paper, Switch, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// MUI Grid

// MUI Icons

// Components
import Loading from 'components/Loading';

// Apollo

// Redux

// Services

// Constants
import { SQL_DATE } from 'constants/Moment';
import { COLORS } from 'constants/Chart';

// Utils
import { capitalize, getSMA } from 'utils';

interface OverviewGraphProps {
    data: {
        graphPoints: {
            label: string;

            sum_subtotal: number;
            sum_tps: number;
            sum_tvq: number;
            sum_total: number;

            sum_subtotal_md: number;
            sum_tps_md: number;
            sum_tvq_md: number;
            sum_total_md: number;

            sum_subtotal_clinic: number;
            sum_tps_clinic: number;
            sum_tvq_clinic: number;
            sum_total_clinic: number;

            sum_logiciel_base_md: number;
            sum_dme_kinlogix_md: number;
            sum_dme_medfar_md: number;
            sum_dme_omnimed_md: number;

            sum_logiciel_base_clinic: number;
            sum_dme_kinlogix_clinic: number;
            sum_dme_medfar_clinic: number;
            sum_dme_omnimed_clinic: number;

            percentage_subtotal_md: number;
            percentage_subtotal_clinic: number;
        }[];
    };
    startDate: Moment | null;
    endDate: Moment | null;
}

export default function OverviewGraph(props: OverviewGraphProps) {
    const theme = useTheme();
    const {
        data: { graphPoints },
        data,
        startDate,
        endDate,
    } = props;

    const [graphData] = useMemo(() => {
        const totalsArrayForSma: number[] = [];

        // Main
        const graphData: (OverviewGraphProps['data']['graphPoints'][0] & {
            sma: number | null;
        })[] = [...data.graphPoints]
            .sort((a, b) => moment(a.label).unix() - moment(b.label).unix())
            .map((point, pointIdx) => {
                const {
                    label,

                    sum_subtotal,
                    sum_tps,
                    sum_tvq,
                    sum_total,

                    sum_subtotal_md,
                    sum_tps_md,
                    sum_tvq_md,
                    sum_total_md,

                    sum_subtotal_clinic,
                    sum_tps_clinic,
                    sum_tvq_clinic,
                    sum_total_clinic,

                    sum_logiciel_base_md,
                    sum_dme_kinlogix_md,
                    sum_dme_medfar_md,
                    sum_dme_omnimed_md,

                    sum_logiciel_base_clinic,
                    sum_dme_kinlogix_clinic,
                    sum_dme_medfar_clinic,
                    sum_dme_omnimed_clinic,

                    percentage_subtotal_md,
                    percentage_subtotal_clinic,
                } = point;

                totalsArrayForSma.push(sum_subtotal_md + sum_subtotal_clinic + sum_tps + sum_tvq);

                return {
                    label: moment(label).format(SQL_DATE),

                    sum_subtotal,
                    sum_tps,
                    sum_tvq,
                    sum_total,

                    sum_subtotal_md:
                        sum_subtotal_md -
                        sum_logiciel_base_md -
                        sum_dme_kinlogix_md -
                        sum_dme_medfar_md -
                        sum_dme_omnimed_md,
                    // sum_subtotal_md,
                    sum_tps_md,
                    sum_tvq_md,
                    sum_total_md,

                    sum_subtotal_clinic:
                        sum_subtotal_clinic -
                        sum_logiciel_base_clinic -
                        sum_dme_kinlogix_clinic -
                        sum_dme_medfar_clinic -
                        sum_dme_omnimed_clinic,
                    sum_tps_clinic,
                    sum_tvq_clinic,
                    sum_total_clinic,

                    sum_logiciel_base_md,
                    sum_dme_kinlogix_md,
                    sum_dme_medfar_md,
                    sum_dme_omnimed_md,

                    sum_logiciel_base_clinic,
                    sum_dme_kinlogix_clinic,
                    sum_dme_medfar_clinic,
                    sum_dme_omnimed_clinic,

                    percentage_subtotal_md,
                    percentage_subtotal_clinic,

                    sma: 0,
                };
            });

        // SMA
        const smas = getSMA(
            graphData.map((p) => p.sum_subtotal_md + p.sum_subtotal_clinic),
            5,
        );
        smas.forEach((val, idx) => {
            graphData[idx].sma = val;
        });

        return [graphData];
    }, [data]);

    return (
        <Grid item xs={12} lg={12}>
            <Paper
                sx={{
                    px: 2,
                    py: 2,
                }}
                elevation={1}
            >
                <Typography variant="h6">Revenu net</Typography>
                <Typography variant="subtitle2" color="#bf9a4c" mt={1}>
                    ** Les points "Revenus md" & "Revenus clinique" sont net (frais de logiciel & DMÉ <i>exclus</i>)
                </Typography>
                <Typography variant="subtitle2" color="#bf9a4c" mt={1}>
                    ** Les points "TPS" & "TVQ" sont calculés sur le montant brut (frais de logiciel & DMÉ <i>inclus</i>
                    )
                </Typography>
                <Typography variant="subtitle2" color="#bf9a4c" mt={1}>
                    ** Les points "Pourcentage md" & "Pourcentage clinique" sont calculés sur le montant brut (frais de
                    logiciel & DMÉ <i>inclus</i>) sans taxes
                </Typography>
                <Typography variant="subtitle2" color="#bf9a4c" mt={1} mb={4}>
                    ** Le point "SMA" est calculé sur l'addition des points "Revenus md" & "Revenus clinique"
                </Typography>

                <Box sx={{ width: '100%', height: 450 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={400}
                            data={graphData}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            // syncId="anyId"
                        >
                            <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                            <XAxis dataKey="label" scale="auto" />
                            <YAxis
                                yAxisId="left"
                                tickFormatter={(value) => `${value.toFixed(0)}%`}
                                label={{
                                    value: "Pourcentage d'attribution",
                                    angle: -90,
                                    position: 'insideLeft',
                                    style: { textAnchor: 'middle', fill: 'white' },
                                }}
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                tickFormatter={(value) =>
                                    new Intl.NumberFormat('en', { notation: 'compact' }).format(value)
                                }
                                label={{
                                    value: '$ facturé',
                                    angle: 90,
                                    position: 'insideRight',
                                    style: { textAnchor: 'middle', fill: 'white' },
                                }}
                            />
                            <ReTooltip
                                labelStyle={{ color: 'black' }}
                                labelFormatter={(label) => capitalize(moment(label).format('dddd Do MMMM Y'))}
                                // formatter={(value) => `${new Intl.NumberFormat('en').format((new Decimal(value).div(2)) as any)} $`}
                            />
                            <Legend />
                            <Area
                                yAxisId="left"
                                name="Pourcentage md"
                                // type="step"
                                type="monotone"
                                dataKey="percentage_subtotal_md"
                                stackId="1"
                                // stroke="#82ca9d"
                                // fill="#82ca9d"
                                stroke={theme.palette.primary.main}
                                fill={theme.palette.primary.main}
                            />
                            <Area
                                yAxisId="left"
                                name="Pourcentage clinique"
                                // type="step"
                                type="monotone"
                                dataKey="percentage_subtotal_clinic"
                                stackId="1"
                                // stroke="#ffc658"
                                // fill="#ffc658"
                                stroke={theme.palette.info.main}
                                fill={theme.palette.info.main}
                            />
                            <Bar
                                yAxisId="right"
                                name="Revenus md"
                                dataKey="sum_subtotal_md"
                                stackId="a"
                                fill={theme.palette.primary.main}
                                barSize={20}
                            />
                            <Bar
                                yAxisId="right"
                                name="Revenus clinique"
                                dataKey="sum_subtotal_clinic"
                                stackId="a"
                                fill={theme.palette.info.main}
                                barSize={20}
                            />
                            <Bar
                                yAxisId="right"
                                name="TPS"
                                dataKey="sum_tps"
                                stackId="a"
                                fill={theme.palette.pink1Dark.main}
                                barSize={20}
                            />
                            <Bar
                                yAxisId="right"
                                name="TVQ"
                                dataKey="sum_tvq"
                                stackId="a"
                                fill={theme.palette.yellow1Dark.main}
                                barSize={20}
                            />
                            <Line
                                yAxisId="right"
                                name="SMA 5"
                                type="monotone"
                                dataKey="sma"
                                stroke="red"
                                activeDot={{ r: 8 }}
                                strokeWidth={2}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Box>
            </Paper>
        </Grid>
    );
}
