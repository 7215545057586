import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdPaymentFields } from 'apollo/fragments';

export const GET_MD_PAYMENT = gql`
    ${MdPaymentFields}

    query GetMdPayment($id: Int) {
        mdPayment(id: $id) {
            ...MdPaymentFields
        }
    }
`;

export const DELETE_MD_PAYMENT = gql`
    mutation DeleteMdPayment($id: Int) {
        deleteMdPayment(id: $id) {
            id
        }
    }
`;
