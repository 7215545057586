import React, { useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { cloneDeep, isEmpty, lowerFirst } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom';

import 'index.css';

// MUI Components
import {
    Alert,
    AlertTitle,
    Box,
    Breadcrumbs,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// MUI Grid

// MUI Icons
import { Clear as ClearIcon, NavigateNext as NavigateNextIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import OverviewGraph from './OverviewGraph';
import MdRevenuesPerFactMethodGraph from './MdRevenuesPerFactMethodGraph';
import MdRevenuesPerOtherFactMethodGraph from './MdRevenuesPerOtherFactMethodGraph';
import MdLossesPerDiscountGraph from './MdLossesPerDiscountGraph';
import MdRevenuesForSoftwareGraph from './MdRevenuesForSoftwareGraph';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_BILLING_PERF_DATA } from './apollo-queries';

// Redux

// Services

// Constants
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';
import { SQL_DATE } from 'constants/Moment';

// Utils
import { capitalize, formatNumber } from 'utils';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Statistiques
    </Typography>,
    <Typography key="1" fontSize={15}>
        Revenus
    </Typography>,
];

export default function BillingPerfDash() {
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter - Start date
    const search_start_date = searchParams.get('start_date');
    const [startDateFilter, setStartDateFilter] = useState<Moment | null>(
        search_start_date ? moment(search_start_date) : moment().subtract(12, 'month').startOf('month'),
    );
    const handleChangeStartDate = (value) => {
        setStartDateFilter(value);
    };
    const handleClearStartDate = () => {
        setStartDateFilter(null);
    };

    // Filter - End date
    const search_end_date = searchParams.get('end_date');
    const [endDateFilter, setEndDateFilter] = useState<Moment | null>(
        search_end_date ? moment(search_end_date) : moment(),
    );
    const handleChangeEndDate = (value) => {
        setEndDateFilter(value);
    };
    const handleClearEndDate = () => {
        setEndDateFilter(null);
    };

    // Apollo queries
    const isInvalidQuery = !startDateFilter || !endDateFilter;
    const {
        loading: billingPerfLoading,
        error: billingPerfError,
        data: billingPerfData,
    } = useQuery(GET_BILLING_PERF_DATA, {
        variables: {
            start_date: startDateFilter,
            end_date: endDateFilter,
        },
        skip: isInvalidQuery,
    });
    const data = billingPerfData?.billingPerfData || {};

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                    <Typography variant="h5" fontWeight="600">
                        Revenus
                    </Typography>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
            </Stack>

            <Grid container spacing={3} mb={2.5}>
                <Grid item xs={2.4}>
                    <DatePicker
                        label={'Date du début'}
                        value={startDateFilter}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: startDateFilter && (
                                <IconButton onClick={handleClearStartDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>

                <Grid item xs={2.4}>
                    <DatePicker
                        label={'Date du fin'}
                        value={endDateFilter}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: endDateFilter && (
                                <IconButton onClick={handleClearEndDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {billingPerfLoading ? (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                            <Loading />
                        </Box>
                    </Grid>
                ) : isInvalidQuery ? (
                    <Grid item xs={12} mt={3} mb={2}>
                        <Alert variant="outlined" severity="warning">
                            <AlertTitle>Veuillez remplir tous les champs</AlertTitle>
                            Veuillez sélectionner une date de début, une date de fin et une méthode de groupement.
                        </Alert>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} mt={3} mb={2}>
                            <Typography variant="h4" fontWeight="bold" textAlign="center">
                                Revenus par état de compte du {startDateFilter?.format('LL')} au{' '}
                                {endDateFilter?.format('LL')}
                            </Typography>
                        </Grid>

                        {!isEmpty(data) && (
                            <>
                                <OverviewGraph data={data} startDate={startDateFilter} endDate={endDateFilter} />

                                <MdRevenuesPerFactMethodGraph
                                    data={data}
                                    startDate={startDateFilter}
                                    endDate={endDateFilter}
                                />

                                <MdRevenuesPerOtherFactMethodGraph
                                    data={data}
                                    startDate={startDateFilter}
                                    endDate={endDateFilter}
                                />

                                <MdLossesPerDiscountGraph
                                    data={data}
                                    startDate={startDateFilter}
                                    endDate={endDateFilter}
                                />

                                <MdRevenuesForSoftwareGraph
                                    data={data}
                                    startDate={startDateFilter}
                                    endDate={endDateFilter}
                                />
                            </>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
}
