import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import { useNavigate, NavigateFunction } from 'react-router';
import { useForm, Controller, useController } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

import NumberFormat from 'react-number-format';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
    Chart,
    BarSeries,
    Title,
    Tooltip as TooltipDevExpress,
    ArgumentAxis,
    ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, ArgumentScale } from '@devexpress/dx-react-chart';
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getClinicsTableColumns } from './grid-columns';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Male as MaleIcon,
    Female as FemaleIcon,
    Check as CheckIcon,
    Clear as ClearIcon,
    Visibility as VisibilityIcon,
    MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import EditDrawer from './EditDrawer';
import BooleanIcon from 'components/BooleanIcon';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, useLazyQuery, gql, LazyQueryResult } from '@apollo/client';
import { GET_CLINICS } from './apollo-queries';

// Redux

// Utils
import {
    formatNumber,
    getBadgeBackgroundColorFromDoctorStatus,
    getBadgeTextColorFromDoctorStatus,
    getRandomNumberBetween,
} from 'utils';

// Constants
import { SQL_DATETIME } from 'constants/Moment';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Gestion des cliniques
    </Typography>,
    <Typography key="1" fontSize={15}>
        Cliniques
    </Typography>,
];

type RouteParams = 'clinicId';

export default function ClinicList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { clinicId } = useParams<RouteParams>();

    // Filter - Clinic ID
    const search_clinic_id = searchParams.get('clinic_id');
    const [clinicIdFilter, setClinicIdFilter] = useState<string>(search_clinic_id ? search_clinic_id : '');
    const handleChangeClinicId = (e) => {
        setClinicIdFilter(e.target.value);
    };
    const [clinicIdFilterDebounced] = useDebounce(clinicIdFilter, 750);

    // Filter - Clinic name
    const search_clinic_name = searchParams.get('clinic_name');
    const [clinicNameFilter, setClinicNameFilter] = useState<string>(search_clinic_name ? search_clinic_name : '');
    const handleChangeClinicName = (e) => {
        setClinicNameFilter(e.target.value);
    };
    const [clinicNameFilterDebounced] = useDebounce(clinicNameFilter, 750);

    // Queries
    const {
        loading: clinicsLoading,
        error: clinicsError,
        data: clinicsData,
    } = useQuery(GET_CLINICS, {
        variables: {
            id: parseInt(clinicIdFilterDebounced),
            name: clinicNameFilterDebounced,
        },
    });

    // EditDrawer
    const [selectedClinicId, setSelectedClinicId] = useState<any | null>(clinicId ? parseInt(clinicId) : null);

    const handleOpenEditDrawer = (id: number) => {
        setSelectedClinicId(id);
    };
    const handleCloseEditDrawer = () => {
        setSelectedClinicId(null);
    };

    // Columns
    const clinicsTableColumns = useMemo(() => getClinicsTableColumns(handleOpenEditDrawer), []);
    const [pageSize, setPageSize] = React.useState<number>(15);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Cliniques
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container mb={4}>
                <Grid item xs={12}>
                    <TextField
                        label="ID clinique"
                        value={clinicIdFilter}
                        onChange={handleChangeClinicId}
                        type="number"
                        size="small"
                        sx={{ mr: 1 }}
                    />

                    <TextField
                        label="Nom clinique"
                        value={clinicNameFilter}
                        onChange={handleChangeClinicName}
                        size="small"
                        sx={{ mr: 1 }}
                    />
                </Grid>
            </Grid>

            <DataGridPro
                getRowId={(row) => row.id}
                rows={clinicsData?.clinics || []}
                columns={clinicsTableColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: {
                        left: ['grp', 'pst', 'name'],
                        right: [
                            // 'qb_customer_id',
                            // 'qb_customer_fully_qualified_name',
                            'balance',
                            'is_still_client',
                            'clinic_billing_info.is_approved',
                            'is_billable',
                            'actions',
                        ],
                    },
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                loading={clinicsLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />

            <EditDrawer clinicId={selectedClinicId} handleCloseEditDrawer={handleCloseEditDrawer} />
        </>
    );
}
