import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getActiviteContexteDialogTableColumns } from './grid-columns';

// MUI Icons
import {} from '@mui/icons-material';

// Components

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import BooleanIcon from 'components/BooleanIcon';

// Redux

// Utils

// Constants

interface ActiviteContexteDialogProps {
    selectedActiviteContexte: any;
    handleCloseActiviteContexteDialog: () => void;
}

export default function ActiviteContexteDialog({
    selectedActiviteContexte,
    handleCloseActiviteContexteDialog,
}: ActiviteContexteDialogProps) {
    const activiteContexteDialogTableColumns = useMemo(() => getActiviteContexteDialogTableColumns(), []);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={selectedActiviteContexte ? true : false}
            onClose={handleCloseActiviteContexteDialog}
        >
            {selectedActiviteContexte && (
                <>
                    <DialogTitle>
                        Contexte d'activité #{selectedActiviteContexte.code} - {selectedActiviteContexte.description}
                    </DialogTitle>
                    <DialogContent>
                        <DataGridPro
                            sx={{ mt: 3 }}
                            getRowId={(row) => uuidv4()}
                            rows={[selectedActiviteContexte]}
                            columns={activiteContexteDialogTableColumns}
                            pageSize={1}
                            rowsPerPageOptions={[1]}
                            disableSelectionOnClick
                            disableColumnMenu
                            disableColumnFilter
                            autoHeight
                            hideFooter
                            onRowClick={(params, event, details) => {}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseActiviteContexteDialog}>Fermer</Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
