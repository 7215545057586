import React from 'react';
import { IconProps, SxProps } from '@mui/material';
import { Male as MaleIcon, Female as FemaleIcon } from '@mui/icons-material';

interface SexIconProps {
    sex?: number;
    nam?: string | null | undefined;
    fontSize?: IconProps['fontSize'];
    sx?: SxProps;
}

type Sex = 'M' | 'W' | null;

export default function SexIcon(props: SexIconProps) {
    let { sex, nam, fontSize, sx } = props;

    fontSize = fontSize || 'small';

    const sxProps = sx || {};

    const processedSex = getSex(sex, nam);

    if (processedSex === 'W') {
        return (
            <FemaleIcon
                fontSize={fontSize}
                sx={{
                    color: 'pink.main',
                    ...sxProps,
                }}
            />
        );
    } else if (processedSex === 'M') {
        return (
            <MaleIcon
                fontSize={fontSize}
                sx={{
                    color: 'blue.main',
                    ...sxProps,
                }}
            />
        );
    } else {
        return <></>;
    }
}

const getSex = (sex: SexIconProps['sex'], nam: SexIconProps['nam']): Sex => {
    let processedSex: Sex;

    if (sex === 0) {
        processedSex = null;
    } else if (sex === 1) {
        processedSex = 'M';
    } else if (sex === 2) {
        processedSex = 'W';
    } else if (nam) {
        if (parseInt(nam.substring(6, 8)) > 12) {
            processedSex = 'W';
        } else {
            processedSex = 'M';
        }
    } else {
        processedSex = null;
    }

    return processedSex;
};
