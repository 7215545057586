import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdBillingInfoFields } from 'apollo/fragments';

export const GET_BILL_LINE_TAGS = gql`
    query GetBillLineTags {
        billLineTags {
            id
            created_at
            updated_at
            slug
            name
            desc
        }
    }
`;

export const GET_BILL_MOD_LINE_TAGS = gql`
    query GetBillModLineTags {
        billModLineTags {
            id
            created_at
            updated_at
            slug
            name
            desc
            position
        }
    }
`;

export const UPDATE_MD_BILL = gql`
    mutation UpdateMdBill(
        $id: Int
        $status: String
        $md_bill_lines: [UpdateMdBillMdBillLinesInput]
        $md_bill_mod_lines: [UpdateMdBillMdBillModLinesInput]
    ) {
        updateMdBill(id: $id, status: $status, md_bill_lines: $md_bill_lines, md_bill_mod_lines: $md_bill_mod_lines) {
            id
        }
    }
`;
