import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    Chip,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Typography,
} from '@mui/material';
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { DataGrid, GridCellValue, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';

// Redux
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { RootState } from 'redux/store';
import { dxsData, facilitiesData, patientsData, doctorsData } from 'redux/data';

// Utils
import { getBadgeBackgroundColorFromStatus, getBadgeTextColorFromStatus } from 'utils';

// Apollo
import { useQuery, gql } from '@apollo/client';

const APOLLO_QUERY = gql`
    query GetAllAdmins {
        admins {
            id
            created_at
            email
            phone
            first_name
            last_name
            avatar_url
        }
    }
`;

export default function AdminList() {
    const theme = useTheme();
    let navigate = useNavigate();

    // const claims = useAppSelector((state) => state.claims);
    // const dispatch = useAppDispatch();

    const { loading, error, data } = useQuery(APOLLO_QUERY);

    const breadcrumbs = [
        <Typography key="1" fontSize={15}>
            Admins
        </Typography>,
    ];

    const columns = useCallback(() => getColumns(theme, navigate), [theme, navigate]);

    if (loading) return <Typography>Chargement...</Typography>;
    if (error) return <Typography>Error! {error.message}</Typography>;

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Admins
            </Typography>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 0, mb: 5 }}>
                {breadcrumbs}
            </Breadcrumbs>

            <Button variant="contained" onClick={() => navigate('create')}>
                Créer admin
            </Button>

            <DataGrid
                rows={data.admins}
                columns={columns()}
                pageSize={20}
                rowsPerPageOptions={[20]}
                checkboxSelection
                disableSelectionOnClick
                // disableColumnMenu
                // rowHeight={100}
                // density="comfortable"
                // showColumnRightBorder
                // showCellRightBorder
                autoHeight
                // hideFooter
            />
        </>
    );
}

const getColumns = (theme: Theme, navigate: NavigateFunction): GridColDef[] => [
    {
        field: 'id',
        headerName: 'ID',
        width: 50,
        align: 'center',
    },
    {
        field: 'created_at',
        headerName: 'Créé le',
        width: 180,
        valueFormatter: (params) => {
            const created_at = params.value as RootState['claims'][0]['created_at'];

            return moment(created_at).format('LLL');
        },
    },
    {
        field: 'email',
        headerName: 'Courriel',
        flex: 1,
    },
    {
        field: 'first_name',
        headerName: 'Prénom',
    },
    {
        field: 'last_name',
        headerName: 'Nom',
    },
    {
        field: 'avatar_url',
        headerName: 'Avatar',
        // flex: 1,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            const { avatar_url } = params.row as any;

            return <Avatar src={avatar_url} sx={{ width: 24, height: 24 }} />;
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            const { id: claimId } = params.row as RootState['claims'][0];

            return (
                <>
                    <IconButton size="small" onClick={() => navigate(`${claimId}/edit/claim`)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={() => navigate(`${claimId}/edit/claim`)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            );
        },
    },
];
