import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { ClinicBillFields, EcFields, ClinicFields } from 'apollo/fragments';

export const GET_CLINIC_BILLS = gql`
    ${ClinicBillFields}

    query GetClinicBills($id: Int, $bill_no: String, $clinic_id: Int, $ec_id: Int) {
        clinicBills(id: $id, bill_no: $bill_no, clinic_id: $clinic_id, ec_id: $ec_id) {
            ...ClinicBillFields

            date_ec
            date_coupure
            dd_periode_fact
            df_periode_fact
            no_paiement

            name

            sum_tag_1
            sum_tag_2
            sum_tag_3
            sum_tag_4
            sum_tag_5
            sum_tag_6
            sum_tag_7
            sum_tag_8

            sum_mod_tag_1
            sum_mod_tag_2
            sum_mod_tag_3
            sum_mod_tag_4
            sum_mod_tag_5
            sum_mod_tag_6
            sum_mod_tag_7
            sum_mod_tag_8
            sum_mod_tag_9
            sum_mod_tag_10
            sum_mod_tag_11
            sum_mod_tag_12
            sum_mod_tag_13
        }
    }
`;

export const GET_ECS = gql`
    ${EcFields}

    query GetEcs {
        ecs {
            ...EcFields
        }
    }
`;

export const GET_CLINICS = gql`
    ${ClinicFields}

    query GetClinics {
        clinics {
            ...ClinicFields
        }
    }
`;
