import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
    Autocomplete,
    ToggleButtonGroup,
    ToggleButton,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    OpenInNew as OpenInNewIcon,
    ArrowForward as ArrowForwardIcon,
    Male as MaleIcon,
    Female as FemaleIcon,
    Help as HelpIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
// import EditDrawer from './EditDrawer';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';

// Redux
import {} from 'redux';

// Utils
import { capitalize, formatNumber, roundNumber } from 'utils';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    TYPE_SERVICE_NAME_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import Loading from 'components/Loading';
import { v4 as uuidv4 } from 'uuid';

const GET_FACTLIGNE_SUM = gql`
    query GetFactligneTotal($licence: Int!, $datefactFrom: String!, $datefactTo: String!) {
        factligneSum(licence: $licence, datefactFrom: $datefactFrom, datefactTo: $datefactTo)
    }
`;

const GET_EC_TOTAL = gql`
    query GetEcTotal($ec_id: Int!, $licence: Int!) {
        ecTotal(ec_id: $ec_id, licence: $licence)
    }
`;

const GET_FACTURES = gql`
    query GetFactures($licence: Int, $ec_id: Int) {
        factures(licence: $licence, ec_id: $ec_id) {
            reqt_id
            nce
            nci
            typeservice
            revision
            licence
            poste
            postemod
            jeton
            statrecv
            annule
            lotid
            creation
            ec_id
            # Poste {
            #     description
            # }
            # TOTAL
            TYPE_SERVICE
        }
    }
`;

const GET_MEDECINS = gql`
    query GetMedecins {
        medecins {
            licence
            prenom
            nom
        }
    }
`;

const GET_EC_MASTER = gql`
    query GetEcMaster {
        ecMaster {
            ec_id
            nopayment
            dateec
            datecut
        }
    }
`;

const GET_EC_MD_GRPS = gql`
    fragment AllEc1717ig on Ec1717ig {
        mdgrpid
        ec_id
        typereglement
        compteur
        valideur
        masterid
        nodemande
        debutperiode
        finperiode
        datereception
        nodemref1
        nodemref2
        codetrans
        libellecodetrans
        codemsgexpl1
        codemsgexpl2
        codemsgexpl3
        montantinteret
        noattestation
    }

    fragment AllFactLigne on FactLigne {
        ligneno
        nce
        revision
        rank
        mdgrppstno
        typelmfact
        codefact
        datefact
        coderole
        debutelmfact
        finelmfact
        coderef
        mntprelim
        mntfinal
        statrecv
    }

    query GetEcMdGrps($ec_id: Int!, $licence: Int!) {
        ecMdGrps(ec_id: $ec_id, licence: $licence) {
            mdgrpid
            md
            grp
            ec_id
            specialite

            Medecin {
                licence
                prenom
                nom
                male
                specialiste
                lieutra
                retraite
            }
            Groupe {
                numero
                licence
                denomination
                incorp
                debut
                fin
            }
            EcMaster {
                ec_id
                agence
                nopayment
                dateec
                datecut
            }

            Ec1703 {
                mdgrpid
                compteur
                valideur
                msg
                msg_permanent
            }
            Ec1704 {
                mdgrpid
                compteur
                valideur
                codetrans
                montantbrut
                libellecodetrans
            }
            Ec1707 {
                mdgrpid
                compteur
                valideur
                codededuction
                montantdeduction
                libellededuction
                dateenrgded
                nocompteded
            }
            Ec1708 {
                mdgrpid
                compteur
                valideur
                nomfed
                montantcotisation
            }
            Ec1709 {
                mdgrpid
                compteur
                valideur
                codetransfert
                classegrptransfert
                nogrptransfert
                montanttransfert
            }
            Ec1710 {
                mdgrpid
                compteur
                valideur
                interetsurrecouv
                interetsuravance
                avancesemises
                avancesrecouv
                interetsursoldeneg
            }
            Ec1711 {
                mdgrpid
                compteur
                valideur
                soldereporte
                soldeareporte
                modifdesolde
                paiementrecu
                debutinteretsursoldeneg
                codemsg
                montantmsg1
            }
            Ec1712 {
                mdgrpid
                compteur
                valideur
                montantnetverse
                nochequeouvirement
                modepaiement
            }
            Ec1716 {
                mdgrpid
                compteur
                valideur
                nomfederation
                montantcumulatif
            }

            Ec1717ig {
                ...AllEc1717ig

                Ec1717f2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    libelleforfaitaire
                    taux
                    montantpaye
                }
                Ec1717m2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    typemesinc
                    libelleelmmesinc
                    elmmesinc
                    libelleelmmesinc2
                    codemsgexpl1
                    codemsgexpl2
                    montantpaye
                    nombrejours
                    montantconsidere
                    nombrejourscons
                    montantconsant
                    nombrejoursconsant
                    reference
                    debutmesureinc
                    finmesureinc
                }
                Ec1717r2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    typemesurepart
                    libelletypemesurepart
                    typeappl
                    montantpaye
                    montantcons
                    montantconsant
                }
                Ec1717v2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codenatserv
                    libellecodenatserv
                    codeemploitemps
                    libellecodeemploitemps
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    nombreheurespayees
                    montantcons
                    nombreheurescons
                    montantconsant
                    nombreheuresconsant
                    reference
                }
                Ec1717v3 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    nombreheurespayees
                    montantcons
                    nombreheurescons
                    montantconsant
                    nombreheuresconsant
                    reference
                }
                Ec1717v4 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    nombrekmspayes
                    montantcons
                    nombrekmscons
                    montantconsant
                    nombrekmsconsant
                    reference
                    refocctempsdepl
                }
                Ec1717x3 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    typeunitpayees
                    nombreunitpayees
                    nombreunitcons
                    nombreunitconsant
                    reference
                }
            }

            Ec1720 {
                nci
                nam
                dateoccurrence
                montantpaye
                dateec
                rev

                Facture {
                    poste
                    FactLignes {
                        ...AllFactLigne
                        AMT_AT_EC(ec_id: $ec_id)
                    }
                }
            }
            Ec1721 {
                mdgrpid
                nci
                noligne
                nam
                dateoccurence
                codetran
                typeident
                valeurident
                montantprel
                montantpaye
                codemsgrfp
                codemsgrfp1
                codemsgrfp2
                codemsgrfp3
                codemsgrfp4
                codemsgrfp5
                id
                rev
                dateec

                Facture {
                    poste
                    FactLignes {
                        ...AllFactLigne
                    }
                }

                MessageExpl {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl1 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl2 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl3 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl4 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl5 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
            }
            Ec1722 {
                mdgrpid
                nci
                noligne
                nam
                dateoccurence
                codetran
                typeident
                valeurident
                montantant
                montantnouv
                montantpaye
                codemsgrfp1
                codemsgrfp2
                codemsgrfp3
                codemsgrfp4
                codemsgrfp5
                id
                rev
                dateec

                Facture {
                    poste
                    FactLignes {
                        ...AllFactLigne
                    }
                }

                MessageExpl1 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl2 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl3 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl4 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl5 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
            }
            Ec1725 {
                mdgrpid
                nci
                noligne
                nam
                dateoccurence
                codetran
                montantremb
                typeident
                valeurident
                montantant
                montantnouv
                montantpaye
                codemsgrfp1
                codemsgrfp2
                codemsgrfp3
                codemsgrfp4
                codemsgrfp5
                id
                rev
                dateec

                Facture {
                    poste
                    FactLignes {
                        ...AllFactLigne
                    }
                }

                MessageExpl1 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl2 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl3 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl4 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl5 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
            }
        }
    }
`;

export default function Ec() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // useEffect(() => {
    //     const currentParams = Object.fromEntries([...searchParams]);
    //     console.log(currentParams);
    //     console.log(searchParams.entries());
    // }, [searchParams]);

    const search_ec_id = searchParams.get('ec_id');
    const search_licence = searchParams.get('licence');

    const [ecIdFilter, setEcIdFilter] = useState<number | null>(search_ec_id ? parseInt(search_ec_id) : null);
    const [medecinLicenceFilter, setMedecinLicenceFilter] = useState<number | null>(
        search_licence ? parseInt(search_licence) : null,
    );

    const { loading: medecinsLoading, error: medecinsError, data: medecinsData } = useQuery(GET_MEDECINS);

    const { loading: ecMasterLoading, error: ecMasterError, data: ecMasterData } = useQuery(GET_EC_MASTER);

    const {
        loading: facturesLoading,
        error: facturesError,
        data: facturesData,
    } = useQuery(GET_FACTURES, {
        variables: {
            ec_id: ecIdFilter,
            licence: medecinLicenceFilter,
        },
        // skip: !ecIdFilter || !medecinLicenceFilter,
        skip: true,
    });

    const {
        loading: ecMdGrpsLoading,
        error: ecMdGrpsError,
        data: ecMdGrpsData,
    } = useQuery(GET_EC_MD_GRPS, {
        variables: {
            ec_id: ecIdFilter,
            licence: medecinLicenceFilter,
        },
        skip: !ecIdFilter || !medecinLicenceFilter,
    });

    const [selectedMdGrpId, setSelectedMdGrpId] = useState<any | null>(null);
    const handleChangeMdGrpId = (event: React.MouseEvent<HTMLElement>, newGroupe: string) => {
        setSelectedMdGrpId(newGroupe);
    };

    const breadcrumbs = [
        <Typography key="1" fontSize={15}>
            EC
        </Typography>,
    ];

    const INITIAL_GROUPING_COLUMN_MODEL = ['ec_id'];

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                États de compte
            </Typography>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 0, mb: 5 }}>
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container spacing={2} mb={4}>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        options={medecinsData?.medecins || []}
                        loading={medecinsLoading}
                        getOptionLabel={(option: any) => {
                            if (option === medecinLicenceFilter) {
                                const selectedMd = (medecinsData?.medecins || []).find((md) => md.licence === option);

                                return selectedMd
                                    ? `${selectedMd.nom}, ${selectedMd.prenom} (${selectedMd.licence})`
                                    : '';
                            } else {
                                return `${option.nom}, ${option.prenom} (${option.licence})`;
                            }
                        }}
                        value={medecinLicenceFilter}
                        onChange={(e, value: any) => {
                            const newMedecinLicence = value?.licence;

                            setMedecinLicenceFilter(newMedecinLicence || null);

                            if (newMedecinLicence) searchParams.set('licence', newMedecinLicence.toString());
                            else searchParams.delete('licence');

                            setSearchParams(searchParams);
                        }}
                        isOptionEqualToValue={(option, value) => {
                            if (option?.licence === value) {
                                return true;
                            } else {
                                return false;
                            }
                        }}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth label="Médecin" />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        options={ecMasterData?.ecMaster || []}
                        loading={ecMasterLoading}
                        getOptionLabel={(option: any) => {
                            const { ec_id, nopayment, dateec, datecut } = option;

                            if (option === ecIdFilter) {
                                const selectedEc = (ecMasterData?.ecMaster || []).find((ec) => ec.ec_id === option);

                                return selectedEc ? `${selectedEc.dateec}  (${selectedEc.nopayment})` : '';
                            } else {
                                return `${dateec}  (${nopayment})`;
                            }
                        }}
                        value={ecIdFilter}
                        onChange={(e, value: any) => {
                            const newEcId = value?.ec_id;

                            setEcIdFilter(newEcId || null);

                            if (newEcId) searchParams.set('ec_id', newEcId.toString());
                            else searchParams.delete('ec_id');

                            setSearchParams(searchParams);
                        }}
                        isOptionEqualToValue={(option, value) => {
                            if (option?.ec_id === value) {
                                return true;
                            } else {
                                return false;
                            }
                        }}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth label="EC" />}
                        groupBy={(option) => moment(option.dateec).format('YYYY')}
                    />
                </Grid>
                <Grid item xs={12}>
                    {/* <Typography variant="h6">
                        Total facturé (avec DPs annulées):{' '}
                        {(facturesData?.factures || [])
                            .reduce((prevValue, item) => prevValue + item.TOTAL, 0)
                            .toFixed(2)}{' '}
                        $
                    </Typography>
                    <Typography variant="h6">
                        Total facturé (sans DPs annulées):{' '}
                        {(facturesData?.factures || [])
                            .reduce((prevValue, item) => (item.annule ? prevValue : prevValue + item.TOTAL), 0)
                            .toFixed(2)}{' '}
                        $
                    </Typography> */}
                </Grid>

                {ecMdGrpsLoading ? (
                    <Grid item xs={12} container justifyContent="center">
                        <Loading />
                    </Grid>
                ) : ecMdGrpsData ? (
                    (ecMdGrpsData?.ecMdGrps || []).length > 0 ? (
                        <>
                            <Grid item xs={12} container justifyContent="center">
                                <Typography>Veuillez sélectionner le groupe:</Typography>
                            </Grid>

                            <Grid item xs={12} container justifyContent="center">
                                <ToggleButtonGroup
                                    color="primary"
                                    value={selectedMdGrpId ? selectedMdGrpId.toString() : null}
                                    exclusive
                                    onChange={handleChangeMdGrpId}
                                    size="small"
                                >
                                    {[...(ecMdGrpsData?.ecMdGrps || [])]
                                        ?.sort((a, b) => {
                                            return a.grp - b.grp;
                                        })
                                        .map((ecMdGrp, idx) => {
                                            const { mdgrpid, grp } = ecMdGrp;

                                            return (
                                                <ToggleButton
                                                    key={idx}
                                                    value={mdgrpid.toString()}
                                                    disabled={mdgrpid.toString() === selectedMdGrpId?.toString()}
                                                >
                                                    Groupe - {grp}
                                                </ToggleButton>
                                            );
                                        })}
                                </ToggleButtonGroup>
                            </Grid>
                        </>
                    ) : (
                        /**
                         * Examples of ECs without any Groupe available:
                         * http://localhost:85/ec?licence=1821362&ec_id=131
                         * http://localhost:85/ec?licence=1821362&ec_id=132
                         */
                        <>
                            <Grid item xs={12} container justifyContent="center">
                                <Typography>Aucun groupe disponible pour cet état de compte.</Typography>
                            </Grid>
                        </>
                    )
                ) : null}

                <Grid item xs={12} mt={3}>
                    <EcMdGrpsComponent ecMdGrpsData={ecMdGrpsData} selectedMdGrpId={selectedMdGrpId} />
                </Grid>
            </Grid>
            {/* 
            {getFacturesLoading ? (
                <Loading />
            ) : (
                <DataGridPro
                    getRowId={(row) => row.reqt_id}
                    rows={facturesData?.factures || []}
                    columns={columns}
                    pagination
                    pageSize={25}
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    autoHeight
                    disableSelectionOnClick
                />
            )} */}

            {/* <EditDrawer
                selectedFacture={selectedFacture}
                onClose={() => {
                    setSelectedFacture(null);
                }}
            /> */}
        </>
    );
}

const EcMdGrpsComponent = ({ ecMdGrpsData, selectedMdGrpId }) => {
    const ecMdGrps = ecMdGrpsData?.ecMdGrps || [];

    const columnsFacturation = useMemo(() => getFacturationColumns(), []);

    const columns1720 = useMemo(() => getEc1720Columns(), []);
    const columns1721 = useMemo(() => getEc1721Columns(), []);
    const columns1722 = useMemo(() => getEc1722Columns(), []);
    const columns1725 = useMemo(() => getEc1725Columns(), []);

    const stridesClaudeArs = [
        { min: -59.99, max: -55.0, fraisPalier: -0.56 },
        { min: 35.0, max: 39.99, fraisPalier: 0.36 },
        { min: 55.0, max: 59.99, fraisPalier: 0.56 },
        { min: 80.0, max: 84.99, fraisPalier: 0.81 },
        { min: 85.0, max: 89.99, fraisPalier: 0.86 },
        { min: 160.0, max: 164.99, fraisPalier: 1.61 },
        { min: 290.0, max: 294.99, fraisPalier: 2.91 },
    ];

    const strides = [
        { min: -289.99, max: -285.0, fraisPalier: -3.619 },
        { min: -94.99, max: -90.0, fraisPalier: -1.162 },
        { min: -59.99, max: -55.0, fraisPalier: -0.705 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: -49.99, max: -45.0, fraisPalier: -0.595 },
        { min: -34.99, max: -30.0, fraisPalier: -0.406 },
        { min: -29.99, max: -25.0, fraisPalier: -0.343 },
        { min: -24.99, max: -20.0, fraisPalier: -0.28 },
        { min: -9.99, max: -5.0, fraisPalier: -0.091 },
        { min: 0, max: 4.99, fraisPalier: 0.033 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 5.0, max: 9.99, fraisPalier: 0.091 },
        { min: 10.0, max: 14.99, fraisPalier: 0.154 },
        { min: 15.0, max: 19.99, fraisPalier: 0.217 },
        { min: 20.0, max: 24.99, fraisPalier: 0.28 },
        { min: 25.0, max: 29.99, fraisPalier: 0.343 },
        { min: 30.0, max: 34.99, fraisPalier: 0.406 },
        { min: 35.0, max: 39.99, fraisPalier: 0.469 },
        { min: 40.0, max: 44.99, fraisPalier: 0.532 },
        { min: 45.0, max: 49.99, fraisPalier: 0.595 },
        { min: 50.0, max: 54.99, fraisPalier: 0.658 },
        { min: 55.0, max: 59.99, fraisPalier: 0.721 },
        { min: 60.0, max: 64.99, fraisPalier: 0.784 },
        { min: 65.0, max: 69.99, fraisPalier: 0.847 },
        { min: 70.0, max: 74.99, fraisPalier: 0.91 },
        { min: 75.0, max: 79.99, fraisPalier: 0.973 },
        { min: 80.0, max: 84.99, fraisPalier: 1.036 },
        { min: 85.0, max: 89.99, fraisPalier: 1.099 },
        { min: 90.0, max: 94.99, fraisPalier: 1.146 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 95.0, max: 99.99, fraisPalier: 1.225 },
        { min: 100.0, max: 104.99, fraisPalier: 1.311 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 105.0, max: 109.99, fraisPalier: 1.351 },
        { min: 110.0, max: 114.99, fraisPalier: 1.414 },
        { min: 115.0, max: 119.99, fraisPalier: 1.477 },
        { min: 120.0, max: 124.99, fraisPalier: 1.54 },
        { min: 125.0, max: 129.99, fraisPalier: 1.603 },
        { min: 130.0, max: 134.99, fraisPalier: 1.666 },
        { min: 135.0, max: 139.99, fraisPalier: 1.729 },
        { min: 140.0, max: 144.99, fraisPalier: 1.792 },
        { min: 145.0, max: 149.99, fraisPalier: 1.855 },
        { min: 150.0, max: 154.99, fraisPalier: 1.918 },
        { min: 155.0, max: 159.99, fraisPalier: 1.981 },
        { min: 160.0, max: 164.99, fraisPalier: 2.044 },
        { min: 165.0, max: 169.99, fraisPalier: 1.942 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 170.0, max: 174.99, fraisPalier: 2.17 },
        { min: 175.0, max: 179.99, fraisPalier: 2.233 },
        { min: 180.0, max: 184.99, fraisPalier: 2.296 },
        { min: 190.0, max: 194.99, fraisPalier: 2.422 },
        { min: 245.0, max: 249.99, fraisPalier: 3.111 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 285.0, max: 289.99, fraisPalier: 3.55 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 310.0, max: 314.99, fraisPalier: 3.934 },
        { min: 320.0, max: 324.99, fraisPalier: 4.06 },
        { min: 325.0, max: 329.99, fraisPalier: 4.111 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 425.0, max: 429.99, fraisPalier: 5.383 },
        { min: 455.0, max: 459.99, fraisPalier: 5.761 },
        { min: 470.0, max: 474.99, fraisPalier: 5.95 },
        { min: 480.0, max: 484.99, fraisPalier: 6.076 },
        { min: 560.0, max: 564.99, fraisPalier: 7.084 },
        { min: 700.0, max: 704.99, fraisPalier: 8.848 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 705.0, max: 709.99, fraisPalier: 8.911 },
        { min: 840.0, max: 844.99, fraisPalier: 10.612 },
        { min: 845.0, max: 849.99, fraisPalier: 12.111 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 915.0, max: 919.99, fraisPalier: 13.111 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 1300.0, max: 1304.99, fraisPalier: 13.111 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 1485.0, max: 1489.99, fraisPalier: 13.111 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 1675.0, max: 1679.99, fraisPalier: 13.111 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 1830.0, max: 1834.99, fraisPalier: 13.111 }, // ! La valeur du fraisPalier n'est pas la bonne valeur, c'est juste un placeholder pour l'instant
        { min: 2015.0, max: 2019.99, fraisPalier: 25.417 },
    ];

    if (selectedMdGrpId) {
        const ecMdGrp = ecMdGrps.find((ecMdGrp) => ecMdGrp.mdgrpid === parseInt(selectedMdGrpId));

        if (!ecMdGrp) {
            return null;
        }

        const {
            mdgrpid,
            md,
            grp,
            ec_id,
            specialite,
            Medecin,
            Groupe,
            EcMaster,

            Ec1703,
            Ec1704,
            Ec1707,
            Ec1708,
            Ec1709,
            Ec1710,
            Ec1711,
            Ec1712,
            Ec1716,
            Ec1720,
            Ec1721,
            Ec1722,
            Ec1725,

            Ec1717ig,
        } = ecMdGrp;

        const { licence, prenom, nom, male, specialiste, lieutra, retraite } = Medecin;
        const {
            numero,
            // licence,
            denomination,
            incorp,
            debut,
            fin,
        } = Groupe || {}; // Groupe can be null which means it is the 0 groupe
        const {
            // ec_id,
            //  agence,
            nopayment,
            dateec,
            datecut,
        } = EcMaster;

        const {
            //  mdgrpid,
            //  compteur,
            //   valideur,
            msg,
            msg_permanent,
        } = Ec1703;
        const {
            // mdgrpid,
            // compteur,
            // valideur,
            nomfed,
            montantcotisation,
        } = Ec1708 || {};
        const {
            // mdgrpid,
            // compteur,
            // valideur,
            interetsurrecouv,
            interetsuravance,
            avancesemises,
            avancesrecouv,
            interetsursoldeneg,
        } = Ec1710 || {};
        const {
            // mdgrpid,
            // compteur,
            // valideur,
            soldereporte,
            soldeareporte,
            modifdesolde,
            paiementrecu,
            debutinteretsursoldeneg,
            codemsg,
            montantmsg1,
        } = Ec1711 || {};
        const {
            // mdgrpid,
            // compteur,
            // valideur,
            montantnetverse,
            nochequeouvirement,
            modepaiement,
        } = Ec1712 || {};

        let modePaiementText = '';
        if (modepaiement == 'C') modePaiementText = 'chèque';
        if (modepaiement == 'V') modePaiementText = 'virement';

        let facturesByPoste: any = {};
        let detailsFacturation: any[] = [];

        const processEcFacturation = (ec17, ecType) => {
            ec17.forEach((ec17Item) => {
                if (ecType === '1720') {
                    const { poste } = ec17Item.Facture || {};

                    if (poste === undefined) {
                        return;
                    }

                    ec17Item.Facture?.FactLignes.forEach((factLigne) => {
                        const { nce, revision, AMT_AT_EC } = factLigne;

                        const montantPayeCalcul = AMT_AT_EC;

                        if (montantPayeCalcul === 0) {
                            return;
                        }

                        processAddToFacturationArray(montantPayeCalcul, { poste, nce, revision }, factLigne);
                    });
                } else {
                    // * 1721, 1722 or 1725
                    const { poste } = ec17Item.Facture || {};

                    if (poste === undefined) {
                        return;
                    }

                    const { nce, rev, montantpaye } = ec17Item;

                    const montantPayeCalcul = montantpaye;

                    if (montantPayeCalcul === 0) {
                        return;
                    }

                    processAddToFacturationArray(montantPayeCalcul, { poste, nce, revision: rev }, ec17Item);
                }
            });
        };

        const processAddToFacturesByPoste = (obj: any) => {
            const { poste, ...facture } = obj;

            facturesByPoste[poste] = [...(facturesByPoste[poste] || []), facture];
        };

        const processAddToFacturationArray = (montantPayeCalcul, obj: any, factLigneOrEc17: any | null = null) => {
            processAddToFacturesByPoste({ ...obj, factLigneOrEc17 });

            let hasCorrespondingStride = false;

            strides.forEach((stride, strideIdx) => {
                const isCorrectStride = montantPayeCalcul >= stride.min && montantPayeCalcul <= stride.max;

                if (isCorrectStride) {
                    hasCorrespondingStride = true;

                    const { poste, nce, revision } = obj;

                    let correspondingDetailFacturationPosteObj = detailsFacturation.find(
                        (detailFact) => detailFact.Poste.poste === poste,
                    );

                    if (!correspondingDetailFacturationPosteObj) {
                        detailsFacturation.push({
                            Poste: { poste: poste },
                            strides: [],
                        });

                        correspondingDetailFacturationPosteObj = detailsFacturation[detailsFacturation.length - 1];
                    }

                    let correspondingDetailFacturationStrideObj = correspondingDetailFacturationPosteObj.strides.find(
                        (detailFact) => detailFact.strideIdx === strideIdx,
                    );

                    if (!correspondingDetailFacturationStrideObj) {
                        correspondingDetailFacturationPosteObj.strides.push({
                            strideIdx: strideIdx,
                            min: stride.min,
                            max: stride.max,
                            fraisPalier: stride.fraisPalier,
                            nbDemandes: 0,
                            totalRamq: 0,
                            totalFmp: 0,
                            factures: [],
                        });

                        correspondingDetailFacturationStrideObj =
                            correspondingDetailFacturationPosteObj.strides[
                                correspondingDetailFacturationPosteObj.strides.length - 1
                            ];
                    }

                    correspondingDetailFacturationStrideObj.nbDemandes += 1;
                    correspondingDetailFacturationStrideObj.totalRamq += montantPayeCalcul * 100;
                    correspondingDetailFacturationStrideObj.totalFmp += stride.fraisPalier * 1000;
                    correspondingDetailFacturationStrideObj.factures.push({ ...obj, factLigneOrEc17 });
                }
            });

            if (!hasCorrespondingStride) {
                console.warn(`No stride found for ${obj.poste}, ${obj.nce}, ${obj.revision}`, factLigneOrEc17);
            }
        };

        processEcFacturation(Ec1720, '1720');
        processEcFacturation(Ec1721, '1721');
        processEcFacturation(Ec1722, '1722');
        processEcFacturation(Ec1725, '1725');

        console.log('start', detailsFacturation);

        let totalFmp: number = 0;
        let totalRamq: number = 0;

        detailsFacturation = detailsFacturation
            .sort((a, b) => a.Poste.poste - b.Poste.poste)
            .map((item, idx) => {
                return {
                    ...item,
                    strides: item.strides
                        .sort((a, b) => a.strideIdx - b.strideIdx)
                        .map((item, idx) => {
                            const totalRamqRounded = roundNumber(item.totalRamq / 100);
                            const totalFmpRounded = roundNumber(item.totalFmp / 1000);

                            totalRamq += totalRamqRounded;
                            totalFmp += totalFmpRounded;

                            return { ...item, totalRamq: totalRamqRounded, totalFmp: totalFmpRounded };
                        }),
                };
            });

        console.log('end', detailsFacturation);
        console.log(totalFmp);

        return (
            <Paper sx={{ p: 3 }} elevation={10}>
                <Grid container>
                    {/* <Grid item xs={12}>
                        <Typography variant="h6">Groupe #{grp}</Typography>
                        <Divider sx={{ mb: 2, mt: 1 }} />
                    </Grid> */}

                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h3" fontWeight="500" fontSize={33}>
                                {male ? 'Dr.' : 'Dre.'} {nom}, {prenom}
                            </Typography>
                        </Box>

                        <Typography variant="subtitle1">No licence: {licence}</Typography>
                    </Grid>

                    <Grid item xs={6} container flexDirection="column" alignItems="flex-end">
                        <Typography variant="h6" fontSize={18}>
                            Date de l'état de compte: {dateec}
                        </Typography>

                        <Typography variant="h6" fontSize={18}>
                            Date de la coupure: {datecut}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} mt={3} container flexDirection="column" alignItems="center">
                        <Typography variant="h6" fontSize={20}>
                            Groupe #{numero || 0}
                        </Typography>

                        {Groupe && (
                            <Typography variant="h6" fontSize={25}>
                                {denomination}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12} mt={8}>
                        <SectionTitle>Détails sur le paiement</SectionTitle>

                        <SubSectionTitle>
                            <Box display="flex" alignItems="center">
                                Solde
                                {Ec1711 && (
                                    <Tooltip
                                        title={
                                            <>
                                                <Typography variant="body2">
                                                    Début du solde: {debutinteretsursoldeneg}
                                                </Typography>
                                                <Typography variant="body2">Code msg: {codemsg}</Typography>
                                                <Typography variant="body2">Montant msg 1: {montantmsg1}</Typography>
                                            </>
                                        }
                                        placement="right"
                                        arrow
                                        sx={{ ml: 0.8 }}
                                    >
                                        <IconButton size="small">
                                            <HelpIcon fontSize="small" color="info" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        </SubSectionTitle>
                        <PaddedSection>
                            <LineItem title="Solde reporté" value={soldereporte} />
                            <LineItem title="Solde à reporter" value={soldeareporte} />
                            <LineItem title="Modification du solde" value={modifdesolde} />
                            <LineItem title="Paiement reçu" value={paiementrecu} />
                        </PaddedSection>

                        <SubSectionTitle>Montants bruts</SubSectionTitle>
                        <PaddedSection>
                            {[...(Ec1704 || [])]
                                .sort((a, b) => {
                                    return a.compteur - b.compteur;
                                })
                                .map((item, idx) => {
                                    const {
                                        // mdgrpid,
                                        // compteur,
                                        // valideur,
                                        codetrans,
                                        montantbrut,
                                        libellecodetrans,
                                    } = item;

                                    const title = (
                                        <Box display="flex" alignItems="center">
                                            {libellecodetrans}
                                            <Tooltip
                                                title={
                                                    <>
                                                        <Typography variant="body2">Codetrans: {codetrans}</Typography>
                                                    </>
                                                }
                                                placement="right"
                                                arrow
                                                sx={{ ml: 0.8 }}
                                            >
                                                <IconButton size="small">
                                                    <HelpIcon fontSize="small" color="info" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    );
                                    const value = montantbrut;

                                    return <LineItem key={idx} title={title} value={value} />;
                                })}
                        </PaddedSection>

                        <SubSectionTitle>Déductions</SubSectionTitle>
                        <PaddedSection>
                            {[...(Ec1707 || [])]
                                .sort((a, b) => {
                                    return a.compteur - b.compteur;
                                })
                                .map((item, idx) => {
                                    const {
                                        mdgrpid,
                                        compteur,
                                        valideur,
                                        codededuction,
                                        montantdeduction,
                                        libellededuction,
                                        dateenrgded,
                                        nocompteded,
                                    } = item;

                                    const title = `${libellededuction} (codededuction: ${codededuction})`;
                                    const value = montantdeduction;

                                    return <LineItem key={idx} title={title} value={value} />;
                                })}
                        </PaddedSection>

                        <SubSectionTitle>Transferts de solde</SubSectionTitle>
                        <PaddedSection>
                            {[...(Ec1709 || [])]
                                .sort((a, b) => {
                                    return a.compteur - b.compteur;
                                })
                                .map((item, idx) => {
                                    const {
                                        mdgrpid,
                                        compteur,
                                        valideur,
                                        codetransfert,
                                        classegrptransfert,
                                        nogrptransfert,
                                        montanttransfert,
                                    } = item;

                                    const title = (
                                        <Box display="flex" alignItems="center">
                                            {`${
                                                codetransfert === 'A' ? 'Transfert au groupe' : 'Transfert du groupe'
                                            } ${nogrptransfert}`}

                                            <Tooltip
                                                title={
                                                    <>
                                                        <Typography variant="body2">
                                                            Classe du groupe de transfert: {classegrptransfert}
                                                        </Typography>
                                                    </>
                                                }
                                                placement="right"
                                                arrow
                                                sx={{ ml: 0.8 }}
                                            >
                                                <IconButton size="small">
                                                    <HelpIcon fontSize="small" color="info" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    );
                                    const value = montanttransfert;

                                    return <LineItem key={idx} title={title} value={value} />;
                                })}
                        </PaddedSection>

                        <SubSectionTitle>Intérêts et avances</SubSectionTitle>
                        <PaddedSection>
                            <LineItem title="Intérêts sur recouv" value={interetsurrecouv} />
                            <LineItem title="Intérêts sur avance" value={interetsuravance} />
                            <LineItem title="Avances remises" value={avancesemises} />
                            <LineItem title="Avances recouv" value={avancesrecouv} />
                            <LineItem title="Intérêts sur solde neg" value={interetsursoldeneg} />
                        </PaddedSection>

                        <SubSectionTitle>Cotisation de fédération</SubSectionTitle>
                        {Ec1708 && (
                            <PaddedSection>
                                <LineItem title={`Cotisation à la ${nomfed}`} value={montantcotisation} />
                            </PaddedSection>
                        )}

                        <SubSectionTitle>Montant net</SubSectionTitle>
                        {Ec1712 && (
                            <PaddedSection>
                                <LineItem
                                    title={`Paiement via ${modePaiementText} (No. ${nochequeouvirement})`}
                                    value={montantnetverse}
                                />
                            </PaddedSection>
                        )}
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <SectionTitle>Messages de la RAMQ</SectionTitle>

                        {msg && (
                            <>
                                <Typography>{msg}</Typography>
                                <br />
                            </>
                        )}

                        {msg_permanent && (
                            <>
                                <Typography>{msg_permanent}</Typography>
                                <br />
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <SectionTitle>Cumulatifs</SectionTitle>

                        <SubSectionTitle>Cumulatifs de fédération</SubSectionTitle>
                        <PaddedSection>
                            {[...(Ec1716 || [])]
                                .sort((a, b) => {
                                    return a.compteur - b.compteur;
                                })
                                .map((item, idx) => {
                                    const {
                                        // mdgrpid,
                                        //  compteur,
                                        //   valideur,
                                        nomfederation,
                                        montantcumulatif,
                                    } = item;

                                    const title = `Montant cumulatif de la ${nomfederation}`;
                                    const value = montantcumulatif;

                                    return <LineItem key={idx} title={title} value={value} />;
                                })}
                        </PaddedSection>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <SectionTitle>Autres demandes</SectionTitle>

                        <Grid item xs={12}>
                            <ForfaitaireComponent Ec1717ig={Ec1717ig} />
                        </Grid>
                    </Grid>

                    <SpanningTable detailsFacturation={detailsFacturation} totalFmp={totalFmp} Ec1717ig={Ec1717ig} />

                    <Typography variant="h5">Total RAMQ {totalRamq}</Typography>

                    {detailsFacturation.map((detailFact, idx) => {
                        const { Poste, strides } = detailFact;

                        return (
                            <Grid item xs={12} mt={5}>
                                <SectionTitle>
                                    Facturation {Poste.poste} ({formatNumber(totalFmp)}$)
                                </SectionTitle>

                                <>
                                    {facturesByPoste[Poste.poste].map((facture, idx) => {
                                        const { nce, revision } = facture;

                                        return (
                                            <Box key={idx}>
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    component={RouterLink}
                                                    to={`/facture?nce=${nce}&revision=${revision}`}
                                                    target="_blank"
                                                >
                                                    {nce}__{revision}
                                                    <OpenInNewIcon fontSize="inherit" />
                                                </IconButton>
                                                {idx + 1 < facturesByPoste.length ? ' / ' : ''}
                                            </Box>
                                        );
                                    })}
                                </>

                                <Grid item xs={12}>
                                    <DataGridPro
                                        getRowId={(row) => uuidv4()}
                                        rows={strides}
                                        columns={columnsFacturation}
                                        pagination
                                        pageSize={100}
                                        rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                        autoHeight
                                        disableSelectionOnClick
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}

                    <Grid item xs={12} mt={5}>
                        <SectionTitle>
                            Factures réglées avec montant final égal au montant préliminaire (1720) (Total de{' '}
                            {formatNumber(
                                Ec1720.reduce((prevValue, curr) => {
                                    return (prevValue += curr.montantpaye);
                                }, 0),
                            )}
                            $)
                        </SectionTitle>

                        <Grid item xs={12}>
                            <DataGridPro
                                getRowId={(row) => uuidv4()}
                                rows={Ec1720 || []}
                                columns={columns1720}
                                pagination
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                autoHeight
                                disableSelectionOnClick
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <SectionTitle>
                            Factures dont le montant final diffère du montant préliminaire (1721) (Total de{' '}
                            {formatNumber(
                                Ec1721.reduce((prevValue, curr) => {
                                    return (prevValue += curr.montantpaye);
                                }, 0),
                            )}
                            $)
                        </SectionTitle>

                        <Grid item xs={12}>
                            <DataGridPro
                                getRowId={(row) => uuidv4()}
                                rows={Ec1721 || []}
                                columns={columns1721}
                                pagination
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                autoHeight
                                disableSelectionOnClick
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <SectionTitle>Factures dont une ou plusieurs lignes ont été modifiées (1722)</SectionTitle>

                        <Grid item xs={12}>
                            <DataGridPro
                                getRowId={(row) => uuidv4()}
                                rows={Ec1722 || []}
                                columns={columns1722}
                                pagination
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                autoHeight
                                disableSelectionOnClick
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={5}>
                        <SectionTitle>Factures dont une ou plusieurs lignes ont été modifiées (1725)</SectionTitle>

                        <Grid item xs={12}>
                            <DataGridPro
                                getRowId={(row) => uuidv4()}
                                rows={Ec1725 || []}
                                columns={columns1725}
                                pagination
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                autoHeight
                                disableSelectionOnClick
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container justifyContent="flex-end" mt={6}>
                        <Button variant="contained" size="small" endIcon={<ArrowForwardIcon />}>
                            Détails sur ces demandes
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    } else {
        return null;
    }
};

const SpanningTable = ({ detailsFacturation, totalFmp, Ec1717ig }) => {
    const totalFmpByPoste: { poste: number; total: number }[] = [];

    detailsFacturation.forEach((item, idx) => {
        const {
            Poste: { poste },
            strides,
        } = item;

        strides.forEach((item, idx) => {
            const { totalFmp } = item;

            let totalFmpByPosteCorresponding = totalFmpByPoste.find((item) => item.poste === poste);

            if (!totalFmpByPosteCorresponding) {
                totalFmpByPoste.push({ poste, total: 0 });

                totalFmpByPosteCorresponding = totalFmpByPoste[totalFmpByPoste.length - 1];
            }

            totalFmpByPosteCorresponding.total += totalFmp;
        });
    });

    const resultsFeuilles = getTotalFeuillesBilled(Ec1717ig);

    let totalFeuilles = 0;
    resultsFeuilles.forEach((ec17) => {
        const { __typename, nbFeuilles } = ec17;

        totalFeuilles += nbFeuilles;
    });

    // resultsFeuilles.map((ec17) => {
    //     const { __typename, nbFeuilles } = ec17;

    //     return (
    //         <>
    //             <Typography variant="h4" fontWeight="bold" mt={5}>
    //                 Nombre de feuilles {__typename}: {nbFeuilles} ({formatNumber(nbFeuilles * 5)} $)
    //             </Typography>
    //         </>
    //     );
    // });

    const rows: { title: string; qty: number; amtPerUnit: number; amtTotal: number }[] = [];

    totalFmpByPoste.forEach((item) => {
        const { poste, total } = item;

        rows.push({
            title: `Total facturé pour poste #${poste}`,
            qty: 1,
            amtPerUnit: total,
            amtTotal: total,
        });
    });

    rows.push({
        title: `Feuilles traitées (Tarif horaire/Salariat/Mixte)`,
        qty: totalFeuilles,
        amtPerUnit: 5,
        amtTotal: totalFeuilles * 5,
    });

    rows.push({
        title: `Rabais relevé électronique (Kin)`,
        qty: 1,
        amtPerUnit: -10.75,
        amtTotal: -10.75,
    });

    const subTotal = rows.reduce((prevValue, curr) => (prevValue += curr.amtTotal), 0);

    const TPS_RATE = 5;
    const TVQ_RATE = 9.975;

    const tps = roundNumber((subTotal * TPS_RATE) / 100);
    const tvq = roundNumber((subTotal * TVQ_RATE) / 100);

    const total = subTotal + tps + tvq;

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={3}>
                            Détails
                        </TableCell>
                        <TableCell align="right">Prix</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Élément facturé</TableCell>
                        <TableCell align="right">Quantité</TableCell>
                        <TableCell align="right">Prix/Unité</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idx) => {
                        const { title, qty, amtPerUnit, amtTotal } = row;

                        return (
                            <TableRow key={idx}>
                                <TableCell>{title}</TableCell>
                                <TableCell align="right">{qty}</TableCell>
                                <TableCell align="right">{formatNumber(amtPerUnit)} $</TableCell>
                                <TableCell align="right">{formatNumber(amtTotal)} $</TableCell>
                            </TableRow>
                        );
                    })}
                    <TableRow>
                        <TableCell rowSpan={4} />
                        <TableCell colSpan={2}>Sous total</TableCell>
                        <TableCell align="right">{formatNumber(subTotal)} $</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>TPS</TableCell>
                        <TableCell align="right">{`${TPS_RATE} %`}</TableCell>
                        <TableCell align="right">{formatNumber(tps)} $</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>TVQ</TableCell>
                        <TableCell align="right">{`${TVQ_RATE} %`}</TableCell>
                        <TableCell align="right">{formatNumber(tvq)} $</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography>Grand total</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography>{formatNumber(total)} $</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const getTotalFeuillesBilled = (Ec1717ig) => {
    const results: any[] = [];

    (Ec1717ig || []).forEach((ec1717ig, idx) => {
        const { Ec1717f2, Ec1717m2, Ec1717r2, Ec1717v2, Ec1717v3, Ec1717v4, Ec1717x3 } = ec1717ig || {};

        [Ec1717f2, Ec1717m2, Ec1717r2, Ec1717v2, Ec1717v3, Ec1717v4, Ec1717x3].forEach((ec17) => {
            ec17.forEach((ec17Item) => {
                const { __typename } = ec17Item;

                let correspondingResultEcType = results.find((result) => result.__typename === __typename);

                if (!correspondingResultEcType) {
                    results.push({ __typename, nbFeuilles: 0 });

                    correspondingResultEcType = results[results.length - 1];
                }

                correspondingResultEcType.nbFeuilles += 1;
            });
        });
    });

    console.log(results);

    return results;
};

const ForfaitaireComponent = ({ Ec1717ig }) => {
    return (
        <PaddedSection>
            {[...(Ec1717ig || [])]
                .sort((a, b) => a.compteur - b.compteur)
                .map((ec1717ig, idx) => {
                    const {
                        mdgrpid,
                        ec_id,
                        typereglement,
                        compteur,
                        valideur,
                        masterid,
                        nodemande,
                        debutperiode,
                        finperiode,
                        datereception,
                        nodemref1,
                        nodemref2,
                        codetrans,
                        libellecodetrans,
                        codemsgexpl1,
                        codemsgexpl2,
                        codemsgexpl3,
                        montantinteret,
                        noattestation,
                    } = ec1717ig;
                    const { Ec1717f2, Ec1717m2, Ec1717r2, Ec1717v2, Ec1717v3, Ec1717v4, Ec1717x3 } = ec1717ig || {};

                    const ec1717igText = `${debutperiode} au ${finperiode}. Reçu le ${datereception}.`;

                    return (
                        <>
                            <SubSectionTitle>
                                <Box display="flex" alignItems="center">
                                    {ec1717igText}
                                    <Tooltip
                                        title={
                                            <>
                                                <Typography variant="body2">mdgrpid: {mdgrpid}</Typography>
                                                <Typography variant="body2">ec_id: {ec_id}</Typography>
                                                <Typography variant="body2">typereglement: {typereglement}</Typography>
                                                <Typography variant="body2">compteur: {compteur}</Typography>
                                                <Typography variant="body2">valideur: {valideur}</Typography>
                                                <Typography variant="body2">masterid: {masterid}</Typography>
                                                <Typography variant="body2">nodemande: {nodemande}</Typography>
                                                <Typography variant="body2">debutperiode: {debutperiode}</Typography>
                                                <Typography variant="body2">finperiode: {finperiode}</Typography>
                                                <Typography variant="body2">datereception: {datereception}</Typography>
                                                <Typography variant="body2">nodemref1: {nodemref1}</Typography>
                                                <Typography variant="body2">nodemref2: {nodemref2}</Typography>
                                                <Typography variant="body2">codetrans: {codetrans}</Typography>
                                                <Typography variant="body2">
                                                    libellecodetrans: {libellecodetrans}
                                                </Typography>
                                                <Typography variant="body2">codemsgexpl1: {codemsgexpl1}</Typography>
                                                <Typography variant="body2">codemsgexpl2: {codemsgexpl2}</Typography>
                                                <Typography variant="body2">codemsgexpl3: {codemsgexpl3}</Typography>
                                                <Typography variant="body2">
                                                    montantinteret: {montantinteret}
                                                </Typography>
                                                <Typography variant="body2">noattestation: {noattestation}</Typography>
                                            </>
                                        }
                                        placement="right"
                                        arrow
                                        sx={{ ml: 0.8 }}
                                    >
                                        <IconButton size="small">
                                            <HelpIcon fontSize="small" color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </SubSectionTitle>

                            <Box sx={{ my: 2 }}></Box>

                            <PaddedSection>
                                <PaddedSection>
                                    <SubSectionTitle>
                                        Forfaitaire, à l'acte, DME et autres? (EC1717 - f2)
                                    </SubSectionTitle>
                                    <PaddedSection>
                                        {[...(Ec1717f2 || [])]
                                            .sort((a, b) => {
                                                return a.compteur - b.compteur;
                                            })
                                            .map((item, idx) => {
                                                const {
                                                    masterid,
                                                    compteur,
                                                    valideur,
                                                    mdgrpid,
                                                    libelleforfaitaire,
                                                    taux,
                                                    montantpaye,
                                                } = item;

                                                const title = `${libelleforfaitaire} (taux: ${taux})`;
                                                const value = montantpaye;

                                                return <LineItem key={idx} title={title} value={value} />;
                                            })}
                                    </PaddedSection>

                                    <SubSectionTitle>Formation? (EC1717 - m2)</SubSectionTitle>
                                    <PaddedSection>
                                        {[...(Ec1717m2 || [])]
                                            .sort((a, b) => {
                                                return a.compteur - b.compteur;
                                            })
                                            .map((item, idx) => {
                                                const {
                                                    masterid,
                                                    compteur,
                                                    valideur,
                                                    mdgrpid,
                                                    nodemande,
                                                    typemesinc,
                                                    libelleelmmesinc,
                                                    elmmesinc,
                                                    libelleelmmesinc2,
                                                    codemsgexpl1,
                                                    codemsgexpl2,
                                                    montantpaye,
                                                    nombrejours,
                                                    montantconsidere,
                                                    nombrejourscons,
                                                    montantconsant,
                                                    nombrejoursconsant,
                                                    reference,
                                                    debutmesureinc,
                                                    finmesureinc,
                                                } = item;

                                                const title = (
                                                    <Box display="flex" alignItems="center">
                                                        {libelleelmmesinc}
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    <Typography>No demande: {nodemande}</Typography>
                                                                    <Typography>typemesinc: {typemesinc}</Typography>
                                                                    <Typography>
                                                                        libelleelmmesinc: {libelleelmmesinc}
                                                                    </Typography>
                                                                    <Typography>elmmesinc: {elmmesinc}</Typography>
                                                                    <Typography>
                                                                        libelleelmmesinc2: {libelleelmmesinc2}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl1: {codemsgexpl1}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl2: {codemsgexpl2}
                                                                    </Typography>
                                                                    <Typography>montantpaye: {montantpaye}</Typography>
                                                                    <Typography>nombrejours: {nombrejours}</Typography>
                                                                    <Typography>
                                                                        montantconsidere: {montantconsidere}
                                                                    </Typography>
                                                                    <Typography>
                                                                        nombrejourscons: {nombrejourscons}
                                                                    </Typography>
                                                                    <Typography>
                                                                        montantconsant: {montantconsant}
                                                                    </Typography>
                                                                    <Typography>
                                                                        nombrejoursconsant: {nombrejoursconsant}
                                                                    </Typography>
                                                                    <Typography>reference: {reference}</Typography>
                                                                    <Typography>
                                                                        debutmesureinc: {debutmesureinc}
                                                                    </Typography>
                                                                    <Typography>
                                                                        finmesureinc: {finmesureinc}
                                                                    </Typography>
                                                                </>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            sx={{ ml: 0.8 }}
                                                        >
                                                            <IconButton size="small">
                                                                <HelpIcon fontSize="small" color="info" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                );
                                                const value = montantpaye;

                                                return <LineItem key={idx} title={title} value={value} />;
                                            })}
                                    </PaddedSection>

                                    <SubSectionTitle>Mesures particulières (EC1717 - r2)</SubSectionTitle>
                                    <PaddedSection>
                                        {[...(Ec1717r2 || [])]
                                            .sort((a, b) => {
                                                return a.compteur - b.compteur;
                                            })
                                            .map((item, idx) => {
                                                const {
                                                    masterid,
                                                    compteur,
                                                    valideur,
                                                    mdgrpid,
                                                    nodemande,
                                                    typemesurepart,
                                                    libelletypemesurepart,
                                                    typeappl,
                                                    montantpaye,
                                                    montantcons,
                                                    montantconsant,
                                                } = item;

                                                const title = (
                                                    <Box display="flex" alignItems="center">
                                                        {libelletypemesurepart}
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    <Typography>No demande: {nodemande}</Typography>
                                                                    <Typography>
                                                                        typemesurepart: {typemesurepart}
                                                                    </Typography>
                                                                    <Typography>
                                                                        libelletypemesurepart: {libelletypemesurepart}
                                                                    </Typography>
                                                                    <Typography>typeappl: {typeappl}</Typography>
                                                                    <Typography>montantpaye: {montantpaye}</Typography>
                                                                    <Typography>montantcons: {montantcons}</Typography>
                                                                    <Typography>
                                                                        montantconsant: {montantconsant}
                                                                    </Typography>
                                                                </>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            sx={{ ml: 0.8 }}
                                                        >
                                                            <IconButton size="small">
                                                                <HelpIcon fontSize="small" color="info" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                );
                                                const value = montantpaye;

                                                return <LineItem key={idx} title={title} value={value} />;
                                            })}
                                    </PaddedSection>

                                    <SubSectionTitle
                                        value={[...(Ec1717v2 || [])].reduce(
                                            (prevValue, curr) => prevValue + curr.montantpaye,
                                            0,
                                        )}
                                    >
                                        Vacation-Honoraires Forfaitaires (EC1717 - v2)
                                    </SubSectionTitle>
                                    <PaddedSection>
                                        {[...(Ec1717v2 || [])]
                                            .sort((a, b) => {
                                                return a.compteur - b.compteur;
                                            })
                                            .map((item, idx) => {
                                                const {
                                                    masterid,
                                                    compteur,
                                                    valideur,
                                                    mdgrpid,
                                                    nodemande,
                                                    codenatserv,
                                                    libellecodenatserv,
                                                    codeemploitemps,
                                                    libellecodeemploitemps,
                                                    codemsgexpl1,
                                                    codemsgexpl2,
                                                    codemsgexpl3,
                                                    montantpaye,
                                                    nombreheurespayees,
                                                    montantcons,
                                                    nombreheurescons,
                                                    montantconsant,
                                                    nombreheuresconsant,
                                                    reference,
                                                } = item;

                                                const title = (
                                                    <Box display="flex" alignItems="center">
                                                        {libellecodeemploitemps}
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    <Typography>No demande: {nodemande}</Typography>
                                                                    <Typography>codenatserv: {codenatserv}</Typography>
                                                                    <Typography>
                                                                        libellecodenatserv: {libellecodenatserv}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codeemploitemps: {codeemploitemps}
                                                                    </Typography>
                                                                    <Typography>
                                                                        libellecodeemploitemps: {libellecodeemploitemps}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl1: {codemsgexpl1}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl2: {codemsgexpl2}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl3: {codemsgexpl3}
                                                                    </Typography>
                                                                    <Typography>montantpaye: {montantpaye}</Typography>
                                                                    <Typography>
                                                                        nombreheurespayees: {nombreheurespayees}
                                                                    </Typography>
                                                                    <Typography>montantcons: {montantcons}</Typography>
                                                                    <Typography>
                                                                        nombreheurescons: {nombreheurescons}
                                                                    </Typography>
                                                                    <Typography>
                                                                        montantconsant: {montantconsant}
                                                                    </Typography>
                                                                    <Typography>
                                                                        nombreheuresconsant: {nombreheuresconsant}
                                                                    </Typography>
                                                                    <Typography>reference: {reference}</Typography>
                                                                </>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            sx={{ ml: 0.8 }}
                                                        >
                                                            <IconButton size="small">
                                                                <HelpIcon fontSize="small" color="info" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                );
                                                const value = montantpaye;

                                                return <LineItem key={idx} title={title} value={value} />;
                                            })}
                                    </PaddedSection>

                                    <SubSectionTitle
                                        value={[...(Ec1717v3 || [])].reduce(
                                            (prevValue, curr) => prevValue + curr.montantpaye,
                                            0,
                                        )}
                                    >
                                        ....? (EC1717 - v3)
                                    </SubSectionTitle>
                                    <PaddedSection>
                                        {[...(Ec1717v3 || [])]
                                            .sort((a, b) => {
                                                return a.compteur - b.compteur;
                                            })
                                            .map((item, idx) => {
                                                const {
                                                    masterid,
                                                    compteur,
                                                    valideur,
                                                    mdgrpid,
                                                    nodemande,
                                                    codemsgexpl1,
                                                    codemsgexpl2,
                                                    codemsgexpl3,
                                                    montantpaye,
                                                    nombreheurespayees,
                                                    montantcons,
                                                    nombreheurescons,
                                                    montantconsant,
                                                    nombreheuresconsant,
                                                    reference,
                                                } = item;

                                                const title = (
                                                    <Box display="flex" alignItems="center">
                                                        Ec1717v3
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    <Typography>No demande: {nodemande}</Typography>
                                                                    <Typography>
                                                                        codemsgexpl1: {codemsgexpl1}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl2: {codemsgexpl2}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl3: {codemsgexpl3}
                                                                    </Typography>
                                                                    <Typography>montantpaye: {montantpaye}</Typography>
                                                                    <Typography>
                                                                        nombreheurespayees: {nombreheurespayees}
                                                                    </Typography>
                                                                    <Typography>montantcons: {montantcons}</Typography>
                                                                    <Typography>
                                                                        nombreheurescons: {nombreheurescons}
                                                                    </Typography>
                                                                    <Typography>
                                                                        montantconsant: {montantconsant}
                                                                    </Typography>
                                                                    <Typography>
                                                                        nombreheuresconsant: {nombreheuresconsant}
                                                                    </Typography>
                                                                    <Typography>reference: {reference}</Typography>
                                                                </>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            sx={{ ml: 0.8 }}
                                                        >
                                                            <IconButton size="small">
                                                                <HelpIcon fontSize="small" color="info" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                );
                                                const value = montantpaye;

                                                return <LineItem key={idx} title={title} value={value} />;
                                            })}
                                    </PaddedSection>

                                    <SubSectionTitle
                                        value={[...(Ec1717v4 || [])].reduce(
                                            (prevValue, curr) => prevValue + curr.montantpaye,
                                            0,
                                        )}
                                    >
                                        Vacation-Honoraires Forfaitaires (EC1717 - v4)
                                    </SubSectionTitle>
                                    <PaddedSection>
                                        {[...(Ec1717v4 || [])]
                                            .sort((a, b) => {
                                                return a.compteur - b.compteur;
                                            })
                                            .map((item, idx) => {
                                                const {
                                                    masterid,
                                                    compteur,
                                                    valideur,
                                                    mdgrpid,
                                                    nodemande,
                                                    codemsgexpl1,
                                                    codemsgexpl2,
                                                    codemsgexpl3,
                                                    montantpaye,
                                                    nombrekmspayes,
                                                    montantcons,
                                                    nombrekmscons,
                                                    montantconsant,
                                                    nombrekmsconsant,
                                                    reference,
                                                    refocctempsdepl,
                                                } = item;

                                                const title = (
                                                    <Box display="flex" alignItems="center">
                                                        Ec1717v4
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    <Typography>No demande: {nodemande}</Typography>
                                                                    <Typography>
                                                                        codemsgexpl1: {codemsgexpl1}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl2: {codemsgexpl2}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl3: {codemsgexpl3}
                                                                    </Typography>
                                                                    <Typography>montantpaye: {montantpaye}</Typography>
                                                                    <Typography>
                                                                        nombrekmspayes: {nombrekmspayes}
                                                                    </Typography>
                                                                    <Typography>montantcons: {montantcons}</Typography>
                                                                    <Typography>
                                                                        nombrekmscons: {nombrekmscons}
                                                                    </Typography>
                                                                    <Typography>
                                                                        montantconsant: {montantconsant}
                                                                    </Typography>
                                                                    <Typography>
                                                                        nombrekmsconsant: {nombrekmsconsant}
                                                                    </Typography>
                                                                    <Typography>reference: {reference}</Typography>
                                                                    <Typography>
                                                                        refocctempsdepl: {refocctempsdepl}
                                                                    </Typography>
                                                                </>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            sx={{ ml: 0.8 }}
                                                        >
                                                            <IconButton size="small">
                                                                <HelpIcon fontSize="small" color="info" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                );
                                                const value = montantpaye;

                                                return <LineItem key={idx} title={title} value={value} />;
                                            })}
                                    </PaddedSection>

                                    <SubSectionTitle
                                        value={[...(Ec1717x3 || [])].reduce(
                                            (prevValue, curr) => prevValue + curr.montantpaye,
                                            0,
                                        )}
                                    >
                                        Rémunération mixte
                                    </SubSectionTitle>
                                    <PaddedSection>
                                        {[...(Ec1717x3 || [])]
                                            .sort((a, b) => {
                                                return a.compteur - b.compteur;
                                            })
                                            .map((item, idx) => {
                                                const {
                                                    masterid,
                                                    compteur,
                                                    valideur,
                                                    mdgrpid,
                                                    nodemande,
                                                    codemsgexpl1,
                                                    codemsgexpl2,
                                                    codemsgexpl3,
                                                    montantpaye,
                                                    typeunitpayees,
                                                    nombreunitpayees,
                                                    nombreunitcons,
                                                    nombreunitconsant,
                                                    reference,
                                                } = item;

                                                const title = (
                                                    <Box display="flex" alignItems="center">
                                                        Ec1717x3
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    <Typography>No demande: {nodemande}</Typography>
                                                                    <Typography>
                                                                        codemsgexpl1: {codemsgexpl1}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl2: {codemsgexpl2}
                                                                    </Typography>
                                                                    <Typography>
                                                                        codemsgexpl3: {codemsgexpl3}
                                                                    </Typography>
                                                                    <Typography>montantpaye: {montantpaye}</Typography>
                                                                    <Typography>
                                                                        typeunitpayees: {typeunitpayees}
                                                                    </Typography>
                                                                    <Typography>
                                                                        nombreunitpayees: {nombreunitpayees}
                                                                    </Typography>
                                                                    <Typography>
                                                                        nombreunitcons: {nombreunitcons}
                                                                    </Typography>
                                                                    <Typography>
                                                                        nombreunitconsant: {nombreunitconsant}
                                                                    </Typography>
                                                                    <Typography>reference: {reference}</Typography>
                                                                </>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            sx={{ ml: 0.8 }}
                                                        >
                                                            <IconButton size="small">
                                                                <HelpIcon fontSize="small" color="info" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                );
                                                const value = montantpaye;

                                                return <LineItem key={idx} title={title} value={value} />;
                                            })}
                                    </PaddedSection>
                                </PaddedSection>
                            </PaddedSection>
                        </>
                    );
                })}
        </PaddedSection>
    );
};

const SectionTitle = ({ children }) => {
    return (
        <>
            <Typography variant="h6" fontSize={22}>
                {children}
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
        </>
    );
};

const SubSectionTitle = ({ children, value = null }) => {
    let valueFormatted: string = '';

    if (!value && value !== 0) {
        valueFormatted = '';
    } else {
        valueFormatted = formatNumber(value);
    }

    return (
        <Grid item xs={12} container justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="subtitle1" fontWeight="bold">
                {children}
            </Typography>

            {valueFormatted && (
                <Typography variant="subtitle1" fontWeight="bold">
                    {valueFormatted}$
                </Typography>
            )}
        </Grid>
    );
};

const PaddedSection = ({ children }) => {
    return (
        <Grid item xs={12} pl={3} mb={3}>
            {children}
        </Grid>
    );
};

const LineItem = ({ title, value }: { title: string | ReactNode; value: number }) => {
    let valueFormatted: string = '';

    if (!value && value !== 0) {
        valueFormatted = '0.00';
    } else {
        valueFormatted = formatNumber(value);
    }

    return (
        <>
            <Grid item xs={12} container justifyContent="space-between">
                <Typography variant="body1">{title}</Typography>

                <Typography variant="body1" fontWeight="bold">
                    {valueFormatted}$
                </Typography>
            </Grid>
            <Divider sx={{ mt: 0.5, mb: 1 }} />
        </>
    );
};

const getColumns = (): GridColDefPro[] => [
    {
        field: 'nce',
        headerName: 'NCE',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'nci',
        headerName: 'No RAMQ (NCI)',
        width: 150,
    },
    {
        ...TYPE_SERVICE_NAME_COL_DEFAULTS,
        field: 'TYPE_SERVICE',
        headerName: 'Type service',
    },
    // {
    //     field: 'nam',
    //     headerName: 'NAM/Nom',
    //     width: 150,
    // },
    // {
    //     field: 'date',
    //     headerName: 'Date',
    //     width: 150,
    // },
    {
        field: 'TOTAL',
        headerName: 'Montant payé',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${params.value?.toFixed(2)} $`,
    },
    {
        field: 'creation',
        headerName: 'Entré dans FMP le',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => moment(params.value, 'x').format(SQL_DATETIME),
    },
    {
        field: 'revision',
        headerName: 'Nb. révisions',
        width: 120,
    },
    {
        field: 'poste',
        headerName: 'Poste',
        width: SMALL_STRING_COLUMN_WIDTH,
    },
    {
        field: 'statrecv',
        headerName: 'Statut recv. (1|2|3)',
        width: 150,
    },
    {
        field: 'annule',
        headerName: 'Annulé?',
        width: SMALL_STRING_COLUMN_WIDTH,
        renderCell: (params) => {
            const annule = params.value;

            return <BooleanIcon value={annule} />;
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nce, revision } = params.row;
            return (
                <>
                    <IconButton size="small" color="primary" href={`/factures/${nce}/${revision}`} target="_blank">
                        <OpenInNewIcon fontSize="inherit" />
                    </IconButton>
                </>
            );
        },
    },
];

const getFacturationColumns = (): GridColDefPro[] => [
    {
        field: 'min',
        headerName: 'De',
        width: 100,
    },
    {
        field: 'max',
        headerName: 'À',
        width: 100,
    },
    {
        field: 'nbDemandes',
        headerName: 'Nb demandes',
        width: 150,
    },
    {
        field: 'fraisPalier',
        headerName: 'Frais palier',
        width: 150,
    },
    {
        field: 'totalFmp',
        headerName: 'Montant facturé',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'totalRamq',
        headerName: 'Montant RAMQ',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'factures',
        headerName: 'Factures comprenant une/plusieurs ligne(s) correspondant au palier',
        width: 1000,
        renderCell: (params) => {
            const factures = params.value;

            return (
                <>
                    {factures.map((facture, idx) => {
                        const { nce, revision, factLigneOrEc17 } = facture;

                        return (
                            <Box key={idx}>
                                <IconButton
                                    size="small"
                                    color="primary"
                                    component={RouterLink}
                                    to={`/facture?nce=${nce}&revision=${revision}`}
                                    target="_blank"
                                >
                                    <Typography variant="body2">
                                        {nce}__{revision}__{factLigneOrEc17.__typename}__{factLigneOrEc17.id}
                                    </Typography>
                                    <OpenInNewIcon fontSize="inherit" />
                                </IconButton>
                                {idx + 1 < factures.length ? ' / ' : ''}
                            </Box>
                        );
                    })}
                </>
            );
        },
    },
];

const getEc1720Columns = (): GridColDefPro[] => [
    {
        field: 'nci',
        headerName: 'NCI',
        width: 150,
    },
    // {
    //     field: 'TYPE_SERVICE',
    //     headerName: 'Type service',
    //     width: DP_TYPE_SERVICE_COLUMN_WIDTH,
    // },
    {
        field: 'nam',
        headerName: 'NAM/Nom',
        width: 150,
    },
    {
        field: 'dateoccurrence',
        headerName: 'Date occurence',
        width: 200,
    },
    {
        field: 'montantpaye',
        headerName: 'Montant payé',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${params.value?.toFixed(2)} $`,
    },
    // {
    //     field: 'dateec',
    //     headerName: 'Date EC',
    //     width: DATETIME_COLUMN_WIDTH,
    //     valueFormatter: (params: any) => moment(params.value).format(SQL_DATE),
    // },
    {
        field: 'rev',
        headerName: 'Version',
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nci, rev } = params.row;

            return (
                <>
                    <IconButton
                        size="small"
                        color="primary"
                        component={RouterLink}
                        to={`/facture?nci=${nci}&revision=${rev}`}
                        target="_blank"
                    >
                        <OpenInNewIcon fontSize="inherit" />
                    </IconButton>
                </>
            );
        },
    },
];

const getEc1721Columns = (): GridColDefPro[] => [
    {
        field: 'nci',
        headerName: 'NCI',
        width: 150,
    },
    // {
    //     field: 'TYPE_SERVICE',
    //     headerName: 'Type service',
    //     width: DP_TYPE_SERVICE_COLUMN_WIDTH,
    // },
    {
        field: 'nam',
        headerName: 'NAM/Nom',
        width: 150,
    },
    {
        field: 'dateoccurrence',
        headerName: 'Date occurence',
        width: 200,
    },
    {
        field: 'montantprel',
        headerName: 'Montant prél.',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'montantpaye',
        headerName: 'Montant payé',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    // {
    //     field: 'dateec',
    //     headerName: 'Date EC',
    //     width: DATETIME_COLUMN_WIDTH,
    //     valueFormatter: (params: any) => moment(params.value).format(SQL_DATE),
    // },
    {
        field: 'rev',
        headerName: 'Version',
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nci, rev } = params.row;

            return (
                <>
                    <IconButton
                        size="small"
                        color="primary"
                        component={RouterLink}
                        to={`/facture?nci=${nci}&revision=${rev}`}
                        target="_blank"
                    >
                        <OpenInNewIcon fontSize="inherit" />
                    </IconButton>
                </>
            );
        },
    },
];

const getEc1722Columns = (): GridColDefPro[] => [
    {
        field: 'nci',
        headerName: 'NCI',
        width: 150,
    },
    // {
    //     field: 'TYPE_SERVICE',
    //     headerName: 'Type service',
    //     width: DP_TYPE_SERVICE_COLUMN_WIDTH,
    // },
    {
        field: 'nam',
        headerName: 'NAM/Nom',
        width: 150,
    },
    {
        field: 'dateoccurrence',
        headerName: 'Date occurence',
        width: 200,
    },
    {
        field: 'montantant',
        headerName: 'Montant ant.',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'montantnouv',
        headerName: 'Montant nouv.',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'montantpaye',
        headerName: 'Montant payé',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    // {
    //     field: 'dateec',
    //     headerName: 'Date EC',
    //     width: DATETIME_COLUMN_WIDTH,
    //     valueFormatter: (params: any) => moment(params.value).format(SQL_DATE),
    // },
    {
        field: 'rev',
        headerName: 'Version',
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nci, rev } = params.row;

            return (
                <>
                    <IconButton
                        size="small"
                        color="primary"
                        component={RouterLink}
                        to={`/facture?nci=${nci}&revision=${rev}`}
                        target="_blank"
                    >
                        <OpenInNewIcon fontSize="inherit" />
                    </IconButton>
                </>
            );
        },
    },
];

const getEc1725Columns = (): GridColDefPro[] => [
    {
        field: 'nci',
        headerName: 'NCI',
        width: 150,
    },
    // {
    //     field: 'TYPE_SERVICE',
    //     headerName: 'Type service',
    //     width: DP_TYPE_SERVICE_COLUMN_WIDTH,
    // },
    {
        field: 'nam',
        headerName: 'NAM/Nom',
        width: 150,
    },
    {
        field: 'dateoccurrence',
        headerName: 'Date occurence',
        width: 200,
    },
    {
        field: 'montantant',
        headerName: 'Montant ant.',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'montantnouv',
        headerName: 'Montant nouv.',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'montantpaye',
        headerName: 'Montant payé',
        width: 150,
        align: 'right',
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    // {
    //     field: 'dateec',
    //     headerName: 'Date EC',
    //     width: DATETIME_COLUMN_WIDTH,
    //     valueFormatter: (params: any) => moment(params.value).format(SQL_DATE),
    // },
    {
        field: 'rev',
        headerName: 'Version',
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nci, rev } = params.row;

            return (
                <>
                    <IconButton
                        size="small"
                        color="primary"
                        component={RouterLink}
                        to={`/facture?nci=${nci}&revision=${rev}`}
                        target="_blank"
                    >
                        <OpenInNewIcon fontSize="inherit" />
                    </IconButton>
                </>
            );
        },
    },
];
