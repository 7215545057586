import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { PaymentFields, MdFields, ClinicFields, BankAccountFields } from 'apollo/fragments';

export const GET_PAYMENTS = gql`
    ${PaymentFields}

    query GetPayments($md_id: Int, $clinic_id: Int, $start_date: DateTime, $end_date: DateTime, $bank_account_id: Int) {
        payments(
            md_id: $md_id
            clinic_id: $clinic_id
            start_date: $start_date
            end_date: $end_date
            bank_account_id: $bank_account_id
        ) {
            ...PaymentFields
        }
    }
`;

export const GET_MDS = gql`
    ${MdFields}

    query GetMds {
        mds {
            ...MdFields
        }
    }
`;

export const GET_CLINICS = gql`
    ${ClinicFields}

    query GetClinics {
        clinics {
            ...ClinicFields
        }
    }
`;

export const GET_BANK_ACCOUNTS = gql`
    ${BankAccountFields}

    query GetBankAccounts {
        bankAccounts {
            ...BankAccountFields
        }
    }
`;
