import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { BillingSheetFields } from 'apollo/fragments';

export const GET_BILLING_SHEETS = gql`
    ${BillingSheetFields}

    query GetBillingSheets {
        billingSheets {
            ...BillingSheetFields
        }
    }
`;

export const UPDATE_BILLING_SHEET = gql`
    mutation UpdateBillingSheet(
        $id: Int
        $name: String
        $desc: String
        $first_bracket_fee: Float
        $second_bracket_fee: Float
        $y_increment: Float
    ) {
        updateBillingSheet(
            id: $id
            name: $name
            desc: $desc
            first_bracket_fee: $first_bracket_fee
            second_bracket_fee: $second_bracket_fee
            y_increment: $y_increment
        ) {
            id
        }
    }
`;
