import React, { useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as ReTooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

// MUI Components
import { Box, Grid, Paper, Switch, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// MUI Grid

// MUI Icons

// Components
import Loading from 'components/Loading';

// Apollo

// Redux

// Services

// Constants
import { SQL_DATE } from 'constants/Moment';

// Utils
import { capitalize, formatNumber, getSMA } from 'utils';
import Decimal from 'decimal.js';

interface SheetCountGraphProps {
    data: {
        dpGraphPoints: {
            label: string;

            count: number;
            count_new: number;
            count_rev: number;

            count_new_paper: number;
            count_new_dme: number;

            count_new_sm: number;
            count_new_fd: number;
            count_new_rmx: number;
            count_new_vac: number;
            count_new_sal: number;

            total_prelim: number;
            total_final: number;
            total_diff: number;
        }[];
    };
    startDate: Moment | null;
    endDate: Moment | null;
}

export default function SheetCountGraph(props: SheetCountGraphProps) {
    const theme = useTheme();
    const {
        data: { dpGraphPoints },
        data,
        startDate,
        endDate,
    } = props;

    const [graphData] = useMemo(() => {
        const totalsArrayForSma: number[] = [];

        // Main
        const graphData: {
            label: string;

            count_new_sm: number;
            count_new_fd: number;
            count_new_rmx: number;
            count_new_vac: number;
            count_new_sal: number;

            sma: number | null;
        }[] = [...data.dpGraphPoints]
            .sort((a, b) => moment(a.label).unix() - moment(b.label).unix())
            .map((point, pointIdx) => {
                const {
                    label,

                    count,
                    count_new,
                    count_rev,

                    count_new_paper,
                    count_new_dme,

                    count_new_sm,
                    count_new_fd,
                    count_new_rmx,
                    count_new_vac,
                    count_new_sal,

                    total_prelim,
                    total_final,
                    total_diff,
                } = point;

                totalsArrayForSma.push(count_new_fd + count_new_rmx + count_new_vac + count_new_sal);

                return {
                    label: moment(label).format(SQL_DATE),

                    count_new_sm,
                    count_new_fd,
                    count_new_rmx,
                    count_new_vac,
                    count_new_sal,

                    sma: 0,
                };
            });

        // SMA
        const smas = getSMA(totalsArrayForSma, 5);
        smas.forEach((val, idx) => {
            graphData[idx].sma = val;
        });

        return [graphData];
    }, [data]);

    return (
        <Grid item xs={12} lg={12}>
            <Paper
                sx={{
                    px: 2,
                    py: 2,
                }}
                elevation={1}
            >
                <Typography variant="h6" mb={4}>
                    Nombre de feuilles créées
                </Typography>

                <Box sx={{ width: '100%', height: 450 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={400}
                            data={graphData}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            // syncId="anyId"
                        >
                            <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                            <XAxis dataKey="label" scale="auto" />
                            <YAxis
                                tickFormatter={(value) =>
                                    new Intl.NumberFormat('en', { notation: 'compact' }).format(value)
                                }
                                label={{
                                    value: 'Nombre de feuilles',
                                    angle: -90,
                                    position: 'insideLeft',
                                    style: { textAnchor: 'middle', fill: 'white' },
                                }}
                            />
                            <ReTooltip
                                labelStyle={{ color: 'black' }}
                                labelFormatter={(label) => capitalize(moment(label).format('dddd Do MMMM Y'))}
                            />
                            <Legend />
                            <Bar
                                name="Nouvelles demandes FD"
                                dataKey="count_new_fd"
                                fill={theme.palette.primary.main}
                                barSize={20}
                            />
                            <Bar
                                name="Nouvelles demandes RMX"
                                dataKey="count_new_rmx"
                                fill={theme.palette.info.main}
                                barSize={20}
                            />
                            <Bar
                                name="Nouvelles demandes VAC"
                                dataKey="count_new_vac"
                                fill={theme.palette.pink1Dark.main}
                                barSize={20}
                            />
                            {/* <Bar
                                name="Nouvelles demandes SAL"
                                dataKey="count_new_sal"
                                fill={theme.palette.yellow1Dark.main}
                                barSize={20}
                            /> */}
                            <Line
                                name="SMA 5"
                                type="monotone"
                                dataKey="sma"
                                stroke="red"
                                activeDot={{ r: 8 }}
                                strokeWidth={2}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Box>
            </Paper>
        </Grid>
    );
}
