import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getEtablissementDialogTableColumns } from './grid-columns';

// MUI Icons
import {} from '@mui/icons-material';

// Components

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils

// Constants

interface EtablissementDialogProps {
    selectedEtablissement: any;
    handleCloseEtablissementDialog: () => void;
}

export default function EtablissementDialog({
    selectedEtablissement,
    handleCloseEtablissementDialog,
}: EtablissementDialogProps) {
    const etablissementDialogTableColumns = useMemo(() => getEtablissementDialogTableColumns(), []);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={selectedEtablissement ? true : false}
            onClose={handleCloseEtablissementDialog}
        >
            {selectedEtablissement && (
                <>
                    <DialogTitle>
                        Établissement #{selectedEtablissement.numero} - {selectedEtablissement.description}
                    </DialogTitle>
                    <DialogContent>
                        <DataGridPro
                            sx={{ mt: 3 }}
                            getRowId={(row) => uuidv4()}
                            rows={[selectedEtablissement]}
                            columns={etablissementDialogTableColumns}
                            pageSize={1}
                            rowsPerPageOptions={[1]}
                            disableSelectionOnClick
                            disableColumnMenu
                            disableColumnFilter
                            autoHeight
                            hideFooter
                            onRowClick={(params, event, details) => {}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEtablissementDialog}>Fermer</Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
