import moment from 'moment';

// MUI Components
import { Box, IconButton, Tooltip, CircularProgress } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { MenuOpen as MenuOpenIcon, Download as DownloadIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    BOOLEAN_COL_DEFAULTS,
    LICENCE_COL_DEFAULTS,
    DATETIME_COL_DEFAULTS,
    AMT_COL_DEFAULTS,
    ID_COL_DEFAULTS,
    DESCRIPTION_COLUMN_WIDTH,
    ACTIVE_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber } from 'utils';

export const getPostesTableColumns = (): GridColDefPro[] => [
    {
        ...ID_COL_DEFAULTS,
        field: 'numero',
        headerName: '# Poste',
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'modulateur',
        headerName: 'Mod.',
        headerAlign: 'center',
        align: 'center',
        width: 80,
    },
    {
        field: 'description',
        headerName: 'Nom',
        minWidth: 200,
        flex: 1,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'actif',
        headerName: 'Actif',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'cab',
        headerName: 'CAB',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'clsc',
        headerName: 'CLSC',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'cli',
        headerName: 'CLI',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'urg',
        headerName: 'URG',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'usi',
        headerName: 'USI',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'adm',
        headerName: 'ADM',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'ger',
        headerName: 'GER',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'psy',
        headerName: 'PSY',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'pal',
        headerName: 'PAL',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'rea',
        headerName: 'REA',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'heb',
        headerName: 'HEB',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'udc',
        headerName: 'UDC',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'umf',
        headerName: 'UMF',
        width: 70,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
];
