import React from 'react';
import { IconProps, SxProps } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';

interface BooleanIconProps {
    value: boolean | number | undefined;
    fontSize?: IconProps['fontSize'];
    sx?: SxProps;
}

export default function BooleanIcon(props: BooleanIconProps) {
    let { value, fontSize, sx } = props;

    fontSize = fontSize || 'small';

    const sxProps = sx || {};

    if (value === undefined) return <></>;

    return value ? (
        <CheckIcon fontSize={fontSize} sx={{ color: 'green1Dark.main', ...sxProps }} />
    ) : (
        <ClearIcon fontSize={fontSize} sx={{ color: 'red1Dark.main', ...sxProps }} />
    );
}
