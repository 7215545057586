import { gql } from '@apollo/client';

// ---------------------------------------- BANK ACCOUNTS ----------------------------------------
export const BankAccountFields = gql`
    fragment BankAccountFields on BankAccount {
        id
        created_at
        updated_at
        name
        qb_account_id
    }
`;

// ---------------------------------------- BILLING ----------------------------------------
export const BillingSheetFields = gql`
    fragment BillingSheetFields on BillingSheet {
        id
        created_at
        updated_at
        name
        desc
        first_bracket_fee
        second_bracket_fee
        y_increment
    }
`;

export const BillLineTagFields = gql`
    fragment BillLineTagFields on BillLineTag {
        id
        created_at
        updated_at
        slug
        name
        desc
    }
`;

export const BillModLineTagFields = gql`
    fragment BillModLineTagFields on BillModLineTag {
        id
        created_at
        updated_at
        slug
        name
        desc
        position
    }
`;

// ---------------------------------------- CLINICS ----------------------------------------
export const ClinicFields = gql`
    fragment ClinicFields on Clinic {
        id
        created_at
        updated_at

        name
        pst
        grp

        contact_email
        contact_phone
        contact_first_name
        contact_last_name
        contact_sex

        is_still_client
        can_login

        joined_on
        left_on

        balance

        qb_customer_id
        qb_customer_fully_qualified_name

        is_billable
        is_billable_until

        should_receive_md_reports
        should_receive_md_reports_until

        should_receive_md_ec_reports
        should_receive_md_df_reports
        should_receive_md_custom_reports
    }
`;

export const ClinicBillingInfoFields = gql`
    fragment ClinicBillingInfoFields on ClinicBillingInfo {
        id
        created_at
        updated_at

        clinic_id

        billing_method
        billing_sheet_id

        fact_fee
        fact_line_fee
        fact_fee_urg
        fact_line_fee_urg
        fact_fee_hospit
        fact_line_fee_hospit
        fact_fee_dme
        fact_line_fee_dme

        rmx_sheet_fee
        rpv_sheet_fee
        rps_sheet_fee

        hors_ramq_req_fee
        manual_fd_fee

        is_approved
    }
`;

export const ClinicBillFields = gql`
    fragment ClinicBillFields on ClinicBill {
        id
        created_at
        updated_at

        clinic_id
        ec_id

        subtotal_amt
        tps_rate
        tvq_rate
        tps_amt
        tvq_amt
        total_amt

        filename
        is_sipt
        qb_invoice_id
        qb_credit_memo_id
        bill_no

        # 'draft' | 'approved' | 'billed' | 'canceled' | 'credited'
        status

        email_sent
        last_email_sent_at

        billing_method
    }
`;

export const ClinicBillLineFields = gql`
    fragment ClinicBillLineFields on ClinicBillLine {
        id
        clinic_bill_id
        bill_line_tag_id
        position
        name
        desc
        unit_amt
        qty
        total_amt
        json_details
        clinic_billing_auto_line_id
        md_id
    }
`;

export const ClinicBillModLineFields = gql`
    fragment ClinicBillModLineFields on ClinicBillModLine {
        id
        clinic_bill_id
        bill_mod_line_tag_id
        name
        desc
        value_type
        value
        total_amt
        clinic_billing_auto_mod_line_id
        md_id
    }
`;

export const ClinicTransactionFields = gql`
    fragment ClinicTransactionFields on ClinicTransaction {
        id
        created_at
        updated_at
        clinic_id
        type
        ref_id
        txn_recorded_at
        name
        amt
        new_balance
    }
`;

export const ClinicHistoricalTransactionFields = gql`
    fragment ClinicHistoricalTransactionFields on ClinicHistoricalTransaction {
        txn_recorded_at
        type # 'payment | 'bill
        ref_id
        ref_no
        amt
        old_balance
        new_balance
    }
`;

export const ClinicPaymentFields = gql`
    fragment ClinicPaymentFields on ClinicPayment {
        id
        created_at
        updated_at
        clinic_id
        txn_recorded_at
        method
        ref_no
        amt
        email_sent
        last_email_sent_at
        bank_account_id
        qb_payment_id
        payment_date
        deleted_at
        new_clinic_payment_id
        private_note
    }
`;

// ---------------------------------------- ECS ----------------------------------------
export const EcFields = gql`
    fragment EcFields on Ec {
        id
        created_at
        updated_at
        date_ec
        date_coupure
        dd_periode_fact
        df_periode_fact
        no_paiement
        fmp_id
    }
`;

// ---------------------------------------- FACTURES ----------------------------------------
export const FactLigneFields = gql`
    fragment FactLigneFields on FactLigne {
        ligneno
        nce
        revision
        rank
        mdgrppstno
        typelmfact
        codefact
        datefact
        coderole
        debutelmfact
        finelmfact
        coderef
        mntprelim
        mntfinal
        statrecv
    }
`;

// ---------------------------------------- FMP USERS ----------------------------------------
export const FmpUserFields = gql`
    fragment FmpUserFields on FmpUser {
        id
        name
    }
`;

// ---------------------------------------- HORS RAMQ ----------------------------------------
export const HorsRamqClaimTypeFields = gql`
    fragment HorsRamqClaimTypeFields on HorsRamqClaimType {
        id
        created_at
        updated_at
        name
    }
`;

export const HorsRamqClaimSubtypeFields = gql`
    fragment HorsRamqClaimSubtypeFields on HorsRamqClaimSubtype {
        id
        created_at
        updated_at
        hors_ramq_claim_type_id
        name
    }
`;

// ---------------------------------------- MDS ----------------------------------------
export const MdFields = gql`
    fragment MdFields on Md {
        id
        created_at
        updated_at
        licence_sm
        email
        password
        first_name
        last_name
        first_name_smchar
        last_name_smchar
        sex
        retraite_on
        avatar_url
        is_still_client
        can_login
        address1
        address2
        city
        zip
        phone_mobile
        phone_home
        phone_work
        joined_on
        left_on
        balance
        qb_customer_id
        qb_customer_fully_qualified_name

        is_billable
        is_billable_until

        should_receive_reports
        should_receive_reports_until

        wants_invoice_emails
        wants_ec_emails
        wants_newsletter_emails
    }
`;

export const MdBillingInfoFields = gql`
    fragment MdBillingInfoFields on MdBillingInfo {
        id
        created_at
        updated_at
        md_id
        billing_method
        billing_sheet_id
        fact_fee
        fact_line_fee
        fact_fee_urg
        fact_line_fee_urg
        fact_fee_hospit
        fact_line_fee_hospit
        fact_fee_dme
        fact_line_fee_dme
        rmx_sheet_fee
        rpv_sheet_fee
        rps_sheet_fee
        hors_ramq_req_fee
        manual_fd_fee
        is_approved
    }
`;

export const MdBillFields = gql`
    fragment MdBillFields on MdBill {
        id
        created_at
        updated_at
        ec_id
        subtotal_amt
        tps_rate
        tvq_rate
        tps_amt
        tvq_amt
        total_amt
        md_id
        filename
        is_sipt
        qb_invoice_id
        qb_credit_memo_id
        bill_no
        status # 'draft' | 'approved' | 'billed' | 'canceled' | 'credited'
        email_sent
        last_email_sent_at
        billing_method
    }
`;

export const MdBillLineFields = gql`
    fragment MdBillLineFields on MdBillLine {
        id
        md_bill_id
        bill_line_tag_id
        position
        name
        desc
        pst
        grp
        unit_amt
        qty
        total_amt
        json_details
        md_billing_auto_line_id
    }
`;

export const MdBillModLineFields = gql`
    fragment MdBillModLineFields on MdBillModLine {
        id
        md_bill_id
        bill_mod_line_tag_id
        name
        desc
        value_type
        value
        total_amt
        md_billing_auto_mod_line_id
    }
`;

export const MdTransactionFields = gql`
    fragment MdTransactionFields on MdTransaction {
        id
        created_at
        updated_at
        md_id
        type
        ref_id
        txn_recorded_at
        name
        amt
        new_balance
    }
`;

export const MdHistoricalTransactionFields = gql`
    fragment MdHistoricalTransactionFields on MdHistoricalTransaction {
        txn_recorded_at
        type # 'payment | 'bill
        ref_id
        ref_no
        amt
        old_balance
        new_balance
    }
`;

export const MdHorsRamqClaimFields = gql`
    fragment MdHorsRamqClaimFields on MdHorsRamqClaim {
        id
        created_at
        updated_at
        md_id
        received_on
        patient_name
        consulted_on
        hors_ramq_claim_type_id
        hors_ramq_claim_type_name
        hors_ramq_claim_subtype_id
        hors_ramq_claim_subtype_name
        amt_asked
        amt_paid
        check_no
        check_date
        is_billed
        md_bill_id
        status
        status_date
        is_sipt
        sipt_admin_name
    }
`;

export const MdWorkStoppageFields = gql`
    fragment MdWorkStoppageFields on MdWorkStoppage {
        id
        created_at
        updated_at
        md_id
        work_stoppage_cause_id
        note
        start_on
        end_on
        indeterminate
        cancel_automatic_billing
    }
`;

export const MdPaymentFields = gql`
    fragment MdPaymentFields on MdPayment {
        id
        created_at
        updated_at
        md_id
        txn_recorded_at
        method
        ref_no
        amt
        email_sent
        last_email_sent_at
        bank_account_id
        qb_payment_id
        payment_date
        deleted_at
        new_md_payment_id
        private_note
    }
`;

// ---------------------------------------- PAYMENTS ----------------------------------------
export const PaymentFields = gql`
    fragment PaymentFields on Payment {
        id
        created_at
        updated_at
        deleted_at
        entity_id
        entity_name
        entity_type
        txn_recorded_at
        method
        ref_no
        amt
        email_sent
        last_email_sent_at
        bank_account_id
        bank_account_name
        qb_payment_id
        payment_date
        new_payment_id
        private_note

        METHOD_NAME
    }
`;

// ---------------------------------------- POSTES ----------------------------------------
export const PosteFields = gql`
    fragment PosteFields on Poste {
        numero
        modulateur
        description
        descriptionsa
        cab
        clsc
        cli
        urg
        usi
        adm
        ger
        psy
        pal
        rea
        heb
        udc
        umf
        actif
    }
`;
