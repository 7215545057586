import React, { useState, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import moment, { Moment } from 'moment';
import { cloneDeep } from 'lodash';

// MUI Components
import { Grid, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// MUI Grid

// MUI Icons

// Components

// Apollo

// Redux

// Services

// Constants
import { SQL_DATE } from 'constants/Moment';
import { CHART_COLORS } from 'constants/Chart';

// Utils
import { getSMA } from 'utils';

interface DpSmCountGraphProps {
    data: {
        analyst_id: number;
        analyst_name: string;
        points: {
            label: string;

            count: number;
            count_new: number;
            count_rev: number;

            count_new_paper: number;
            count_new_dme: number;

            count_new_sm: number;
            count_new_fd: number;
            count_new_rmx: number;
            count_new_vac: number;
            count_new_sal: number;

            total_prelim: number;
            total_final: number;
            total_diff: number;
        }[];
    }[];
    startDate: Moment | null;
    endDate: Moment | null;
}

export default function DpSmCountGraph(props: DpSmCountGraphProps) {
    const theme = useTheme();
    const { data, startDate, endDate } = props;

    const [categories, series] = useMemo(() => {
        const categories: string[] = [];
        const series: ApexAxisChartSeries = [];

        const totalsArrayForSma: number[] = [];

        [...data]
            .sort((a, b) => (a.analyst_name || '').localeCompare(b.analyst_name || ''))
            .forEach((analystData, idx) => {
                const { analyst_id, analyst_name, points } = analystData;

                // Fill the categories only for the first iteration
                if (idx === 0) {
                    [...points]
                        .sort((a, b) => moment(a.label).unix() - moment(b.label).unix())
                        .forEach((point, pointIdx) => {
                            const { label } = point;

                            categories.push(moment(label).format(SQL_DATE));
                        });
                }

                // Add a new serie representing the data of the looped analyst
                [...points]
                    .sort((a, b) => moment(a.label).unix() - moment(b.label).unix())
                    .forEach((point, pointIdx) => {
                        const {
                            label,

                            count,
                            count_new,
                            count_rev,

                            count_new_paper,
                            count_new_dme,

                            count_new_sm,
                            count_new_fd,
                            count_new_rmx,
                            count_new_vac,
                            count_new_sal,

                            total_prelim,
                            total_final,
                            total_diff,
                        } = point;

                        if (pointIdx === 0) {
                            series.push({
                                name: `${analyst_name || 'N/A'} (#${analyst_id})`,
                                data: [],
                            });
                        }

                        series[series.length - 1].data.push(count_new_sm as any);

                        if (totalsArrayForSma[pointIdx] === undefined) {
                            totalsArrayForSma.push(count_new_sm);
                        } else {
                            totalsArrayForSma[pointIdx] = totalsArrayForSma[pointIdx] + count_new_sm;
                        }
                    });
            });

        // SMA
        const smas = getSMA(
            totalsArrayForSma.map((val) => val / data.length),
            5,
        );
        series.push({
            name: `5 SMA`,
            data: smas,
            type: 'line',
        });

        // AVG
        const avgs = totalsArrayForSma.map((val) => val / data.length);
        series.push({
            name: `AVG`,
            data: avgs,
            type: 'line',
        });

        return [categories, series];
    }, [data]);

    // Set the options
    const options = cloneDeep(ANALYST_COMPARISON_GRAPH_OPTIONS);
    options.xaxis!.categories = categories;

    return (
        <Grid item xs={12} lg={12}>
            <Paper
                sx={{
                    px: 2,
                    py: 2,
                }}
                elevation={1}
            >
                <Typography variant="h5" fontWeight={500} mb={4}>
                    Nombre de nouvelles demandes SM (services médicaux) par analyste
                </Typography>

                <Chart options={options} series={series} height={700} />
            </Paper>
        </Grid>
    );
}

const ANALYST_COMPARISON_GRAPH_OPTIONS: ApexOptions = {
    chart: {
        // toolbar: { show: false },
        // type: 'bar',
        background: 'transparant',
    },
    theme: {
        mode: 'dark',
        palette: 'palette2', // upto palette10
    },
    stroke: {
        curve: 'smooth',
        width: 3,
    },
    xaxis: {
        categories: [],
        labels: {
            show: true,
            rotateAlways: true,
        },
    },
    yaxis: {
        decimalsInFloat: 0,
    },
    colors: CHART_COLORS,
};
