import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import './index.css';

// Components
import {
    Avatar,
    Box,
    ButtonBase,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Link,
    Paper,
    Typography,
    Button,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperLib, Navigation, Pagination, Scrollbar, A11y, Mousewheel, Keyboard } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Document, LoadingProcessData, Outline, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

// Utils
import { capitalize, getScanStatusColor, getScanStatusText } from 'utils/index';

// Redux
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { red } from '@mui/material/colors';

import { SizeMe } from 'react-sizeme';
import Divider from '@mui/material/Divider';

type RouteParams = 'employeeId' | 'date';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFDocumentWrapper = styled('div')({
    '> div': {
        width: '100% !important',
        height: 'auto !important',
    },
});

const INITIAL_LOADING_DOCUMENT_PROGRESS = {
    loaded: 0,
    total: 0,
};

const LEFT_SIDEBAR_WIDTH = '20%';
const TOP_SWIPER_HEIGHT = '20%';

export default function ScanDay() {
    const { pathname } = useLocation();
    const { employeeId, date } = useParams<RouteParams>();

    const theme = useTheme();

    // const claim = useAppSelector((state) => state.claims.find((claim) => claim.id === parseInt(claimId!)))!;
    // const dispatch = useAppDispatch();

    const [scanList, setScanList] = useState([] as any[]);

    /**
     * * These 2 states track the same value
     *
     * selectedScanIndex get updated after the slide transitions end.
     * selectedScanIndexForPagination gets updated right when the swiper slide changes (swiper.onSlideChange)
     *
     * We need selectedScanIndex to be updated after the swiper transition because doing it before the end of the transition creates a transition animation lag due to the PDFDocument component (loads the PDF at the same time as the animation occurs, probably too much load for the DOM rendering)
     * We need selectedScanIndexForPagination to be updated right when swiper slide changes because we want the pagination to update right after the user changes the slide.
     */
    const [selectedScanIndex, setSelectedScanIndex] = useState(0);
    /**
     * * This tracks the same value as selectedScanIndex BUT gets updated right when the swiper slide changes (swiper.onSlideChange) instead of getting updated after the slide transitions end. We use this value for the pagination counter.
     */
    const [selectedScanIndexForPagination, setSelectedScanIndexForPagination] = useState(0);

    const [selectedScanPageCount, setSelectedScanPageCount] = useState(0);
    const [selectedScanPageNumber, setSelectedScanPageNumber] = useState(1);
    const [loadingDocumentProgress, setLoadingDocumentProgress] = useState<{ loaded: number; total: number }>(
        INITIAL_LOADING_DOCUMENT_PROGRESS,
    );
    // let swiperRef: typeof Swiper | null = null;
    const [swiperRef, setSwiperRef] = useState<any>(null);

    useEffect(() => {
        fetch('http://localhost:84/api/scan/2/2022-02-09', {
            mode: 'cors',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setScanList(result);
                },
                (error) => {
                    console.error(error);
                },
            );
    }, []);

    // const selectScan = useCallback(
    //     (wantedScanIndex) => {
    //         if (wantedScanIndex >= 0 && wantedScanIndex <= scanList.length - 1) {
    //             setSelectedScanIndex(wantedScanIndex);
    //         }
    //     },
    //     [setSelectedScanIndex, scanList],
    // );
    // const selectPrevScan = useCallback(() => {
    //     swiperRef?.slidePrev();
    //     selectScan(selectedScanIndex - 1);
    // }, [selectScan, selectedScanIndex, swiperRef]);
    // const selectNextScan = useCallback(() => {
    //     console.log(swiperRef);
    //     swiperRef?.slideNext();
    //     selectScan(selectedScanIndex + 1);
    // }, [selectScan, selectedScanIndex, swiperRef]);

    const pagesRef = useRef<any[]>([]);

    const selectPage = useCallback(
        (wantedPage) => {
            if (wantedPage >= 1 && wantedPage <= selectedScanPageCount) {
                setSelectedScanPageNumber(wantedPage);
            }
        },
        [setSelectedScanPageNumber, selectedScanPageCount],
    );
    const selectPrevPage = useCallback(() => {
        selectPage(selectedScanPageNumber - 1);
        pagesRef.current[selectedScanPageNumber - 2].scrollIntoView();
    }, [selectPage, selectedScanPageNumber, pagesRef]);
    const selectNextPage = useCallback(() => {
        selectPage(selectedScanPageNumber + 1);
        pagesRef.current[selectedScanPageNumber].scrollIntoView();
    }, [selectPage, selectedScanPageNumber, pagesRef]);

    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        console.log('loading stopped');
        setSelectedScanPageCount(numPages);
        setSelectedScanPageNumber(1);
        setLoadingDocumentProgress(INITIAL_LOADING_DOCUMENT_PROGRESS);
    };
    const onDocumentLoadProgress = (data: LoadingProcessData) => {
        setLoadingDocumentProgress(data);
    };

    const onSlideChange = (swiper: SwiperLib) => {
        setSelectedScanIndexForPagination(swiper.activeIndex);
    };
    const onSlideChangeTransitionEnd = (swiper: SwiperLib) => {
        setSelectedScanIndex(swiper.activeIndex);
    };

    const handleKeyDown = useCallback(
        (event) => {
            switch (event.keyCode) {
                case 37:
                    // * Now handled directly by the Swiper Keyboard module
                    // Left arrow
                    // selectPrevScan();
                    break;
                case 38:
                    // Up arrow
                    event.preventDefault();
                    selectPrevPage();
                    break;
                case 39:
                    // * Now handled directly by the Swiper Keyboard module
                    // Right arrow
                    // selectNextScan();
                    break;
                case 40:
                    // Down arrow
                    event.preventDefault();
                    selectNextPage();
                    break;
            }
        },
        [selectPrevPage, selectNextPage],
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <Paper elevation={0} sx={{ borderRadius: 0, p: 0 }}>
            <Box
                sx={{
                    position: 'fixed',
                    boxSizing: 'border-box',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: LEFT_SIDEBAR_WIDTH,
                    px: 3,
                    py: 2,
                    bgcolor: 'primary.main',
                    overflowY: 'scroll',
                    zIndex: 15,
                }}
            >
                <Grid container>
                    <Grid item xs={12} mb={3}>
                        {scanList[selectedScanIndex] && (
                            <>
                                <Typography variant="h5">{scanList[selectedScanIndex].physicianFullName}</Typography>
                                <Typography variant="subtitle1">
                                    {scanList[selectedScanIndex].scanDate} au {scanList[selectedScanIndex].scanDate}
                                </Typography>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    sx={{
                                        bgcolor: getScanStatusColor(theme, scanList[selectedScanIndex].status).bgcolor,
                                        color: getScanStatusColor(theme, scanList[selectedScanIndex].status).color,
                                        py: 0.5,
                                    }}
                                    mt={2}
                                >
                                    <Typography variant="button" sx={{ fontSize: 12 }}>
                                        {getScanStatusText(scanList[selectedScanIndex].status)}
                                    </Typography>
                                </Box>

                                <Typography variant="body1" mt={2.5}>
                                    Page {selectedScanPageNumber || (selectedScanPageCount ? 1 : '--')} sur{' '}
                                    {selectedScanPageCount || '--'}
                                </Typography>
                            </>
                        )}

                        {/* <Button disabled={selectedScanPageNumber <= 1} onClick={selectPrevPage}>
                                        Page précédente
                                    </Button>
                                    <Button
                                        disabled={selectedScanPageNumber >= selectedScanPageCount}
                                        onClick={selectNextPage}
                                    >
                                        Page suivante
                                    </Button> */}
                    </Grid>

                    <Document
                        file={scanList[selectedScanIndex]?.scanSignedUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadProgress={onDocumentLoadProgress}
                        loading={() => {
                            return (
                                <Typography>
                                    Chargement des thumbnails du scan...{' '}
                                    {loadingDocumentProgress.loaded == 0
                                        ? '0'
                                        : (
                                              (loadingDocumentProgress.loaded / loadingDocumentProgress.total) *
                                              100
                                          ).toFixed(2)}
                                    %
                                </Typography>
                            );
                        }}
                    >
                        <Grid item xs={12} container spacing={2}>
                            {Array.from(new Array(selectedScanPageCount), (el, index) => (
                                <Grid item xs={12} key={index} ref={(ref) => (pagesRef.current[index] = ref)}>
                                    {/* <PDFDocumentWrapper> */}
                                    <ButtonBase
                                        onClick={() => selectPage(index + 1)}
                                        sx={{ display: 'block', width: '100%' }}
                                    >
                                        <Paper
                                            elevation={3}
                                            sx={{
                                                border: `2px ${
                                                    selectedScanPageNumber == index + 1 ? 'red' : 'rgba(0,0,0,0)'
                                                } solid`,
                                            }}
                                        >
                                            <SizeMe>
                                                {({ size }) => (
                                                    <Page
                                                        pageNumber={index + 1}
                                                        renderAnnotationLayer={false}
                                                        renderInteractiveForms={false}
                                                        renderTextLayer={false}
                                                        // canvasRef={(ref) => (selectedScanPagesRefs.current[index] = ref)}
                                                        width={size.width ? size.width : 1}
                                                        loading="Chargement de la page..."
                                                    />
                                                )}
                                            </SizeMe>
                                        </Paper>
                                    </ButtonBase>
                                    <Typography variant="subtitle2" textAlign="center" mt={1}>
                                        Page {index + 1}
                                    </Typography>
                                    {/* </PDFDocumentWrapper> */}
                                </Grid>
                            ))}
                        </Grid>
                    </Document>
                </Grid>
            </Box>

            <Paper
                sx={{
                    position: 'fixed',
                    boxSizing: 'border-box',
                    top: 0,
                    bottom: 0,
                    left: LEFT_SIDEBAR_WIDTH,
                    right: 0,
                    overflowY: 'auto',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        boxSizing: 'border-box',
                        top: 0,
                        left: LEFT_SIDEBAR_WIDTH,
                        right: 0,
                        height: TOP_SWIPER_HEIGHT,
                        zIndex: 10,
                        py: 2,
                        px: 2,
                    }}
                >
                    <Swiper
                        slidesPerView={6}
                        spaceBetween={30}
                        className="mySwiper"
                        centeredSlides
                        // allowTouchMove={false}
                        slideToClickedSlide
                        modules={[
                            Pagination,
                            Keyboard,
                            Mousewheel,
                            // Navigation,
                        ]}
                        pagination={{
                            type: 'progressbar',
                        }}
                        keyboard={{
                            enabled: true,
                        }}
                        mousewheel
                        onSlideChange={onSlideChange}
                        onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
                        // onSwiper={setSwiperRef}
                    >
                        {scanList.map((item, index) => {
                            const {
                                physicianLicense,
                                physicianFullName,
                                scanSignedUrl,
                                visitDate,
                                scanDate,
                                scanPageCount,
                                estimatedTimeToProcessInSec,
                                status,
                            } = item;

                            return (
                                <SwiperSlide
                                    // onClick={() => {
                                    //     console.log('clickedd', index);
                                    //     // setSelectedScanIndex(index);
                                    // }}
                                    style={{ paddingTop: '15px', paddingBottom: '10px' }}
                                >
                                    {({ isActive }) => (
                                        <ButtonBase
                                            // onClick={() => swiperRef.slideTo(index)}
                                            sx={{ display: 'block', width: '100%' }}
                                        >
                                            <Paper
                                                elevation={theme.palette.mode == 'dark' ? 15 : 3}
                                                sx={{
                                                    border: '2px solid',
                                                    borderColor: isActive ? 'red' : 'transparent',
                                                    ':hover': {
                                                        boxShadow: 6,
                                                    },
                                                }}
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    sx={{
                                                        bgcolor: 'primary.main',
                                                        px: 1,
                                                        py: 0.5,
                                                    }}
                                                >
                                                    <Typography variant="caption">#{index + 1}</Typography>
                                                    {/* <Box
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignContent="center"
                                                            >
                                                                <Typography
                                                                    variant="caption"
                                                                    sx={{ color: 'primary.contrastText' }}
                                                                >
                                                                    Temps est.
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{ color: 'primary.contrastText' }}
                                                                >
                                                                    {estimatedTimeToProcessInSec} sec
                                                                </Typography>
                                                            </Box>

                                                            <Box
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignContent="center"
                                                            >
                                                                <Typography
                                                                    variant="caption"
                                                                    sx={{ color: 'primary.contrastText' }}
                                                                >
                                                                    Temps réel.
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{ color: 'primary.contrastText' }}
                                                                >
                                                                    30 sec
                                                                </Typography>
                                                            </Box> */}
                                                </Box>

                                                <Grid container spacing={1} sx={{ p: 1 }}>
                                                    <Grid item>
                                                        <Typography variant="body1" fontWeight="bold">
                                                            {physicianFullName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2">Semaine du {scanDate}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    sx={{
                                                        bgcolor: getScanStatusColor(theme, status).bgcolor,
                                                        color: getScanStatusColor(theme, status).color,
                                                        py: 0.5,
                                                    }}
                                                >
                                                    <Typography variant="button" sx={{ fontSize: 12 }}>
                                                        {getScanStatusText(status)}
                                                    </Typography>
                                                </Box>
                                            </Paper>
                                        </ButtonBase>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                        <Box slot="container-end">
                            <Typography textAlign="center" variant="body2">
                                Scan {selectedScanIndexForPagination + 1}/{scanList.length}
                            </Typography>
                        </Box>
                    </Swiper>
                </Box>

                {/* <Grid item xs={12}>
                    <Divider></Divider>
                </Grid> */}

                <Box
                    sx={{
                        position: 'fixed',
                        boxSizing: 'border-box',
                        top: TOP_SWIPER_HEIGHT,
                        left: LEFT_SIDEBAR_WIDTH,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        overflowY: 'scroll',
                        pt: 1,
                    }}
                >
                    <Document
                        file={scanList[selectedScanIndex]?.scanSignedUrl}
                        loading={() => {
                            return (
                                <Typography>
                                    Chargement du scan...{' '}
                                    {loadingDocumentProgress.loaded == 0
                                        ? '0'
                                        : (
                                              (loadingDocumentProgress.loaded / loadingDocumentProgress.total) *
                                              100
                                          ).toFixed(2)}
                                    %
                                </Typography>
                            );
                        }}
                    >
                        <Page
                            pageNumber={selectedScanPageNumber}
                            renderAnnotationLayer={false}
                            renderInteractiveForms={false}
                            renderTextLayer={false}
                            loading="Chargement de la page..."
                            onLoadSuccess={() => {
                                removeTextLayerOffset();
                            }}
                        />
                    </Document>
                </Box>
            </Paper>

            {/* <Grid item xs={12}>
                    <Typography variant="h6" textAlign="center">
                        Vos éléments à facturer aujourd'hui le 4 février 2022
                    </Typography>
                </Grid>

                <Grid item xs={12} flexDirection="column" alignContent="start">
                    <Typography variant="body1" textAlign="center">
                        6/202
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                        Temps restant estimé: 3h40
                    </Typography>
                </Grid> */}

            {/* {data[selectedScan] && ( */}
            {/* <Document
                        // file={data[selectedScan].scanSignedUrl}
                        file={SAMPLE_PDF}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadError}
                    >
                        {Array.from(new Array(selectedScanPageCount), (el, index) => (
                            <Page key={`page_${index + 1}`} selectedScanPageNumber={index + 1} />
                        ))}
                    </Document> */}
            {/* )} */}
        </Paper>
    );
}

function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    textLayers.forEach((layer) => {
        const { style } = layer as any;
        style.top = '0';
        style.left = '0';
        style.transform = '';
    });
}
