import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';

// MUI Components
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { Save as SaveIcon } from '@mui/icons-material';

// Components
import { AutocompleteControl, DateControl, NumberControl, SelectControl, TextControl } from 'components/inputs';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_BANK_ACCOUNTS, GET_CLINICS, CREATE_CLINIC_PAYMENT } from './apollo-queries';
import { GET_CLINIC_TRANSACTIONS } from 'views/clinics-transactions/ClinicTransactionList/apollo-queries';

// Redux
import {} from 'redux';

// Utils
import { getErrorMsg } from 'utils';

// Constants
import { PAYMENT_METHODS } from 'constants/DbConstants';
import { SQL_DATE } from 'constants/Moment';

interface ClinicPaymentCreateDialogProps {
    isClinicPaymentCreateDialogOpen: boolean;
    handleCloseClinicPaymentCreateDialog: () => void;
}

interface ClinicPaymentCreateDialogContentProps {
    isClinicPaymentCreateDialogOpen: boolean;
    handleCloseClinicPaymentCreateDialog: () => void;
}

export function ClinicPaymentCreateDialog({
    isClinicPaymentCreateDialogOpen,
    handleCloseClinicPaymentCreateDialog,
}: ClinicPaymentCreateDialogProps) {
    const isDialogOpen = isClinicPaymentCreateDialogOpen ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={handleCloseClinicPaymentCreateDialog}>
            <ClinicPaymentCreateDialogContent
                isClinicPaymentCreateDialogOpen={isClinicPaymentCreateDialogOpen}
                handleCloseClinicPaymentCreateDialog={handleCloseClinicPaymentCreateDialog}
            />
        </Dialog>
    );
}

const ClinicPaymentCreateDialogContent = ({
    isClinicPaymentCreateDialogOpen,
    handleCloseClinicPaymentCreateDialog,
}: ClinicPaymentCreateDialogContentProps) => {
    const theme = useTheme();

    const {
        loading: bankAccountsLoading,
        error: bankAccountsError,
        data: bankAccountsData,
    } = useQuery(GET_BANK_ACCOUNTS, {
        skip: !isClinicPaymentCreateDialogOpen,
    });
    const { loading: clinicsLoading, error: clinicsError, data: clinicsData } = useQuery(GET_CLINICS);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
    } = useForm({
        defaultValues: {
            clinic_id: null,
            method: null,
            ref_no: '',
            amt: 0,
            bank_account_id: null,
            payment_date: moment().format(SQL_DATE),
            private_note: null,
        },
    });

    const [
        createClinicPayment,
        { loading: createClinicPaymentLoading, error: createClinicPaymentError, data: createClinicPaymentData },
    ] = useMutation(CREATE_CLINIC_PAYMENT, {
        refetchQueries: [GET_CLINIC_TRANSACTIONS],
    });

    const onSubmit = async (data) => {
        await createClinicPayment({
            variables: {
                clinic_id: data.clinic_id,
                method: data.method,
                ref_no: data.ref_no,
                amt: data.amt,
                bank_account_id: data.bank_account_id,
                payment_date: data.payment_date,
                private_note: data.private_note,
            },
        });

        handleCloseClinicPaymentCreateDialog();
    };

    // Error snackbar
    const [isErrorSnackbarOpened, setIsErrorSnackbarOpened] = React.useState(false);
    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpened(false);
    };
    useEffect(() => setIsErrorSnackbarOpened(createClinicPaymentError ? true : false), [createClinicPaymentError]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <DialogTitle>Enregistrer un paiement</DialogTitle>

                <DialogContent>
                    <Grid container spacing={3} pt={1}>
                        <Grid item xs={12}>
                            <AutocompleteControl
                                name="clinic_id"
                                control={control as any}
                                options={clinicsData?.clinics || []}
                                valuePropertyName="id"
                                loading={clinicsLoading}
                                getOptionLabel={(option: any) => {
                                    const { id, name, pst } = option;

                                    if (option === getValues('clinic_id')) {
                                        const selectedClinic = (clinicsData?.clinics || []).find(
                                            (clinic) => clinic.id === option,
                                        );

                                        return selectedClinic ? `${selectedClinic.name} (${selectedClinic.pst})` : '';
                                    } else {
                                        return `${name} (${pst})`;
                                    }
                                }}
                                isOptionEqualToValue={(option: any, value) => option?.id === value}
                                textFieldOptions={{ label: 'Clinique' }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <SelectControl
                                name="method"
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Méthode de paiement' }}
                                dropdownOptions={PAYMENT_METHODS.sort((a, b) => a.name.localeCompare(b.name)).map(
                                    (paymentMethod) => (
                                        <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
                                            {paymentMethod.name}
                                        </MenuItem>
                                    ),
                                )}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextControl
                                name="ref_no"
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Numéro de référence' }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <NumberControl
                                name={`amt`}
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Montant' }}
                                money
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DateControl
                                name={`payment_date`}
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Date du paiement' }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SelectControl
                                name="bank_account_id"
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Compte de banque' }}
                                dropdownOptions={[...(bankAccountsData?.bankAccounts || [])]
                                    .sort((a, b) => a.name - b.name)
                                    .map((bankAccount) => (
                                        <MenuItem key={bankAccount.id} value={bankAccount.id}>
                                            {bankAccount.name}
                                        </MenuItem>
                                    ))}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextControl
                                name="private_note"
                                control={control as any}
                                errors={errors as any}
                                options={{ label: 'Note privée', rows: 4 }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        variant="text"
                        onClick={handleSubmit(onSubmit)}
                        endIcon={<SaveIcon />}
                        loading={createClinicPaymentLoading}
                        loadingPosition="end"
                        type="submit"
                    >
                        Sauvegarder
                    </LoadingButton>
                </DialogActions>
            </form>

            <Snackbar
                open={isErrorSnackbarOpened}
                autoHideDuration={6000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {getErrorMsg(createClinicPaymentError)}
                </Alert>
            </Snackbar>
        </>
    );
};
