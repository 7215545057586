import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import DrawerHeaderTitle from 'components/DrawerHeaderTitle';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';

// Redux
import {} from 'redux';

// Utils
import { capitalize } from 'utils';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    TYPE_SERVICE_NAME_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATETIME } from 'constants/Moment';

const GET_FACTURE = gql`
    query GetFacture($reqt_id: Int!) {
        facture(reqt_id: $reqt_id) {
            reqt_id
            nce
            nci
            typeservice
            revision
            licence
            poste
            postemod
            jeton
            statrecv
            annule
            lotid
            creation
            ec_id
            Medecin {
                licence
                prenom
                nom
                male
                specialiste
                lieutra
                retraite
            }
            Poste {
                description
            }
            FactLignes {
                ligneno
                nce
                revision
                rank
                mdgrppstno
                typelmfact
                codefact
                datefact
                coderole
                debutelmfact
                finelmfact
                coderef
                mntprelim
                mntfinal
                statrecv
            }
        }
    }
`;

export default function FacturesTabPanel({
    tabIndexAsString,
    selectedLot,
}: {
    tabIndexAsString: string;
    selectedLot: any;
}) {
    // Facture Dialog
    const [selectedFacture, setSelectedFacture] = useState<any | null>(null);
    const handleOpenFactureDialog = (selectedFacture) => {
        setSelectedFacture(selectedFacture);
    };
    const handleCloseFactureDialog = () => {
        setSelectedFacture(null);
    };

    // Query
    const [getFacture, { loading: factureLoading, error: factureError, data: facture }] = useLazyQuery(GET_FACTURE, {
        onCompleted: ({ facture }) => {
            setSelectedFacture(facture);
        },
        notifyOnNetworkStatusChange: true, // Needs to be set to true. If not set to true, clicking on a facture, closing the drawer and then reclicking on the same facture will not trigger the drawer open
    });

    // Columns
    const columns = useMemo(() => getFacturesTableColumns(handleOpenFactureDialog, getFacture), [getFacture]);

    return (
        <TabPanel value={tabIndexAsString}>
            <DataGridPro
                getRowId={(row) => row.reqt_id}
                rows={selectedLot.Factures}
                columns={columns}
                pagination
                pageSize={15}
                rowsPerPageOptions={[15, 25, 50, 75, 100]}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: { left: ['nce'], right: ['actions'] },
                }}
            />
            <FactureDialog selectedFacture={selectedFacture} handleCloseFactureDialog={handleCloseFactureDialog} />
        </TabPanel>
    );
}

const FactureDialog = ({ selectedFacture, handleCloseFactureDialog }) => {
    const factureDialogTableColumns = useMemo(() => getFactureDialogTableColumns(), []);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={selectedFacture ? true : false}
            onClose={handleCloseFactureDialog}
        >
            {selectedFacture && (
                <>
                    <DialogTitle>Facture #{selectedFacture.nce}</DialogTitle>
                    <DialogContent>
                        <DataGridPro
                            sx={{ mt: 3 }}
                            getRowId={(row) => row.ligneno}
                            rows={selectedFacture.FactLignes}
                            columns={factureDialogTableColumns}
                            pagination
                            pageSize={25}
                            rowsPerPageOptions={[25, 50, 75, 100]}
                            autoHeight
                            disableSelectionOnClick
                            disableColumnMenu
                            disableColumnFilter
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseFactureDialog}>Fermer</Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

const getFacturesTableColumns = (
    handleOpenFactureDialog: (facture: any) => void,
    getFacture: (params: any) => void,
): GridColDefPro[] => [
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nce, revision } = params.row;

            return (
                <>
                    {/* <IconButton
                        onClick={() => {
                            getFacture({ variables: { reqt_id: params.row.reqt_id } });
                        }}
                        size="small"
                        color="primary"
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButton> */}
                    <IconButton
                        size="small"
                        color="primary"
                        component={RouterLink}
                        to={`/facture?nce=${nce}&revision=${revision}`}
                        target="_blank"
                    >
                        <OpenInNewIcon fontSize="small" />
                    </IconButton>
                </>
            );
        },
    },
    {
        field: 'nce',
        headerName: 'NCE',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'creation',
        headerName: 'Créé le',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => (params.value ? moment(params.value).format(SQL_DATETIME) : null),
    },
    {
        field: 'ec_id',
        headerName: 'EC ID',
        width: ID_COLUMN_WIDTH,
        hide: true,
    },
    {
        field: 'reqt_id',
        headerName: 'No. RAMQ externe',
        width: 150,
        hide: true,
    },
    {
        field: 'nci',
        headerName: 'No. RAMQ interne',
        width: 150,
        hide: true,
    },
    {
        ...TYPE_SERVICE_NAME_COL_DEFAULTS,
        field: 'TYPE_SERVICE',
        headerName: 'Type service',
    },
    {
        field: 'revision',
        headerName: 'Version',
        width: 120,
    },
    {
        field: 'licence',
        headerName: 'Licence',
        width: SMALL_STRING_COLUMN_WIDTH,
    },
    {
        field: 'poste',
        headerName: 'Poste',
        width: SMALL_STRING_COLUMN_WIDTH,
    },
    {
        field: 'postemod',
        headerName: 'Poste modulateur (1|2|3)',
        width: MEDIUM_STRING_COLUMN_WIDTH,
        hide: true,
    },
    {
        field: 'jeton',
        headerName: 'Jeton RAMQ',
        width: 120,
        hide: true,
    },
    {
        field: 'statrecv',
        headerName: 'Statut recv. (1|2|3)',
        width: 150,
    },
    {
        field: 'annule',
        headerName: 'Annulé?',
        width: SMALL_STRING_COLUMN_WIDTH,
        renderCell: (params) => {
            const annule = params.value;

            return <BooleanIcon value={annule} />;
        },
    },
];

const getFactureDialogTableColumns = (): GridColDefPro[] => [
    {
        field: 'rank',
        headerName: 'Position',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'ligneno',
        headerName: '# Ligne fact.',
        width: 120,
    },
    {
        field: 'revision',
        headerName: 'Nb. révisions',
        width: 120,
    },
    {
        field: 'mdgrppstno',
        headerName: 'mdgrppstno',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'codefact',
        headerName: 'Code fact.',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'coderole',
        headerName: 'Code rôle',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'coderef',
        headerName: 'Code ref.',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'mntprelim',
        headerName: '$ préliminaire',
        width: 120,
    },
    {
        field: 'mntfinal',
        headerName: '$ terminal',
        width: 120,
    },
    {
        field: 'datefact',
        headerName: 'Date facturation',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => moment(params.value, 'x').format(SQL_DATETIME),
    },
    {
        field: 'debutelmfact',
        headerName: 'Début elm. fact.',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => moment(params.value, 'x').format(SQL_DATETIME),
    },
    {
        field: 'finelmfact',
        headerName: 'Fin elm. fact.',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => moment(params.value, 'x').format(SQL_DATETIME),
    },
    {
        field: 'statrecv',
        headerName: 'Statut recv. (1|2|3)',
        width: 150,
    },
];
