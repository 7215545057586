import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getGrpPstTableColumns } from './grid-columns';

// MUI Icons
import {} from '@mui/icons-material';

// Components
import PosteDialog from '../PosteDialog';
import ActiviteContexteDialog from '../ActiviteContexteDialog';
import ActiviteCodeDialog from '../ActiviteCodeDialog';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils

// Constants

interface GrpPstTabPanelProps {
    tabIndexAsString: string;
    medecin: any;
}

export default function GrpPstTabPanel({ tabIndexAsString, medecin }: GrpPstTabPanelProps) {
    const INITIAL_GROUPING_COLUMN_MODEL = ['groupe'];

    // const apiRef = useGridApiRefPro();
    // const columnsWithoutGroupedColumns = useKeepGroupingColumnsHidden(
    //     apiRef,
    //     getGrpPstTableColumns(),
    //     INITIAL_GROUPING_COLUMN_MODEL,
    // );

    // Poste Dialog
    const [selectedPoste, setSelectedPoste] = useState<any | null>(null);
    const handleOpenPosteDialog = (poste) => {
        setSelectedPoste(poste);
    };
    const handleClosePosteDialog = () => {
        setSelectedPoste(null);
    };

    // ActiviteContexte Dialog
    const [selectedActiviteContexte, setSelectedActiviteContexte] = useState<any | null>(null);
    const handleOpenActiviteContexteDialog = (activiteContexte) => {
        setSelectedActiviteContexte(activiteContexte);
    };
    const handleCloseActiviteContexteDialog = () => {
        setSelectedActiviteContexte(null);
    };

    // ActiviteCode Dialog
    const [selectedActiviteCode, setSelectedActiviteCode] = useState<any | null>(null);
    const handleOpenActiviteCodeDialog = (activiteCode) => {
        setSelectedActiviteCode(activiteCode);
    };
    const handleCloseActiviteCodeDialog = () => {
        setSelectedActiviteCode(null);
    };

    // Columns
    const columns = useMemo(
        () =>
            getGrpPstTableColumns(
                handleOpenPosteDialog,
                handleOpenActiviteContexteDialog,
                handleOpenActiviteCodeDialog,
            ),
        [],
    );

    return (
        <TabPanel value={tabIndexAsString}>
            <DataGridPro
                // apiRef={apiRef}
                getRowId={(row) => uuidv4()}
                rows={medecin.MedecinGrpPst}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                autoHeight
                disableSelectionOnClick
                hideFooter
                // rowGroupingColumnMode="single"
                initialState={
                    {
                        // rowGrouping: {
                        //     model: INITIAL_GROUPING_COLUMN_MODEL,
                        // },
                    }
                }
                // defaultGroupingExpansionDepth={-1}
            />
            <PosteDialog selectedPoste={selectedPoste} handleClosePosteDialog={handleClosePosteDialog} />
            <ActiviteContexteDialog
                selectedActiviteContexte={selectedActiviteContexte}
                handleCloseActiviteContexteDialog={handleCloseActiviteContexteDialog}
            />
            <ActiviteCodeDialog
                selectedActiviteCode={selectedActiviteCode}
                handleCloseActiviteCodeDialog={handleCloseActiviteCodeDialog}
            />
        </TabPanel>
    );
}
