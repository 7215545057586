import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import EditDrawer from './EditDrawer';
import Loading from 'components/Loading';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';

// Redux
import {} from 'redux';

// Utils
import { capitalize } from 'utils';

// Constants
import { DATETIME_COLUMN_WIDTH, SMALL_STRING_COLUMN_WIDTH } from 'constants/DataGrid';
import { SQL_DATETIME } from 'constants/Moment';

const GET_LOTS = gql`
    query GetLots($lotid: Int) {
        lots(lotid: $lotid) {
            lotid
            agence
            ouverture
            fermeture
            utilisateur
            nbredp
            lasttransmission
            enattente
            annule
            utilisateuredit
            actif
            refact
            Utilisateur {
                idname
            }
            UtilisateurEdit {
                idname
            }
        }
    }
`;

const GET_LOT = gql`
    query GetLot($lotid: Int!) {
        lot(lotid: $lotid) {
            lotid
            agence
            ouverture
            fermeture
            utilisateur
            nbredp
            lasttransmission
            enattente
            annule
            utilisateuredit
            actif
            refact
            Utilisateur {
                idname
            }
            UtilisateurEdit {
                idname
            }
            Factures {
                reqt_id
                nce
                nci
                typeservice
                revision
                licence
                poste
                postemod
                jeton
                statrecv
                annule
                lotid
                creation
                ec_id
                TYPE_SERVICE
                Medecin {
                    licence
                    prenom
                    nom
                    male
                    specialiste
                    lieutra
                    retraite
                }
                Poste {
                    description
                }
            }
        }
    }
`;

export default function LotList() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const search_lot_id = searchParams.get('lot_id');

    const [lotIdFilter, setLotIdFilter] = useState<number | null>(search_lot_id ? parseInt(search_lot_id) : null);

    const [getLots, { loading: lotsLoading, error: lotsError, data: lotsData }] = useLazyQuery(GET_LOTS);

    const [selectedLot, setSelectedLot] = useState<any | null>(null);

    const [getLot, { loading: lotLoading, error: lotError, data: lot }] = useLazyQuery(GET_LOT, {
        onCompleted: ({ lot }) => {
            setSelectedLot(lot);
        },
        notifyOnNetworkStatusChange: true, // Needs to be set to true. If not set to true, clicking on a lot, closing the drawer and then reclicking on the same lot will not trigger the drawer open
    });

    const breadcrumbs = [
        <Typography key="1" fontSize={15}>
            Lots
        </Typography>,
    ];

    const columns = useMemo(() => getColumns(getLot), [getLot]);

    if (lotsLoading) return <Loading />;
    if (lotsError) return <Typography>Error! {lotsError.message}</Typography>;

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Lots
            </Typography>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 0, mb: 5 }}>
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container spacing={2} mb={4}>
                <Grid item xs={6}>
                    <TextField
                        value={lotIdFilter || ''}
                        onChange={(e) => setLotIdFilter(e.target.value ? parseInt(e.target.value) : null)}
                        type="number"
                    ></TextField>
                </Grid>

                <Grid item xs={6}>
                    <Button
                        onClick={() =>
                            getLots({
                                variables: {
                                    lotid: lotIdFilter,
                                },
                            })
                        }
                    >
                        Fetch
                    </Button>
                </Grid>
            </Grid>

            {lotsLoading ? (
                <Grid item xs={12} container justifyContent="center">
                    <Loading />
                </Grid>
            ) : (
                <DataGridPro
                    getRowId={(row) => row.lotid}
                    rows={lotsData?.lots || []}
                    columns={columns}
                    pagination
                    pageSize={15}
                    rowsPerPageOptions={[15, 25, 50, 75, 100]}
                    autoHeight
                    disableSelectionOnClick
                />
            )}

            <EditDrawer
                selectedLot={selectedLot}
                onClose={() => {
                    setSelectedLot(null);
                }}
            />
        </>
    );
}

const getColumns = (getLot: (params: any) => void): GridColDefPro[] => [
    {
        field: 'lotid',
        headerName: '# Lot',
        width: 80,
    },
    {
        field: 'ouverture',
        headerName: 'Ouverture',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => (params.value ? moment(params.value).format(SQL_DATETIME) : null),
    },
    {
        field: 'fermeture',
        headerName: 'Fermeture',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => (params.value ? moment(params.value).format(SQL_DATETIME) : null),
    },
    {
        field: 'nbredp',
        headerName: 'Nb. DPs',
        width: 100,
    },
    {
        field: 'lasttransmission',
        headerName: 'Dernière trans.',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => (params.value ? moment(params.value).format(SQL_DATETIME) : null),
    },
    {
        field: 'utilisateur-idname',
        headerName: 'Créé par',
        width: 150,
        valueGetter: (params: any) => capitalize(params.row.Utilisateur.idname),
    },
    {
        field: 'utilisateuredit-idname',
        headerName: 'Last mod. par',
        width: 150,
        valueGetter: (params: any) => capitalize(params.row.UtilisateurEdit?.idname || ''),
    },
    {
        field: 'enattente',
        headerName: 'En attente',
        width: SMALL_STRING_COLUMN_WIDTH,
        renderCell: (params: any) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'actif',
        headerName: 'Actif',
        width: SMALL_STRING_COLUMN_WIDTH,
        renderCell: (params: any) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'refact',
        headerName: 'Refact',
        width: SMALL_STRING_COLUMN_WIDTH,
        renderCell: (params: any) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 100,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            return (
                <>
                    <IconButton
                        onClick={() => {
                            getLot({ variables: { lotid: params.row.lotid } });
                        }}
                        size="small"
                        color="primary"
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </>
            );
        },
    },
];
