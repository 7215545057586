import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdFields } from 'apollo/fragments';

export const GET_BILL_LINE_TAGS = gql`
    query GetBillLineTags {
        billLineTags {
            id
            created_at
            updated_at
            slug
            name
            desc
        }
    }
`;

export const GET_BILL_MOD_LINE_TAGS = gql`
    query GetBillModLineTags {
        billModLineTags {
            id
            created_at
            updated_at
            slug
            name
            desc
            position
        }
    }
`;

export const UPDATE_MD_BILL = gql`
    mutation UpdateClinicBill(
        $id: Int
        $status: String
        $clinic_bill_lines: [UpdateClinicBillClinicBillLinesInput]
        $clinic_bill_mod_lines: [UpdateClinicBillClinicBillModLinesInput]
    ) {
        updateClinicBill(
            id: $id
            status: $status
            clinic_bill_lines: $clinic_bill_lines
            clinic_bill_mod_lines: $clinic_bill_mod_lines
        ) {
            id
        }
    }
`;

export const GET_MDS = gql`
    ${MdFields}

    query GetMds($grp: Int, $pst: String) {
        mds(grp: $grp, pst: $pst) {
            ...MdFields
        }
    }
`;
