import { gql } from '@apollo/client';
import { FmpUserFields } from 'apollo/fragments';

export const GET_ANALYST_PERF_DATA = gql`
    query getAnalystPerfData($start_date: Date!, $end_date: Date!, $group_by: String!, $fmp_user_ids: [Int]!) {
        analystPerfData(
            start_date: $start_date
            end_date: $end_date
            group_by: $group_by
            fmp_user_ids: $fmp_user_ids
        ) {
            dps_total

            total_prelim
            total_final

            analystsGraphs {
                analyst_id
                analyst_name
                points {
                    label

                    count
                    count_new
                    count_rev

                    count_new_paper
                    count_new_dme

                    count_new_sm
                    count_new_fd
                    count_new_rmx
                    count_new_vac
                    count_new_sal

                    total_prelim
                    total_final
                    total_diff
                }
            }
        }
    }
`;

export const GET_FMP_USERS = gql`
    ${FmpUserFields}

    query GetFmpUsers {
        fmpUsers {
            ...FmpUserFields
        }
    }
`;
