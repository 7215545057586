import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getIdentifiantUtilProfTableColumns } from './grid-columns';

// MUI Icons
import {} from '@mui/icons-material';

// Components

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils

// Constants

interface IdentifiantUtilProfTabPanelProps {
    tabIndexAsString: string;
    medecin: any;
}

export default function IdentifiantUtilProfTabPanel({ tabIndexAsString, medecin }: IdentifiantUtilProfTabPanelProps) {
    // Columns
    const columns = useMemo(() => getIdentifiantUtilProfTableColumns(), []);

    return (
        <TabPanel value={tabIndexAsString}>
            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={medecin.IdentifiantUtilProf}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                autoHeight
                disableSelectionOnClick
                hideFooter
            />
        </TabPanel>
    );
}
