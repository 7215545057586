import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { Code as CodeIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import { ID_COLUMN_WIDTH, AMT_COL_DEFAULTS } from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getBillLineActeDetailsColumns = (setSelectedBracket): GridColDefPro[] => [
    {
        field: 'billing_sheet_bracket_id',
        headerName: 'Bracket ID',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'from',
        headerName: 'De',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
        width: 100,
    },
    {
        field: 'to',
        headerName: 'À',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
        width: 100,
    },
    {
        field: 'count',
        headerName: 'Nb. de lignes',
        minWidth: 150,
        flex: 1,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'fee',
        headerName: 'Tarif',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: false })} $`,
        align: 'right',
        width: 150,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_fee',
        headerName: 'Total',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: false })} $`,
        align: 'right',
        width: 150,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            return (
                <>
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                            setSelectedBracket(row);
                        }}
                    >
                        <CodeIcon fontSize="small" />
                    </IconButton>
                </>
            );
        },
    },
];

export const getBillLineLigneDetailsColumns = (setSelectedBracket): GridColDefPro[] => [
    {
        field: 'lot_id',
        headerName: 'Lot ID',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'fact_count',
        headerName: 'Nb demandes',
        width: 150,
    },
    {
        field: 'fact_line_count',
        headerName: 'Nb lignes',
        width: 150,
    },
    {
        field: 'total_non_dme',
        headerName: 'Total',
        width: 150,
        valueGetter: ({ row }) => {
            const { fact_total_fee, fact_line_total_fee } = row;
            const total = fact_total_fee + fact_line_total_fee;

            return total;
        },
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        field: 'fact_count_dme',
        headerName: 'Nb demandes DMÉ',
        width: 150,
    },
    {
        field: 'fact_line_count_dme',
        headerName: 'Nb lignes DMÉ',
        width: 150,
    },
    {
        field: 'total_dme',
        headerName: 'Total DMÉ',
        width: 150,
        valueGetter: ({ row }) => {
            const { fact_total_fee_dme, fact_line_total_fee_dme } = row;
            const total = fact_total_fee_dme + fact_line_total_fee_dme;

            return total;
        },
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_fee',
        headerName: 'Total',
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            return (
                <>
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                            setSelectedBracket(row);
                        }}
                    >
                        <CodeIcon fontSize="small" />
                    </IconButton>
                </>
            );
        },
    },
];
