import React from 'react';
import { SxProps, Typography } from '@mui/material';

interface DrawerHeaderTitleProps {
    sx: SxProps | null;
}
export default function DrawerHeaderTitle(props) {
    const { sx, children } = props;

    const sxProps = sx || {};

    return (
        <Typography variant="h3" fontSize={30} fontWeight="500" sx={{ ...sxProps }}>
            {children}
        </Typography>
    );
}
