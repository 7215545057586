import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getPostesTableColumns } from './grid-columns';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { GET_POSTES } from './apollo-queries';

// Redux
import {} from 'redux';

// Utils
import { capitalize } from 'utils';

// Constants
import { SQL_DATETIME } from 'constants/Moment';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Objets
    </Typography>,
    <Typography key="1" fontSize={15}>
        Postes
    </Typography>,
];

export default function PosteList() {
    // Apollo queries
    const { loading: postesLoading, error: postesError, data: postesData } = useQuery(GET_POSTES, {});

    // Columns
    const postesTableColumns = useMemo(() => getPostesTableColumns(), []);
    const [pageSize, setPageSize] = React.useState<number>(15);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Postes
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={postesData?.postes || []}
                columns={postesTableColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: {
                        left: ['numero', 'modulateur', 'description'],
                    },
                    sorting: {
                        sortModel: [{ field: 'numero', sort: 'asc' }],
                    },
                }}
                loading={postesLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />
        </>
    );
}
