import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import { Chip, Grid, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {} from '@mui/icons-material';

// Components

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils

// Constants

interface RamqTabPanelProps {
    tabIndexAsString: string;
    medecin: any;
}

export default function RamqTabPanel({ tabIndexAsString, medecin }: RamqTabPanelProps) {
    if (!medecin.MedecinFacInfo) {
        return (
            <TabPanel value={tabIndexAsString}>
                <Typography>Aucune information sur la facturation de ce médecin n'a été trouvé.</Typography>
            </TabPanel>
        );
    }

    const { info, profuniv, moins500, autocv, groupeb, parcourriel, codeip, moisfiscal, jourfiscal, fraiscab } =
        medecin.MedecinFacInfo;

    return (
        <TabPanel value={tabIndexAsString}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Typography>Professeur université?</Typography>
                    <Chip
                        label={profuniv ? 'Oui' : 'Non'}
                        color={profuniv ? 'primary' : 'error'}
                        size="small"
                        sx={{ borderRadius: 1, mt: 0.5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography>Moins de 500?</Typography>
                    <Chip
                        label={moins500 ? 'Oui' : 'Non'}
                        color={moins500 ? 'primary' : 'error'}
                        size="small"
                        sx={{ borderRadius: 1, mt: 0.5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography>autocv?</Typography>
                    <Chip
                        label={autocv ? 'Oui' : 'Non'}
                        color={autocv ? 'primary' : 'error'}
                        size="small"
                        sx={{ borderRadius: 1, mt: 0.5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography>Groupe B?</Typography>
                    <Chip
                        label={groupeb ? 'Oui' : 'Non'}
                        color={groupeb ? 'primary' : 'error'}
                        size="small"
                        sx={{ borderRadius: 1, mt: 0.5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography>parcourriel?</Typography>
                    <Chip
                        label={parcourriel ? 'Oui' : 'Non'}
                        color={parcourriel ? 'primary' : 'error'}
                        size="small"
                        sx={{ borderRadius: 1, mt: 0.5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography>fraiscab</Typography>
                    <Chip
                        label={fraiscab ? 'Oui' : 'Non'}
                        color={fraiscab ? 'primary' : 'error'}
                        size="small"
                        sx={{ borderRadius: 1 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography>codeip?</Typography>
                    <Typography>{codeip}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Jour/mois fiscal</Typography>
                    <Typography>{`${jourfiscal}/${moisfiscal}`}</Typography>
                </Grid>
                <Grid item xs={12} mt={3}>
                    <Typography variant="h6" fontWeight="bold">
                        Informations supplémentaires:
                    </Typography>
                    {/* <Typography>{info}</Typography> */}
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: info }}></Typography>
                    {/* <TextField
                placeholder="MultiLine with rows: 2 and rowsMax: 4"
                multiline
                rows={2}
                maxRows={4}
                value={info}
                fullWidth
            /> */}
                </Grid>
            </Grid>
        </TabPanel>
    );
}
