import moment from 'moment';

// MUI Components
import { Box, IconButton, Tooltip, CircularProgress, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { MenuOpen as MenuOpenIcon, Download as DownloadIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import { DATETIME_COL_DEFAULTS, AMT_COL_DEFAULTS, ID_COL_DEFAULTS } from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getMdHistoricalTransactionsTableColumns = (
    handleDownloadMdBill,
    mdHistoricalTransactionIdsOfCurrentFileDownloads,
): GridColDefPro[] => [
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'txn_recorded_at',
        headerName: 'Date transaction',
        valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATETIME) : null),
    },
    {
        field: 'type',
        headerName: 'Type de transaction',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueGetter: ({ value }) => (value === 'payment' ? 'Paiement' : 'Facture'),
    },
    {
        field: 'ref_id',
        headerName: 'Ref ID',
        headerAlign: 'center',
        align: 'center',
        width: 120,
    },
    {
        field: 'ref_no',
        headerName: 'No facture/paiement',
        headerAlign: 'center',
        align: 'center',
        width: 150,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'old_balance',
        headerName: 'Ancien Solde',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'amt',
        headerName: 'Montant',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
        renderCell: ({ value, row }) => {
            let textColor = '';
            if (row.type === 'payment') {
                value = -value;
            }

            if (value > 0) textColor = 'success.main';
            else if (value === 0) textColor = 'warning.main';
            else if (value < 0) textColor = 'error.main';
            else textColor = 'success.main';

            return (
                <Typography variant="body2" sx={{ color: textColor }}>
                    {formatNumber(value as number, { cents: true })} $
                </Typography>
            );
        },
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'new_balance',
        headerName: 'Nouveau solde',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { type, ref_id } = row;

            const isFileDownloadButtonLoading = mdHistoricalTransactionIdsOfCurrentFileDownloads.includes(ref_id);

            return (
                <Box display="flex" alignItems="center">
                    {isFileDownloadButtonLoading ? (
                        <IconButton size="medium" color="primary" disabled={true}>
                            <CircularProgress size={14} />
                        </IconButton>
                    ) : (
                        type === 'bill' &&
                        ref_id && (
                            <Tooltip title="Télécharger la facture." placement="left" disableInteractive>
                                <IconButton
                                    size="medium"
                                    color="primary"
                                    onClick={() => {
                                        handleDownloadMdBill(ref_id);
                                    }}
                                >
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )
                    )}
                </Box>
            );
        },
    },
];
