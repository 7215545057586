import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { ClinicPaymentFields } from 'apollo/fragments';

export const GET_CLINIC_PAYMENT = gql`
    ${ClinicPaymentFields}

    query GetClinicPayment($id: Int) {
        clinicPayment(id: $id) {
            ...ClinicPaymentFields
        }
    }
`;

export const DELETE_CLINIC_PAYMENT = gql`
    mutation DeleteClinicPayment($id: Int) {
        deleteClinicPayment(id: $id) {
            id
        }
    }
`;
