import React, { useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { cloneDeep, isEmpty, lowerFirst } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom';

import 'index.css';

// MUI Components
import {
    Alert,
    AlertTitle,
    Box,
    Breadcrumbs,
    Chip,
    CircularProgress,
    CircularProgressProps,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// MUI Grid

// MUI Icons
import {
    Clear as ClearIcon,
    NavigateNext as NavigateNextIcon,
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import DpCountGraph from './DpCountGraph';
import SheetCountGraph from './SheetCountGraph';
import DpTotalsGraph from './DpTotalsGraph';
import TotalPerActGraph from './TotalPerActGraph';
import DpDmeGraph from './DpDmeGraph';
import DpFactligneGraph from './DpFactligneGraph';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_ADMIN_GENERAL_PERF_PAGE, GET_FMP_USERS } from './apollo-queries';

// Redux

// Services

// Constants
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';
import { SQL_DATE } from 'constants/Moment';

// Utils
import { capitalize, formatNumber, numberToHuman } from 'utils';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Statistiques
    </Typography>,
    <Typography key="1" fontSize={15}>
        Performance générale
    </Typography>,
];

const GROUP_BY_OPTIONS = [
    {
        label: 'Jour',
        value: 'day',
    },
    {
        label: 'Semaine',
        value: 'week',
    },
    {
        label: 'Mois',
        value: 'month',
    },
    {
        label: 'État de compte',
        value: 'ec',
    },
];

export default function GeneralPerformanceDash() {
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter - Start date
    const search_start_date = searchParams.get('start_date');
    const [startDateFilter, setStartDateFilter] = useState<Moment | null>(
        // search_start_date ? moment(search_start_date) : moment('2022-06-01').subtract(8, 'week').startOf('week'),
        search_start_date ? moment(search_start_date) : moment().subtract(8, 'week').startOf('week'),
    );
    const handleChangeStartDate = (value) => {
        setStartDateFilter(value);
    };
    const handleClearStartDate = () => {
        setStartDateFilter(null);
    };

    // Filter - End date
    const search_end_date = searchParams.get('end_date');
    const [endDateFilter, setEndDateFilter] = useState<Moment | null>(
        // search_end_date ? moment(search_end_date) : moment('2022-06-01'),
        search_end_date ? moment(search_end_date) : moment(),
    );
    const handleChangeEndDate = (value) => {
        setEndDateFilter(value);
    };
    const handleClearEndDate = () => {
        setEndDateFilter(null);
    };

    // Filter - Group by
    const search_group_by = searchParams.get('group_by');
    const [groupByFilter, setGroupByFilter] = useState<string | null>(search_group_by ? search_group_by : 'day');
    const handleChangeGroupBy = (e) => {
        setGroupByFilter(e.target.value || null);
    };

    // Filter - Analyst Ids
    const search_fmp_user_ids = searchParams.get('fmp_user_ids');
    const [fmpUserIdsFilter, setFmpUserIdsFilter] = useState<number[]>(
        search_fmp_user_ids ? search_fmp_user_ids.split(',').map((string) => parseInt(string)) : [],
    );
    const handleChangeFmpUserIds = (e) => {
        const {
            target: { value },
        } = e;
        setFmpUserIdsFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',').map((string) => parseInt(string)) : value,
        );
    };

    // Apollo queries
    const isInvalidQuery = !startDateFilter || !endDateFilter || !groupByFilter;
    const {
        loading: adminGeneralPerfPageLoading,
        error: adminGeneralPerfPageError,
        data: adminGeneralPerfPageData,
    } = useQuery(GET_ADMIN_GENERAL_PERF_PAGE, {
        variables: {
            start_date: startDateFilter,
            end_date: endDateFilter,
            group_by: groupByFilter,
            fmp_user_ids: fmpUserIdsFilter,
        },
        skip: isInvalidQuery,
    });
    const data = adminGeneralPerfPageData?.adminGeneralPerfPage || {};

    const { loading: fmpUsersLoading, error: fmpUsersError, data: fmpUsersData } = useQuery(GET_FMP_USERS);
    const fmpUsers = fmpUsersData?.fmpUsers || [];

    // Targets/Objectives
    const totalPrelimTarget = 2000000;
    const countNewPaperTarget = 14000;
    const countNewDmeTarget = 8000;
    const countNewFactLignesTarget = 30000;

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                    <Typography variant="h5" fontWeight="600">
                        Performance générale
                    </Typography>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
            </Stack>

            <Grid container spacing={3} mb={2.5}>
                <Grid item xs={2.4}>
                    <DatePicker
                        label={'Date du début'}
                        value={startDateFilter}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: startDateFilter && (
                                <IconButton onClick={handleClearStartDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>

                <Grid item xs={2.4}>
                    <DatePicker
                        label={'Date du fin'}
                        value={endDateFilter}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: endDateFilter && (
                                <IconButton onClick={handleClearEndDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>

                <Grid item xs={2.4}>
                    <TextField
                        select
                        label="Grouper par"
                        value={groupByFilter}
                        onChange={handleChangeGroupBy}
                        size="small"
                        fullWidth
                    >
                        {GROUP_BY_OPTIONS.map((option, idx) => (
                            <MenuItem key={idx} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={2.4}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="analysts-multiselect">Analystes</InputLabel>
                        <Select
                            labelId="analysts-multiselect"
                            multiple
                            value={fmpUserIdsFilter}
                            onChange={handleChangeFmpUserIds}
                            input={<OutlinedInput label="Analystes" size="small" fullWidth />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => {
                                        const fmpUser = fmpUsers.find((fmpUser) => fmpUser.id === value);
                                        const { id, name } = fmpUser;

                                        return <Chip key={id} label={name} size="small" />;
                                    })}
                                </Box>
                            )}
                        >
                            {fmpUsers.map((fmpUser) => {
                                const { id, name } = fmpUser;

                                return (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={4}>
                <Grid item xs={12}>
                    <Typography variant="h4" fontWeight="bold">
                        {/* Objectif de la semaine */}
                        Progression de la semaine en cours
                    </Typography>
                </Grid>

                {/* <Grid item xs={3}>
                    <StatsCardDiff />
                </Grid> */}

                <Grid item container spacing={3}>
                    <Grid item xs={3}>
                        <StatsCardProgress
                            title="Montant préliminaire facturé"
                            currVal={numberToHuman(data?.currentWeekStats?.total_prelim, 2, null, ' $')}
                            targetVal={numberToHuman(totalPrelimTarget, 2, null, ' $')}
                            percentage={((data?.currentWeekStats?.total_prelim || 0) * 100) / totalPrelimTarget}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <StatsCardProgress
                            title="Demandes papiers envoyées"
                            currVal={numberToHuman(data?.currentWeekStats?.count_new_paper, 0, null, ' demandes')}
                            targetVal={numberToHuman(countNewPaperTarget, 0, null, ' demandes')}
                            percentage={((data?.currentWeekStats?.count_new_paper || 0) * 100) / countNewPaperTarget}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <StatsCardProgress
                            title="Demandes électroniques envoyées"
                            currVal={numberToHuman(data?.currentWeekStats?.count_new_dme, 0, null, ' demandes')}
                            targetVal={numberToHuman(countNewDmeTarget, 0, null, ' demandes')}
                            percentage={((data?.currentWeekStats?.count_new_dme || 0) * 100) / countNewDmeTarget}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <StatsCardProgress
                            title="Nombre d'actes facturés"
                            currVal={numberToHuman(data?.currentWeekStats?.count_new_factligne, 0, null, ' lignes')}
                            targetVal={numberToHuman(countNewFactLignesTarget, 0, null, ' lignes')}
                            percentage={
                                ((data?.currentWeekStats?.count_new_factligne || 0) * 100) / countNewFactLignesTarget
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={4}>
                {adminGeneralPerfPageLoading ? (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                            <Loading />
                        </Box>
                    </Grid>
                ) : isInvalidQuery ? (
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="warning">
                            <AlertTitle>Veuillez remplir tous les champs</AlertTitle>
                            Veuillez sélectionner une date de début, une date de fin et une méthode de groupement.
                        </Alert>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h4" fontWeight="bold">
                                Performance générale par {getGroupByDisplayedText(groupByFilter)} du{' '}
                                {startDateFilter?.format('LL')} au {endDateFilter?.format('LL')}
                            </Typography>
                        </Grid>

                        {!isEmpty(data) && (
                            <>
                                <DpCountGraph data={data} startDate={startDateFilter} endDate={endDateFilter} />

                                <DpTotalsGraph data={data} startDate={startDateFilter} endDate={endDateFilter} />

                                <DpFactligneGraph data={data} startDate={startDateFilter} endDate={endDateFilter} />

                                <SheetCountGraph data={data} startDate={startDateFilter} endDate={endDateFilter} />

                                <DpDmeGraph data={data} startDate={startDateFilter} endDate={endDateFilter} />

                                <TotalPerActGraph data={data} startDate={startDateFilter} endDate={endDateFilter} />
                            </>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
}

const getGroupByDisplayedText = (groupBy: string | null) => {
    const option = GROUP_BY_OPTIONS.find((item) => item.value === groupBy);

    if (option) return lowerFirst(option.label);
    else return '';
};

const StatsCardDiff = () => {
    return (
        <Paper
            sx={{
                px: 2,
                py: 2,
            }}
        >
            <Typography variant="h6">Montant facturé</Typography>
            <Stack direction="row" alignItems="center" mt={2} mb={1}>
                <Typography variant="h4" fontWeight="bold" mr={2}>
                    23 465.44$
                </Typography>

                <Stack direction="row" alignItems="center">
                    <Typography variant="body2" fontWeight="bold" color="success.main">
                        +9.83%
                    </Typography>

                    <ArrowUpwardIcon color="success" fontSize="small" />
                </Stack>
            </Stack>
            <Typography variant="caption" fontSize={12}>
                Comparé à 21 918.77$ le jour précédent
            </Typography>
        </Paper>
    );
};

interface StatsCardProgressProps {
    title: string;
    currVal: string;
    targetVal: string;
    percentage: number;
}
const StatsCardProgress = (props: StatsCardProgressProps) => {
    const { title, currVal, targetVal, percentage } = props;

    return (
        <Paper
            sx={{
                px: 2,
                py: 2,
            }}
        >
            <Typography variant="h6">{title}</Typography>

            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} mb={1}>
                <Typography variant="h4" fontWeight="bold" mr={2}>
                    {currVal}
                </Typography>

                <Stack direction="row">
                    <CircularProgressWithLabel value={percentage} color={percentage >= 100 ? 'success' : 'warning'} />
                </Stack>
            </Stack>

            <LinearProgress
                variant="determinate"
                value={percentage}
                color={percentage >= 100 ? 'success' : 'warning'}
                sx={{ mt: 2, mb: 2 }}
            />

            <Typography variant="caption" fontSize={12} textAlign="right" component="div">
                Sur objectif de {targetVal}
            </Typography>
        </Paper>
    );
};

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
};
