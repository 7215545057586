import { gql } from '@apollo/client';
import {} from 'apollo/fragments';

export const GET_BILLING_PERF_DATA = gql`
    query getBillingPerfData($start_date: Date!, $end_date: Date!) {
        billingPerfData(start_date: $start_date, end_date: $end_date) {
            graphPoints {
                label

                sum_subtotal
                sum_tps
                sum_tvq
                sum_total

                sum_subtotal_md
                sum_tps_md
                sum_tvq_md
                sum_total_md

                sum_subtotal_clinic
                sum_tps_clinic
                sum_tvq_clinic
                sum_total_clinic

                percentage_subtotal_md
                percentage_subtotal_clinic

                sum_frais_fact_acte_md
                sum_frais_fact_ligne_md

                percentage_frais_fact_acte_md
                percentage_frais_fact_ligne_md

                sum_frais_fact_acte_clinic
                sum_frais_fact_ligne_clinic

                percentage_frais_fact_acte_clinic
                percentage_frais_fact_ligne_clinic

                sum_frais_fact_rmx_md
                sum_frais_fact_rpv_md
                sum_frais_fact_rps_md
                sum_frais_fact_fd_acte_md
                sum_frais_fact_fd_ligne_md
                sum_frais_fact_19680_md

                sum_frais_fact_rmx_clinic
                sum_frais_fact_rpv_clinic
                sum_frais_fact_rps_clinic
                sum_frais_fact_fd_acte_clinic
                sum_frais_fact_fd_ligne_clinic
                sum_frais_fact_19680_clinic

                sum_rabais_amuq_md
                sum_rabais_annee_1_md
                sum_rabais_annee_2_md
                sum_rabais_annee_3_md
                sum_rabais_referencement_md
                sum_rabais_clinique_md
                sum_ajust_credit_md
                sum_ajust_debit_md
                sum_logiciel_base_md
                sum_dme_kinlogix_md
                sum_dme_medfar_md
                sum_dme_omnimed_md
                sum_rabais_groupe_md
                sum_rabais_bienvenue_md
                sum_mandats_speciaux_md
                sum_rabais_participation_portail_md
                sum_enveloppe_md

                percentage_total_discount_on_total_sales_md

                percentage_total_software_on_total_sales_md

                sum_rabais_amuq_clinic
                sum_rabais_annee_1_clinic
                sum_rabais_annee_2_clinic
                sum_rabais_annee_3_clinic
                sum_rabais_referencement_clinic
                sum_rabais_clinique_clinic
                sum_ajust_credit_clinic
                sum_ajust_debit_clinic
                sum_logiciel_base_clinic
                sum_dme_kinlogix_clinic
                sum_dme_medfar_clinic
                sum_dme_omnimed_clinic
                sum_rabais_groupe_clinic
                sum_rabais_bienvenue_clinic
                sum_mandats_speciaux_clinic
                sum_rabais_participation_portail_clinic
                sum_enveloppe_clinic

                percentage_total_discount_on_total_sales_clinic

                percentage_total_software_on_total_sales_clinic
            }
        }
    }
`;
