import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { Button, IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    POSTE_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
    ACTIVE_COLUMN_WIDTH,
    LONG_STRING_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getGrpPstTableColumns = (
    handleOpenPosteDialog: (poste: any) => void,
    handleOpenActiviteContexteDialog: (activiteContexte: any) => void,
    handleOpenActiviteCodeDialog: (activiteCode: any) => void,
): GridColDefPro[] => [
    {
        field: 'groupe',
        headerName: 'Groupe',
        width: 100,
        hide: true,
        // groupingValueGetter: (params) => {
        //     const groupe = params.value;
        //     const {
        //         Groupe: { denomination, incorp },
        //     } = params.row;
        //     return `#${groupe} - ${denomination} / ${incorp}`;
        // },
    },
    // {
    //     field: 'mdgrppstno',
    //     headerName: '# GrpPst',
    //     width: ID_COLUMN_WIDTH,
    //     renderCell: (params) => {
    //         const mdgrppstno = params.value;

    //         return (
    //             <>
    //                 <Typography variant="body2" color="primary">
    //                     {mdgrppstno}
    //                 </Typography>
    //             </>
    //         );
    //     },
    // },
    {
        field: 'poste',
        headerName: 'Poste',
        width: ID_COLUMN_WIDTH,
        renderCell: (params) => {
            const poste = params.value;

            if (poste)
                return (
                    <>
                        <Button
                            onClick={() => {
                                handleOpenPosteDialog(params.row.Poste);
                            }}
                            variant="contained"
                            size="small"
                        >
                            {poste}
                        </Button>
                    </>
                );
        },
    },
    {
        field: 'actif',
        headerName: 'Actif',
        width: ACTIVE_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'posteNom',
        headerName: 'Nom poste',
        width: LONG_STRING_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.Poste?.description,
    },
    {
        field: 'activiteContexteCode',
        headerName: "# Ctx. d'activité",
        width: 150,
        valueGetter: ({ row }) => row.MedecinPstCa?.ActiviteContexte?.code,
        renderCell: ({ value, row }) =>
            value ? (
                <>
                    <Button
                        onClick={() => {
                            handleOpenActiviteContexteDialog(row.MedecinPstCa.ActiviteContexte);
                        }}
                        variant="contained"
                        size="small"
                    >
                        {value}
                    </Button>
                </>
            ) : null,
    },
    {
        field: 'activiteContexteCode',
        headerName: "# Ctx. d'activité",
        width: 150,
        valueGetter: ({ row }) => row.MedecinPstCa?.ActiviteCode?.code,
        renderCell: ({ value, row }) =>
            value ? (
                <>
                    <Button
                        onClick={() => {
                            handleOpenActiviteCodeDialog(row.MedecinPstCa.ActiviteCode);
                        }}
                        variant="contained"
                        size="small"
                    >
                        {value}
                    </Button>
                </>
            ) : null,
    },
    {
        field: 'rh',
        headerName: 'RH',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'modulateur',
        headerName: 'Modulateur',
        width: MODULATEUR_COLUMN_WIDTH,
    },
    {
        field: 'sm',
        headerName: 'SM',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'rmx',
        headerName: 'RMX',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'vac',
        headerName: 'VAC',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'sal',
        headerName: 'SAL',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'condrmx',
        headerName: '# Cond. RMX',
        width: 125,
        valueGetter: ({ row }) => row.MedecinGrpPstCondRmx?.ConditionRmx.description,
    },
    {
        field: 'debut',
        headerName: 'Début',
        width: 200,
        valueFormatter: ({ value }) => value && moment(value as any, 'x').format(SQL_DATETIME),
    },
    {
        field: 'fin',
        headerName: 'Fin',
        width: 200,
        valueFormatter: ({ value }) => value && moment(value as any, 'x').format(SQL_DATETIME),
    },
];
