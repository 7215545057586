import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme, Theme } from '@mui/material/styles';

// MUI Components
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Snackbar,
    Alert,
    Box,
    AlertTitle,
    DialogContentText,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { Delete as DeleteIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { DELETE_CLINIC_PAYMENT, GET_CLINIC_PAYMENT } from './apollo-queries';
import { GET_CLINIC_TRANSACTIONS } from 'views/clinics-transactions/ClinicTransactionList/apollo-queries';

// Redux

// Utils
import { getErrorMsg } from 'utils';

// Constants

interface ClinicPaymentDeleteDialogProps {
    isClinicPaymentDeleteDialogOpen: boolean;
    handleCloseClinicPaymentDeleteDialog: () => void;
    selectedPaymentId: number | null;
}

interface ClinicPaymentDeleteDialogContentProps {
    isClinicPaymentDeleteDialogOpen: boolean;
    handleCloseClinicPaymentDeleteDialog: () => void;
    selectedPaymentId: number | null;
}

export function ClinicPaymentDeleteDialog({
    isClinicPaymentDeleteDialogOpen,
    handleCloseClinicPaymentDeleteDialog,
    selectedPaymentId,
}: ClinicPaymentDeleteDialogProps) {
    const isDialogOpen = isClinicPaymentDeleteDialogOpen ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={handleCloseClinicPaymentDeleteDialog}>
            <ClinicPaymentDeleteDialogContent
                isClinicPaymentDeleteDialogOpen={isClinicPaymentDeleteDialogOpen}
                handleCloseClinicPaymentDeleteDialog={handleCloseClinicPaymentDeleteDialog}
                selectedPaymentId={selectedPaymentId}
            />
        </Dialog>
    );
}

const ClinicPaymentDeleteDialogContent = ({
    isClinicPaymentDeleteDialogOpen,
    handleCloseClinicPaymentDeleteDialog,
    selectedPaymentId,
}: ClinicPaymentDeleteDialogContentProps) => {
    const theme = useTheme();

    const {
        loading: clinicPaymentLoading,
        error: clinicPaymentError,
        data: clinicPaymentData,
    } = useQuery(GET_CLINIC_PAYMENT, {
        variables: { id: selectedPaymentId },
        skip: !isClinicPaymentDeleteDialogOpen,
    });

    const [
        deleteClinicPayment,
        { loading: deleteClinicPaymentLoading, error: deleteClinicPaymentError, data: deleteClinicPaymentData },
    ] = useMutation(DELETE_CLINIC_PAYMENT, {
        refetchQueries: [GET_CLINIC_TRANSACTIONS, GET_CLINIC_PAYMENT],
    });

    const clinicPayment = clinicPaymentData?.clinicPayment;

    const onSubmit = async () => {
        await deleteClinicPayment({
            variables: {
                id: selectedPaymentId,
            },
        });

        handleCloseClinicPaymentDeleteDialog();
    };

    // Error snackbar
    const [isErrorSnackbarOpened, setIsErrorSnackbarOpened] = React.useState(false);
    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpened(false);
    };
    useEffect(() => setIsErrorSnackbarOpened(deleteClinicPaymentError ? true : false), [deleteClinicPaymentError]);

    const isAlreadyDeleted = clinicPayment?.deleted_at ? true : false;

    return (
        <>
            <DialogTitle>Supprimer le paiement #{selectedPaymentId}?</DialogTitle>

            <DialogContent>
                {clinicPaymentLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height={100}>
                        <Loading />
                    </Box>
                ) : (
                    <Grid container spacing={3} pt={1}>
                        {isAlreadyDeleted && (
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <AlertTitle>Vous ne pouvez supprimer ce paiement</AlertTitle>
                                    Ce paiement ne peut être supprimé puisqu'il a déjà été supprimé antérieurement.
                                    {clinicPayment.new_clinic_payment_id && (
                                        <>
                                            <br />
                                            <br />
                                            Suivant l'annulation du paiement, un nouveau paiement avec un ID de{' '}
                                            <strong>{clinicPayment.new_clinic_payment_id}</strong> a été créé pour
                                            remplacer ce paiement.
                                        </>
                                    )}
                                </Alert>
                            </Grid>
                        )}

                        {!isAlreadyDeleted && (
                            <Grid item xs={12}>
                                <DialogContentText>
                                    Le paiement restera accessible mais un nouveau paiement négatif sera enregistré au
                                    compte. Une transaction sera aussi ajoutée au journal de transaction pour ajuster la
                                    balance de cette clinique.
                                </DialogContentText>
                            </Grid>
                        )}
                    </Grid>
                )}
            </DialogContent>

            <DialogActions>
                {isAlreadyDeleted ? (
                    <Button variant="text" onClick={handleCloseClinicPaymentDeleteDialog}>
                        Fermer
                    </Button>
                ) : (
                    <>
                        <Button variant="text" color="secondaryText" onClick={handleCloseClinicPaymentDeleteDialog}>
                            Ne pas supprimer
                        </Button>
                        <LoadingButton
                            variant="text"
                            endIcon={<DeleteIcon />}
                            loading={deleteClinicPaymentLoading}
                            loadingPosition="end"
                            onClick={onSubmit}
                        >
                            Supprimer
                        </LoadingButton>
                    </>
                )}
            </DialogActions>

            <Snackbar
                open={isErrorSnackbarOpened}
                autoHideDuration={6000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {getErrorMsg(deleteClinicPaymentError)}
                </Alert>
            </Snackbar>
        </>
    );
};
