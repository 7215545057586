import React, { useState, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import moment, { Moment } from 'moment';
import { cloneDeep, lowerFirst, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom';

import 'index.css';

// MUI Components
import {
    Alert,
    AlertTitle,
    Box,
    Breadcrumbs,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DatePicker, StaticDatePicker } from '@mui/lab';
import { useTheme, darken, lighten } from '@mui/material/styles';

// MUI Grid

// MUI Icons
import { Clear as ClearIcon, NavigateNext as NavigateNextIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import PaperDpCountGraph from './PaperDpCountGraph';
import DmeDpCountGraph from './DmeDpCountGraph';
import RevDpCountGraph from './RevDpCountGraph';
import DpSmCountGraph from './DpSmCountGraph';
import DpFdCountGraph from './DpFdCountGraph';
import DpRmxCountGraph from './DpRmxCountGraph';
import DpVacCountGraph from './DpVacCountGraph';
import RfpPrelimAmtGraph from './RfpPrelimAmtGraph';
import RfpFinalAmtGraph from './RfpFinalAmtGraph';
import RfpDiffAmtGraph from './RfpDiffAmtGraph';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_ANALYST_PERF_DATA, GET_FMP_USERS } from './apollo-queries';

// Redux

// Services

// Constants
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';
import { SQL_DATE } from 'constants/Moment';

// Utils
import { capitalize, formatNumber } from 'utils';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Statistiques
    </Typography>,
    <Typography key="1" fontSize={15}>
        Performance analyste
    </Typography>,
];

const GROUP_BY_OPTIONS = [
    {
        label: 'Jour',
        value: 'day',
    },
    {
        label: 'Semaine',
        value: 'week',
    },
    {
        label: 'Mois',
        value: 'month',
    },
    {
        label: 'État de compte',
        value: 'ec',
    },
];

export default function AnalystPerformanceDash() {
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter - Start date
    const search_start_date = searchParams.get('start_date');
    const [startDateFilter, setStartDateFilter] = useState<Moment | null>(
        // search_start_date ? moment(search_start_date) : moment('2022-06-01').subtract(24, 'week').startOf('week'),
        search_start_date ? moment(search_start_date) : moment().subtract(24, 'week').startOf('week'),
    );
    const handleChangeStartDate = (value) => {
        setStartDateFilter(value);
    };
    const handleClearStartDate = () => {
        setStartDateFilter(null);
    };

    // Filter - End date
    const search_end_date = searchParams.get('end_date');
    const [endDateFilter, setEndDateFilter] = useState<Moment | null>(
        // search_end_date ? moment(search_end_date) : moment('2022-06-01'),
        search_end_date ? moment(search_end_date) : moment(),
    );
    const handleChangeEndDate = (value) => {
        setEndDateFilter(value);
    };
    const handleClearEndDate = () => {
        setEndDateFilter(null);
    };

    // Filter - Group by
    const search_group_by = searchParams.get('group_by');
    const [groupByFilter, setGroupByFilter] = useState<string | null>(search_group_by ? search_group_by : 'week');
    const handleChangeGroupBy = (e) => {
        setGroupByFilter(e.target.value || null);
    };

    // Filter - Analyst Ids
    const search_fmp_user_ids = searchParams.get('fmp_user_ids');
    const [fmpUserIdsFilter, setFmpUserIdsFilter] = useState<number[]>(
        search_fmp_user_ids
            ? search_fmp_user_ids.split(',').map((string) => parseInt(string))
            : [
                  22, // Cathy
                  55, // Claudine
                  17, // Lucie
                  56, // Manon
                  48, // Marie-Claude
                  42, // Nancy
                  52, // Sandra
                  54, // Sandrafact
                  26, // Virginie
              ],
    );
    const handleChangeFmpUserIds = (e) => {
        const {
            target: { value },
        } = e;
        setFmpUserIdsFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',').map((string) => parseInt(string)) : value,
        );
    };

    // Apollo queries
    const isInvalidQuery = !startDateFilter || !endDateFilter || !groupByFilter;
    const {
        loading: analystPerfLoading,
        error: analystPerfError,
        data: analystPerfData,
    } = useQuery(GET_ANALYST_PERF_DATA, {
        variables: {
            start_date: startDateFilter,
            end_date: endDateFilter,
            group_by: groupByFilter,
            fmp_user_ids: fmpUserIdsFilter,
        },
        skip: isInvalidQuery,
    });
    const data = analystPerfData?.analystPerfData || {};
    const analystsGraphs = data?.analystsGraphs || [];

    const { loading: fmpUsersLoading, error: fmpUsersError, data: fmpUsersData } = useQuery(GET_FMP_USERS);
    const fmpUsers = fmpUsersData?.fmpUsers || [];

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                    <Typography variant="h5" fontWeight="600">
                        Performance analyste
                    </Typography>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
            </Stack>

            <Grid container spacing={3} mb={2.5}>
                <Grid item xs={2.4}>
                    <DatePicker
                        label={'Date du début'}
                        value={startDateFilter}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: startDateFilter && (
                                <IconButton onClick={handleClearStartDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>

                <Grid item xs={2.4}>
                    <DatePicker
                        label={'Date du fin'}
                        value={endDateFilter}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: endDateFilter && (
                                <IconButton onClick={handleClearEndDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>

                <Grid item xs={2.4}>
                    <TextField
                        select
                        label="Grouper par"
                        value={groupByFilter}
                        onChange={handleChangeGroupBy}
                        size="small"
                        fullWidth
                    >
                        {GROUP_BY_OPTIONS.map((option, idx) => (
                            <MenuItem key={idx} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={4.8}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="analysts-multiselect">Analystes</InputLabel>
                        <Select
                            labelId="analysts-multiselect"
                            multiple
                            value={fmpUserIdsFilter}
                            onChange={handleChangeFmpUserIds}
                            input={<OutlinedInput label="Analystes" size="small" fullWidth />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value, idx) => {
                                        const fmpUser = fmpUsers.find((fmpUser) => fmpUser.id === value);

                                        if (fmpUser) {
                                            const { id, name } = fmpUser;

                                            return <Chip key={idx} label={name} size="small" />;
                                        } else {
                                            return <div key={idx}></div>;
                                        }
                                    })}
                                </Box>
                            )}
                        >
                            {fmpUsers.map((fmpUser) => {
                                const { id, name } = fmpUser;

                                return (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {analystPerfLoading ? (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                            <Loading />
                        </Box>
                    </Grid>
                ) : isInvalidQuery ? (
                    <Grid item xs={12} mt={3} mb={2}>
                        <Alert variant="outlined" severity="warning">
                            <AlertTitle>Veuillez remplir tous les champs</AlertTitle>
                            Veuillez sélectionner une date de début, une date de fin et une méthode de groupement.
                        </Alert>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} mt={3} mb={2}>
                            <Typography variant="h4" fontWeight="bold" textAlign="center">
                                Performance analyste par {getGroupByDisplayedText(groupByFilter)} du{' '}
                                {startDateFilter?.format('LL')} au {endDateFilter?.format('LL')}
                            </Typography>
                        </Grid>

                        {!isEmpty(data) && (
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <PaperDpCountGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DmeDpCountGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <RevDpCountGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DpSmCountGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DpFdCountGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DpRmxCountGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DpVacCountGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <RfpPrelimAmtGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <RfpFinalAmtGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <RfpDiffAmtGraph
                                        data={analystsGraphs}
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
}

const getGroupByDisplayedText = (groupBy: string | null) => {
    const option = GROUP_BY_OPTIONS.find((item) => item.value === groupBy);

    if (option) return lowerFirst(option.label);
    else return '';
};
