import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import { Chip, Grid, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {} from '@mui/icons-material';

// Components

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils

// Constants

interface OverviewTabPanelProps {
    tabIndexAsString: string;
    medecin: any;
}

export default function OverviewTabPanel({ tabIndexAsString, medecin }: OverviewTabPanelProps) {
    // const medecinStatsData = medecinStatsGqlQueryStatus.data?.factligneSum || null;

    return (
        <TabPanel value={tabIndexAsString}>
            {/* {medecinStatsGqlQueryStatus.loading ? (
                <Loading />
            ) : (
                medecinStatsData && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <BillingAreaChart data={medecinStatsData} />
                        </Grid>
                        <Grid item xs={6}>
                            <BillingBarChart data={medecinStatsData} />
                        </Grid>
                        <Grid item xs={6}>
                            <BillingLineChart data={medecinStatsData} />
                        </Grid>
                    </Grid>
                )
            )} */}
        </TabPanel>
    );
}
