import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdBillFields, EcFields, MdFields } from 'apollo/fragments';

export const GET_MD_BILLS = gql`
    ${MdBillFields}

    query GetMdBills($id: Int, $bill_no: String, $md_id: Int, $ec_id: Int) {
        mdBills(id: $id, bill_no: $bill_no, md_id: $md_id, ec_id: $ec_id) {
            ...MdBillFields

            date_ec
            date_coupure
            dd_periode_fact
            df_periode_fact
            no_paiement

            first_name
            last_name

            sum_tag_1
            sum_tag_2
            sum_tag_3
            sum_tag_4
            sum_tag_5
            sum_tag_6
            sum_tag_7
            sum_tag_8

            sum_mod_tag_1
            sum_mod_tag_2
            sum_mod_tag_3
            sum_mod_tag_4
            sum_mod_tag_5
            sum_mod_tag_6
            sum_mod_tag_7
            sum_mod_tag_8
            sum_mod_tag_9
            sum_mod_tag_10
            sum_mod_tag_11
            sum_mod_tag_12
            sum_mod_tag_13
        }
    }
`;

export const GET_ECS = gql`
    ${EcFields}

    query GetEcs {
        ecs {
            ...EcFields
        }
    }
`;

export const GET_MDS = gql`
    ${MdFields}

    query GetMds {
        mds {
            ...MdFields
        }
    }
`;
