import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery, FetchResult } from '@apollo/client';
import { useNavigate } from 'react-router';

import { AuthContext } from './context';

import { Auth } from 'services';
import { Admin } from 'types';

const GET_ADMIN = gql`
    query GetAdmin {
        admin {
            id
            created_at
            updated_at
            email
            password
            first_name
            last_name
            phone
            avatar_url
            can_login
        }
    }
`;

export default function AuthProvider({ children }: { children: React.ReactNode }) {
    const [admin, setAdmin] = useState<Admin | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getAdmin();
    }, []);

    let [
        getAdmin,
        {
            loading: getAdminLoading,
            error: getAdminError,
            data: getAdminData,
            called,
            previousData,
            refetch: refetchAdmin,
        },
    ] = useLazyQuery(GET_ADMIN, {
        onCompleted: ({ admin }) => {
            setAdmin(admin);
            setLoading(false);
        },
        onError: () => {
            setAdmin(null);
            setLoading(false);
        },
    });

    let login = async (token: string) => {
        Auth.login(token);

        setLoading(true);
        const res = await refetchAdmin();
        setAdmin(res.data.admin);
        setLoading(false);

        return true;
    };

    let logout = async () => {
        Auth.logout();
        setAdmin(null);

        return true;
    };

    let value = {
        admin: admin,
        error: getAdminError,
        isLoading: loading,
        login,
        logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
