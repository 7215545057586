import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
    Controller,
    useForm,
    useController,
    Control,
    FormState,
    useFieldArray,
    UseFormWatch,
    UseFormReturn,
} from 'react-hook-form';
import { isEmpty } from 'lodash';

// MUI Components
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Grid,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    useTheme,
    Stack,
    Snackbar,
    Alert,
    Box,
} from '@mui/material';

// MUI Grid

// MUI Icons
import {
    Delete as DeleteIcon,
    Help as HelpIcon,
    Add as AddIcon,
    Edit as EditIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

// Components
import { MoneyControl } from 'components/inputs/MoneyControl';
import { NumberControl } from 'components/inputs/NumberControl';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_MD_BILL, GET_BILL_LINE_TAGS, GET_BILL_MOD_LINE_TAGS, GET_MDS } from './apollo-queries';
import { GET_MD_BILL } from 'views/bills/BillList/EditDrawer/apollo-queries';

// Redux

// Utils
import { roundNumber, formatNumber, getErrorMsg } from 'utils';
import Decimal from 'decimal.js';
import { LoadingButton } from '@mui/lab';
import {
    GET_BILLING_SHEETS,
    UPDATE_BILLING_SHEET,
} from 'views/bracket-sheets/BacketSheetFunctionCalculator/apollo-queries';
import { AutocompleteControl } from 'components/inputs';

// Constants

interface BillTableProps {
    bill: any;
    handleDownloadClinicBill: (clinic_bill_id: number, ec_id: number, clinic_id: number) => void;
}

interface FieldDataProps {
    field: any | null;
    fieldIdx: number | null;
}

const BILL_MOD_LINE_VALUE_TYPES = [
    { value: 'amt', label: 'Montant fixe' },
    { value: 'percentage', label: 'Pourcentage' },
];

export default function ClinicBillTable({ bill, handleDownloadClinicBill }: BillTableProps) {
    const {
        id,
        created_at,
        updated_at,
        clinic_id,
        ec_id,
        subtotal_amt,
        tps_rate,
        tps_amt,
        tvq_rate,
        tvq_amt,
        total_amt,
        filename,
        is_sipt,
        qb_invoice_id,
        bill_no,
        status,
        email_sent,
        last_email_sent,
        billing_method,
        ClinicBillLines,
        ClinicBillModLines,
        Clinic,
    } = bill;

    const {
        loading: billLineTagsLoading,
        error: billLineTagsError,
        data: billLineTagsData,
    } = useQuery(GET_BILL_LINE_TAGS);

    const {
        loading: mdsLoading,
        error: mdsError,
        data: mdsData,
    } = useQuery(GET_MDS, {
        variables: {
            // grp: Clinic.grp,
            // pst: Clinic.pst,
        },
    });

    const form = useForm({
        defaultValues: {
            id: bill.id,
            status: bill.status,
            clinic_bill_lines: bill.ClinicBillLines,
            clinic_bill_mod_lines: bill.ClinicBillModLines,
        },
    });
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
    } = form;

    // ! Needs to be placed/initialized before the useEffect function containing the form reset logic to work properly
    const {
        fields: clinicBillLinesFields,
        append: clinicBillLinesAppend,
        // prepend,
        remove: clinicBillLinesRemove,
        // swap,
        // move,
        // insert,
    } = useFieldArray({
        control,
        name: 'clinic_bill_lines',
    });

    // ! Needs to be placed/initialized before the useEffect function containing the form reset logic to work properly
    const {
        fields: clinicBillModLinesFields,
        append: clinicBillModLinesAppend,
        // prepend,
        remove: clinicBillModLinesRemove,
        // swap,
        // move,
        // insert,
    } = useFieldArray({
        control,
        name: 'clinic_bill_mod_lines',
    });

    useEffect(() => {
        const clinicBillLines = bill?.ClinicBillLines || {};

        const clinicBillModLines = bill?.ClinicBillModLines || [];

        if (!isEmpty(bill)) {
            reset({
                id: bill.id,
                status: bill.status,
                clinic_bill_lines: clinicBillLines,
                clinic_bill_mod_lines: clinicBillModLines,
            });
        }
    }, [reset, bill]);

    const [
        updateClinicBill,
        { loading: updateClinicBillLoading, error: updateClinicBillError, data: updateClinicBillData },
    ] = useMutation(UPDATE_MD_BILL, {
        refetchQueries: [GET_MD_BILL],
    });

    const onSubmit = async (data) => {
        await updateClinicBill({
            variables: {
                id: data.id,
                status: data.status,
                clinic_bill_lines: data.clinic_bill_lines.map(
                    ({ id, bill_line_tag_id, position, name, desc, unit_amt, qty, md_id }) => ({
                        id: id,
                        bill_line_tag_id,
                        position,
                        name,
                        desc,
                        unit_amt,
                        qty,
                        md_id,
                    }),
                ),
                clinic_bill_mod_lines: data.clinic_bill_mod_lines.map(
                    ({ id, bill_mod_line_tag_id, name, desc, value_type, value, md_id }) => ({
                        id: id,
                        bill_mod_line_tag_id,
                        name,
                        desc,
                        value_type,
                        value,
                        md_id,
                    }),
                ),
            },
        });

        handleDownloadClinicBill(id, ec_id, clinic_id);

        // clinicRefetch();
    };

    const onClickAddBillLine = () => {
        const newBillLines = getValues('clinic_bill_lines');
        const maxPosition = Math.max(...newBillLines.map((billLine) => billLine.position), 0);

        clinicBillLinesAppend({
            bill_line_tag_id: null,
            position: maxPosition + 1,
            name: '',
            desc: '',
            unit_amt: 0,
            qty: 0,
            md_id: null,
        });
    };
    const onClickRemoveBillLine = (fieldIdx: number) => {
        clinicBillLinesRemove(fieldIdx);
    };

    const onClickAddBillModLine = () => {
        clinicBillModLinesAppend({
            bill_mod_line_tag_id: null,
            name: '',
            desc: '',
            value_type: 'amt',
            value: 0,
            md_id: null,
        });
    };
    const onClickRemoveBillModLine = (fieldIdx: number) => {
        clinicBillModLinesRemove(fieldIdx);
    };
    const onClickEditBillModLine = (field: any, fieldIdx: number) => {
        setFieldData({
            field,
            fieldIdx,
        });
        handleOpenBillModLineEditDialog();
    };

    // Error snackbar
    const [isErrorSnackbarOpened, setIsErrorSnackbarOpened] = React.useState(false);
    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpened(false);
    };
    useEffect(() => setIsErrorSnackbarOpened(updateClinicBillError ? true : false), [updateClinicBillError]);

    // Bracket sheet edit Dialog
    const [isBillModLineEditDialogOpen, setIsBillModLineEditDialogOpen] = useState<boolean>(false);
    const handleOpenBillModLineEditDialog = () => {
        setIsBillModLineEditDialogOpen(true);
    };
    const handleCloseBillModLineEditDialog = () => {
        setIsBillModLineEditDialogOpen(false);
    };
    const [fieldData, setFieldData] = useState<FieldDataProps>({
        field: null,
        fieldIdx: null,
    });

    const liveTotals = useMemo(() => {
        const newBillLines = getValues('clinic_bill_lines');
        const newBillModLines = getValues('clinic_bill_mod_lines');

        const subtotalFraisFact: number = newBillLines.reduce(
            (prevValue, currValue) =>
                prevValue + new Decimal(currValue.qty || 0).mul(currValue.unit_amt || 0).toNumber(),
            0,
        );

        const subtotal: number = newBillModLines.reduce((prevValue, currValue) => {
            if (currValue.value_type === 'amt') {
                // Fixed amt
                return prevValue + currValue.value;
            } else {
                // Percentage
                return (
                    prevValue +
                    new Decimal(currValue.value).mul(subtotalFraisFact).div(100).div(100).toDecimalPlaces(0).toNumber()
                );
            }
        }, subtotalFraisFact);

        const tps = new Decimal(subtotal).mul(tps_rate).div(100).toDecimalPlaces(0).toNumber();
        const tvq = new Decimal(subtotal).mul(tvq_rate).div(100).toDecimalPlaces(0).toNumber();
        const total = new Decimal(subtotal).add(tps).add(tvq).toDecimalPlaces(0).toNumber();

        return {
            subtotalFraisFact,
            subtotal,
            tps,
            tvq,
            total,
        };
    }, [tps_rate, tvq_rate, watch(), getValues]);

    const rowSpan = 7 + watch('clinic_bill_mod_lines').length;

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow
                        // sx={{ bgcolor: 'primary.main' }}
                        >
                            <TableCell align="left" width={100}>
                                <Typography variant="body1" fontSize={15} fontWeight={600}>
                                    Position
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1" fontSize={15} fontWeight={600}>
                                    Élément facturé
                                </Typography>
                            </TableCell>
                            <TableCell align="left" width={225}>
                                <Typography variant="body1" fontSize={15} fontWeight={600}>
                                    Type de revenu
                                </Typography>
                            </TableCell>
                            <TableCell align="left" width={300}>
                                <Typography variant="body1" fontSize={15} fontWeight={600}>
                                    Médecin
                                </Typography>
                            </TableCell>
                            <TableCell align="left" width={150}>
                                <Typography variant="body1" fontSize={15} fontWeight={600}>
                                    Prix unitaire
                                </Typography>
                            </TableCell>
                            <TableCell align="left" width={125}>
                                <Typography variant="body1" fontSize={15} fontWeight={600}>
                                    Quantité
                                </Typography>
                            </TableCell>
                            <TableCell align="right" width={115}>
                                <Typography variant="body1" fontSize={15} fontWeight={600}>
                                    Total
                                </Typography>
                            </TableCell>
                            <TableCell align="right" width={100}>
                                <Typography variant="body1" fontSize={15} fontWeight={600}>
                                    Actions
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clinicBillLinesFields.map((field, fieldIdx) => {
                            const unit_amt = watch(`clinic_bill_lines.${fieldIdx}.unit_amt`);
                            const qty = watch(`clinic_bill_lines.${fieldIdx}.qty`);

                            const total = new Decimal(unit_amt || 0)
                                .mul(qty || 0)
                                .div(100)
                                .toFixed(2);

                            // ! The key property of each Jsx element must be set to field.id for the useFieldArray hook to work correctly (don't use the fieldIdx)
                            return (
                                <TableRow key={field.id}>
                                    <TableCell align="center">
                                        <NumberControl
                                            name={`clinic_bill_lines.${fieldIdx}.position`}
                                            control={control}
                                            options={{ label: 'Position' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={`clinic_bill_lines.${fieldIdx}.name`}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    label="Titre de la ligne"
                                                    size="small"
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={`clinic_bill_lines.${fieldIdx}.bill_line_tag_id`}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    label="Tag ligne"
                                                    size="small"
                                                    fullWidth
                                                    select
                                                    // helperText="Classification du type de ligne"
                                                    {...field}
                                                >
                                                    {(billLineTagsData?.billLineTags || []).map((billLineTag) => (
                                                        <MenuItem key={billLineTag.id} value={billLineTag.id}>
                                                            {billLineTag.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <AutocompleteControl
                                            name={`clinic_bill_lines.${fieldIdx}.md_id`}
                                            control={control}
                                            options={mdsData?.mds || []}
                                            valuePropertyName="id"
                                            loading={mdsLoading}
                                            getOptionLabel={(option: any) => {
                                                const { id, first_name, last_name } = option;

                                                if (option === getValues(`clinic_bill_lines.${fieldIdx}.md_id`)) {
                                                    const selectedMd = (mdsData?.mds || []).find(
                                                        (md) => md.id === option,
                                                    );

                                                    return selectedMd
                                                        ? `${selectedMd.last_name}, ${selectedMd.first_name} (${selectedMd.id})`
                                                        : '';
                                                } else {
                                                    return `${last_name}, ${first_name} (${id})`;
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value) => option?.id === value}
                                            textFieldOptions={{ label: 'Médecin' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <MoneyControl
                                            name={`clinic_bill_lines.${fieldIdx}.unit_amt`}
                                            control={control}
                                            errors={errors}
                                            options={{ label: 'Tarif par unité' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumberControl
                                            name={`clinic_bill_lines.${fieldIdx}.qty`}
                                            control={control}
                                            errors={errors}
                                            options={{ label: 'Quantité' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body1">{total}</Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                        <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => {
                                                onClickRemoveBillLine(fieldIdx);
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Button
                                    variant="text"
                                    size="small"
                                    color="success"
                                    onClick={onClickAddBillLine}
                                    endIcon={<AddIcon />}
                                >
                                    Ajouter une ligne
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={rowSpan} colSpan={4} />
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography fontSize={14}>Sous total des frais de facturation</Typography>
                            </TableCell>
                            <TableCell align="right">
                                {formatNumber(liveTotals.subtotalFraisFact, { cents: true })} $
                            </TableCell>
                        </TableRow>
                        {clinicBillModLinesFields.map((field, fieldIdx) => {
                            const name = watch(`clinic_bill_mod_lines.${fieldIdx}.name`);
                            const value_type = watch(`clinic_bill_mod_lines.${fieldIdx}.value_type`);
                            const value = watch(`clinic_bill_mod_lines.${fieldIdx}.value`);

                            let subtotal = 0;
                            if (value_type === 'amt') {
                                // Fixed amt
                                subtotal = value;
                            } else {
                                // Percentage
                                subtotal = new Decimal(value)
                                    .mul(liveTotals.subtotalFraisFact)
                                    .div(100)
                                    .div(100)
                                    .toDecimalPlaces(0)
                                    .toNumber();
                            }

                            // ! The key property of each Jsx element must be set to field.id for the useFieldArray hook to work correctly (don't use the fieldIdx)
                            return (
                                <TableRow key={field.id}>
                                    <TableCell colSpan={3}>
                                        <Stack direction="row" alignItems="center">
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={() => {
                                                    onClickRemoveBillModLine(fieldIdx);
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="warning"
                                                onClick={() => {
                                                    onClickEditBillModLine(field, fieldIdx);
                                                }}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>

                                            <Typography fontSize={14} sx={{ ml: 2 }}>
                                                {name} ({new Decimal(value).div(100).toFixed(2)}
                                                {value_type === 'amt' ? '$' : '%'})
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="right">{formatNumber(subtotal, { cents: true })} $</TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell colSpan={4} align="right">
                                <Button
                                    variant="text"
                                    size="small"
                                    color="success"
                                    onClick={onClickAddBillModLine}
                                    endIcon={<AddIcon />}
                                >
                                    Ajouter un ajustement
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography fontSize={14}>Sous total</Typography>
                            </TableCell>
                            <TableCell align="right">{formatNumber(liveTotals.subtotal, { cents: true })} $</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography fontSize={14}>TPS ({tps_rate} %)</Typography>
                            </TableCell>
                            <TableCell align="right">{formatNumber(liveTotals.tps, { cents: true })} $</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography fontSize={14}>TVQ ({tvq_rate} %)</Typography>
                            </TableCell>
                            <TableCell align="right">{formatNumber(liveTotals.tvq, { cents: true })} $</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography fontWeight="500">Grand total</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="500">
                                    {formatNumber(liveTotals.total, { cents: true })} $
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={8} align="center">
                                <LoadingButton
                                    variant="contained"
                                    size="large"
                                    color="success"
                                    onClick={handleSubmit(onSubmit)}
                                    endIcon={<SaveIcon />}
                                    loading={updateClinicBillLoading}
                                    loadingPosition="end"
                                >
                                    Sauvegarder & regénérer cette facture
                                </LoadingButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <BillModLineEditDialog
                isBillModLineEditDialogOpen={isBillModLineEditDialogOpen}
                handleCloseBillModLineEditDialog={handleCloseBillModLineEditDialog}
                fieldData={fieldData}
                form={form}
                bill={bill}
            />

            <Snackbar
                open={isErrorSnackbarOpened}
                autoHideDuration={6000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {getErrorMsg(updateClinicBillError)}
                </Alert>
            </Snackbar>
        </form>
    );
}

interface BillModLineEditDialogProps {
    isBillModLineEditDialogOpen: boolean;
    handleCloseBillModLineEditDialog: () => void;
    fieldData: FieldDataProps;
    form: UseFormReturn<any, any>;
    bill: any;
}

export function BillModLineEditDialog({
    isBillModLineEditDialogOpen,
    handleCloseBillModLineEditDialog,
    fieldData,
    form,
    bill,
}: BillModLineEditDialogProps) {
    const isDialogOpen = isBillModLineEditDialogOpen ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={handleCloseBillModLineEditDialog}>
            <BillModLineEditDialogContent
                isBillModLineEditDialogOpen={isBillModLineEditDialogOpen}
                handleCloseBillModLineEditDialog={handleCloseBillModLineEditDialog}
                fieldData={fieldData}
                form={form}
                bill={bill}
            />
        </Dialog>
    );
}

interface BillModLineEditDialogContentProps {
    isBillModLineEditDialogOpen: boolean;
    handleCloseBillModLineEditDialog: () => void;
    fieldData: FieldDataProps;
    form: UseFormReturn<any, any>;
    bill: any;
}

const BillModLineEditDialogContent = ({
    isBillModLineEditDialogOpen,
    handleCloseBillModLineEditDialog,
    fieldData,
    form,
    bill,
}: BillModLineEditDialogContentProps) => {
    const theme = useTheme();

    const { field, fieldIdx } = fieldData;
    const { control, watch, getValues } = form;

    const {
        loading: billModLineTagsLoading,
        error: billModLineTagsError,
        data: billModLineTagsData,
    } = useQuery(GET_BILL_MOD_LINE_TAGS, {
        skip: !isBillModLineEditDialogOpen,
    });

    const {
        loading: mdsLoading,
        error: mdsError,
        data: mdsData,
    } = useQuery(GET_MDS, {
        variables: {
            // grp: bill.Clinic.grp,
            // pst: bill.Clinic.pst,
        },
    });

    return (
        <>
            <DialogTitle>Veuillez modifier l'ajustement</DialogTitle>

            <DialogContent>
                <Grid container spacing={3} pt={3}>
                    <Grid item xs={12}>
                        <Controller
                            name={`clinic_bill_mod_lines.${fieldIdx}.name`}
                            control={control}
                            render={({ field }) => <TextField label="Nom ligne" size="small" fullWidth {...field} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name={`clinic_bill_mod_lines.${fieldIdx}.desc`}
                            control={control}
                            render={({ field }) => (
                                <TextField label="Description ligne" size="small" fullWidth {...field} />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            key={field.id}
                            name={`clinic_bill_mod_lines.${fieldIdx}.value_type`}
                            control={control}
                            render={({ field }) => (
                                <TextField label="Type de valeur" size="small" fullWidth select {...field}>
                                    {BILL_MOD_LINE_VALUE_TYPES.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    </Grid>

                    {watch(`clinic_bill_mod_lines.${fieldIdx}.value_type`) === 'amt' && (
                        <Grid item xs={6}>
                            <NumberControl
                                name={`clinic_bill_mod_lines.${fieldIdx}.value`}
                                control={control}
                                options={{ label: 'Montant fixe' }}
                                decimals={2}
                            />
                        </Grid>
                    )}

                    {watch(`clinic_bill_mod_lines.${fieldIdx}.value_type`) === 'percentage' && (
                        <Grid item xs={6}>
                            <NumberControl
                                name={`clinic_bill_mod_lines.${fieldIdx}.value`}
                                control={control}
                                options={{ label: 'Pourcentage', suffix: '%' }}
                                decimals={2}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Controller
                            name={`clinic_bill_mod_lines.${fieldIdx}.bill_mod_line_tag_id`}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Tag ligne"
                                    size="small"
                                    fullWidth
                                    select
                                    helperText="Classification du type de ligne"
                                    {...field}
                                >
                                    {(billModLineTagsData?.billModLineTags || []).map((billModLineTag) => (
                                        <MenuItem key={billModLineTag.id} value={billModLineTag.id}>
                                            {billModLineTag.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AutocompleteControl
                            name={`clinic_bill_mod_lines.${fieldIdx}.md_id`}
                            control={control}
                            options={mdsData?.mds || []}
                            valuePropertyName="id"
                            loading={mdsLoading}
                            getOptionLabel={(option: any) => {
                                const { id, first_name, last_name } = option;

                                if (option === getValues(`clinic_bill_mod_lines.${fieldIdx}.md_id`)) {
                                    const selectedMd = (mdsData?.mds || []).find((md) => md.id === option);

                                    return selectedMd
                                        ? `${selectedMd.last_name}, ${selectedMd.first_name} (${selectedMd.id})`
                                        : '';
                                } else {
                                    return `${last_name}, ${first_name} (${id})`;
                                }
                            }}
                            isOptionEqualToValue={(option: any, value) => option?.id === value}
                            textFieldOptions={{ label: 'Médecin' }}
                        />
                    </Grid>

                    {/* {renderError(updateBillingSheetError)} */}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button type="submit" variant="text" onClick={handleCloseBillModLineEditDialog}>
                    Ok
                </Button>
            </DialogActions>
        </>
    );
};
