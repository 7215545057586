import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Code as CodeIcon,
    Male as MaleIcon,
    Female as FemaleIcon,
    MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import SexIcon from 'components/SexIcon';

// Constants
import {
    BOOLEAN_COL_DEFAULTS,
    DATE_COL_DEFAULTS,
    EMAIL_COL_DEFAULTS,
    HALF_NAME_COLUMN_WIDTH,
    HALF_NAME_COL_DEFAULTS,
    LICENCE_COL_DEFAULTS,
    PHONE_NUMBER_COL_DEFAULTS,
    SEX_COL_DEFAULTS,
    ZIP_COL_DEFAULTS,
    MONEY_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize, formatPhoneNumber } from 'utils';

export const getMdsTableColumns = (handleOpenEditDrawer): GridColDefPro[] => [
    {
        ...LICENCE_COL_DEFAULTS,
        field: 'id',
        headerName: 'Licence',
    },
    {
        ...HALF_NAME_COL_DEFAULTS,
        field: 'first_name',
        headerName: 'Prénom',
    },
    {
        ...HALF_NAME_COL_DEFAULTS,
        field: 'last_name',
        headerName: 'Nom',
    },
    {
        ...SEX_COL_DEFAULTS,
        field: 'sex',
        headerName: 'Sexe',
        renderCell: (params) => {
            const sex = params.value;

            return <SexIcon sex={sex} />;
        },
    },
    {
        ...EMAIL_COL_DEFAULTS,
        field: 'email',
        headerName: 'Courriel',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'joined_on',
        headerName: 'Client depuis',
        valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATE) : null),
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'left_on',
        headerName: 'Parti le',
        valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATE) : null),
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'retraite_on',
        headerName: 'Date retraite',
        valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATE) : null),
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'is_still_client',
        headerName: 'Client',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'can_login',
        headerName: 'Accès Portail',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'address1',
        headerName: 'Adresse 1',
        width: 180,
    },
    {
        field: 'address2',
        headerName: 'Adresse 2',
        width: 120,
    },
    {
        field: 'city',
        headerName: 'Ville',
        width: 120,
    },
    {
        ...ZIP_COL_DEFAULTS,
        field: 'zip',
        headerName: 'Code postal',
    },
    {
        ...PHONE_NUMBER_COL_DEFAULTS,
        field: 'phone_mobile',
        headerName: 'No. mobile',
        valueFormatter: ({ value }) => formatPhoneNumber(value),
    },
    {
        ...PHONE_NUMBER_COL_DEFAULTS,
        field: 'phone_home',
        headerName: 'No. maison',
        valueFormatter: ({ value }) => formatPhoneNumber(value),
    },
    {
        ...PHONE_NUMBER_COL_DEFAULTS,
        field: 'phone_work',
        headerName: 'No. travail',
        valueFormatter: ({ value }) => formatPhoneNumber(value),
    },
    {
        ...MONEY_COL_DEFAULTS,
        field: 'balance',
        headerName: 'Balance',
        valueFormatter: ({ value }) => `${formatNumber(value / 100)} $`,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'is_billable',
        headerName: 'Facturable',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        width: 80,
        headerAlign: 'center',
        align: 'center',
        field: 'qb_customer_id',
        headerName: 'QB ID',
    },
    {
        ...HALF_NAME_COL_DEFAULTS,
        width: 150,
        field: 'qb_customer_fully_qualified_name',
        headerName: 'QB name',
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'md_billing_info.is_approved',
        headerName: 'Approuvé',
        valueGetter: ({ row }) => row.MdBillingInfo.is_approved,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'md_billing_info.billing_method',
        headerName: 'Méthode fact.',
        width: 120,
        valueGetter: ({ row }) => row.MdBillingInfo.billing_method,
        valueFormatter: ({ value }) => capitalize(value),
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'md_billing_info.billing_sheet_id',
        headerName: 'ID matrice',
        width: 120,
        valueGetter: ({ row }) => row.MdBillingInfo.billing_sheet_id,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { id } = row;

            return (
                <>
                    <IconButton
                        onClick={() => {
                            handleOpenEditDrawer(id);
                            window.history.replaceState(null, '', `/mds/${id}`);
                        }}
                        size="medium"
                        color="primary"
                    >
                        <MenuOpenIcon fontSize="inherit" />
                    </IconButton>
                </>
            );
        },
    },
];
