import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';

// MUI Components
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Snackbar,
    Alert,
    Box,
    AlertTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { Save as SaveIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import { AutocompleteControl, DateControl, NumberControl, SelectControl, TextControl } from 'components/inputs';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_BANK_ACCOUNTS, GET_MDS, UPDATE_MD_PAYMENT, GET_MD_PAYMENT } from './apollo-queries';
import { GET_MD_TRANSACTIONS } from 'views/mds-transactions/MdTransactionList/apollo-queries';

// Redux

// Utils
import { getErrorMsg } from 'utils';

// Constants
import { PAYMENT_METHODS } from 'constants/DbConstants';
import { isEmpty } from 'lodash';
import { SQL_DATE } from 'constants/Moment';

interface MdPaymentEditDialogProps {
    isMdPaymentEditDialogOpen: boolean;
    handleCloseMdPaymentEditDialog: () => void;
    selectedPaymentId: number | null;
}

interface MdPaymentEditDialogContentProps {
    isMdPaymentEditDialogOpen: boolean;
    handleCloseMdPaymentEditDialog: () => void;
    selectedPaymentId: number | null;
}

export function MdPaymentEditDialog({
    isMdPaymentEditDialogOpen,
    handleCloseMdPaymentEditDialog,
    selectedPaymentId,
}: MdPaymentEditDialogProps) {
    const isDialogOpen = isMdPaymentEditDialogOpen ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={handleCloseMdPaymentEditDialog}>
            <MdPaymentEditDialogContent
                isMdPaymentEditDialogOpen={isMdPaymentEditDialogOpen}
                handleCloseMdPaymentEditDialog={handleCloseMdPaymentEditDialog}
                selectedPaymentId={selectedPaymentId}
            />
        </Dialog>
    );
}

const MdPaymentEditDialogContent = ({
    isMdPaymentEditDialogOpen,
    handleCloseMdPaymentEditDialog,
    selectedPaymentId,
}: MdPaymentEditDialogContentProps) => {
    const theme = useTheme();

    const {
        loading: mdPaymentLoading,
        error: mdPaymentError,
        data: mdPaymentData,
    } = useQuery(GET_MD_PAYMENT, {
        variables: { id: selectedPaymentId },
        skip: !isMdPaymentEditDialogOpen,
    });

    const {
        loading: bankAccountsLoading,
        error: bankAccountsError,
        data: bankAccountsData,
    } = useQuery(GET_BANK_ACCOUNTS, {
        skip: !isMdPaymentEditDialogOpen,
    });
    const { loading: mdsLoading, error: mdsError, data: mdsData } = useQuery(GET_MDS);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
    } = useForm({
        defaultValues: {
            id: null,
            md_id: null,
            method: null,
            ref_no: '',
            amt: 0,
            bank_account_id: null,
            payment_date: moment().format(SQL_DATE),
            private_note: null,
        },
    });

    const [
        editMdPaymentEdit,
        { loading: editMdPaymentEditLoading, error: editMdPaymentEditError, data: editMdPaymentEditData },
    ] = useMutation(UPDATE_MD_PAYMENT, {
        refetchQueries: [GET_MD_TRANSACTIONS],
    });

    const mdPayment = mdPaymentData?.mdPayment;
    useEffect(() => {
        if (!isEmpty(mdPayment)) {
            reset({
                id: mdPayment.id,
                md_id: mdPayment.md_id,
                method: mdPayment.method,
                ref_no: mdPayment.ref_no,
                amt: mdPayment.amt,
                bank_account_id: mdPayment.bank_account_id,
                payment_date: mdPayment.payment_date,
                private_note: mdPayment.private_note,
            });
        }
    }, [reset, mdPayment]);

    const onSubmit = async (data) => {
        console.log(data);

        await editMdPaymentEdit({
            variables: {
                id: data.id,
                md_id: data.md_id,
                method: data.method,
                ref_no: data.ref_no,
                amt: data.amt,
                bank_account_id: data.bank_account_id,
                payment_date: data.payment_date,
                private_note: data.private_note,
            },
        });

        handleCloseMdPaymentEditDialog();
    };

    // Error snackbar
    const [isErrorSnackbarOpened, setIsErrorSnackbarOpened] = React.useState(false);
    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpened(false);
    };
    useEffect(() => setIsErrorSnackbarOpened(editMdPaymentEditError ? true : false), [editMdPaymentEditError]);

    const areTransactionnalFieldsDisabled = mdPayment?.deleted_at ? true : false;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <DialogTitle>Éditer le paiement #{selectedPaymentId}</DialogTitle>

                <DialogContent>
                    {mdPaymentLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height={100}>
                            <Loading />
                        </Box>
                    ) : (
                        <Grid container spacing={3} pt={1}>
                            {areTransactionnalFieldsDisabled && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        <AlertTitle>Vous ne pouvez éditer certains champs</AlertTitle>
                                        Les champs "Médecin" et "Montant" ne peuvent être édités puisque ce paiement a
                                        été annulé antérieurement et que certaines transactions du grand livre font
                                        référence à ce paiement.
                                        {mdPayment.new_md_payment_id && (
                                            <>
                                                <br />
                                                <br />
                                                Suivant l'annulation du paiement, un nouveau paiement avec un ID de{' '}
                                                <strong>{mdPayment.new_md_payment_id}</strong> a été créé pour remplacer
                                                ce paiement.
                                            </>
                                        )}
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <AutocompleteControl
                                    name="md_id"
                                    control={control as any}
                                    options={mdsData?.mds || []}
                                    valuePropertyName="id"
                                    loading={mdsLoading}
                                    getOptionLabel={(option: any) => {
                                        const { id, first_name, last_name } = option;

                                        if (option === getValues('md_id')) {
                                            const selectedMd = (mdsData?.mds || []).find((md) => md.id === option);

                                            return selectedMd
                                                ? `${selectedMd.last_name}, ${selectedMd.first_name} (${selectedMd.id})`
                                                : '';
                                        } else {
                                            return `${last_name}, ${first_name} (${id})`;
                                        }
                                    }}
                                    isOptionEqualToValue={(option: any, value) => option?.id === value}
                                    textFieldOptions={{ label: 'Médecin' }}
                                    disabled={areTransactionnalFieldsDisabled}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <SelectControl
                                    name="method"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Méthode de paiement' }}
                                    dropdownOptions={PAYMENT_METHODS.sort((a, b) => a.name.localeCompare(b.name)).map(
                                        (paymentMethod) => (
                                            <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
                                                {paymentMethod.name}
                                            </MenuItem>
                                        ),
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextControl
                                    name="ref_no"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Numéro de référence' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <NumberControl
                                    name={`amt`}
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Montant' }}
                                    money
                                    disabled={areTransactionnalFieldsDisabled}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DateControl
                                    name={`payment_date`}
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Date du paiement' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <SelectControl
                                    name="bank_account_id"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Compte de banque' }}
                                    dropdownOptions={[...(bankAccountsData?.bankAccounts || [])]
                                        .sort((a, b) => a.name - b.name)
                                        .map((bankAccount) => (
                                            <MenuItem key={bankAccount.id} value={bankAccount.id}>
                                                {bankAccount.name}
                                            </MenuItem>
                                        ))}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextControl
                                    name="private_note"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Note privée', rows: 4 }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        variant="text"
                        onClick={handleSubmit(onSubmit)}
                        endIcon={<SaveIcon />}
                        loading={editMdPaymentEditLoading}
                        loadingPosition="end"
                        type="submit"
                    >
                        Sauvegarder
                    </LoadingButton>
                </DialogActions>
            </form>

            <Snackbar
                open={isErrorSnackbarOpened}
                autoHideDuration={6000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {getErrorMsg(editMdPaymentEditError)}
                </Alert>
            </Snackbar>
        </>
    );
};
