import React, { useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as ReTooltip,
    Legend,
    ResponsiveContainer,
    Treemap,
} from 'recharts';
import Decimal from 'decimal.js';

// MUI Components
import { Box, Grid, Paper, Switch, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// MUI Grid

// MUI Icons

// Components
import Loading from 'components/Loading';

// Apollo

// Redux

// Services

// Constants
import { SQL_DATE } from 'constants/Moment';

// Utils
import { capitalize, formatNumber, getSMA } from 'utils';

interface TotalPerActGraphProps {
    data: {
        actGraphPoints: {
            codefact: number;

            count: number;
            count_new: number;
            count_rev: number;

            total_prelim: number;
            total_final: number;
            total_diff: number;

            percentage_count: string;
            percentage_total_prelim: string;
            percentage_total_final: string;
        }[];
    };
    startDate: Moment | null;
    endDate: Moment | null;
}

export default function TotalPerActGraph(props: TotalPerActGraphProps) {
    const theme = useTheme();
    const {
        data: { actGraphPoints },
        data,
        startDate,
        endDate,
    } = props;

    const [graphData] = useMemo(() => {
        // Main
        const graphData: {
            name: string;

            codefact: number;

            count: number;

            total_prelim: number;
            total_final: number;
            total_diff: number;

            percentage_count: string;
            percentage_total_prelim: string;
            percentage_total_final: string;
        }[] = [...data.actGraphPoints]
            .sort((a, b) => a.codefact - b.codefact)
            .map((point, pointIdx) => {
                const {
                    codefact,

                    count,
                    count_new,
                    count_rev,

                    total_prelim,
                    total_final,
                    total_diff,

                    percentage_count,
                    percentage_total_prelim,
                    percentage_total_final,
                } = point;

                return {
                    name: `${codefact} : (${percentage_total_final} %)`,

                    codefact,

                    count,

                    total_prelim,
                    total_final,
                    total_diff,

                    percentage_count,
                    percentage_total_prelim,
                    percentage_total_final,
                };
            });

        return [graphData];
    }, [data]);

    return (
        <Grid item xs={12} lg={12}>
            <Paper
                sx={{
                    px: 2,
                    py: 2,
                }}
                elevation={1}
            >
                <Typography variant="h6" mb={1}>
                    Montant préliminaire facturé à la RAMQ par code (RFP)
                </Typography>
                <Typography variant="subtitle1" mb={3}>
                    ** Comprend les 25 codes les plus facturés seulement
                </Typography>

                <Box sx={{ width: '100%', height: 450 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <Treemap
                            width={500}
                            height={400}
                            data={graphData}
                            // nameKey="codefact"
                            dataKey="total_prelim"
                            stroke="#fff"
                            fill={theme.palette.primary.main}
                        >
                            <ReTooltip labelStyle={{ color: 'black' }} content={<CustomTooltip />} />

                            {/* <ReTooltip
                                labelStyle={{ color: 'black' }}
                                formatter={(value, name, props) => {
                                    console.log(value, name, props);
                                    return `${new Intl.NumberFormat('en').format(value as any)} $`;
                                }}
                            /> */}
                        </Treemap>
                    </ResponsiveContainer>
                </Box>
            </Paper>
        </Grid>
    );
}

const CustomTooltip = (props) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
        const {
            value,
            payload: {
                name,

                codefact,

                count,

                total_prelim,
                total_final,
                total_diff,

                percentage_count,
                percentage_total_prelim,
                percentage_total_final,
            },
        } = payload[0];

        return (
            <Paper sx={{ p: 2, bgcolor: 'white', color: 'black' }}>
                <Typography variant="h6">{`Code ${codefact}`}</Typography>

                <Typography mt={2}>{`Montant total préliminaire : ${formatNumber(
                    total_prelim,
                )} $ (${percentage_total_prelim} %)`}</Typography>
                <Typography>{`Montant total final : ${formatNumber(
                    total_final,
                )} $ (${percentage_total_final} %)`}</Typography>

                <Typography mt={2}>{`Quantité : ${count} (${percentage_count} %)`}</Typography>
            </Paper>
        );
    }

    return <></>;
};
