import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdHistoricalTransactionFields, MdFields } from 'apollo/fragments';

export const GET_MD_HISTORICAL_TRANSACTIONS = gql`
    ${MdHistoricalTransactionFields}

    query GetMdHistoricalTransactions($md_id: Int, $start_date: DateTime, $end_date: DateTime) {
        mdHistoricalTransactions(md_id: $md_id, start_date: $start_date, end_date: $end_date) {
            ...MdHistoricalTransactionFields
        }
    }
`;

export const GET_MDS = gql`
    ${MdFields}

    query GetMds {
        mds {
            ...MdFields
        }
    }
`;
