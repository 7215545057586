import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// MUI Components
import { Box } from '@mui/material';

// Components
import MiniDrawer from 'components/Navbar';

// Views
import Login from 'views/auth/Login';
import MaintenancePage from 'views/general/MaintenancePage';
import NotFoundPage from 'views/general/NotFoundPage';
import Dashboard from 'views/Dashboard/index';
import LotEdit from 'views/lots/LotEdit';
import LotList from 'views/lots/LotList';
import MdList from 'views/md/MdList';
import ScanList from 'views/scans/ScanList';
import ScanDay from 'views/scans/ScanDay';
import PosteList from 'views/postes/PosteList';
import AdminList from 'views/admin/AdminList';
import AdminEdit from 'views/admin/AdminEdit';
import FactureList from 'views/factures/FactureList';
import FactureShow from 'views/factures/FactureShow';
import Ec from 'views/ecs/Ec';
import BillList from 'views/bills/BillList';
import ClinicBillList from 'views/clinics-bills/ClinicBillList';
import BracketSheetFunctionCalculator from 'views/bracket-sheets/BacketSheetFunctionCalculator';
import ClinicTransactionList from 'views/clinics-transactions/ClinicTransactionList';
import ClinicHistoricalTransactionList from 'views/clinics-transactions/ClinicHistoricalTransactionList';
import MdTransactionList from 'views/mds-transactions/MdTransactionList';
import MdHistoricalTransactionList from 'views/mds-transactions/MdHistoricalTransactionList';
import ClinicList from 'views/clinics/ClinicList';
import PaymentList from 'views/payments/PaymentList';
import GeneralPerformanceDash from 'views/stats/GeneralPerformanceDash';
import AnalystPerformanceDash from 'views/stats/AnalystPerformanceDash';
import BillingPerfDash from 'views/stats/BillingPerfDash';
import MdHorsRamqClaimList from 'views/hors-ramq/MdHorsRamqClaimList';

// Providers
import { useAuth } from 'providers/AuthProvider/context';
import Loading from 'components/Loading';
import { Api } from 'services';

export const LOGIN_ROUTE = '/login';

export const MAINTENANCE_ROUTE = '/maintenance';
export const MAIN_PAGE_WHEN_AUTHENTICATED = '/mds';

const Router = () => {
    return (
        <Routes>
            {/* Auth */}
            <Route
                path={LOGIN_ROUTE}
                element={
                    <RedirectIfLoggedIn>
                        <Login />
                    </RedirectIfLoggedIn>
                }
            />

            <Route
                path={MAINTENANCE_ROUTE}
                element={
                    <RedirectIfBackOnline>
                        <MaintenancePage />
                    </RedirectIfBackOnline>
                }
            />

            <Route
                path=""
                element={
                    <RequireAuth>
                        <MiniDrawer />
                    </RequireAuth>
                }
            >
                <Route path="/home" element={<Dashboard />} />

                {/* Scans */}
                <Route path="/scans/:employeeId/:date" element={<ScanDay />} />

                {/* Stats */}
                <Route path="/stats/general-performance" element={<GeneralPerformanceDash />} />
                <Route path="/stats/analyst-performance" element={<AnalystPerformanceDash />} />
                <Route path="/stats/billing-performance" element={<BillingPerfDash />} />

                {/* Mds */}
                <Route path="/mds" element={<MdList />} />
                <Route path="/mds/:mdId" element={<MdList />} />

                {/* Mds - Billing */}
                <Route path="/mds/bills" element={<BillList />} />
                <Route path="/mds/bills/:id" element={<BillList />} />
                <Route path="/mds/transactions" element={<MdTransactionList />} />
                <Route path="/mds/historical-transactions" element={<MdHistoricalTransactionList />} />

                {/* Mds - Hors RAMQ */}
                <Route path="/mds/hors-ramq" element={<MdHorsRamqClaimList />} />

                {/* Clinics */}
                <Route path="/clinics" element={<ClinicList />} />
                <Route path="/clinics/:clinicId" element={<ClinicList />} />

                {/* Clinics - Billing */}
                <Route path="/clinics/bills" element={<ClinicBillList />} />
                <Route path="/clinics/bills/:id" element={<ClinicBillList />} />
                <Route path="/clinics/transactions" element={<ClinicTransactionList />} />
                <Route path="/clinics/historical-transactions" element={<ClinicHistoricalTransactionList />} />

                {/* Accounting - Payments */}
                <Route path="/accounting/payments" element={<PaymentList />} />

                {/* Facturation RAMQ */}
                <Route path="/ec" element={<Ec />} />
                <Route path="/lots" element={<LotList />} />
                <Route path="/factures" element={<FactureList />} />
                <Route path="/facture" element={<FactureShow />} />
                {/* <Route path="/lots/:claimId/edit/:tabName" element={<LotEdit />} /> */}

                {/* Facturation */}
                <Route
                    path="/brackets/bracket-sheet-function-calculator"
                    element={<BracketSheetFunctionCalculator />}
                />

                {/* Scans */}
                <Route path="/scan-dispatcher" element={<ScanList />} />

                {/* Postes */}
                <Route path="/postes" element={<PosteList />} />

                {/* Admins */}
                <Route path="/admin" element={<AdminList />} />
                <Route path="/admin/create" element={<AdminEdit />} />
                <Route path="/admin/:adminId" element={<AdminEdit />} />

                <Route path="" element={<Dashboard />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    if (auth.isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" width="100vw" height="100vh">
                <Loading />
            </Box>
        );
    } else {
        if (auth.admin) {
            return children;
        } else {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
        }
    }
}

function RedirectIfLoggedIn({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    if (auth.admin) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={MAIN_PAGE_WHEN_AUTHENTICATED} state={{ from: location }} replace />;
    } else {
        return children;
    }
}

function RedirectIfBackOnline({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    const [apiStatusOnline, setApiStatusOnline] = useState(false);

    const getApiStatus = async () => {
        const res = await Api.get('api-status');
        setApiStatusOnline(res.data);
    };

    useEffect(() => {
        getApiStatus();
    }, []);

    if (!apiStatusOnline) {
        return children;
    } else {
        if (auth.isLoading) {
            return children;
        } else if (auth.admin) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            return <Navigate to={MAIN_PAGE_WHEN_AUTHENTICATED} state={{ from: location }} replace />;
        } else {
            return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
        }
    }
}

export default Router;
