export const PAYMENT_METHODS = [
    { id: 'cash', name: 'Cash' },
    { id: 'check', name: 'Chèque' },
    { id: 'bank', name: 'Dépôt direct' },
    { id: 'interac', name: 'Interac' },
];

export const HORS_RAMQ_STATUSES = [
    { name: 'Réclamation rejetée - patient plus couvert par le régime' },
    { name: 'Réclamation rejetée - Double inscription' },
    { name: 'Formulaire manquant' },
    { name: 'Information manquante - En attente de la réponse du patient' },
    { name: 'Réception du paiement - correction du paiement demandée' },
    { name: 'Réclamation rejetée - assurance non canadienne' },
    { name: 'Réception du formulaire complété par le patient' },
    { name: 'Réclamation rejetée - photocopie de la signature du patient' },
    { name: 'Réclamation transmise' },
    { name: 'Réclamation rejetée - délai dépassé' },
    { name: 'Réclamation rejetée - déménagement plus de 3 mois' },
    { name: 'Lettre revenue sans occupant' },
    { name: 'Réception du paiement' },
    { name: 'Erreur de paiement - chèque retourné avec demande de correction' },
    { name: 'Réception' },
    { name: 'Réclamation rejetée - accident non déclarée' },
    { name: 'Paiement déjà reçu' },
];
