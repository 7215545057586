import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdTransactionFields, MdFields } from 'apollo/fragments';

export const GET_MD_TRANSACTIONS = gql`
    ${MdTransactionFields}

    query GetMdTransactions($md_id: Int, $start_date: DateTime, $end_date: DateTime) {
        mdTransactions(md_id: $md_id, start_date: $start_date, end_date: $end_date) {
            ...MdTransactionFields
        }
    }
`;

export const GET_MDS = gql`
    ${MdFields}

    query GetMds {
        mds {
            ...MdFields
        }
    }
`;
