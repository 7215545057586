import moment from 'moment';

// MUI Components
import { Box, IconButton, Tooltip, CircularProgress } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { MenuOpen as MenuOpenIcon, Download as DownloadIcon, OpenInFull as OpenInFullIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    BOOLEAN_COL_DEFAULTS,
    LICENCE_COL_DEFAULTS,
    DATETIME_COL_DEFAULTS,
    AMT_COL_DEFAULTS,
    ID_COL_DEFAULTS,
    HALF_NAME_COL_DEFAULTS,
    DATE_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';
import ColoredNumber from 'components/ColoredNumber';

export const getBillsColumns = (
    handleOpenEditDrawer,
    handleDownloadMdBill,
    clinicBillIdsOfCurrentFileDownloads,
): GridColDefPro[] => [
    {
        ...ID_COL_DEFAULTS,
        field: 'id',
        headerName: 'ID',
    },
    {
        ...ID_COL_DEFAULTS,
        field: 'bill_no',
        headerName: 'No. Facture',
        width: 100,
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'created_at',
        headerName: 'Créé le',
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'updated_at',
        headerName: 'Modifié le',
    },
    {
        ...LICENCE_COL_DEFAULTS,
        field: 'clinic_id',
        headerName: 'ID clinique',
    },
    {
        ...HALF_NAME_COL_DEFAULTS,
        field: 'name',
        headerName: 'Nom clinique',
    },
    {
        field: 'ec_id',
        headerName: 'ID EC',
        headerAlign: 'center',
        align: 'center',
        width: 80,
    },
    {
        field: 'or',
        headerName: 'OR',
        headerAlign: 'center',
        align: 'center',
        width: 80,
        valueGetter: ({ row }) => `${moment(row.date_ec).year()}-${row.no_paiement}`,
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'date_ec',
        headerName: 'Date EC',
    },
    {
        field: 'billing_method',
        headerName: 'Méthode fact.',
        headerAlign: 'center',
        align: 'center',
        width: 120,
        valueFormatter: ({ value }) => capitalize(value),
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'subtotal_amt',
        headerName: 'Sous-total',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'tps_amt',
        headerName: 'TPS',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'tvq_amt',
        headerName: 'TVQ',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_amt',
        headerName: 'Total',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        field: 'status',
        headerName: 'Statut',
        headerAlign: 'center',
        align: 'center',
        width: 80,
        valueFormatter: ({ value }) => capitalize(value),
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'email_sent',
        headerName: 'Email envoyé',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'last_email_sent_at',
        headerName: 'Dernier email',
        valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATETIME) : null),
    },
    {
        width: 120,
        headerAlign: 'center',
        align: 'center',
        field: 'qb_invoice_id',
        headerName: 'QB bill ID',
    },
    {
        width: 120,
        headerAlign: 'center',
        align: 'center',
        field: 'qb_credit_memo_id',
        headerName: 'QB memo ID',
    },
    {
        width: 80,
        headerAlign: 'center',
        align: 'center',
        field: 'filename',
        headerName: 'Filename',
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'is_sipt',
        headerName: 'SIPT?',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_tag_1',
        headerName: 'Fact acte',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_tag_2',
        headerName: 'Fact ligne',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_tag_3',
        headerName: 'RMX',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_tag_4',
        headerName: 'RPV',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_tag_5',
        headerName: 'RPS',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_tag_6',
        headerName: 'FD (acte)',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_tag_7',
        headerName: 'FD (ligne)',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_tag_8',
        headerName: 'Quart 19680',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_1',
        headerName: 'Rabais AMUQ',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_2',
        headerName: 'Rabais année 1',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_3',
        headerName: 'Rabais année 2',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_4',
        headerName: 'Rabais année 3',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_5',
        headerName: 'Rabais réf.',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_6',
        headerName: 'Rabais clinique',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_7',
        headerName: 'Ajust. Crédit',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_8',
        headerName: 'Ajust. Débit',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_9',
        headerName: 'Logiciel',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_10',
        headerName: 'Kinlogix',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_11',
        headerName: 'Medfar',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_12',
        headerName: 'Omnimed',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'sum_mod_tag_13',
        headerName: 'Rabais groupe',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { id, ec_id, clinic_id, filename, bill_no } = row;

            const isFileDownloadButtonLoading = clinicBillIdsOfCurrentFileDownloads.includes(id);

            return (
                <Box display="flex" alignItems="center">
                    {isFileDownloadButtonLoading ? (
                        <IconButton size="medium" color="primary" disabled={true}>
                            <CircularProgress size={14} />
                        </IconButton>
                    ) : (
                        filename && (
                            <Tooltip title="Télécharger la facture." placement="left" disableInteractive>
                                <IconButton
                                    size="medium"
                                    color="primary"
                                    onClick={() => {
                                        handleDownloadMdBill(id, ec_id, clinic_id);
                                    }}
                                >
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )
                    )}

                    <IconButton
                        size="medium"
                        color="warning"
                        onClick={() => {
                            handleOpenEditDrawer(id);
                            window.history.replaceState(null, '', `/clinics/bills/${id}`);
                        }}
                    >
                        <OpenInFullIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                </Box>
            );
        },
    },
];
