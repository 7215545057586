import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useForm, useController, Control, FormState, useFieldArray } from 'react-hook-form';
import Decimal from 'decimal.js';
import NumberFormat from 'react-number-format';
import { isEmpty } from 'lodash';

// MUI Components
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
    Divider,
    Switch,
    Tooltip,
    IconButton,
} from '@mui/material';
import { DatePicker, TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { Delete as DeleteIcon, Help as HelpIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import { CheckboxControl, DateControl, NumberControl, TextControl } from 'components/inputs';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_CLINIC, UPDATE_CLINIC } from './apollo-queries';

// Redux

// Utils

// Constants
import { SQL_DATE } from 'constants/Moment';

interface MdEditProps {
    clinicId: number;
}

export default function MdEdit({ clinicId }: MdEditProps) {
    const {
        loading: clinicLoading,
        error: clinicError,
        data: clinicData,
        refetch: clinicRefetch,
        networkStatus: clinicNetworkStatus,
    } = useQuery(GET_CLINIC, {
        variables: { id: clinicId },
        skip: !clinicId,
        notifyOnNetworkStatusChange: true,
    });

    const clinic = clinicData?.clinic || {};

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
    } = useForm({
        defaultValues: {
            id: clinic.id,
            is_still_client: clinic.is_still_client,
            left_on: clinic.left_on,
            can_login: clinic.can_login,
            qb_customer_id: clinic.qb_customer_id,
            qb_customer_fully_qualified_name: clinic.qb_customer_fully_qualified_name || '',

            is_billable: clinic.is_billable,
            is_billable_until: clinic.is_billable_until,

            should_receive_md_reports: clinic.should_receive_md_reports,
            should_receive_md_reports_until: clinic.should_receive_md_reports_until,

            should_receive_md_ec_reports: clinic.should_receive_md_ec_reports,
            should_receive_md_df_reports: clinic.should_receive_md_df_reports,
            should_receive_md_custom_reports: clinic.should_receive_md_custom_reports,
        },
    });

    useEffect(() => {
        const clinic = clinicData?.clinic || {};

        if (!isEmpty(clinic)) {
            reset({
                id: clinic.id,
                is_still_client: clinic.is_still_client,
                left_on: clinic.left_on,
                can_login: clinic.can_login,
                qb_customer_id: clinic.qb_customer_id,
                qb_customer_fully_qualified_name: clinic.qb_customer_fully_qualified_name,

                is_billable: clinic.is_billable,
                is_billable_until: clinic.is_billable_until,

                should_receive_md_reports: clinic.should_receive_md_reports,
                should_receive_md_reports_until: clinic.should_receive_md_reports_until,

                should_receive_md_ec_reports: clinic.should_receive_md_ec_reports,
                should_receive_md_df_reports: clinic.should_receive_md_df_reports,
                should_receive_md_custom_reports: clinic.should_receive_md_custom_reports,
            });
        }
    }, [reset, clinicData]);

    const [updateMd, { loading: updateMdLoading, error: updateMdError, data: updateMdData }] = useMutation(
        UPDATE_CLINIC,
        {
            // refetchQueries: [GET_SCANS],
        },
    );

    const onSubmit = async (data) => {
        console.log(data);

        await updateMd({
            variables: {
                id: data.id,
                is_still_client: data.is_still_client,
                left_on: data.left_on,
                can_login: data.can_login,
                qb_customer_id: data.qb_customer_id,
                qb_customer_fully_qualified_name: data.qb_customer_fully_qualified_name,

                is_billable: data.is_billable,
                is_billable_until: data.is_billable_until,

                should_receive_md_reports: data.should_receive_md_reports,
                should_receive_md_reports_until: data.should_receive_md_reports_until,

                should_receive_md_ec_reports: data.should_receive_md_ec_reports,
                should_receive_md_df_reports: data.should_receive_md_df_reports,
                should_receive_md_custom_reports: data.should_receive_md_custom_reports,
            },
        });

        clinicRefetch();
    };

    const renderError = (error) => {
        if (!error) return <></>;

        console.log(Object.keys(error));

        let jsxErrors: React.ReactNode;
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            jsxErrors = error.graphQLErrors.map((item, idx) => {
                return (
                    <Typography key={idx} color="red" textAlign="center">
                        {`${item.extensions.code}: ${item.message}`}
                    </Typography>
                );
            });
        } else {
            jsxErrors = (
                <Typography color="red" textAlign="center">
                    {error.message}
                </Typography>
            );
        }

        return (
            <Grid item xs={12} mt={2}>
                {jsxErrors}
            </Grid>
        );
    };

    const inputGridXl = 6;
    const inputGridLg = 6;
    const inputGridMd = 6;
    const inputGridSm = 6;
    const inputGridXs = 12;

    return clinicLoading ? (
        <Loading />
    ) : (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <Grid container spacing={3}>
                <Grid item container>
                    <Grid item xs={12} mb={3}>
                        <Typography variant="subtitle1" fontWeight="500">
                            Statuts & Permissions
                        </Typography>
                    </Grid>

                    <Grid item container ml={4} columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`is_still_client`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: 'Client' }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si cette clinique est encore un client de l'agence."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            {!watch('is_still_client') && (
                                <Grid
                                    item
                                    xs={inputGridXs}
                                    sm={inputGridSm}
                                    md={inputGridMd}
                                    lg={inputGridLg}
                                    xl={inputGridXl}
                                    container
                                    alignItems="center"
                                >
                                    <DateControl
                                        name={`left_on`}
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Date de départ' }}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid item xs={12} container alignItems="center">
                                <CheckboxControl
                                    name={`can_login`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: 'Accès au portail clinique' }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case pour permettre à la clinique d'accéder à leur portail."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`is_billable`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: 'Facturable' }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si des factures doivent être produites/envoyées à cette clinique. Pour facturer jusqu'à un EC précis, veuillez indiquer la date de l'état de compte (la date du vendredi) dans le champ juste à droite."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <DateControl
                                    name={`is_billable_until`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: "Facturable jusqu'à l'ÉTC du (inclusif)" }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`should_receive_md_reports`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: "Envoyer les documents d'ÉTC" }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si les documents d'ÉTC des médecins (documents EC et/ou DF et/ou Rapport sommaire) de cette clinique doivent être envoyés à cette clinique. Pour envoyer les documents d'ÉTC jusqu'à un EC précis, veuillez indiquer la date de l'état de compte (la date du vendredi) dans le champ juste à droite."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <DateControl
                                    name={`should_receive_md_reports_until`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: "Envoyer les documents d'ÉTC jusqu'à l'ÉTC du (inclusif)" }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container ml={8} rowSpacing={2}>
                            <Grid item xs={12} container>
                                <Grid item xs={12} container alignItems="center">
                                    <CheckboxControl
                                        name={`should_receive_md_ec_reports`}
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Envoyer les documents EC' }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container>
                                <Grid item xs={12} container alignItems="center">
                                    <CheckboxControl
                                        name={`should_receive_md_df_reports`}
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Envoyer les documents DF' }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container>
                                <Grid item xs={12} container alignItems="center">
                                    <CheckboxControl
                                        name={`should_receive_md_custom_reports`}
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Envoyer les rapports sommaires' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container mt={2}>
                    <Grid item xs={12} mb={3}>
                        <Typography variant="subtitle1" fontWeight="500">
                            Infos Quickbooks
                        </Typography>
                    </Grid>

                    <Grid item container ml={4} columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                            <NumberControl
                                name="qb_customer_id"
                                control={control}
                                errors={errors}
                                options={{ label: 'QB customer ID' }}
                            />
                        </Grid>

                        <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                            <TextControl
                                name="qb_customer_fully_qualified_name"
                                control={control}
                                errors={errors}
                                options={{ label: 'QB customer fully qualified name' }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {renderError(updateMdError)}

                <Grid item xs={12} container justifyContent="flex-end" mt={0}>
                    <Button type="submit" variant="contained" disabled={updateMdLoading}>
                        Sauvegarder
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
