import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme, Theme } from '@mui/material/styles';

// MUI Components
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Snackbar,
    Alert,
    Box,
    AlertTitle,
    DialogContentText,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { Delete as DeleteIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { DELETE_MD_HORS_RAMQ_CLAIM, GET_MD_HORS_RAMQ_CLAIM } from './apollo-queries';
import { GET_MD_HORS_RAMQ_CLAIMS } from 'views/hors-ramq/MdHorsRamqClaimList/apollo-queries';

// Redux

// Utils
import { getErrorMsg } from 'utils';

// Constants

interface MdHorsRamqClaimDeleteDialogProps {
    isMdHorsRamqClaimDeleteDialogOpen: boolean;
    handleCloseMdHorsRamqClaimDeleteDialog: () => void;
    selectedMdHorsRamqClaimId: number | null;
}

interface MdHorsRamqClaimDeleteDialogContentProps {
    isMdHorsRamqClaimDeleteDialogOpen: boolean;
    handleCloseMdHorsRamqClaimDeleteDialog: () => void;
    selectedMdHorsRamqClaimId: number | null;
}

export function MdHorsRamqClaimDeleteDialog({
    isMdHorsRamqClaimDeleteDialogOpen,
    handleCloseMdHorsRamqClaimDeleteDialog,
    selectedMdHorsRamqClaimId,
}: MdHorsRamqClaimDeleteDialogProps) {
    const isDialogOpen = isMdHorsRamqClaimDeleteDialogOpen ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={handleCloseMdHorsRamqClaimDeleteDialog}>
            <MdHorsRamqClaimDeleteDialogContent
                isMdHorsRamqClaimDeleteDialogOpen={isMdHorsRamqClaimDeleteDialogOpen}
                handleCloseMdHorsRamqClaimDeleteDialog={handleCloseMdHorsRamqClaimDeleteDialog}
                selectedMdHorsRamqClaimId={selectedMdHorsRamqClaimId}
            />
        </Dialog>
    );
}

const MdHorsRamqClaimDeleteDialogContent = ({
    isMdHorsRamqClaimDeleteDialogOpen,
    handleCloseMdHorsRamqClaimDeleteDialog,
    selectedMdHorsRamqClaimId,
}: MdHorsRamqClaimDeleteDialogContentProps) => {
    const theme = useTheme();

    const {
        loading: mdHorsRamqClaimLoading,
        error: mdHorsRamqClaimError,
        data: mdHorsRamqClaimData,
    } = useQuery(GET_MD_HORS_RAMQ_CLAIM, {
        variables: { id: selectedMdHorsRamqClaimId },
        skip: !isMdHorsRamqClaimDeleteDialogOpen,
    });

    const [
        deleteMdHorsRamqClaim,
        { loading: deleteMdHorsRamqClaimLoading, error: deleteMdHorsRamqClaimError, data: deleteMdHorsRamqClaimData },
    ] = useMutation(DELETE_MD_HORS_RAMQ_CLAIM, {
        refetchQueries: [GET_MD_HORS_RAMQ_CLAIMS],
    });

    const onSubmit = async () => {
        await deleteMdHorsRamqClaim({
            variables: {
                id: selectedMdHorsRamqClaimId,
            },
        });

        handleCloseMdHorsRamqClaimDeleteDialog();
    };

    // Error snackbar
    const [isErrorSnackbarOpened, setIsErrorSnackbarOpened] = React.useState(false);
    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpened(false);
    };
    useEffect(() => setIsErrorSnackbarOpened(deleteMdHorsRamqClaimError ? true : false), [deleteMdHorsRamqClaimError]);

    return (
        <>
            <DialogTitle>Supprimer la demande #{selectedMdHorsRamqClaimId}?</DialogTitle>

            <DialogContent>
                {mdHorsRamqClaimLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height={100}>
                        <Loading />
                    </Box>
                ) : (
                    <Grid container spacing={3} pt={1}>
                        <Grid item xs={12}>
                            <DialogContentText>La demande sera complètement supprimée du système.</DialogContentText>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>

            <DialogActions>
                <>
                    <Button variant="text" color="secondaryText" onClick={handleCloseMdHorsRamqClaimDeleteDialog}>
                        Ne pas supprimer
                    </Button>
                    <LoadingButton
                        variant="text"
                        endIcon={<DeleteIcon />}
                        loading={deleteMdHorsRamqClaimLoading}
                        loadingPosition="end"
                        onClick={onSubmit}
                    >
                        Supprimer
                    </LoadingButton>
                </>
            </DialogActions>

            <Snackbar
                open={isErrorSnackbarOpened}
                autoHideDuration={6000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {getErrorMsg(deleteMdHorsRamqClaimError)}
                </Alert>
            </Snackbar>
        </>
    );
};
