import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { Button, IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    POSTE_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
    DESCRIPTION_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getPratiqueGroupeMdTableColumns = (
    handleOpenEtablissementDialog: (etablissement: any) => void,
): GridColDefPro[] => [
    {
        field: 'pratiquegrpno',
        headerName: '# Groupe de pratique',
        width: MEDIUM_STRING_COLUMN_WIDTH,
    },
    {
        field: 'etabCode',
        headerName: 'Établissement',
        width: 150,
        valueGetter: ({ row }) => row.PratiqueGroupe?.Etablissement?.numero,
        renderCell: ({ value, row }) =>
            value ? (
                <>
                    <Button
                        onClick={() => {
                            handleOpenEtablissementDialog(row.PratiqueGroupe.Etablissement);
                        }}
                        variant="contained"
                        size="small"
                    >
                        {value}
                    </Button>
                </>
            ) : null,
    },
    {
        field: 'etabDescription',
        headerName: 'Nom établissement',
        minWidth: DESCRIPTION_COLUMN_WIDTH,
        flex: 1,
        valueGetter: ({ row }) => row.PratiqueGroupe?.Etablissement?.description,
    },
];
