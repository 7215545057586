import React from 'react';
import { Box, Grid, LinearProgress, Typography, CircularProgress } from '@mui/material';

// Components
import GuestLayout from 'components/GuestLayout';

export default function MaintenancePage() {
    return (
        <GuestLayout>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Box display="flex" justifyContent="center">
                        <img
                            src="/img/logo-blanc.jpg"
                            alt=""
                            style={{
                                display: 'block',
                                height: '70px',
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} mb={4}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="h4" align="center" mr={2}>
                            Mise à jour quotidienne en cours...
                        </Typography>
                        <CircularProgress size={26} color="primary" />
                    </Box>

                    <Typography variant="subtitle1" mt={1.5} align="center">
                        Nous serons de retour bientôt!
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                    <LinearProgress color="primary" />
                </Grid>
            </Grid>
        </GuestLayout>
    );
}
