import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { ClinicFields } from 'apollo/fragments';

export const GET_CLINIC = gql`
    ${ClinicFields}

    query GetClinic($id: Int!) {
        clinic(id: $id) {
            ...ClinicFields
        }
    }
`;
