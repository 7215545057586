import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdFields } from 'apollo/fragments';

export const GET_MD = gql`
    ${MdFields}

    query GetMd($id: Int!) {
        md(id: $id) {
            ...MdFields

            # MdGrps {
            #     id
            #     md_id
            #     grp
            #     sm
            #     rmx
            #     rpv
            #     rps
            #     billing_method
            #     billing_sheet_id
            #     fact_fee
            #     fact_fee_urg
            #     fact_line_fee
            #     rmx_sheet_fee
            #     rpv_sheet_fee
            #     rps_sheet_fee
            #     is_active

            #     Groupe {
            #         numero
            #         denomination
            #         incorp
            #         debut
            #         fin
            #     }
            #     MdGrpBillLineMods {
            #         id
            #         created_at
            #         updated_at
            #         md_grp_id
            #         bill_line_tag_id
            #         name
            #         desc
            #         value_type
            #         percentage
            #         amt
            #         discount_start_on
            #         discount_end_on
            #         permanent
            #     }
            # }
            MedecinSpec {
                Specialite {
                    numero
                    nom
                }
            }
            MedecinDetails {
                licence
                modulateur
                descriptif
                courriel
                adresse
                ville
                codepostal
                province
                telcell
                teldom
                teltra
                exttra
                telfax
                telpaget
                defaut
            }
            MedecinFacInfo {
                licence
                info
                profuniv
                moins500
                autocv
                groupeb
                parcourriel
                codeip
                moisfiscal
                jourfiscal
                fraiscab
            }
            MedecinGmf {
                licence
                groupe
                codeetab
                autoffannuel
                Groupe {
                    numero
                    licence
                    denomination
                    incorp
                    debut
                    fin
                }
                Etablissement {
                    numero
                    description
                    adresse
                    ville
                    cp
                    noregion
                    province
                    ext0
                    ext1
                    ext2
                    ext3
                    ext4
                    ext5
                    ext6
                    ext7
                    ext8
                    Region {
                        nom
                    }
                }
            }
            MedecinGrpPst {
                mdgrppstno
                licence
                groupe
                poste
                modulateur
                sm
                rmx
                vac
                sal
                debut
                fin
                actif
                rh
                taux
                Groupe {
                    denomination
                }
                Poste {
                    numero
                    modulateur
                    description
                    cab
                    clsc
                    cli
                    urg
                    usi
                    adm
                    ger
                    psy
                    pal
                    rea
                    heb
                    udc
                    umf
                    actif
                }
                MedecinGrpPstCondRmx {
                    ConditionRmx {
                        description
                    }
                }
                MedecinPstCa {
                    licence
                    poste
                    modulateur
                    contexte
                    code
                    ActiviteContexte {
                        code
                        description
                        rmx
                        vac
                        sal
                    }
                    ActiviteCode {
                        code
                        description
                        codecontexte
                        omni
                        spec
                        rmx
                        vac
                        sal
                    }
                }
            }
            MedecinPstCa {
                licence
                poste
                modulateur
                contexte
                code
                ActiviteContexte {
                    code
                    description
                    rmx
                    vac
                    sal
                }
                ActiviteCode {
                    code
                    description
                    codecontexte
                    omni
                    spec
                    rmx
                    vac
                    sal
                }
            }
            Groupes {
                numero
                denomination
                incorp
                debut
                fin
                AgenceMd {
                    sm
                    rmx
                    vac
                    sal
                    actif
                }
            }
            DmeSiteMd {
                siteid
                userno
                licence
                DmeSite {
                    site
                    siteid
                    description
                    dme
                    Etablissement {
                        numero
                        description
                        adresse
                        ville
                        cp
                        noregion
                        province
                        ext0
                        ext1
                        ext2
                        ext3
                        ext4
                        ext5
                        ext6
                        ext7
                        ext8
                        Region {
                            nom
                        }
                    }
                }
            }
            PratiqueGroupeMd {
                licence
                pratiquegrpno
                PratiqueGroupe {
                    pratiquegrpno
                    codeetab
                    Etablissement {
                        numero
                        description
                        adresse
                        ville
                        cp
                        noregion
                        province
                        ext0
                        ext1
                        ext2
                        ext3
                        ext4
                        ext5
                        ext6
                        ext7
                        ext8
                        Region {
                            nom
                        }
                    }
                }
            }
            IdentifiantUtilProf {
                idutilno
                IdentifiantUtil {
                    idutilno
                    nogmf
                    description
                }
            }
        }
    }
`;

export const SEND_MD_ONBOARDING_EMAIL = gql`
    mutation SendMdOnboardingEmail($md_id: Int!) {
        sendMdOnboardingEmail(md_id: $md_id)
    }
`;
