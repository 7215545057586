import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    POSTE_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
    ACTIVE_COLUMN_WIDTH,
    LONG_STRING_COLUMN_WIDTH,
    ADDRESS_COLUMN_WIDTH,
    CITY_COLUMN_WIDTH,
    DESCRIPTION_COLUMN_WIDTH,
    REGION_NAME_COLUMN_WIDTH,
    ZIP_CODE_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getPosteDialogTableColumns = (): GridColDefPro[] => [
    {
        field: 'cab',
        headerName: 'CAB',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'clsc',
        headerName: 'CLSC',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'cli',
        headerName: 'CLI',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'urg',
        headerName: 'URG',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'usi',
        headerName: 'USI',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'adm',
        headerName: 'ADM',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'ger',
        headerName: 'GER',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'psy',
        headerName: 'PSY',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'pal',
        headerName: 'PAL',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'rea',
        headerName: 'REA',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'heb',
        headerName: 'HEB',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'udc',
        headerName: 'UDC',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'umf',
        headerName: 'UMF',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
];
