import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { ClinicTransactionFields, ClinicFields } from 'apollo/fragments';

export const GET_CLINIC_TRANSACTIONS = gql`
    ${ClinicTransactionFields}

    query GetClinicTransactions($clinic_id: Int, $start_date: DateTime, $end_date: DateTime) {
        clinicTransactions(clinic_id: $clinic_id, start_date: $start_date, end_date: $end_date) {
            ...ClinicTransactionFields
        }
    }
`;

export const GET_CLINICS = gql`
    ${ClinicFields}

    query GetClinics {
        clinics {
            ...ClinicFields
        }
    }
`;
