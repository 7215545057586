import React from 'react';
import { TextField } from '@mui/material';
import Decimal from 'decimal.js';
import { useState, useEffect, useCallback } from 'react';
import { Control, FormState, useController } from 'react-hook-form';
import NumberFormat from 'react-number-format';

interface NumberControlProps {
    name: string;
    control: Control<any, any>;
    errors?: FormState<any>['errors'];
    options?: { label?: string; suffix?: string; thousandSeparator?: string };
    money?: boolean;
    percentage?: boolean;
    decimals?: number;
    disabled?: boolean;
}

export function NumberControl({
    name,
    control,
    errors,
    options = {},
    money = false,
    percentage = false,
    decimals = undefined,
    disabled,
}: NumberControlProps) {
    let { label, suffix, thousandSeparator } = options;

    if (money) {
        decimals = decimals === undefined ? 2 : decimals;
        thousandSeparator = thousandSeparator === undefined ? ' ' : thousandSeparator;
        suffix = suffix === undefined ? '$' : suffix;
    }

    if (percentage) {
        decimals = decimals === undefined ? 2 : decimals;
        thousandSeparator = thousandSeparator === undefined ? '' : thousandSeparator;
        suffix = suffix === undefined ? '%' : suffix;
    }

    const {
        field: { onChange, value },
    } = useController({ name, control });
    const [formattedValue, setFormattedValue] = useState(convertDecimals(value, decimals));

    useEffect(() => {
        setFormattedValue(convertDecimals(value, decimals));
    }, [value, decimals]);

    const onValueChange = useCallback(
        ({ value, floatValue, formattedValue }) => {
            if (decimals !== undefined) {
                onChange(new Decimal(value || 0).mul(10 ** decimals).toNumber());
            } else {
                onChange(new Decimal(value || 0).toNumber());
            }

            setFormattedValue(formattedValue);
        },
        [onChange, decimals],
    );

    return (
        <>
            <NumberFormat
                customInput={TextField}
                value={formattedValue}
                onValueChange={onValueChange}
                size="small"
                fullWidth
                thousandSeparator={thousandSeparator}
                suffix={suffix}
                decimalScale={decimals}
                fixedDecimalScale={true}
                disabled={disabled}
                {...options}
            />
        </>
    );
}

const convertDecimals = (fullNumber: number, decimals: number | undefined) => {
    if (decimals === undefined) {
        return fullNumber || 0;
    } else {
        return (fullNumber || 0) / 10 ** decimals;
    }
};
