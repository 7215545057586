import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
    IconButton,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    Alert,
    AlertColor,
} from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    OpenInNew as OpenInNewIcon,
    Male as MaleIcon,
    Female as FemaleIcon,
    MoreVert as MoreVertIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import BooleanIcon from 'components/BooleanIcon';
import DrawerHeaderTitle from 'components/DrawerHeaderTitle';
import ContactTabPanel from './ContactTabPanel';
import DmeSiteMdTabPanel from './DmeSiteTabPanel';
import GrpPstTabPanel from './GrpPstTabPanel';
import IdentifiantUtilProfTabPanel from './IdentifiantUtilProfTabPanel';
import OverviewTabPanel from './OverviewTabPanel';

// Apollo
import { useQuery, useLazyQuery, from, useMutation } from '@apollo/client';
import { GET_MD, SEND_MD_ONBOARDING_EMAIL } from './apollo-queries';

// Redux
import {} from 'redux';

// Utils
import { formatNumber, formatPhoneNumber, capitalize } from 'utils';

// Constants
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import BillingTabPanel from './BillingTabPanel';
import EditTabPanel from './EditTabPanel';
import GrpTabPanel from './GrpTabPanel';
import PratiqueGroupeMdTabPanel from './PratiqueGroupeMdTabPanel';
import RamqTabPanel from './RamqTabPanel';
import SexIcon from 'components/SexIcon';
import { Snackbar } from '@mui/material';

interface EditDrawerProps {
    mdId: number | null;
    handleCloseEditDrawer: () => void;
}

interface EditDrawerContentProps {
    medecin: any;
    handleCloseEditDrawer: () => void;
}

export default function EditDrawer({ mdId, handleCloseEditDrawer }: EditDrawerProps) {
    // Apollo queries
    const {
        loading: mdLoading,
        error: mdError,
        data: mdData,
    } = useQuery(GET_MD, {
        variables: { id: mdId },
        skip: !mdId,
    });

    const md = mdData?.md;

    const isDrawerOpen = mdId ? true : false;

    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseEditDrawer}
            PaperProps={{ sx: { mt: '64px', height: 'calc(100vh - 64px) !important', p: 3 } }}
        >
            {mdLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                    <Loading />
                </Box>
            ) : (
                <EditDrawerContent medecin={md} handleCloseEditDrawer={handleCloseEditDrawer} />
            )}
        </Drawer>
    );
}

export const EditDrawerContent = ({ medecin, handleCloseEditDrawer }: EditDrawerContentProps) => {
    const [tabIndex, setTabIndex] = React.useState('facturation-interne');

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue.toString());
    };

    // Menu
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    // Onboarding email confirmation dialog
    const [isOnboardingEmailConfirmationDialogOpen, setIsOnboardingEmailConfirmationDialogOpen] = useState(false);

    // Snackbar
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
    const handleOpenSnackbar = ({ message, severity }) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setIsSnackbarOpen(true);
    };
    const handleCloseSnackbar = () => setIsSnackbarOpen(false);

    if (!medecin) return <></>;

    const { first_name, last_name, sex } = medecin;

    return (
        <Grid container spacing={2} width="60vw">
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} container spacing={2}>
                    <Grid item>
                        <Avatar
                            src={
                                ''
                                // 'https://storage.cloud.google.com/fmparadis-public-bucket-staging/admin-avatars/grogu.jpeg?authuser=2'
                            }
                            sx={{ width: 140, height: 140 }}
                        >
                            <Typography variant="h2" fontWeight="bold">
                                {last_name.charAt(0)}
                                {first_name.charAt(0)}
                            </Typography>
                        </Avatar>
                    </Grid>

                    <Grid item xs ml={2}>
                        <Grid item xs={12} container alignItems="center">
                            <Grid item xs={6}>
                                <Box display="flex" alignItems="center">
                                    <SexIcon
                                        sex={sex}
                                        sx={{
                                            color: 'blue1Dark.main',
                                            fontSize: 30,
                                            ml: '-7px',
                                            mr: 1,
                                        }}
                                    />
                                    <Typography variant="h3">
                                        {sex === 1 ? 'Dr' : sex === 2 ? 'Dre' : ''} {last_name}, {first_name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                                <Typography variant="h4" sx={{ mr: 2 }}>
                                    {medecin.id}
                                </Typography>

                                <IconButton onClick={handleOpenMenu}>
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={handleCloseMenu}>
                                    <MenuItem
                                        onClick={() => {
                                            setIsOnboardingEmailConfirmationDialogOpen(true);
                                            handleCloseMenu();
                                        }}
                                    >
                                        Envoyer courriel de bievenue
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 1, mb: 3 }}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} container spacing={1} columnSpacing={6}>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Spécialité:
                                </Typography>
                                <Typography variant="body1">
                                    {medecin.MedecinSpec?.Specialite?.nom || 'Aucune'}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Courriel:
                                </Typography>
                                <Typography variant="body1">{medecin.email}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Balance:
                                </Typography>
                                <Typography variant="body1">{formatNumber(medecin.balance / 100)} $</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Tel. mobile:
                                </Typography>
                                <Typography variant="body1">{formatPhoneNumber(medecin.phone_mobile)}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Tel. maison:
                                </Typography>
                                <Typography variant="body1">{formatPhoneNumber(medecin.phone_home)}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Tel. travail:
                                </Typography>
                                <Typography variant="body1">{formatPhoneNumber(medecin.phone_work)}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Rejoint le:
                                </Typography>
                                <Typography variant="body1">
                                    {medecin.joined_on ? moment(medecin.joined_on).format(SQL_DATE) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="space-between">
                                <Typography variant="body1" fontWeight={500}>
                                    Parti le:
                                </Typography>
                                <Typography variant="body1">
                                    {medecin.left_on ? moment(medecin.left_on).format(SQL_DATE) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" fontWeight={500}>
                                        Encore client:{' '}
                                    </Typography>
                                    <BooleanIcon value={medecin.is_still_client} sx={{ ml: 1 }} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
                <TabContext value={tabIndex}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={onTabChange}
                            variant="scrollable"
                            // scrollButtons
                            allowScrollButtonsMobile
                        >
                            <Tab label="Overview" value="overview" />
                            <Tab label="Edit" value="md-edit" />
                            <Tab label="Facturation" value="facturation-interne" />
                            <Tab label="Arrêt travail" value="work-stoppage" />
                            <Tab label="Adresses" value="adresses" />
                            <Tab label="RAMQ" value="facturation-ramq" />
                            <Tab label="Groupes" value="groupes" />
                            <Tab label="Postes" value="postes" />
                            <Tab label="Gmf" value="gmf" />
                            {/* <Tab label="PstCa" value="6" /> */}
                            <Tab label="Groupes de pratique" value="groupes-pratique" />
                            <Tab label="DME" value="dme" />
                            <Tab label="IDUTIL" value="idutil" />
                        </TabList>
                    </Box>
                    <>
                        <OverviewTabPanel
                            tabIndexAsString="overview"
                            medecin={medecin}
                            // medecinStatsGqlQueryStatus={medecinStatsGqlQueryStatus}
                        />
                        <EditTabPanel tabIndexAsString="md-edit" medecin={medecin} />
                        <BillingTabPanel tabIndexAsString="facturation-interne" medecin={medecin} />
                        {/* <BillingTabPanel tabIndexAsString="work-stoppage" medecin={medecin} /> */}
                        <ContactTabPanel tabIndexAsString="adresses" medecin={medecin} />
                        <RamqTabPanel tabIndexAsString="facturation-ramq" medecin={medecin} />
                        <GrpTabPanel tabIndexAsString="groupes" medecin={medecin} />
                        <GrpPstTabPanel tabIndexAsString="postes" medecin={medecin} />
                        {/* <GmfTabPanel tabIndexAsString="gmf" medecin={medecin} /> */}
                        {/* <PstCaTabPanel tabIndexAsString="pstca" medecin={medecin} /> */}
                        <PratiqueGroupeMdTabPanel tabIndexAsString="groupes-pratique" medecin={medecin} />
                        <DmeSiteMdTabPanel tabIndexAsString="dme" medecin={medecin} />
                        <IdentifiantUtilProfTabPanel tabIndexAsString="idutil" medecin={medecin} />
                    </>
                </TabContext>
            </Grid>

            <OnboardingEmailConfirmation
                isDialogOpen={isOnboardingEmailConfirmationDialogOpen}
                handleCloseDialog={() => setIsOnboardingEmailConfirmationDialogOpen(false)}
                md={medecin}
                handleOpenSnackbar={handleOpenSnackbar}
            />

            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                action={
                    <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* <Grid item xs={6}>
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field }) => <TextField label="Prénom" size="small" fullWidth {...field} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field }) => <TextField label="Nom" size="small" fullWidth {...field} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => <TextField label="Courriel" size="small" fullWidth {...field} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PhoneControl name="phone" errors={errors} control={control} />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <TextField label="Mot de passe" size="small" fullWidth {...field} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PickAvatarControl name="avatar_url" errors={errors} control={control} />
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end" mt={4}>
                            <Button type="submit" variant="contained">
                                Sauvegarder
                            </Button>
                        </Grid> */}
        </Grid>
    );
};

interface OnboardingEmailConfirmationProps {
    isDialogOpen: boolean;
    handleCloseDialog: () => void;
    md: any;
    handleOpenSnackbar: (payload: { message: string; severity: AlertColor }) => void;
}
export const OnboardingEmailConfirmation = ({
    isDialogOpen,
    handleCloseDialog,
    md,
    handleOpenSnackbar,
}: OnboardingEmailConfirmationProps) => {
    // Apollo queries
    const [sendMdOnboardingEmail, { loading: sendMdOnboardingEmailLoading }] = useMutation(SEND_MD_ONBOARDING_EMAIL);

    return (
        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Confirmation d'envoi</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Voulez-vous vraiment envoyer le courriel de bienvenue à ce médecin?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="secondaryText">
                    Non
                </Button>
                <LoadingButton
                    onClick={async () => {
                        await sendMdOnboardingEmail({ variables: { md_id: md.id } });
                        handleOpenSnackbar({ message: 'Courriel de bienvenue envoyé!', severity: 'success' });
                        handleCloseDialog();
                    }}
                    loading={sendMdOnboardingEmailLoading}
                >
                    Oui, envoyer le courriel
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
