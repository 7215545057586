import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useForm, useController, Control, FormState, useFieldArray } from 'react-hook-form';
import Decimal from 'decimal.js';
import NumberFormat from 'react-number-format';
import { isEmpty } from 'lodash';

// MUI Components
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
    Divider,
    Switch,
    Tooltip,
    IconButton,
} from '@mui/material';
import { DatePicker, TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { Delete as DeleteIcon, Help as HelpIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import {
    AutocompleteControl,
    CheckboxControl,
    DateControl,
    NumberControl,
    SelectControl,
    TextControl,
} from 'components/inputs';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
    GET_CLINIC,
    GET_BILLING_SHEETS,
    GET_BILL_LINE_TAGS,
    UPDATE_CLINIC_BILLING_INFO,
    GET_BILL_MOD_LINE_TAGS,
    GET_MDS,
} from './apollo-queries';

// Redux

// Utils

// Constants
import { SQL_DATE } from 'constants/Moment';

interface BillLinesUseStateItem {
    clinic_grp_id: number;
    bill_line_tag_id: number;
    name: string;
    desc: string | null;
    value_type: 'percentage' | 'amt';
    percentage: number;
    amt: number;
    start_on: string | null;
    end_on: string | null;
    permanent: boolean;
}

const BILLING_METHODS = [
    { value: 'acte', label: "À l'acte" },
    { value: 'ligne', label: 'À la ligne' },
];

const BILL_MOD_LINE_VALUE_TYPES = [
    { value: 'amt', label: 'Montant fixe' },
    { value: 'percentage', label: 'Pourcentage' },
];

interface ClinicBillingEditProps {
    clinicId: number;
}

export default function ClinicBillingEdit({ clinicId }: ClinicBillingEditProps) {
    const {
        loading: clinicLoading,
        error: clinicError,
        data: clinicData,
        refetch: clinicRefetch,
        networkStatus: clinicNetworkStatus,
    } = useQuery(GET_CLINIC, {
        variables: { id: clinicId },
        skip: !clinicId,
        notifyOnNetworkStatusChange: true,
    });
    const clinic = clinicData?.clinic;

    const {
        loading: billingSheetsLoading,
        error: billingSheetsError,
        data: billingSheetsData,
    } = useQuery(GET_BILLING_SHEETS, {});

    const {
        loading: billLineTagsLoading,
        error: billLineTagsError,
        data: billLineTagsData,
    } = useQuery(GET_BILL_LINE_TAGS, {});

    const {
        loading: billModLineTagsLoading,
        error: billModLineTagsError,
        data: billModLineTagsData,
    } = useQuery(GET_BILL_MOD_LINE_TAGS, {});

    const {
        loading: mdsLoading,
        error: mdsError,
        data: mdsData,
    } = useQuery(GET_MDS, {
        variables: {
            grp: clinic?.grp,
            pst: clinic?.pst,
        },
        skip: !clinic,
    });

    const clinicBillingInfo = clinicData?.clinic?.ClinicBillingInfo || {};

    const clinicBillingAutoLines = clinicData?.clinic?.ClinicBillingAutoLines || [];

    const clinicBillingAutoModLines = clinicData?.clinic?.ClinicBillingAutoModLines || [];

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
    } = useForm({
        defaultValues: {
            id: clinicBillingInfo.id,
            billing_method: clinicBillingInfo.billing_method || '',
            billing_sheet_id: clinicBillingInfo.billing_sheet_id || null,
            fact_fee: clinicBillingInfo.fact_fee,
            fact_line_fee: clinicBillingInfo.fact_line_fee,
            fact_fee_urg: clinicBillingInfo.fact_fee_urg,
            fact_line_fee_urg: clinicBillingInfo.fact_line_fee_urg,
            fact_fee_hospit: clinicBillingInfo.fact_fee_hospit,
            fact_line_fee_hospit: clinicBillingInfo.fact_line_fee_hospit,
            fact_fee_dme: clinicBillingInfo.fact_fee_dme,
            fact_line_fee_dme: clinicBillingInfo.fact_line_fee_dme,
            rmx_sheet_fee: clinicBillingInfo.rmx_sheet_fee,
            rpv_sheet_fee: clinicBillingInfo.rpv_sheet_fee,
            rps_sheet_fee: clinicBillingInfo.rps_sheet_fee,
            hors_ramq_req_fee: clinicBillingInfo.hors_ramq_req_fee,
            manual_fd_fee: clinicBillingInfo.manual_fd_fee,
            is_approved: clinicBillingInfo.is_approved || false,
            clinic_billing_auto_lines: clinicBillingAutoLines,
            clinic_billing_auto_mod_lines: clinicBillingAutoModLines,
        },
    });

    // ! Needs to be placed/initialized before the useEffect function containing the form reset logic to work properly
    const {
        fields: autoLineFields,
        append: autoLineAppend,
        // prepend,
        remove: autoLineRemove,
        // swap,
        // move,
        // insert,
    } = useFieldArray({
        control,
        name: 'clinic_billing_auto_lines',
    });

    // ! Needs to be placed/initialized before the useEffect function containing the form reset logic to work properly
    const {
        fields: autoModLineFields,
        append: autoModLineAppend,
        // prepend,
        remove: autoModLineRemove,
        // swap,
        // move,
        // insert,
    } = useFieldArray({
        control,
        name: 'clinic_billing_auto_mod_lines',
    });

    useEffect(() => {
        const clinicBillingInfo = clinicData?.clinic?.ClinicBillingInfo || {};

        const clinicBillingAutoLines = clinicData?.clinic?.ClinicBillingAutoLines || [];

        const clinicBillingAutoModLines = clinicData?.clinic?.ClinicBillingAutoModLines || [];

        if (!isEmpty(clinicBillingInfo)) {
            reset({
                id: clinicBillingInfo.id,
                billing_method: clinicBillingInfo.billing_method,
                billing_sheet_id: clinicBillingInfo.billing_sheet_id,
                fact_fee: clinicBillingInfo.fact_fee,
                fact_line_fee: clinicBillingInfo.fact_line_fee,
                fact_fee_urg: clinicBillingInfo.fact_fee_urg,
                fact_line_fee_urg: clinicBillingInfo.fact_line_fee_urg,
                fact_fee_hospit: clinicBillingInfo.fact_fee_hospit,
                fact_line_fee_hospit: clinicBillingInfo.fact_line_fee_hospit,
                fact_fee_dme: clinicBillingInfo.fact_fee_dme,
                fact_line_fee_dme: clinicBillingInfo.fact_line_fee_dme,
                rmx_sheet_fee: clinicBillingInfo.rmx_sheet_fee,
                rpv_sheet_fee: clinicBillingInfo.rpv_sheet_fee,
                rps_sheet_fee: clinicBillingInfo.rps_sheet_fee,
                hors_ramq_req_fee: clinicBillingInfo.hors_ramq_req_fee,
                manual_fd_fee: clinicBillingInfo.manual_fd_fee,
                is_approved: clinicBillingInfo.is_approved,
                clinic_billing_auto_lines: clinicBillingAutoLines,
                clinic_billing_auto_mod_lines: clinicBillingAutoModLines,
            });
        }
    }, [reset, clinicData]);

    const [
        updateClinicBillingInfo,
        {
            loading: updateClinicBillingInfoLoading,
            error: updateClinicBillingInfoError,
            data: updateClinicBillingInfoData,
        },
    ] = useMutation(UPDATE_CLINIC_BILLING_INFO, {
        // refetchQueries: [GET_SCANS],
    });

    const onSubmit = async (data) => {
        console.log(data);

        await updateClinicBillingInfo({
            variables: {
                id: data.id,
                billing_method: data.billing_method,
                billing_sheet_id: data.billing_sheet_id,
                fact_fee: data.fact_fee,
                fact_line_fee: data.fact_line_fee,
                fact_fee_urg: data.fact_fee_urg,
                fact_line_fee_urg: data.fact_line_fee_urg,
                fact_fee_hospit: data.fact_fee_hospit,
                fact_line_fee_hospit: data.fact_line_fee_hospit,
                fact_fee_dme: data.fact_fee_dme,
                fact_line_fee_dme: data.fact_line_fee_dme,
                rmx_sheet_fee: data.rmx_sheet_fee,
                rpv_sheet_fee: data.rpv_sheet_fee,
                rps_sheet_fee: data.rps_sheet_fee,
                hors_ramq_req_fee: data.hors_ramq_req_fee,
                manual_fd_fee: data.manual_fd_fee,
                is_approved: data.is_approved,
                clinic_billing_auto_lines: data.clinic_billing_auto_lines.map(
                    ({ __typename, created_at, updated_at, ...rest }) => rest,
                ),
                clinic_billing_auto_mod_lines: data.clinic_billing_auto_mod_lines.map(
                    ({ __typename, created_at, updated_at, ...rest }) => rest,
                ),
            },
        });

        clinicRefetch();
    };

    const onClickAddAutoLine = () => {
        autoLineAppend({
            clinic_id: null,
            bill_line_tag_id: null,
            name: '',
            desc: '',
            unit_amt: 0,
            qty: 0,
            start_on: null,
            end_on: null,
            is_permanent: true,
            md_id: null,
        });
    };
    const onClickRemoveAutoLine = (fieldIdx: number) => {
        autoLineRemove(fieldIdx);
    };

    const onClickAddAutoModLine = () => {
        autoModLineAppend({
            clinic_id: null,
            bill_mod_line_tag_id: null,
            name: '',
            desc: '',
            value_type: 'amt',
            value: 0,
            start_on: null,
            end_on: null,
            is_permanent: true,
            md_id: null,
        });
    };
    const onClickRemoveAutoModLine = (fieldIdx: number) => {
        autoModLineRemove(fieldIdx);
    };

    const renderError = (error) => {
        if (!error) return <></>;

        console.log(Object.keys(error));

        let jsxErrors: React.ReactNode;
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            jsxErrors = error.graphQLErrors.map((item, idx) => {
                return (
                    <Typography key={idx} color="red" textAlign="center">
                        {`${item.extensions.code}: ${item.message}`}
                    </Typography>
                );
            });
        } else {
            jsxErrors = (
                <Typography color="red" textAlign="center">
                    {error.message}
                </Typography>
            );
        }

        return (
            <Grid item xs={12} mt={2}>
                {jsxErrors}
            </Grid>
        );
    };

    return clinicLoading ? (
        <Loading />
    ) : (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <SelectControl
                        name="billing_method"
                        control={control}
                        errors={errors}
                        options={{
                            label: 'Méthode de facturation',
                        }}
                        dropdownOptions={BILLING_METHODS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    />
                </Grid>

                {watch('billing_method') === 'acte' && (
                    <Grid item xs={3}>
                        <SelectControl
                            name="billing_sheet_id"
                            control={control}
                            errors={errors}
                            options={{
                                label: 'Matrice de prix',
                            }}
                            dropdownOptions={[...(billingSheetsData?.billingSheets || [])]
                                .sort((a, b) => a.name - b.name)
                                .map((billingSheet) => (
                                    <MenuItem key={billingSheet.id} value={billingSheet.id}>
                                        {billingSheet.name}
                                    </MenuItem>
                                ))}
                        />
                    </Grid>
                )}

                {watch('billing_method') === 'ligne' && (
                    <>
                        <Grid item container ml={8} mt={2}>
                            <Grid item xs={12} mb={2}>
                                <Typography variant="subtitle1" fontWeight="500">
                                    Tarification de la facturation à la ligne en urgence
                                </Typography>
                            </Grid>

                            <Grid item container spacing={3}>
                                <Grid item xs={2.25}>
                                    <NumberControl
                                        name="fact_fee_urg"
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Tarif par facture' }}
                                        money
                                    />
                                </Grid>

                                <Grid item xs={2.25}>
                                    <NumberControl
                                        name="fact_line_fee_urg"
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Tarif par ligne' }}
                                        money
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container ml={8} mt={2}>
                            <Grid item xs={12} mb={2}>
                                <Typography variant="subtitle1" fontWeight="500">
                                    Tarification de la facturation à la ligne en hospitalisation
                                </Typography>
                            </Grid>

                            <Grid item container spacing={3}>
                                <Grid item xs={2.25}>
                                    <NumberControl
                                        name="fact_fee_hospit"
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Tarif par facture' }}
                                        money
                                    />
                                </Grid>

                                <Grid item xs={2.25}>
                                    <NumberControl
                                        name="fact_line_fee_hospit"
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Tarif par ligne' }}
                                        money
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container ml={8} mt={2}>
                            <Grid item xs={12} mb={2}>
                                <Typography variant="subtitle1" fontWeight="500">
                                    Tarification de la facturation à la ligne en cabinet & autre
                                </Typography>
                            </Grid>

                            <Grid item container spacing={3}>
                                <Grid item xs={2.25}>
                                    <NumberControl
                                        name="fact_fee"
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Tarif par facture' }}
                                        money
                                    />
                                </Grid>

                                <Grid item xs={2.25}>
                                    <NumberControl
                                        name="fact_line_fee"
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Tarif par ligne' }}
                                        money
                                    />
                                </Grid>

                                <Grid item xs={2.25}>
                                    <NumberControl
                                        name="fact_fee_dme"
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Tarif par facture DMÉ' }}
                                        money
                                    />
                                </Grid>

                                <Grid item xs={2.25}>
                                    <NumberControl
                                        name="fact_line_fee_dme"
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Tarif par ligne DMÉ' }}
                                        money
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}

                <Grid item container ml={8} mt={2}>
                    <Grid item xs={12} mb={2}>
                        <Typography variant="subtitle1" fontWeight="500">
                            Tarification des requêtes spéciales
                        </Typography>
                    </Grid>

                    <Grid item container spacing={3}>
                        <Grid item xs={6}>
                            <NumberControl
                                name="hors_ramq_req_fee"
                                control={control}
                                errors={errors}
                                options={{ label: 'Tarif pour requêtes hors RAMQ' }}
                                money
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <NumberControl
                                name="manual_fd_fee"
                                control={control}
                                errors={errors}
                                options={{ label: 'FD manuel' }}
                                money
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container ml={8} mt={2}>
                    <Grid item xs={12} mb={2}>
                        <Typography variant="subtitle1" fontWeight="500">
                            Tarification des feuilles
                        </Typography>
                    </Grid>

                    <Grid item container spacing={3}>
                        <Grid item xs={4}>
                            <NumberControl
                                name="rmx_sheet_fee"
                                control={control}
                                errors={errors}
                                options={{ label: 'Tarif par feuille RMX' }}
                                money
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <NumberControl
                                name="rpv_sheet_fee"
                                control={control}
                                errors={errors}
                                options={{ label: 'Tarif par feuille RPV' }}
                                money
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <NumberControl
                                name="rps_sheet_fee"
                                control={control}
                                errors={errors}
                                options={{ label: 'Tarif par feuille RPS' }}
                                money
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container ml={8} mt={2}>
                    <Grid item xs={12} mb={2}>
                        <Typography variant="subtitle1" fontWeight="500">
                            Lignes de facture ajoutées automatiquement
                        </Typography>
                    </Grid>

                    <Grid item container spacing={3}>
                        {autoLineFields.length === 0 && (
                            <Grid item xs={12} mb={2}>
                                <Typography color="warning.main" textAlign="center">
                                    Aucune ligne configurée pour la facturation automatique.
                                </Typography>
                            </Grid>
                        )}

                        {autoLineFields.map((field, fieldIdx) => {
                            // ! The key property of each Jsx element must be set to field.id for the useFieldArray hook to work correctly (don't use the fieldIdx)
                            return (
                                <Grid key={field.id} item container spacing={3} ml={8} mb={4}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={4}>
                                            <TextControl
                                                name={`clinic_billing_auto_lines.${fieldIdx}.name`}
                                                control={control}
                                                errors={errors}
                                                options={{
                                                    label: 'Titre ligne',
                                                    helperText: 'Titre de la ligne qui sera affichée sur la facture',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextControl
                                                name={`clinic_billing_auto_lines.${fieldIdx}.desc`}
                                                control={control}
                                                errors={errors}
                                                options={{
                                                    label: 'Description ligne',
                                                    helperText:
                                                        'Description de la ligne qui sera affichée sur la facture',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SelectControl
                                                name={`clinic_billing_auto_lines.${fieldIdx}.bill_line_tag_id`}
                                                control={control}
                                                errors={errors}
                                                options={{
                                                    label: 'Tag ligne',
                                                    helperText: 'Classification du type de ligne',
                                                }}
                                                dropdownOptions={(billLineTagsData?.billLineTags || []).map(
                                                    (billLineTag) => (
                                                        <MenuItem key={billLineTag.id} value={billLineTag.id}>
                                                            {billLineTag.name}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={3}>
                                        <Grid item xs={4}>
                                            <NumberControl
                                                name={`clinic_billing_auto_lines.${fieldIdx}.unit_amt`}
                                                control={control}
                                                errors={errors}
                                                options={{ label: 'Montant par unité' }}
                                                money
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <NumberControl
                                                name={`clinic_billing_auto_lines.${fieldIdx}.qty`}
                                                control={control}
                                                errors={errors}
                                                options={{ label: 'Quantité' }}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <AutocompleteControl
                                                name={`clinic_billing_auto_lines.${fieldIdx}.md_id`}
                                                control={control as any}
                                                options={mdsData?.mds || []}
                                                valuePropertyName="id"
                                                loading={mdsLoading}
                                                getOptionLabel={(option: any) => {
                                                    const { id, first_name, last_name } = option;

                                                    if (
                                                        option ===
                                                        getValues(`clinic_billing_auto_lines.${fieldIdx}.md_id`)
                                                    ) {
                                                        const selectedMd = (mdsData?.mds || []).find(
                                                            (md) => md.id === option,
                                                        );

                                                        return selectedMd
                                                            ? `${selectedMd.last_name}, ${selectedMd.first_name} (${selectedMd.id})`
                                                            : '';
                                                    } else {
                                                        return `${last_name}, ${first_name} (${id})`;
                                                    }
                                                }}
                                                isOptionEqualToValue={(option: any, value) => option?.id === value}
                                                textFieldOptions={{ label: 'Médecin' }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={3}>
                                        <Grid item xs={4} container alignItems="center">
                                            <CheckboxControl
                                                name={`clinic_billing_auto_lines.${fieldIdx}.is_permanent`}
                                                control={control}
                                                errors={errors}
                                                options={{ label: "Durée d'effectif indéterminée" }}
                                            />
                                        </Grid>

                                        {watch(`clinic_billing_auto_lines.${fieldIdx}.is_permanent`) === false && (
                                            <Grid item xs={4}>
                                                <DateControl
                                                    name={`clinic_billing_auto_lines.${fieldIdx}.start_on`}
                                                    control={control}
                                                    errors={errors}
                                                    options={{ label: "Date de début d'effectif" }}
                                                />
                                            </Grid>
                                        )}

                                        {watch(`clinic_billing_auto_lines.${fieldIdx}.is_permanent`) === false && (
                                            <Grid item xs={4}>
                                                <DateControl
                                                    name={`clinic_billing_auto_lines.${fieldIdx}.end_on`}
                                                    control={control}
                                                    errors={errors}
                                                    options={{ label: "Date de fin d'effectif" }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <Button
                                            variant="text"
                                            size="small"
                                            color="error"
                                            onClick={() => {
                                                onClickRemoveAutoLine(fieldIdx);
                                            }}
                                            endIcon={<DeleteIcon />}
                                        >
                                            Supprimer
                                        </Button>
                                    </Grid>

                                    {/* {fieldIdx + 1 < fields.length && ( */}
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    {/* )} */}
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Grid item xs={12} container justifyContent="center" mt={1}>
                        <Button variant="outlined" size="small" onClick={onClickAddAutoLine}>
                            Ajouter une ligne
                        </Button>
                    </Grid>
                </Grid>

                <Grid item container ml={8} mt={2}>
                    <Grid item xs={12} mb={2}>
                        <Typography variant="subtitle1" fontWeight="500">
                            Lignes de modification de facture ajoutées automatiquement
                        </Typography>
                    </Grid>

                    <Grid item container spacing={3}>
                        {autoModLineFields.length === 0 && (
                            <Grid item xs={12} mb={2}>
                                <Typography color="warning.main" textAlign="center">
                                    Aucune ligne configurée pour la facturation automatique.
                                </Typography>
                            </Grid>
                        )}

                        {autoModLineFields.map((field, fieldIdx) => {
                            // ! The key property of each Jsx element must be set to field.id for the useFieldArray hook to work correctly (don't use the fieldIdx)
                            return (
                                <Grid key={field.id} item container spacing={3} ml={8} mb={4}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={4}>
                                            <TextControl
                                                name={`clinic_billing_auto_mod_lines.${fieldIdx}.name`}
                                                control={control}
                                                errors={errors}
                                                options={{
                                                    label: 'Titre ligne',
                                                    helperText: 'Titre de la ligne qui sera affichée sur la facture',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextControl
                                                name={`clinic_billing_auto_mod_lines.${fieldIdx}.desc`}
                                                control={control}
                                                errors={errors}
                                                options={{
                                                    label: 'Description ligne',
                                                    helperText:
                                                        'Description de la ligne qui sera affichée sur la facture',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SelectControl
                                                name={`clinic_billing_auto_mod_lines.${fieldIdx}.bill_mod_line_tag_id`}
                                                control={control}
                                                errors={errors}
                                                options={{
                                                    label: 'Tag ligne',
                                                    helperText: 'Classification du type de ligne',
                                                }}
                                                dropdownOptions={(billModLineTagsData?.billModLineTags || []).map(
                                                    (billModLineTag) => (
                                                        <MenuItem key={billModLineTag.id} value={billModLineTag.id}>
                                                            {billModLineTag.name}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={3}>
                                        <Grid item xs={4}>
                                            <SelectControl
                                                name={`clinic_billing_auto_mod_lines.${fieldIdx}.value_type`}
                                                control={control}
                                                errors={errors}
                                                options={{
                                                    label: 'Type de valeur',
                                                }}
                                                dropdownOptions={BILL_MOD_LINE_VALUE_TYPES.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            />
                                        </Grid>

                                        {watch(`clinic_billing_auto_mod_lines.${fieldIdx}.value_type`) === 'amt' && (
                                            <Grid item xs={4}>
                                                <NumberControl
                                                    name={`clinic_billing_auto_mod_lines.${fieldIdx}.value`}
                                                    control={control}
                                                    errors={errors}
                                                    options={{ label: 'Montant fixe' }}
                                                    money
                                                />
                                            </Grid>
                                        )}

                                        {watch(`clinic_billing_auto_mod_lines.${fieldIdx}.value_type`) ===
                                            'percentage' && (
                                            <Grid item xs={4}>
                                                <NumberControl
                                                    name={`clinic_billing_auto_mod_lines.${fieldIdx}.value`}
                                                    control={control}
                                                    errors={errors}
                                                    options={{ label: 'Pourcentage' }}
                                                    percentage
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={4}>
                                            <AutocompleteControl
                                                name={`clinic_billing_auto_mod_lines.${fieldIdx}.md_id`}
                                                control={control as any}
                                                options={mdsData?.mds || []}
                                                valuePropertyName="id"
                                                loading={mdsLoading}
                                                getOptionLabel={(option: any) => {
                                                    const { id, first_name, last_name } = option;

                                                    if (
                                                        option ===
                                                        getValues(`clinic_billing_auto_mod_lines.${fieldIdx}.md_id`)
                                                    ) {
                                                        const selectedMd = (mdsData?.mds || []).find(
                                                            (md) => md.id === option,
                                                        );

                                                        return selectedMd
                                                            ? `${selectedMd.last_name}, ${selectedMd.first_name} (${selectedMd.id})`
                                                            : '';
                                                    } else {
                                                        return `${last_name}, ${first_name} (${id})`;
                                                    }
                                                }}
                                                isOptionEqualToValue={(option: any, value) => option?.id === value}
                                                textFieldOptions={{ label: 'Médecin' }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={3}>
                                        <Grid item xs={4} container alignItems="center">
                                            <CheckboxControl
                                                name={`clinic_billing_auto_mod_lines.${fieldIdx}.is_permanent`}
                                                control={control}
                                                errors={errors}
                                                options={{ label: "Durée d'effectif indéterminée" }}
                                            />
                                        </Grid>

                                        {watch(`clinic_billing_auto_mod_lines.${fieldIdx}.is_permanent`) === false && (
                                            <Grid item xs={4}>
                                                <DateControl
                                                    name={`clinic_billing_auto_mod_lines.${fieldIdx}.start_on`}
                                                    control={control}
                                                    errors={errors}
                                                    options={{ label: "Date de début d'effectif" }}
                                                />
                                            </Grid>
                                        )}

                                        {watch(`clinic_billing_auto_mod_lines.${fieldIdx}.is_permanent`) === false && (
                                            <Grid item xs={4}>
                                                <DateControl
                                                    name={`clinic_billing_auto_mod_lines.${fieldIdx}.end_on`}
                                                    control={control}
                                                    errors={errors}
                                                    options={{ label: "Date de fin d'effectif" }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <Button
                                            variant="text"
                                            size="small"
                                            color="error"
                                            onClick={() => {
                                                onClickRemoveAutoModLine(fieldIdx);
                                            }}
                                            endIcon={<DeleteIcon />}
                                        >
                                            Supprimer
                                        </Button>
                                    </Grid>

                                    {/* {fieldIdx + 1 < fields.length && ( */}
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    {/* )} */}
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Grid item xs={12} container justifyContent="center" mt={1}>
                        <Button variant="outlined" size="small" onClick={onClickAddAutoModLine}>
                            Ajouter une ligne
                        </Button>
                    </Grid>
                </Grid>

                {/* <Grid item xs={3}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={rabaisAmuqChecked}
                            onChange={(e) => setRabaisAmuqChecked(e.target.checked)}
                            size="small"
                        />
                    }
                    label="Rabais AMUQ"
                />
            </Grid> */}

                <Grid item xs={12} container justifyContent="flex-end" alignItems="center" mt={4}>
                    <CheckboxControl
                        name="is_approved"
                        control={control}
                        errors={errors}
                        options={{ label: 'Approuvé/Prêt pour facturation' }}
                    />
                    <Tooltip
                        title="Veuillez cocher cette case pour autorisé le logiciel à généré des factures avec les informations entrées ci-dessus. Si cette case est unchecked lors de la génération des factures pour un EC x, aucune facture ne sera générée par le système de facturation pour ce client."
                        placement="top"
                    >
                        <IconButton size="small">
                            <HelpIcon color="info" sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                </Grid>

                {renderError(updateClinicBillingInfoError)}

                <Grid item xs={12} container justifyContent="flex-end" mt={0}>
                    <Button type="submit" variant="contained" disabled={updateClinicBillingInfoLoading}>
                        Sauvegarder
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
