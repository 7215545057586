import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams, useParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import FileSaver from 'file-saver';

// MUI Components
import { Autocomplete, Breadcrumbs, Button, Grid, TextField, Typography } from '@mui/material';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getBillsColumns } from './grid-columns';

// MUI Icons
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import EditDrawer from './EditDrawer';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_MD_BILLS, GET_ECS, GET_MDS } from './apollo-queries';
import { Api } from 'services';

// Redux

// Utils

// Constants
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';
import { useDebounce } from 'use-debounce';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Gestion des médecins
    </Typography>,
    <Typography key="1" fontSize={15}>
        Factures
    </Typography>,
];

type RouteParams = 'id';

const DEBOUNCE_DELAY = 500;

export default function BillList() {
    const { id } = useParams<RouteParams>();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter - MdBill ID
    const search_md_bill_id = searchParams.get('md_bill_id');
    const [mdBillIdFilter, setMdBillIdFilter] = useState<string>(search_md_bill_id ? search_md_bill_id : '');
    const handleChangeMdBillId = (e) => {
        setMdBillIdFilter(e.target.value);
    };
    const [mdBillIdFilterDebounced] = useDebounce(mdBillIdFilter, DEBOUNCE_DELAY);

    // Filter - MdBill bill_no
    const search_md_bill_bill_no = searchParams.get('md_bill_bill_no');
    const [mdBillBillNoFilter, setMdBillBillNoFilter] = useState<string>(
        search_md_bill_bill_no ? search_md_bill_bill_no : '',
    );
    const handleChangeMdBillBillNoFilter = (e) => {
        setMdBillBillNoFilter(e.target.value);
    };
    const [mdBillBillNoFilterDebounced] = useDebounce(mdBillBillNoFilter, DEBOUNCE_DELAY);

    // Filter - Ec ID
    const search_ec_id = searchParams.get('ec_id');
    const [ecIdFilter, setEcIdFilter] = useState<number | null>(search_ec_id ? parseInt(search_ec_id) : null);
    const handleChangeEcId = (e, value: any) => {
        const ecId = value?.id;
        setEcIdFilter(ecId || null);
    };

    // Filter - Md ID
    const search_md_id = searchParams.get('md_id');
    const [mdIdFilter, setMdIdFilter] = useState<number | null>(search_md_id ? parseInt(search_md_id) : null);
    const handleChangeMdId = (e, value: any) => {
        const mdId = value?.id;
        setMdIdFilter(mdId || null);
    };

    // Apollo queries
    const { loading: ecsLoading, error: ecsError, data: ecsData } = useQuery(GET_ECS);
    const { loading: mdsLoading, error: mdsError, data: mdsData } = useQuery(GET_MDS);
    const {
        loading: mdBillsLoading,
        error: mdBillsError,
        data: mdBillsData,
    } = useQuery(GET_MD_BILLS, {
        variables: {
            id: parseInt(mdBillIdFilterDebounced),
            bill_no: mdBillBillNoFilterDebounced,
            md_id: mdIdFilter,
            ec_id: ecIdFilter,
        },
    });

    // EditDrawer
    const [selectedBillId, setSelectedBillId] = useState<number | null>(id ? parseInt(id) : null);

    const handleOpenEditDrawer = useCallback((id: number) => {
        setSelectedBillId(id);
    }, []);
    const handleCloseEditDrawer = useCallback(() => {
        setSelectedBillId(null);
    }, []);

    // Md bill file download
    const [mdBillIdsOfCurrentFileDownloads, setMdBillIdsOfCurrentFileDownloads] = useState<number[]>([]);
    const handleDownloadMdBill = useCallback(
        async (md_bill_id: number, ec_id: number, md_id: number) => {
            setMdBillIdsOfCurrentFileDownloads([...mdBillIdsOfCurrentFileDownloads, md_bill_id]);

            try {
                const res = await Api.get('storage/md-bill', {
                    responseType: 'blob',
                    timeout: 1000 * 20, // 20 seconds
                    params: {
                        md_bill_id: md_bill_id,
                    },
                });

                const filename = `${ec_id}-${md_id}__${md_bill_id}.pdf`;
                FileSaver.saveAs(res.data, filename);
            } catch (e) {
            } finally {
                setMdBillIdsOfCurrentFileDownloads(
                    mdBillIdsOfCurrentFileDownloads.filter((item) => item === md_bill_id),
                );
            }
        },
        [setMdBillIdsOfCurrentFileDownloads, mdBillIdsOfCurrentFileDownloads],
    );

    // Columns
    const billsColumns = useMemo(
        () => getBillsColumns(handleOpenEditDrawer, handleDownloadMdBill, mdBillIdsOfCurrentFileDownloads),
        [handleOpenEditDrawer, handleDownloadMdBill, mdBillIdsOfCurrentFileDownloads],
    );
    const [pageSize, setPageSize] = React.useState<number>(15);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Factures
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container spacing={3} mb={2.5}>
                <Grid item xs={2.5}>
                    <TextField
                        label="ID facture"
                        value={mdBillIdFilter}
                        onChange={handleChangeMdBillId}
                        type="number"
                        size="small"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <TextField
                        label="No. facture"
                        value={mdBillBillNoFilter}
                        onChange={handleChangeMdBillBillNoFilter}
                        size="small"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <Autocomplete
                        // disablePortal
                        options={ecsData?.ecs || []}
                        loading={ecsLoading}
                        getOptionLabel={(option: any) => {
                            const { id, no_paiement, date_ec, date_coupure } = option;

                            if (option === ecIdFilter) {
                                const selectedEc = (ecsData?.ecs || []).find((ec) => ec.id === option);

                                return selectedEc
                                    ? `${moment(selectedEc.date_ec).format(SQL_DATE)}  (OR-${selectedEc.no_paiement})`
                                    : '';
                            } else {
                                return `${moment(date_ec).format(SQL_DATE)}  (OR-${no_paiement})`;
                            }
                        }}
                        value={ecIdFilter}
                        onChange={handleChangeEcId}
                        isOptionEqualToValue={(option: any, value) => option?.id === value}
                        renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth label="État de compte" />
                        )}
                        groupBy={(option) => `Année ${moment(option.date_ec).format('YYYY')}`}
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <Autocomplete
                        // disablePortal
                        options={mdsData?.mds || []}
                        loading={mdsLoading}
                        getOptionLabel={(option: any) => {
                            const { first_name, last_name, id } = option;

                            if (option === mdIdFilter) {
                                const selectedMd = (mdsData?.mds || []).find((md) => md.id === option);

                                return selectedMd
                                    ? `${selectedMd.last_name}, ${selectedMd.first_name} (${selectedMd.id})`
                                    : '';
                            } else {
                                return `${last_name}, ${first_name} (${id})`;
                            }
                        }}
                        value={mdIdFilter}
                        onChange={handleChangeMdId}
                        isOptionEqualToValue={(option: any, value) => option?.id === value}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth label="Médecin" />}
                    />
                </Grid>
            </Grid>

            <DataGridPro
                getRowId={(row) => row.id}
                rows={mdBillsData?.mdBills || []}
                columns={billsColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: {
                        left: ['id', 'bill_no', 'md_id', 'last_name', 'first_name'],
                        right: ['actions'],
                    },
                    sorting: {
                        sortModel: [{ field: 'date_ec', sort: 'desc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            created_at: false,
                            updated_at: false,
                            ec_id: false,
                        },
                    },
                }}
                loading={mdBillsLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />

            <EditDrawer
                mdBillId={selectedBillId}
                handleCloseEditDrawer={handleCloseEditDrawer}
                handleDownloadMdBill={handleDownloadMdBill}
            />
        </>
    );
}
