import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import {
    MdFields,
    EcFields,
    MdBillFields,
    MdBillLineFields,
    MdBillModLineFields,
    BillLineTagFields,
    BillModLineTagFields,
} from 'apollo/fragments';

export const GET_MD_BILL = gql`
    ${MdFields}
    ${EcFields}
    ${MdBillFields}
    ${MdBillLineFields}
    ${MdBillModLineFields}
    ${BillLineTagFields}
    ${BillModLineTagFields}

    query GetMdBill($id: Int!) {
        mdBill(id: $id) {
            ...MdBillFields

            Md {
                ...MdFields
            }
            Ec {
                ...EcFields
            }
            MdBillLines {
                ...MdBillLineFields

                BillLineTag {
                    ...BillLineTagFields
                }
            }
            MdBillModLines {
                ...MdBillModLineFields

                BillModLineTag {
                    ...BillModLineTagFields
                }
            }
        }
    }
`;
