import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import Decimal from 'decimal.js';
import { useState, useEffect, useCallback } from 'react';
import { Control, Controller, FormState, useController } from 'react-hook-form';

interface AutocompleteControlProps {
    name: string;
    control: Control<any, any>;
    options: any[];
    valuePropertyName: string;
    loading?: boolean;
    renderOption?: () => any;
    getOptionLabel: (option: any) => string;
    isOptionEqualToValue: (option: any, value: any) => boolean;
    textFieldOptions?: { label?: string; suffix?: string; disabled?: boolean };
    disabled?: boolean;
}

export function AutocompleteControl({
    name,
    control,
    options = [],
    valuePropertyName,
    loading,
    renderOption,
    getOptionLabel,
    isOptionEqualToValue,
    textFieldOptions,
    disabled,
}: AutocompleteControlProps) {
    const {
        field: { onChange, value },
    } = useController({ name, control });

    const onValueChange = useCallback(
        (e, value) => {
            onChange(value[valuePropertyName]);
        },
        [onChange, valuePropertyName],
    );

    return (
        <Autocomplete
            // disablePortal
            options={options || []}
            loading={loading}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onValueChange}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
            renderInput={(params) => <TextField {...params} size="small" fullWidth {...textFieldOptions} />}
        />
    );
}
