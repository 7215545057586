import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import Loading from 'components/Loading';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';

// Redux
import {} from 'redux';

// Utils
import { capitalize } from 'utils';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    TYPE_SERVICE_NAME_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATETIME } from 'constants/Moment';

const GET_FACTLIGNE_SUM = gql`
    query GetFactligneTotal($licence: String!, $datefactFrom: String!, $datefactTo: String!) {
        factligneSum(licence: $licence, datefactFrom: $datefactFrom, datefactTo: $datefactTo)
    }
`;

const GET_FACTURES = gql`
    query GetFactures($licence: Int!) {
        factures(licence: $licence) {
            reqt_id
            nce
            nci
            typeservice
            revision
            licence
            poste
            postemod
            jeton
            statrecv
            annule
            lotid
            creation
            ec_id
            # Poste {
            #     description
            # }
            TYPE_SERVICE
        }
    }
`;

export default function FactureList() {
    const {
        loading: facturesLoading,
        error: facturesError,
        data: facturesData,
    } = useQuery(GET_FACTURES, {
        variables: {
            licence: 1070739,
        },
    });

    const breadcrumbs = [
        <Typography key="1" fontSize={15}>
            DPs
        </Typography>,
    ];

    const INITIAL_GROUPING_COLUMN_MODEL = ['ec_id'];

    const columns = useMemo(() => getColumns(), []);

    if (facturesLoading) return <Loading />;
    if (facturesError) return <Typography>Error! {facturesError.message}</Typography>;

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Demandes de paiement
            </Typography>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 0, mb: 5 }}>
                {breadcrumbs}
            </Breadcrumbs>

            <DataGridPro
                getRowId={(row) => row.reqt_id}
                rows={facturesData.factures || []}
                columns={columns}
                pagination
                pageSize={15}
                rowsPerPageOptions={[15, 25, 50, 75, 100]}
                autoHeight
                disableSelectionOnClick
                // rowGroupingColumnMode="single"
                initialState={{
                    // rowGrouping: {
                    //     model: INITIAL_GROUPING_COLUMN_MODEL,
                    // },
                    pinnedColumns: { left: ['nce'], right: ['actions'] },
                }}
                // defaultGroupingExpansionDepth={-1}
            />
        </>
    );
}

const getColumns = (): GridColDefPro[] => [
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nce, revision } = params.row;

            return (
                <>
                    <IconButton
                        size="small"
                        color="primary"
                        component={RouterLink}
                        to={`/factures/${nce}/${revision}`}
                        target="_blank"
                    >
                        <OpenInNewIcon fontSize="small" />
                    </IconButton>
                </>
            );
        },
    },
    {
        field: 'nce',
        headerName: 'NCE',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'creation',
        headerName: 'Créé le',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => moment(params.value).format(SQL_DATETIME),
    },
    {
        field: 'ec_id',
        headerName: 'EC ID',
        width: ID_COLUMN_WIDTH,
        valueFormatter: (params) => params.value + ' test',
    },
    {
        field: 'reqt_id',
        headerName: 'No. RAMQ externe',
        width: 150,
    },
    {
        field: 'nci',
        headerName: 'No. RAMQ interne',
        width: 150,
    },
    {
        ...TYPE_SERVICE_NAME_COL_DEFAULTS,
        field: 'TYPE_SERVICE',
        headerName: 'Type service',
    },
    {
        field: 'revision',
        headerName: 'Nb. révisions',
        width: 120,
    },
    {
        field: 'licence',
        headerName: 'Licence',
        width: SMALL_STRING_COLUMN_WIDTH,
    },
    {
        field: 'poste',
        headerName: 'Poste',
        width: SMALL_STRING_COLUMN_WIDTH,
    },
    {
        field: 'postemod',
        headerName: 'Poste modulateur (1|2|3)',
        width: MEDIUM_STRING_COLUMN_WIDTH,
    },
    {
        field: 'jeton',
        headerName: 'Jeton RAMQ',
        width: 120,
    },
    {
        field: 'statrecv',
        headerName: 'Statut recv. (1|2|3)',
        width: 150,
    },
    {
        field: 'annule',
        headerName: 'Annulé?',
        width: SMALL_STRING_COLUMN_WIDTH,
        renderCell: (params) => <BooleanIcon value={params.value} />,
    },
];
