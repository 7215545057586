import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdFields } from 'apollo/fragments';

export const GET_MD = gql`
    ${MdFields}

    query GetMd($id: Int!) {
        md(id: $id) {
            ...MdFields
        }
    }
`;

export const UPDATE_MD = gql`
    mutation UpdateMd(
        $id: Int
        $is_still_client: Boolean
        $left_on: Date
        $can_login: Boolean
        $qb_customer_id: Int
        $qb_customer_fully_qualified_name: String
        $is_billable: Boolean
        $is_billable_until: Date
        $should_receive_reports: Boolean
        $should_receive_reports_until: Date
        $wants_invoice_emails: Boolean
        $wants_ec_emails: Boolean
        $wants_newsletter_emails: Boolean
    ) {
        updateMd(
            id: $id
            is_still_client: $is_still_client
            left_on: $left_on
            can_login: $can_login
            qb_customer_id: $qb_customer_id
            qb_customer_fully_qualified_name: $qb_customer_fully_qualified_name
            is_billable: $is_billable
            is_billable_until: $is_billable_until
            should_receive_reports: $should_receive_reports
            should_receive_reports_until: $should_receive_reports_until
            wants_invoice_emails: $wants_invoice_emails
            wants_ec_emails: $wants_ec_emails
            wants_newsletter_emails: $wants_newsletter_emails
        ) {
            id
        }
    }
`;
