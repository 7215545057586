import moment from 'moment';

// MUI Components
import { Box, IconButton, Tooltip, CircularProgress, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    MenuOpen as MenuOpenIcon,
    Download as DownloadIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import { DATETIME_COL_DEFAULTS, AMT_COL_DEFAULTS, ID_COL_DEFAULTS, LICENCE_COL_DEFAULTS } from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';
import ColoredNumber from 'components/ColoredNumber';

export const getMdTransactionsTableColumns = (
    handleDownloadMdBill,
    mdTransactionIdsOfCurrentFileDownloads,
    handleOpenMdPaymentEditDialog,
    handleOpenMdPaymentDeleteDialog,
): GridColDefPro[] => [
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'txn_recorded_at',
        headerName: 'Date transaction',
    },
    {
        ...LICENCE_COL_DEFAULTS,
        field: 'md_id',
        headerName: 'ID médecin',
    },
    {
        field: 'name',
        headerName: 'Description',
        minWidth: 250,
        flex: 1,
    },
    {
        field: 'type',
        headerName: 'Type de transaction',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => capitalize(value),
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'amt',
        headerName: 'Montant',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
        renderCell: ({ value, row }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'new_balance',
        headerName: 'Nouveau solde',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { type, ref_id } = row;

            const isFileDownloadButtonLoading = mdTransactionIdsOfCurrentFileDownloads.includes(ref_id);

            return (
                <Box display="flex" alignItems="center">
                    {isFileDownloadButtonLoading ? (
                        <IconButton size="medium" color="primary" disabled={true}>
                            <CircularProgress size={14} />
                        </IconButton>
                    ) : (
                        type === 'bill' &&
                        ref_id && (
                            <Tooltip title="Télécharger la facture." placement="left" disableInteractive>
                                <IconButton
                                    size="medium"
                                    color="primary"
                                    onClick={() => {
                                        handleDownloadMdBill(ref_id);
                                    }}
                                >
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )
                    )}

                    {type === 'payment' && (
                        <IconButton
                            size="medium"
                            color="primary"
                            onClick={() => {
                                handleOpenMdPaymentEditDialog(ref_id);
                            }}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    )}

                    {type === 'payment' && (
                        <IconButton
                            size="medium"
                            color="error"
                            onClick={() => {
                                handleOpenMdPaymentDeleteDialog(ref_id);
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            );
        },
    },
];
