import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdHorsRamqClaimFields } from 'apollo/fragments';

export const GET_MD_HORS_RAMQ_CLAIM = gql`
    ${MdHorsRamqClaimFields}

    query GetMdHorsRamqClaim($id: Int) {
        mdHorsRamqClaim(id: $id) {
            ...MdHorsRamqClaimFields
        }
    }
`;

export const DELETE_MD_HORS_RAMQ_CLAIM = gql`
    mutation DeleteMdHorsRamqClaim($id: Int) {
        deleteMdHorsRamqClaim(id: $id) {
            id
        }
    }
`;
