import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';

// MUI Components
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Snackbar,
    Alert,
    Box,
    AlertTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { Save as SaveIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import { AutocompleteControl, DateControl, NumberControl, SelectControl, TextControl } from 'components/inputs';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_BANK_ACCOUNTS, GET_CLINICS, UPDATE_CLINIC_PAYMENT, GET_CLINIC_PAYMENT } from './apollo-queries';
import { GET_CLINIC_TRANSACTIONS } from 'views/clinics-transactions/ClinicTransactionList/apollo-queries';

// Redux

// Utils
import { getErrorMsg } from 'utils';

// Constants
import { PAYMENT_METHODS } from 'constants/DbConstants';
import { isEmpty } from 'lodash';
import { SQL_DATE } from 'constants/Moment';

interface ClinicPaymentEditDialogProps {
    isClinicPaymentEditDialogOpen: boolean;
    handleCloseClinicPaymentEditDialog: () => void;
    selectedPaymentId: number | null;
}

interface ClinicPaymentEditDialogContentProps {
    isClinicPaymentEditDialogOpen: boolean;
    handleCloseClinicPaymentEditDialog: () => void;
    selectedPaymentId: number | null;
}

export function ClinicPaymentEditDialog({
    isClinicPaymentEditDialogOpen,
    handleCloseClinicPaymentEditDialog,
    selectedPaymentId,
}: ClinicPaymentEditDialogProps) {
    const isDialogOpen = isClinicPaymentEditDialogOpen ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={handleCloseClinicPaymentEditDialog}>
            <ClinicPaymentEditDialogContent
                isClinicPaymentEditDialogOpen={isClinicPaymentEditDialogOpen}
                handleCloseClinicPaymentEditDialog={handleCloseClinicPaymentEditDialog}
                selectedPaymentId={selectedPaymentId}
            />
        </Dialog>
    );
}

const ClinicPaymentEditDialogContent = ({
    isClinicPaymentEditDialogOpen,
    handleCloseClinicPaymentEditDialog,
    selectedPaymentId,
}: ClinicPaymentEditDialogContentProps) => {
    const theme = useTheme();

    const {
        loading: clinicPaymentLoading,
        error: clinicPaymentError,
        data: clinicPaymentData,
    } = useQuery(GET_CLINIC_PAYMENT, {
        variables: { id: selectedPaymentId },
        skip: !isClinicPaymentEditDialogOpen,
    });

    const {
        loading: bankAccountsLoading,
        error: bankAccountsError,
        data: bankAccountsData,
    } = useQuery(GET_BANK_ACCOUNTS, {
        skip: !isClinicPaymentEditDialogOpen,
    });
    const { loading: clinicsLoading, error: clinicsError, data: clinicsData } = useQuery(GET_CLINICS);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
    } = useForm({
        defaultValues: {
            id: null,
            clinic_id: null,
            method: null,
            ref_no: '',
            amt: 0,
            bank_account_id: null,
            payment_date: moment().format(SQL_DATE),
            private_note: null,
        },
    });

    const [
        editClinicPaymentEdit,
        { loading: editClinicPaymentEditLoading, error: editClinicPaymentEditError, data: editClinicPaymentEditData },
    ] = useMutation(UPDATE_CLINIC_PAYMENT, {
        refetchQueries: [GET_CLINIC_TRANSACTIONS],
    });

    const clinicPayment = clinicPaymentData?.clinicPayment;
    useEffect(() => {
        if (!isEmpty(clinicPayment)) {
            reset({
                id: clinicPayment.id,
                clinic_id: clinicPayment.clinic_id,
                method: clinicPayment.method,
                ref_no: clinicPayment.ref_no,
                amt: clinicPayment.amt,
                bank_account_id: clinicPayment.bank_account_id,
                payment_date: clinicPayment.payment_date,
                private_note: clinicPayment.private_note,
            });
        }
    }, [reset, clinicPayment]);

    const onSubmit = async (data) => {
        console.log(data);

        await editClinicPaymentEdit({
            variables: {
                id: data.id,
                clinic_id: data.clinic_id,
                method: data.method,
                ref_no: data.ref_no,
                amt: data.amt,
                bank_account_id: data.bank_account_id,
                payment_date: data.payment_date,
                private_note: data.private_note,
            },
        });

        handleCloseClinicPaymentEditDialog();
    };

    // Error snackbar
    const [isErrorSnackbarOpened, setIsErrorSnackbarOpened] = React.useState(false);
    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpened(false);
    };
    useEffect(() => setIsErrorSnackbarOpened(editClinicPaymentEditError ? true : false), [editClinicPaymentEditError]);

    const areTransactionnalFieldsDisabled = clinicPayment?.deleted_at ? true : false;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <DialogTitle>Éditer le paiement #{selectedPaymentId}</DialogTitle>

                <DialogContent>
                    {clinicPaymentLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height={100}>
                            <Loading />
                        </Box>
                    ) : (
                        <Grid container spacing={3} pt={1}>
                            {areTransactionnalFieldsDisabled && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        <AlertTitle>Vous ne pouvez éditer certains champs</AlertTitle>
                                        Les champs "Clinique" et "Montant" ne peuvent être édités puisque ce paiement a
                                        été annulé antérieurement et que certaines transactions du grand livre font
                                        référence à ce paiement.
                                        {clinicPayment.new_clinic_payment_id && (
                                            <>
                                                <br />
                                                <br />
                                                Suivant l'annulation du paiement, un nouveau paiement avec un ID de{' '}
                                                <strong>{clinicPayment.new_clinic_payment_id}</strong> a été créé pour
                                                remplacer ce paiement.
                                            </>
                                        )}
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <AutocompleteControl
                                    name="clinic_id"
                                    control={control as any}
                                    options={clinicsData?.clinics || []}
                                    valuePropertyName="id"
                                    loading={clinicsLoading}
                                    getOptionLabel={(option: any) => {
                                        const { id, name, pst } = option;

                                        if (option === getValues('clinic_id')) {
                                            const selectedClinic = (clinicsData?.clinics || []).find(
                                                (clinic) => clinic.id === option,
                                            );

                                            return selectedClinic
                                                ? `${selectedClinic.name} (${selectedClinic.pst})`
                                                : '';
                                        } else {
                                            return `${name} (${pst})`;
                                        }
                                    }}
                                    isOptionEqualToValue={(option: any, value) => option?.id === value}
                                    textFieldOptions={{ label: 'Clinique' }}
                                    disabled={areTransactionnalFieldsDisabled}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <SelectControl
                                    name="method"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Méthode de paiement' }}
                                    dropdownOptions={PAYMENT_METHODS.sort((a, b) => a.name.localeCompare(b.name)).map(
                                        (paymentMethod) => (
                                            <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
                                                {paymentMethod.name}
                                            </MenuItem>
                                        ),
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextControl
                                    name="ref_no"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Numéro de référence' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <NumberControl
                                    name={`amt`}
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Montant' }}
                                    money
                                    disabled={areTransactionnalFieldsDisabled}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DateControl
                                    name={`payment_date`}
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Date du paiement' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <SelectControl
                                    name="bank_account_id"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Compte de banque' }}
                                    dropdownOptions={[...(bankAccountsData?.bankAccounts || [])]
                                        .sort((a, b) => a.name - b.name)
                                        .map((bankAccount) => (
                                            <MenuItem key={bankAccount.id} value={bankAccount.id}>
                                                {bankAccount.name}
                                            </MenuItem>
                                        ))}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextControl
                                    name="private_note"
                                    control={control as any}
                                    errors={errors as any}
                                    options={{ label: 'Note privée', rows: 4 }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        variant="text"
                        onClick={handleSubmit(onSubmit)}
                        endIcon={<SaveIcon />}
                        loading={editClinicPaymentEditLoading}
                        loadingPosition="end"
                        type="submit"
                    >
                        Sauvegarder
                    </LoadingButton>
                </DialogActions>
            </form>

            <Snackbar
                open={isErrorSnackbarOpened}
                autoHideDuration={6000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {getErrorMsg(editClinicPaymentEditError)}
                </Alert>
            </Snackbar>
        </>
    );
};
