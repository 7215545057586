import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdBillingInfoFields } from 'apollo/fragments';

export const GET_MD = gql`
    ${MdBillingInfoFields}

    query GetMd($id: Int!) {
        md(id: $id) {
            id
            created_at
            updated_at
            licence_sm
            password
            first_name
            last_name
            first_name_smchar
            last_name_smchar
            sex
            retraite_on
            avatar_url
            is_still_client
            can_login

            MdBillingInfo {
                ...MdBillingInfoFields
            }

            MdBillingAutoLines {
                id
                created_at
                updated_at
                md_id
                bill_line_tag_id
                name
                desc
                unit_amt
                qty
                start_on
                end_on
                is_permanent
            }

            MdBillingAutoModLines {
                id
                created_at
                updated_at
                md_id
                bill_mod_line_tag_id
                name
                desc
                value_type
                value
                start_on
                end_on
                is_permanent
            }
        }
    }
`;

export const GET_BILLING_SHEETS = gql`
    query GetBillingSheets($id: Int) {
        billingSheets(id: $id) {
            id
            created_at
            updated_at
            name
        }
    }
`;

export const GET_BILL_LINE_TAGS = gql`
    query GetBillLineTags {
        billLineTags {
            id
            created_at
            updated_at
            slug
            name
            desc
        }
    }
`;

export const GET_BILL_MOD_LINE_TAGS = gql`
    query GetBillModLineTags {
        billModLineTags {
            id
            created_at
            updated_at
            slug
            name
            desc
            position
        }
    }
`;

export const UPDATE_MD_BILLING_INFO = gql`
    mutation UpdateMdBillingInfo(
        $id: Int
        $billing_method: String
        $billing_sheet_id: Int
        $fact_fee: Int
        $fact_line_fee: Int
        $fact_fee_urg: Int
        $fact_line_fee_urg: Int
        $fact_fee_hospit: Int
        $fact_line_fee_hospit: Int
        $fact_fee_dme: Int
        $fact_line_fee_dme: Int
        $rmx_sheet_fee: Int
        $rpv_sheet_fee: Int
        $rps_sheet_fee: Int
        $hors_ramq_req_fee: Int
        $manual_fd_fee: Int
        $is_approved: Boolean
        $md_billing_auto_lines: [UpdateMdBillingInfoMdBillingAutoLinesInput]
        $md_billing_auto_mod_lines: [UpdateMdBillingInfoMdBillingAutoModLinesInput]
    ) {
        updateMdBillingInfo(
            id: $id
            billing_method: $billing_method
            billing_sheet_id: $billing_sheet_id
            fact_fee: $fact_fee
            fact_line_fee: $fact_line_fee
            fact_fee_urg: $fact_fee_urg
            fact_line_fee_urg: $fact_line_fee_urg
            fact_fee_hospit: $fact_fee_hospit
            fact_line_fee_hospit: $fact_line_fee_hospit
            fact_fee_dme: $fact_fee_dme
            fact_line_fee_dme: $fact_line_fee_dme
            rmx_sheet_fee: $rmx_sheet_fee
            rpv_sheet_fee: $rpv_sheet_fee
            rps_sheet_fee: $rps_sheet_fee
            hors_ramq_req_fee: $hors_ramq_req_fee
            manual_fd_fee: $manual_fd_fee
            is_approved: $is_approved
            md_billing_auto_lines: $md_billing_auto_lines
            md_billing_auto_mod_lines: $md_billing_auto_mod_lines
        ) {
            id
        }
    }
`;
