import React from 'react';
import { IconProps, SxProps, Typography } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import { formatNumber } from 'utils';

interface ColoredNumberProps {
    value: number;
    sx?: SxProps;
}

export default function ColoredNumber(props: ColoredNumberProps) {
    let { value, sx } = props;

    const sxProps = sx || {};

    let textColor = '';

    if (value > 0) textColor = 'success.main';
    else if (value == 0) textColor = 'warning.main';
    else if (value < 0) textColor = 'error.main';
    else textColor = 'warning.main';

    return (
        <Typography variant="body2" sx={{ color: textColor }}>
            {formatNumber(value as number, { cents: true })} $
        </Typography>
    );
}
