import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getPosteDialogTableColumns } from './grid-columns';

// MUI Icons
import {} from '@mui/icons-material';

// Components

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import BooleanIcon from 'components/BooleanIcon';

// Redux

// Utils

// Constants

interface PosteDialogProps {
    selectedPoste: any;
    handleClosePosteDialog: () => void;
}

export default function PosteDialog({ selectedPoste, handleClosePosteDialog }: PosteDialogProps) {
    const posteDialogTableColumns = useMemo(() => getPosteDialogTableColumns(), []);

    return (
        <Dialog fullWidth={true} maxWidth={'lg'} open={selectedPoste ? true : false} onClose={handleClosePosteDialog}>
            {selectedPoste && (
                <>
                    <DialogTitle>
                        Poste #{selectedPoste.numero} - {selectedPoste.description}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Statut: {selectedPoste.actif ? 'Actif' : 'Inactif'}
                            {'   '}
                            <BooleanIcon
                                value={selectedPoste.actif}
                                sx={{ verticalAlign: 'middle', fontSize: '20px' }}
                            ></BooleanIcon>
                            <br />
                            Modulateur: {selectedPoste.modulateur}
                        </DialogContentText>
                        <DataGridPro
                            sx={{ mt: 3 }}
                            getRowId={(row) => uuidv4()}
                            rows={[selectedPoste]}
                            columns={posteDialogTableColumns}
                            pageSize={1}
                            rowsPerPageOptions={[1]}
                            disableSelectionOnClick
                            disableColumnMenu
                            disableColumnFilter
                            autoHeight
                            hideFooter
                            onRowClick={(params, event, details) => {}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePosteDialog}>Fermer</Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
