import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import {
    ClinicFields,
    EcFields,
    ClinicBillFields,
    ClinicBillLineFields,
    ClinicBillModLineFields,
    BillLineTagFields,
    BillModLineTagFields,
} from 'apollo/fragments';

export const GET_CLINIC_BILL = gql`
    ${ClinicFields}
    ${EcFields}
    ${ClinicBillFields}
    ${ClinicBillLineFields}
    ${ClinicBillModLineFields}
    ${BillLineTagFields}
    ${BillModLineTagFields}

    query GetClinicBill($id: Int!) {
        clinicBill(id: $id) {
            ...ClinicBillFields

            Clinic {
                ...ClinicFields
            }
            Ec {
                ...EcFields
            }
            ClinicBillLines {
                ...ClinicBillLineFields

                BillLineTag {
                    ...BillLineTagFields
                }
            }
            ClinicBillModLines {
                ...ClinicBillModLineFields

                BillModLineTag {
                    ...BillModLineTagFields
                }
            }
        }
    }
`;
