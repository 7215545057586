import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme, styled } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { isArray } from 'lodash';

// MUI Components
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
    IconButton,
    TooltipProps,
    tooltipClasses,
    Chip,
    Stack,
    Slide,
    Dialog,
    AppBar,
    Toolbar,
    Button,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    OpenInNew as OpenInNewIcon,
    Download as DownloadIcon,
    Help as HelpIcon,
    Info as InfoIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import BillTabPanel from './BillTabPanel';
import BillLineDetailsTabPanel from './BillLineDetailsTabPanel/index';

// Apollo
import { useQuery, useLazyQuery, from } from '@apollo/client';
import { GET_CLINIC_BILL } from './apollo-queries';

// Redux
import {} from 'redux';

// Utils
import { formatNumber, capitalize } from 'utils';

// Constants
import { SQL_DATETIME } from 'constants/Moment';
import { Tooltip } from '@mui/material';
import BooleanIcon from 'components/BooleanIcon';
import { FACT_BILL_LINE_TAGS } from 'constants/Billing';

interface EditDrawerProps {
    clinicBillId: number | null;
    handleCloseEditDrawer: () => void;
    handleDownloadClinicBill: (clinic_bill_id: number, ec_id: number, clinic_id: number) => void;
}

interface EditDrawerContentProps {
    bill: any;
    handleCloseEditDrawer: () => void;
    handleDownloadClinicBill: (clinic_bill_id: number, ec_id: number, clinic_id: number) => void;
}

export default function EditDrawer({ clinicBillId, handleCloseEditDrawer, handleDownloadClinicBill }: EditDrawerProps) {
    // Apollo queries
    const {
        loading: clinicBillLoading,
        error: clinicBillError,
        data: clinicBillData,
    } = useQuery(GET_CLINIC_BILL, {
        variables: { id: clinicBillId },
        skip: !clinicBillId,
    });

    const bill = clinicBillData?.clinicBill;

    const isDrawerOpen = clinicBillId ? true : false;

    return (
        <Dialog
            fullScreen
            open={clinicBillData?.clinicBill ? true : false}
            onClose={handleCloseEditDrawer}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'fixed' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseEditDrawer} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Fermer
                    </Typography>
                    {/* <Button color="inherit" onClick={handleCloseEditDrawer}>
                        Sauvegarder
                    </Button> */}
                </Toolbar>
            </AppBar>

            <AppBarSpacer />

            {clinicBillLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                    <Loading />
                </Box>
            ) : (
                <EditDrawerContent
                    bill={bill}
                    handleCloseEditDrawer={handleCloseEditDrawer}
                    handleDownloadClinicBill={handleDownloadClinicBill}
                />
            )}
        </Dialog>
    );
}

const EditDrawerContent = ({ bill, handleCloseEditDrawer, handleDownloadClinicBill }: EditDrawerContentProps) => {
    const [tabIndex, setTabIndex] = useState('1');

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue.toString());
    };

    if (!bill) return <></>;

    const {
        id,
        created_at,
        updated_at,
        ec_id,
        subtotal_amt,
        tps_rate,
        tvq_rate,
        tps_amt,
        tvq_amt,
        total_amt,
        clinic_id,
        filename,
        is_sipt,
        qb_invoice_id,
        bill_no,
        status,
        email_sent,
        last_email_sent_at,
        Clinic,
        Ec,
        ClinicBillLines,
    } = bill;

    const {
        // licence,
        name,
    } = Clinic || {};

    const {
        // id,
        // created_at,
        // updated_at,
        date_ec,
        date_coupure,
        dd_periode_fact,
        df_periode_fact,
        no_paiement,
        fmp_id,
    } = Ec || {};

    let otherTabPanels: { value: string; label: string; billLine: any }[] = [];

    ClinicBillLines.filter(
        (billLine) => FACT_BILL_LINE_TAGS.includes(billLine.bill_line_tag_id) && isArray(billLine.json_details),
    )
        .sort((a, b) => a.position - b.position)
        .forEach((billLine, idx) => {
            otherTabPanels.push({
                value: (3 + idx).toString(),
                label: `Détaillé - ${billLine.name}`,
                billLine: billLine,
            });
        });

    return (
        <Grid container>
            {/** Meta info section */}
            <Grid item xs={12} container spacing={2} p={3}>
                {/** Main info section */}
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={6}>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center">
                            <Typography variant="h3" fontWeight="600">
                                Facture #{bill_no}
                            </Typography>

                            <Box ml={3}>
                                <Tooltip title="Télécharger la facture." placement="top" disableInteractive>
                                    <IconButton
                                        size="medium"
                                        color="primary"
                                        onClick={() => {
                                            handleDownloadClinicBill(id, ec_id, clinic_id);
                                        }}
                                    >
                                        <DownloadIcon fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                                <NoMaxWidthTooltip
                                    title={
                                        <Box width={330} paddingRight={2}>
                                            <Box display="flex" justifyContent="space-between" mt={0.5}>
                                                <Typography variant="body2">ID facture :</Typography>
                                                <Typography variant="body2">{id}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" mt={0.5}>
                                                <Typography variant="body2">ID facture QB :</Typography>
                                                <Typography variant="body2">{qb_invoice_id}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" mt={0.5}>
                                                <Typography variant="body2">ID EC :</Typography>
                                                <Typography variant="body2">{ec_id}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" mt={0.5}>
                                                <Typography variant="body2">Produit par SIPT :</Typography>
                                                <BooleanIcon value={is_sipt} />
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" mt={0.5}>
                                                <Typography variant="body2">PDF :</Typography>
                                                <Typography variant="body2">{filename}</Typography>
                                            </Box>
                                        </Box>
                                    }
                                    placement="right"
                                    disableInteractive
                                    arrow
                                >
                                    <IconButton size="medium" color="info" sx={{ mb: 0.3 }}>
                                        {/* <HelpIcon fontSize="medium" color="info" /> */}
                                        <InfoIcon fontSize="medium" color="info" />
                                    </IconButton>
                                </NoMaxWidthTooltip>
                            </Box>
                        </Stack>

                        <Box display="flex" alignItems="center" mt={1}>
                            <Typography variant="h5">
                                Facturé à {name} [{clinic_id}]
                            </Typography>

                            <IconButton
                                size="small"
                                color="primary"
                                component={RouterLink}
                                to={`/clinics/${clinic_id}`}
                                target="_blank"
                            >
                                <OpenInNewIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="column" alignItems="flex-end">
                            <Typography variant="h4" fontWeight="500" textAlign="right">
                                OR {`${moment(date_ec).year()}-${no_paiement}`} <br />
                            </Typography>
                            <Chip label={capitalize(status)} color="success" sx={{ mt: 2 }} />
                        </Stack>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                    </Grid> */}

                {/** Secondary info section */}
                <Grid item xs={12} container mt={3}>
                    <Grid item xs={4}>
                        <Typography>Créée le:</Typography>
                        <Typography variant="h6" fontWeight="500" mt={1}>
                            {moment(created_at).format(SQL_DATETIME)}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Modifiée le:</Typography>
                        <Typography variant="h6" fontWeight="500" mt={1}>
                            {moment(updated_at).format(SQL_DATETIME)}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Dernier envoi par courriel:</Typography>
                        <Typography variant="h6" fontWeight="500" mt={1}>
                            {last_email_sent_at ? moment(last_email_sent_at).format(SQL_DATETIME) : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {/** Tab list section */}
            <Grid item xs={12} sx={{ mt: 2.5 }}>
                <TabContext value={tabIndex}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={onTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                        >
                            <Tab label="Facture" value="1" />
                            {otherTabPanels.map((tabPanel, idx) => {
                                const { value, label, billLine } = tabPanel;

                                return <Tab key={idx} label={label} value={value} />;
                            })}
                        </TabList>
                    </Box>

                    <BillTabPanel
                        tabIndexAsString="1"
                        bill={bill}
                        handleDownloadClinicBill={handleDownloadClinicBill}
                    />

                    {otherTabPanels.map((tabPanel, idx) => {
                        const { value, label, billLine } = tabPanel;

                        return (
                            <div key={idx}>
                                <BillLineDetailsTabPanel tabIndexAsString={value} billLine={billLine} />
                            </div>
                        );
                    })}
                </TabContext>
            </Grid>
        </Grid>
    );
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AppBarSpacer = styled('div')(({ theme }) => ({
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
