import React, { Component, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useForm, Controller, useController } from 'react-hook-form';
import NumberFormat from 'react-number-format';

// Css

// Contexts

// Models

// Controllers

// Components
import { TextField, Switch, FormControlLabel, Grid, Input, Paper, Button } from '@mui/material';

type RouteParams = 'adminId';

export default function AdminEdit() {
    const { adminId } = useParams<RouteParams>();

    const [isCreateRoute, setIsCreateRoute] = useState(false);
    const [admin, setAdmin] = useState(null);
    const [adminInitial, setAdminInitial] = useState(null);

    useEffect(() => {
        if (adminId) {
            // Edit route
            setIsCreateRoute(false);
            // region = RegionModel.find(regions, regionId)!;
        } else {
            // Create route
            setIsCreateRoute(true);
            // region = RegionModel.getBlueprint();
        }
    }, []);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            avatar_url: '',
        },
    });

    const onSubmit = (data) => {
        console.log(data);

        const formData = new FormData();

        for (const name in data) {
            formData.append(name, data[name]);
        }

        fetch('http://localhost:84/admins', {
            mode: 'cors',
            method: 'POST',
            // headers: {
            //     Accept: 'application/json',
            //     'Content-Type': 'application/json',
            // },
            body: formData,
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    console.log(result);
                    // setScanList(result);
                },
                (error) => {
                    console.error(error);
                },
            );
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <Paper elevation={3} sx={{ p: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field }) => <TextField label="Prénom" size="small" fullWidth {...field} />}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field }) => <TextField label="Nom" size="small" fullWidth {...field} />}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => <TextField label="Courriel" size="small" fullWidth {...field} />}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <PhoneControl name="phone" errors={errors} control={control} />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <TextField label="Mot de passe" size="small" fullWidth {...field} />
                                )}
                            />
                        </Grid>

                        <PickAvatarControl name="avatar_url" errors={errors} control={control} />

                        {/* <Grid item xs={12}>
                            <Controller
                                name="avatar_url"
                                control={control}
                                render={({ field }) => (
                                    <Button variant="contained" component="label">
                                        Upload File
                                        <input type="file" hidden {...field} />
                                    </Button>
                                )}
                            />
                        </Grid> */}

                        <Grid item xs={12} container justifyContent="flex-end" mt={4}>
                            <Button type="submit" variant="contained">
                                Sauvegarder
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </>
    );
}

export function PickAvatar({ name, errors, register }) {
    const { onChange, ref } = register(name);
    const [image, setImage] = useState();

    const onAvatarChange = useCallback(async (event) => {
        if (event.target.files?.[0]) {
            const base64 = await getBase64(event.target.files[0]);

            setImage(base64);
            onChange(event);
        }
    }, []);

    return (
        <div>
            <label>Any file (...register):</label>
            {image && <img src={image} width="100px" />}
            <input type="file" name={name} ref={ref} onChange={onAvatarChange} />
            <p>{errors[name]?.message}</p>
        </div>
    );
}

export function PickAvatarControl({ name, errors, control, multiple = false }) {
    const { field } = useController({ name, control });
    const [image, setImage] = useState();

    const onAvatarChange = useCallback(async (event) => {
        if (event.target.files?.[0]) {
            const base64 = await getBase64(event.target.files[0]);

            setImage(base64);

            if (multiple) {
                field.onChange(event.target.files);
            } else {
                field.onChange(event.target.files[0]);
            }
        }
    }, []);

    return (
        <>
            {image && <img src={image} width="100px" />}
            <Button variant="contained" component="label">
                Upload File
                <input type="file" hidden onChange={onAvatarChange} />
                <p>{errors[name]?.message}</p>
            </Button>
        </>
    );
}

export function PhoneControl({ name, errors, control }) {
    const { field } = useController({ name, control });
    const [formattedValue, setFormattedValue] = useState('');

    const onValueChange = useCallback(({ value, floatValue, formattedValue }) => {
        setFormattedValue(formattedValue);
        field.onChange(value);
    }, []);

    return (
        <>
            <NumberFormat
                customInput={TextField}
                label="Numéro de téléphone"
                size="small"
                fullWidth
                format="+1 (###) ###-####"
                mask="_"
                value={formattedValue}
                onValueChange={onValueChange}
            />
        </>
    );
}

// <Controller
// name="phone"
// control={control}
// render={({ field }) => (
//     <NumberFormat
//         customInput={TextField}
//         label="Numéro de téléphone"
//         size="small"
//         fullWidth
//         format="+1 (###) ###-####"
//         mask="_"
//         {...field}
//     />
// )}
// />

export function getBase64(file): Promise<any> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as any);
        reader.onerror = (error) => reject(error);
    });
}
