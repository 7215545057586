import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {} from '@mui/icons-material';

// Components
import ClinicBillTable from 'components/ClinicBillTable';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils
import { roundNumber, formatNumber } from 'utils';

// Constants

interface BillTabPanelProps {
    tabIndexAsString: string;
    bill: any;
    handleDownloadClinicBill: (clinic_bill_id: number, ec_id: number, clinic_id: number) => void;
}

export default function BillTabPanel({ tabIndexAsString, bill, handleDownloadClinicBill }: BillTabPanelProps) {
    return (
        <TabPanel value={tabIndexAsString}>
            <ClinicBillTable bill={bill} handleDownloadClinicBill={handleDownloadClinicBill} />
        </TabPanel>
    );
}
