import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { BankAccountFields, ClinicFields } from 'apollo/fragments';

export const GET_BANK_ACCOUNTS = gql`
    ${BankAccountFields}

    query GetBankAccounts {
        bankAccounts {
            ...BankAccountFields
        }
    }
`;

export const GET_CLINICS = gql`
    ${ClinicFields}

    query GetClinics {
        clinics {
            ...ClinicFields
        }
    }
`;

export const CREATE_CLINIC_PAYMENT = gql`
    mutation CreateClinicPayment(
        $clinic_id: Int
        $method: String
        $ref_no: String
        $amt: Int
        $bank_account_id: Int
        $payment_date: Date
        $private_note: String
    ) {
        createClinicPayment(
            clinic_id: $clinic_id
            method: $method
            ref_no: $ref_no
            amt: $amt
            bank_account_id: $bank_account_id
            payment_date: $payment_date
            private_note: $private_note
        ) {
            clinic_id
            method
            ref_no
            amt
            bank_account_id
            payment_date
            private_note
        }
    }
`;
