import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Code as CodeIcon,
    Male as MaleIcon,
    Female as FemaleIcon,
    MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import SexIcon from 'components/SexIcon';

// Constants
import {
    BOOLEAN_COL_DEFAULTS,
    DATE_COL_DEFAULTS,
    EMAIL_COL_DEFAULTS,
    HALF_NAME_COLUMN_WIDTH,
    HALF_NAME_COL_DEFAULTS,
    LICENCE_COL_DEFAULTS,
    PHONE_NUMBER_COL_DEFAULTS,
    SEX_COL_DEFAULTS,
    ZIP_COL_DEFAULTS,
    MONEY_COL_DEFAULTS,
    CENTERED_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize, formatPhoneNumber } from 'utils';

export const getClinicsTableColumns = (handleOpenEditDrawer): GridColDefPro[] => [
    {
        ...LICENCE_COL_DEFAULTS,
        field: 'id',
        headerName: 'ID',
    },
    {
        ...HALF_NAME_COL_DEFAULTS,
        field: 'name',
        headerName: 'Nom',
        width: 200,
    },
    {
        ...CENTERED_COL_DEFAULTS,
        field: 'grp',
        headerName: 'Groupe',
    },
    {
        ...CENTERED_COL_DEFAULTS,
        field: 'pst',
        headerName: 'Poste',
    },
    {
        ...HALF_NAME_COL_DEFAULTS,
        field: 'contact_first_name',
        headerName: 'Prénom contact',
    },
    {
        ...HALF_NAME_COL_DEFAULTS,
        field: 'contact_last_name',
        headerName: 'Nom contact',
    },
    {
        ...SEX_COL_DEFAULTS,
        field: 'contact_sex',
        headerName: 'Sexe contact',
        renderCell: ({ value }) => <SexIcon sex={value} />,
        width: 120,
    },
    {
        ...EMAIL_COL_DEFAULTS,
        field: 'contact_email',
        headerName: 'Courriel contact',
    },
    {
        ...PHONE_NUMBER_COL_DEFAULTS,
        field: 'contact_phone',
        headerName: 'Tel. contact',
        valueFormatter: ({ value }) => formatPhoneNumber(value),
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'joined_on',
        headerName: 'Client depuis',
        valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATE) : null),
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'left_on',
        headerName: 'Parti le',
        valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATE) : null),
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'is_still_client',
        headerName: 'Client',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'can_login',
        headerName: 'Accès Portail',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...MONEY_COL_DEFAULTS,
        field: 'balance',
        headerName: 'Balance',
        valueFormatter: ({ value }) => `${formatNumber(value / 100)} $`,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'is_billable',
        headerName: 'Facturable',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        width: 80,
        headerAlign: 'center',
        align: 'center',
        field: 'qb_customer_id',
        headerName: 'QB ID',
    },
    {
        ...HALF_NAME_COL_DEFAULTS,
        width: 150,
        field: 'qb_customer_fully_qualified_name',
        headerName: 'QB name',
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'clinic_billing_info.is_approved',
        headerName: 'Approuvé',
        valueGetter: ({ row }) => row.ClinicBillingInfo.is_approved,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'clinic_billing_info.billing_method',
        headerName: 'Méthode fact.',
        width: 120,
        valueGetter: ({ row }) => row.ClinicBillingInfo.billing_method,
        valueFormatter: ({ value }) => capitalize(value),
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'clinic_billing_info.billing_sheet_id',
        headerName: 'ID matrice',
        width: 120,
        valueGetter: ({ row }) => row.ClinicBillingInfo.billing_sheet_id,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { id } = row;

            return (
                <>
                    <IconButton
                        onClick={() => {
                            handleOpenEditDrawer(id);
                            window.history.replaceState(null, '', `/clinics/${id}`);
                        }}
                        size="medium"
                        color="primary"
                    >
                        <MenuOpenIcon fontSize="inherit" />
                    </IconButton>
                </>
            );
        },
    },
];
