import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import { useNavigate, NavigateFunction } from 'react-router';
import { useForm, Controller, useController } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

import NumberFormat from 'react-number-format';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
    Chart,
    BarSeries,
    Title,
    Tooltip as TooltipDevExpress,
    ArgumentAxis,
    ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, ArgumentScale } from '@devexpress/dx-react-chart';
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import './index.css';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getMdsTableColumns } from './grid-columns';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Male as MaleIcon,
    Female as FemaleIcon,
    Check as CheckIcon,
    Clear as ClearIcon,
    Visibility as VisibilityIcon,
    MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import EditDrawer from './EditDrawer';
import BooleanIcon from 'components/BooleanIcon';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, useLazyQuery, gql, LazyQueryResult } from '@apollo/client';
import { GET_MDS } from './apollo-queries';

// Redux

// Utils
import {
    formatNumber,
    getBadgeBackgroundColorFromDoctorStatus,
    getBadgeTextColorFromDoctorStatus,
    getRandomNumberBetween,
} from 'utils';

// Constants
import { SQL_DATETIME } from 'constants/Moment';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Gestion des médecins
    </Typography>,
    <Typography key="1" fontSize={15}>
        Médecins
    </Typography>,
];

type RouteParams = 'mdId';

export default function MdList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { mdId } = useParams<RouteParams>();

    // Filter - Md ID
    const search_md_id = searchParams.get('md_id');
    const [mdIdFilter, setMdIdFilter] = useState<string>(search_md_id ? search_md_id : '');
    const handleChangeMdId = (e) => {
        setMdIdFilter(e.target.value);
    };
    const [mdIdFilterDebounced] = useDebounce(mdIdFilter, 750);

    // Filter - Md first_name
    const search_md_first_name = searchParams.get('md_first_name');
    const [mdFirstNameFilter, setMdFirstNameFilter] = useState<string>(
        search_md_first_name ? search_md_first_name : '',
    );
    const handleChangeMdFirstName = (e) => {
        setMdFirstNameFilter(e.target.value);
    };
    const [mdFirstNameFilterDebounced] = useDebounce(mdFirstNameFilter, 750);

    // Filter - Md last_name
    const search_md_last_name = searchParams.get('md_last_name');
    const [mdLastNameFilter, setMdLastNameFilter] = useState<string>(search_md_last_name ? search_md_last_name : '');
    const handleChangeMdLastName = (e) => {
        setMdLastNameFilter(e.target.value);
    };
    const [mdLastNameFilterDebounced] = useDebounce(mdLastNameFilter, 750);

    // Queries
    const {
        loading: mdsLoading,
        error: mdsError,
        data: mdsData,
    } = useQuery(GET_MDS, {
        variables: {
            id: parseInt(mdIdFilterDebounced),
            first_name: mdFirstNameFilterDebounced,
            last_name: mdLastNameFilterDebounced,
        },
    });

    // EditDrawer
    const [selectedMdId, setSelectedMdId] = useState<any | null>(mdId ? parseInt(mdId) : null);

    const handleOpenEditDrawer = (id: number) => {
        setSelectedMdId(id);
    };
    const handleCloseEditDrawer = () => {
        setSelectedMdId(null);
    };

    // Columns
    const mdsTableColumns = useMemo(() => getMdsTableColumns(handleOpenEditDrawer), []);
    const [pageSize, setPageSize] = React.useState<number>(15);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Médecins
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container mb={4}>
                <Grid item xs={12}>
                    <TextField
                        label="Licence"
                        value={mdIdFilter}
                        onChange={handleChangeMdId}
                        type="number"
                        size="small"
                        sx={{ mr: 1 }}
                    />

                    <TextField
                        label="Prénom"
                        value={mdFirstNameFilter}
                        onChange={handleChangeMdFirstName}
                        size="small"
                        sx={{ mr: 1 }}
                    />

                    <TextField
                        label="Nom"
                        value={mdLastNameFilter}
                        onChange={handleChangeMdLastName}
                        size="small"
                        sx={{ mr: 1 }}
                    />
                </Grid>
            </Grid>

            <DataGridPro
                getRowId={(row) => row.id}
                rows={mdsData?.mds || []}
                columns={mdsTableColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: {
                        left: ['id', 'last_name', 'first_name'],
                        right: [
                            // 'qb_customer_id',
                            // 'qb_customer_fully_qualified_name',
                            'balance',
                            'is_still_client',
                            'md_billing_info.is_approved',
                            'is_billable',
                            'actions',
                        ],
                    },
                    sorting: {
                        sortModel: [{ field: 'last_name', sort: 'asc' }],
                    },
                }}
                loading={mdsLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />

            <EditDrawer mdId={selectedMdId} handleCloseEditDrawer={handleCloseEditDrawer} />
        </>
    );
}

const BillingLineChart = ({ data }) => {
    const theme = useTheme();

    let years = [
        { year: '2018', color: theme.palette.error.main },
        { year: '2019', color: theme.palette.warning.main },
        { year: '2020', color: theme.palette.info.main },
        { year: '2021', color: theme.palette.primary.main },
    ];

    let dataFormatted = [
        { monthName: 'Jan' },
        { monthName: 'Feb' },
        { monthName: 'Mar' },
        { monthName: 'Apr' },
        { monthName: 'May' },
        { monthName: 'Jun' },
        { monthName: 'Jul' },
        { monthName: 'Aug' },
        { monthName: 'Sep' },
        { monthName: 'Oct' },
        { monthName: 'Nov' },
        { monthName: 'Dec' },
    ] as any[];

    data.forEach((period) => {
        const { year, monthName, total_billed } = period;

        const elm = dataFormatted.find((item) => item.monthName === monthName)!;

        elm[year] = total_billed || 0;
    });

    console.log('formatt', dataFormatted);

    return (
        <Paper sx={{ p: 3, pl: 0 }}>
            <Grid item xs={12} mb={2}>
                <Typography variant="body1" textAlign="center">
                    Montant facturé à la RAMQ
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={250}>
                    <LineChart data={dataFormatted}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="monthName">{/* <Label value="Mois" offset={0} position="bottom" /> */}</XAxis>
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {years.map((item, index) => (
                            <Line key={index} type="monotone" dataKey={item.year} stroke={item.color} />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
        </Paper>
    );
};

const BillingBarChart = ({ data }) => {
    const theme = useTheme();

    let dataFormatted = [
        { year: '2018', color: theme.palette.error.main, total_billed: 0 },
        { year: '2019', color: theme.palette.warning.main, total_billed: 0 },
        { year: '2020', color: theme.palette.info.main, total_billed: 0 },
        { year: '2021', color: theme.palette.primary.main, total_billed: 0 },
    ];

    data.forEach((period) => {
        const { year, monthName, total_billed } = period;

        const elm = dataFormatted.find((item) => item.year == year)!;

        elm.total_billed += total_billed;
    });

    return (
        <Paper sx={{ p: 3, pl: 0 }}>
            <Grid item xs={12} mb={2}>
                <Typography variant="body1" textAlign="center">
                    Montant facturé à la RAMQ
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={dataFormatted}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="year">{/* <Label value="Mois" offset={0} position="bottom" /> */}</XAxis>
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total_billed" fill={theme.palette.info.main} name="Total facturé">
                            {dataFormatted.map((item, index) => (
                                <Cell key={`cell-${index}`} fill={item.color} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Paper>
    );
};

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return toPercent(ratio, 2);
};

const renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => result + entry.value, 0);

    return (
        <Paper sx={{ px: 1.5, py: 0.5 }} elevation={5}>
            <p className="total">{`${label} (Total: ${total})`}</p>
            <ul className="list">
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
                    </li>
                ))}
            </ul>
        </Paper>
    );
};

const BillingAreaChart = ({ data }) => {
    const theme = useTheme();

    let years = [
        { year: '2018', color: theme.palette.error.main },
        { year: '2019', color: theme.palette.warning.main },
        { year: '2020', color: theme.palette.info.main },
        { year: '2021', color: theme.palette.primary.main },
    ];

    let dataFormatted = [
        { monthName: 'Jan' },
        { monthName: 'Feb' },
        { monthName: 'Mar' },
        { monthName: 'Apr' },
        { monthName: 'May' },
        { monthName: 'Jun' },
        { monthName: 'Jul' },
        { monthName: 'Aug' },
        { monthName: 'Sep' },
        { monthName: 'Oct' },
        { monthName: 'Nov' },
        { monthName: 'Dec' },
    ] as any[];

    data.forEach((period) => {
        const { year, monthName, total_billed } = period;

        const elm = dataFormatted.find((item) => item.monthName === monthName)!;

        elm[year] = total_billed;
    });

    return (
        <Paper sx={{ p: 3, pl: 0 }}>
            <Grid item xs={12} mb={2}>
                <Typography variant="body1" textAlign="center">
                    Montant facturé à la RAMQ
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={250}>
                    <AreaChart data={dataFormatted} stackOffset="expand">
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="monthName" />
                        <YAxis tickFormatter={toPercent} />
                        <Tooltip content={renderTooltipContent} />
                        <Legend />
                        {years.map((item, index) => (
                            <Area
                                key={index}
                                type="monotone"
                                dataKey={item.year}
                                stroke={item.color}
                                stackId="1"
                                fill={item.color}
                            />
                        ))}
                    </AreaChart>
                </ResponsiveContainer>
            </Grid>
        </Paper>
    );
};

// const GmfTabPanel = ({ tabIndexAsString, medecin }: { tabIndexAsString: string; medecin: any }) => {
//     const INITIAL_GROUPING_COLUMN_MODEL = ['groupe'];

//     // Etablissement Dialog
//     const [selectedEtablissement, setSelectedEtablissement] = useState<any | null>(null);
//     const handleOpenEtablissementDialog = (activiteContexte) => {
//         setSelectedEtablissement(activiteContexte);
//     };
//     const handleCloseEtablissementDialog = () => {
//         setSelectedEtablissement(null);
//     };

//     // Columns
//     const columns = useMemo(() => getGmfTableColumns(handleOpenEtablissementDialog), []);

//     return (
//         <TabPanel value={tabIndexAsString}>
//             <DataGridPro
//                 getRowId={(row) => uuidv4()}
//                 rows={medecin.MedecinGmf}
//                 columns={columns}
//                 pagination
//                 pageSize={25}
//                 rowsPerPageOptions={[25, 50, 75, 100]}
//                 autoHeight
//                 disableSelectionOnClick
//                 experimentalFeatures={{
//                     rowGrouping: true,
//                 }}
//                 rowGroupingColumnMode="single"
//                 initialState={{
//                     rowGrouping: {
//                         model: INITIAL_GROUPING_COLUMN_MODEL,
//                     },
//                 }}
//                 defaultGroupingExpansionDepth={-1}
//             />
//             <EtablissementDialog
//                 selectedEtablissement={selectedEtablissement}
//                 handleCloseEtablissementDialog={handleCloseEtablissementDialog}
//             />
//         </TabPanel>
//     );
// };

// const PstCaTabPanel = ({ tabIndexAsString, medecin }: { tabIndexAsString: string; medecin: any }) => {
//     const INITIAL_GROUPING_COLUMN_MODEL = ['poste'];

//     // ActiviteContexte Dialog
//     const [selectedActiviteContexte, setSelectedActiviteContexte] = useState<any | null>(null);
//     const handleOpenActiviteContexteDialog = (activiteContexte) => {
//         setSelectedActiviteContexte(activiteContexte);
//     };
//     const handleCloseActiviteContexteDialog = () => {
//         setSelectedActiviteContexte(null);
//     };

//     // ActiviteCode Dialog
//     const [selectedActiviteCode, setSelectedActiviteCode] = useState<any | null>(null);
//     const handleOpenActiviteCodeDialog = (activiteCode) => {
//         setSelectedActiviteCode(activiteCode);
//     };
//     const handleCloseActiviteCodeDialog = () => {
//         setSelectedActiviteCode(null);
//     };

//     // Columns
//     const columns = useMemo(
//         () => getPstCaTableColumns(handleOpenActiviteContexteDialog, handleOpenActiviteCodeDialog),
//         [],
//     );

//     return (
//         <TabPanel value={tabIndexAsString}>
//             <DataGridPro
//                 getRowId={(row) => uuidv4()}
//                 rows={medecin.MedecinPstCa}
//                 columns={columns}
//                 pagination
//                 pageSize={25}
//                 rowsPerPageOptions={[25, 50, 75, 100]}
//                 disableSelectionOnClick
//                 autoHeight
//                 onRowClick={(params, event, details) => {}}
//                 experimentalFeatures={{
//                     rowGrouping: true,
//                 }}
//                 rowGroupingColumnMode="single"
//                 initialState={{
//                     rowGrouping: {
//                         model: INITIAL_GROUPING_COLUMN_MODEL,
//                     },
//                 }}
//                 defaultGroupingExpansionDepth={-1}
//             />
//             <ActiviteContexteDialog
//                 selectedActiviteContexte={selectedActiviteContexte}
//                 handleCloseActiviteContexteDialog={handleCloseActiviteContexteDialog}
//             />
//             <ActiviteCodeDialog
//                 selectedActiviteCode={selectedActiviteCode}
//                 handleCloseActiviteCodeDialog={handleCloseActiviteCodeDialog}
//             />
//         </TabPanel>
//     );
// };

// const getPstCaTableColumns = (
//     handleOpenActiviteContexteDialog: (activeContexte: any) => void,
//     handleOpenActiviteCodeDialog: (activeCode: any) => void,
// ): GridColDefPro[] => [
//     {
//         field: 'poste',
//         headerName: 'Poste',
//         width: ID_COLUMN_WIDTH,
//         hide: true,
//     },
//     {
//         field: 'poste',
//         headerName: '# Poste',
//         width: ID_COLUMN_WIDTH,
//     },
//     {
//         field: 'modulateur',
//         headerName: 'Modulateur',
//         width: MODULATEUR_COLUMN_WIDTH,
//     },
//     {
//         field: 'contexteCode',
//         headerName: 'Contexte activité',
//         width: MEDIUM_STRING_COLUMN_WIDTH,
//         valueGetter: (params) => {
//             const contexteCode = params.row.ActiviteContexte?.code;

//             return contexteCode;
//         },
//         renderCell: (params) => {
//             const contexteCode = params.value;

//             if (contexteCode)
//                 return (
//                     <>
//                         <Button
//                             onClick={() => {
//                                 handleOpenActiviteContexteDialog(params.row.ActiviteContexte);
//                             }}
//                             variant="contained"
//                             size="small"
//                         >
//                             {contexteCode}
//                         </Button>
//                     </>
//                 );
//         },
//     },
//     {
//         field: 'codeCode',
//         headerName: 'Code activité',
//         width: MEDIUM_STRING_COLUMN_WIDTH,
//         valueGetter: (params) => {
//             const codeCode = params.row.ActiviteCode?.code;

//             return codeCode;
//         },
//         renderCell: (params) => {
//             const codeCode = params.value;

//             if (codeCode)
//                 return (
//                     <>
//                         <Button
//                             onClick={() => {
//                                 handleOpenActiviteCodeDialog(params.row.ActiviteCode);
//                             }}
//                             variant="contained"
//                             size="small"
//                         >
//                             {codeCode}
//                         </Button>
//                     </>
//                 );
//         },
//     },
// ];

// const getGmfTableColumns = (handleOpenEtablissementDialog: (etablissement: any) => void): GridColDefPro[] => [
//     {
//         field: 'groupe',
//         headerName: 'Groupe',
//         width: ID_COLUMN_WIDTH,
//         hide: true,
//     },
//     {
//         field: 'codeetab',
//         headerName: 'Établissement',
//         width: 150,
//         renderCell: (params) => {
//             const codeetab = params.value;

//             if (codeetab)
//                 return (
//                     <>
//                         <Button
//                             onClick={() => {
//                                 handleOpenEtablissementDialog(params.row.Etablissement);
//                             }}
//                             variant="contained"
//                             size="small"
//                         >
//                             {codeetab}
//                         </Button>
//                     </>
//                 );
//         },
//     },
//     {
//         field: 'etabDescription',
//         headerName: 'Nom établissement',
//         minWidth: DESCRIPTION_COLUMN_WIDTH,
//         flex: 1,
//         valueGetter: (params) => {
//             return params.row.Etablissement?.description;
//         },
//     },
//     {
//         field: 'autoffannuel',
//         headerName: 'Auto FF annuel',
//         width: 150,
//         headerAlign: 'center',
//         align: 'center',
//         renderCell: (params: any) => <BooleanIcon value={params.value} />,
//     },
// ];

// const useKeepGroupingColumnsHidden = (
//     apiRef: GridApiRefPro | null,
//     columns: GridColumnsPro,
//     initialModel: GridRowGroupingModelPro,
//     leafField?: string,
// ) => {
//     console.log('new usekeepgrouping call');
//     console.log(apiRef);
//     const prevModel = React.useRef(initialModel);

//     React.useEffect(() => {
//         console.log('YUUPPPPP');
//         if (apiRef) {
//             console.log('YUUPPPPP 32333333');
//             apiRef.current.subscribeEvent(GridEventsPro.rowGroupingModelChange, (newModel) => {
//                 const columnVisibilityModel = {
//                     ...gridColumnVisibilityModelSelectorPro(apiRef),
//                 };
//                 newModel.forEach((field) => {
//                     if (!prevModel.current.includes(field)) {
//                         columnVisibilityModel[field] = false;
//                     }
//                 });
//                 prevModel.current.forEach((field) => {
//                     if (!newModel.includes(field)) {
//                         columnVisibilityModel[field] = true;
//                     }
//                 });
//                 apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
//                 prevModel.current = newModel;
//             });
//         }
//     }, [apiRef]);

//     return React.useMemo(
//         () =>
//             columns.map((colDef) =>
//                 initialModel.includes(colDef.field) || (leafField && colDef.field === leafField)
//                     ? { ...colDef, hide: true }
//                     : colDef,
//             ),
//         [columns, initialModel, leafField],
//     );
// };
