import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import DrawerHeaderTitle from 'components/DrawerHeaderTitle';
import FacturesTabPanel from './FacturesTabPanel';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';

// Redux
import {} from 'redux';

// Utils
import { capitalize } from 'utils';

// Constants
import { DATETIME_COLUMN_WIDTH, SMALL_STRING_COLUMN_WIDTH } from 'constants/DataGrid';
import { SQL_DATETIME } from 'constants/Moment';

export default function EditDrawer({ selectedLot, onClose }: { selectedLot: any | null; onClose: () => void }) {
    const [tabIndex, setTabIndex] = React.useState('2');

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue.toString());
    };

    return (
        <Drawer
            anchor="right"
            open={selectedLot ? true : false}
            onClose={onClose}
            PaperProps={{ sx: { mt: '64px', height: 'calc(100vh - 64px) !important', p: 3 } }}
        >
            {selectedLot && (
                <Grid container spacing={2} width="60vw">
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={2}>
                            <Avatar
                                src={
                                    'https://storage.cloud.google.com/fmparadis-public-bucket-staging/admin-avatars/grogu.jpeg?authuser=2'
                                }
                                sx={{ width: 150, height: 150 }}
                            />
                        </Grid>

                        <Grid item xs={10}>
                            <Grid item xs={12} container spacing={3}>
                                <Grid item xs={6}>
                                    <DrawerHeaderTitle>Lot #{selectedLot.lotid}</DrawerHeaderTitle>
                                    <Typography variant="subtitle1" mt={1}>
                                        Facturé par {capitalize(selectedLot.Utilisateur.idname)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h3" fontSize={30} fontWeight="500" textAlign="right">
                                        {selectedLot.nbredp} Demandes
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider sx={{ mt: 1, mb: 3 }} />

                            <Grid item xs={12} container spacing={3}>
                                <Grid item xs={4}>
                                    <Typography>Ouverture:</Typography>
                                    <Typography variant="h6" fontSize={18} fontWeight="500">
                                        {selectedLot.ouverture && moment(selectedLot.ouverture).format(SQL_DATETIME)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Fermeture:</Typography>
                                    <Typography variant="h6" fontSize={18} fontWeight="500">
                                        {selectedLot.fermeture && moment(selectedLot.fermeture).format(SQL_DATETIME)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Dernière transmission:</Typography>
                                    <Typography variant="h6" fontSize={18} fontWeight="500">
                                        {selectedLot.lasttransmission &&
                                            moment(selectedLot.lasttransmission).format(SQL_DATETIME)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <TabContext value={tabIndex}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={onTabChange} aria-label="lab API tabs example">
                                    <Tab label="Overview" value="1" />
                                    <Tab label="Factures" value="2" />
                                </TabList>
                            </Box>

                            <TabPanel value="1">
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography>En attente:</Typography>
                                        <BooleanIcon value={selectedLot.enattente} fontSize="medium" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Actif:</Typography>
                                        <BooleanIcon value={selectedLot.actif} fontSize="medium" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Refact:</Typography>
                                        <BooleanIcon value={selectedLot.refact} fontSize="medium" />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <FacturesTabPanel tabIndexAsString="2" selectedLot={selectedLot} />
                        </TabContext>
                    </Grid>
                </Grid>
            )}
        </Drawer>
    );
}
