import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useForm, useController, Control, FormState, useFieldArray } from 'react-hook-form';
import Decimal from 'decimal.js';
import NumberFormat from 'react-number-format';
import { isEmpty } from 'lodash';

// MUI Components
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
    Divider,
    Switch,
    Tooltip,
    IconButton,
} from '@mui/material';
import { DatePicker, TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { Delete as DeleteIcon, Help as HelpIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import { CheckboxControl, DateControl, NumberControl, TextControl } from 'components/inputs';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_MD, UPDATE_MD } from './apollo-queries';

// Redux

// Utils

// Constants
import { SQL_DATE } from 'constants/Moment';

interface MdEditProps {
    mdId: number;
}

export default function MdEdit({ mdId }: MdEditProps) {
    const {
        loading: mdLoading,
        error: mdError,
        data: mdData,
        refetch: mdRefetch,
        networkStatus: mdNetworkStatus,
    } = useQuery(GET_MD, {
        variables: { id: mdId },
        skip: !mdId,
        notifyOnNetworkStatusChange: true,
    });

    const md = mdData?.md || {};

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
    } = useForm({
        defaultValues: {
            id: md.id,
            is_still_client: md.is_still_client,
            left_on: md.left_on,
            can_login: md.can_login,
            qb_customer_id: md.qb_customer_id,
            qb_customer_fully_qualified_name: md.qb_customer_fully_qualified_name || '',

            is_billable: md.is_billable,
            is_billable_until: md.is_billable_until,

            should_receive_reports: md.should_receive_reports,
            should_receive_reports_until: md.should_receive_reports_until,

            wants_invoice_emails: md.wants_invoice_emails,
            wants_ec_emails: md.wants_ec_emails,
            wants_newsletter_emails: md.wants_newsletter_emails,
        },
    });

    useEffect(() => {
        const md = mdData?.md || {};

        if (!isEmpty(md)) {
            reset({
                id: md.id,
                is_still_client: md.is_still_client,
                left_on: md.left_on,
                can_login: md.can_login,
                qb_customer_id: md.qb_customer_id,
                qb_customer_fully_qualified_name: md.qb_customer_fully_qualified_name,

                is_billable: md.is_billable,
                is_billable_until: md.is_billable_until,

                should_receive_reports: md.should_receive_reports,
                should_receive_reports_until: md.should_receive_reports_until,

                wants_invoice_emails: md.wants_invoice_emails,
                wants_ec_emails: md.wants_ec_emails,
                wants_newsletter_emails: md.wants_newsletter_emails,
            });
        }
    }, [reset, mdData]);

    const [updateMd, { loading: updateMdLoading, error: updateMdError, data: updateMdData }] = useMutation(UPDATE_MD, {
        // refetchQueries: [GET_SCANS],
    });

    const onSubmit = async (data) => {
        console.log(data);

        await updateMd({
            variables: {
                id: data.id,
                is_still_client: data.is_still_client,
                left_on: data.left_on,
                can_login: data.can_login,
                qb_customer_id: data.qb_customer_id,
                qb_customer_fully_qualified_name: data.qb_customer_fully_qualified_name,

                is_billable: data.is_billable,
                is_billable_until: data.is_billable_until,

                should_receive_reports: data.should_receive_reports,
                should_receive_reports_until: data.should_receive_reports_until,

                wants_invoice_emails: data.wants_invoice_emails,
                wants_ec_emails: data.wants_ec_emails,
                wants_newsletter_emails: data.wants_newsletter_emails,
            },
        });

        mdRefetch();
    };

    const renderError = (error) => {
        if (!error) return <></>;

        console.log(Object.keys(error));

        let jsxErrors: React.ReactNode;
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            jsxErrors = error.graphQLErrors.map((item, idx) => {
                return (
                    <Typography key={idx} color="red" textAlign="center">
                        {`${item.extensions.code}: ${item.message}`}
                    </Typography>
                );
            });
        } else {
            jsxErrors = (
                <Typography color="red" textAlign="center">
                    {error.message}
                </Typography>
            );
        }

        return (
            <Grid item xs={12} mt={2}>
                {jsxErrors}
            </Grid>
        );
    };

    const inputGridXl = 6;
    const inputGridLg = 6;
    const inputGridMd = 6;
    const inputGridSm = 6;
    const inputGridXs = 12;

    return mdLoading ? (
        <Loading />
    ) : (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <Grid container spacing={3}>
                <Grid item container>
                    <Grid item xs={12} mb={3}>
                        <Typography variant="subtitle1" fontWeight="500">
                            Statuts & Permissions
                        </Typography>
                    </Grid>

                    <Grid item container ml={4} columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`is_still_client`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: 'Client' }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si ce médecin est encore un client de l'agence."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            {!watch('is_still_client') && (
                                <Grid
                                    item
                                    xs={inputGridXs}
                                    sm={inputGridSm}
                                    md={inputGridMd}
                                    lg={inputGridLg}
                                    xl={inputGridXl}
                                    container
                                    alignItems="center"
                                >
                                    <DateControl
                                        name={`left_on`}
                                        control={control}
                                        errors={errors}
                                        options={{ label: 'Date de départ' }}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid item xs={12} container alignItems="center">
                                <CheckboxControl
                                    name={`can_login`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: 'Accès au portail médecin' }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case pour permettre au médecin d'accéder à son portail."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`is_billable`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: 'Facturable' }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si des factures doivent être produites pour ce médecin. Pour facturer jusqu'à un EC précis, veuillez indiquer la date de l'état de compte (la date du vendredi) dans le champ juste à droite."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <DateControl
                                    name={`is_billable_until`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: "Facturable jusqu'à l'ÉTC du (inclusif)" }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`should_receive_reports`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: "Produire les documents d'ÉTC" }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si les documents d'ÉTC (documents EC et/ou DF et/ou Rapport sommaire) de ce médecin doivent être produits. Pour produire les documents d'ÉTC jusqu'à un EC précis, veuillez indiquer la date de l'état de compte (la date du vendredi) dans le champ juste à droite."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <DateControl
                                    name={`should_receive_reports_until`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: "Produire les documents d'ÉTC jusqu'à l'ÉTC du (inclusif)" }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`wants_invoice_emails`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: 'Veut recevoir ses factures par email' }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si ce médecin désire recevoir ses factures par email."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`wants_ec_emails`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: "Veut recevoir les notifs d'EC par email" }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si ce médecin désire recevoir les notifications d'EC par email."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container columnSpacing={3}>
                            <Grid
                                item
                                xs={inputGridXs}
                                sm={inputGridSm}
                                md={inputGridMd}
                                lg={inputGridLg}
                                xl={inputGridXl}
                                container
                                alignItems="center"
                            >
                                <CheckboxControl
                                    name={`wants_newsletter_emails`}
                                    control={control}
                                    errors={errors}
                                    options={{ label: 'Veut recevoir les nouvelles par email' }}
                                />
                                <Tooltip
                                    title="Veuillez cocher cette case si ce médecin désire recevoir les messages divers envoyés par email (infolettre)."
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpIcon color="info" sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container mt={2}>
                    <Grid item xs={12} mb={3}>
                        <Typography variant="subtitle1" fontWeight="500">
                            Infos Quickbooks
                        </Typography>
                    </Grid>

                    <Grid item container ml={4} columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                            <NumberControl
                                name="qb_customer_id"
                                control={control}
                                errors={errors}
                                options={{ label: 'QB customer ID' }}
                            />
                        </Grid>

                        <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                            <TextControl
                                name="qb_customer_fully_qualified_name"
                                control={control}
                                errors={errors}
                                options={{ label: 'QB customer fully qualified name' }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {renderError(updateMdError)}

                <Grid item xs={12} container justifyContent="flex-end" mt={0}>
                    <Button type="submit" variant="contained" disabled={updateMdLoading}>
                        Sauvegarder
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
