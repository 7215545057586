import React, { useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as ReTooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

// MUI Components
import { Box, Grid, Paper, Switch, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// MUI Grid

// MUI Icons

// Components
import Loading from 'components/Loading';

// Apollo

// Redux

// Services

// Constants
import { SQL_DATE } from 'constants/Moment';
import { COLORS } from 'constants/Chart';

// Utils
import { capitalize, getSMA } from 'utils';

interface MdRevenuesForSoftwareGraphProps {
    data: {
        graphPoints: {
            label: string;

            sum_subtotal: number;
            sum_tps: number;
            sum_tvq: number;
            sum_total: number;

            sum_subtotal_md: number;
            sum_tps_md: number;
            sum_tvq_md: number;
            sum_total_md: number;

            sum_subtotal_clinic: number;
            sum_tps_clinic: number;
            sum_tvq_clinic: number;
            sum_total_clinic: number;

            percentage_subtotal_md: string;
            percentage_subtotal_clinic: string;

            sum_frais_fact_acte_md: number;
            sum_frais_fact_ligne_md: number;

            percentage_frais_fact_acte_md: string;
            percentage_frais_fact_ligne_md: string;

            sum_frais_fact_rmx_md: number;
            sum_frais_fact_rpv_md: number;
            sum_frais_fact_rps_md: number;
            sum_frais_fact_fd_acte_md: number;
            sum_frais_fact_fd_ligne_md: number;
            sum_frais_fact_19680_md: number;

            sum_rabais_amuq_md: number;
            sum_rabais_annee_1_md: number;
            sum_rabais_annee_2_md: number;
            sum_rabais_annee_3_md: number;
            sum_rabais_referencement_md: number;
            sum_rabais_clinique_md: number;
            sum_ajust_credit_md: number;
            sum_ajust_debit_md: number;
            sum_logiciel_base_md: number;
            sum_dme_kinlogix_md: number;
            sum_dme_medfar_md: number;
            sum_dme_omnimed_md: number;
            sum_rabais_groupe_md: number;
            sum_rabais_bienvenue_md: number;
            sum_mandats_speciaux_md: number;
            sum_rabais_participation_portail_md: number;
            sum_enveloppe_md: number;

            percentage_total_discount_on_total_sales_md: string;

            percentage_total_software_on_total_sales_md: string;
        }[];
    };
    startDate: Moment | null;
    endDate: Moment | null;
}

export default function MdRevenuesForSoftwareGraph(props: MdRevenuesForSoftwareGraphProps) {
    const theme = useTheme();
    const {
        data: { graphPoints },
        data,
        startDate,
        endDate,
    } = props;

    const [graphData] = useMemo(() => {
        const totalsArrayForSma: number[] = [];

        // Main
        const graphData: (MdRevenuesForSoftwareGraphProps['data']['graphPoints'][0] & {
            sma: number | null;
        })[] = [...data.graphPoints]
            .sort((a, b) => moment(a.label).unix() - moment(b.label).unix())
            .map((point, pointIdx) => {
                const {
                    label,

                    sum_subtotal,
                    sum_tps,
                    sum_tvq,
                    sum_total,

                    sum_subtotal_md,
                    sum_tps_md,
                    sum_tvq_md,
                    sum_total_md,

                    sum_subtotal_clinic,
                    sum_tps_clinic,
                    sum_tvq_clinic,
                    sum_total_clinic,

                    percentage_subtotal_md,
                    percentage_subtotal_clinic,

                    sum_frais_fact_acte_md,
                    sum_frais_fact_ligne_md,

                    percentage_frais_fact_acte_md,
                    percentage_frais_fact_ligne_md,

                    sum_frais_fact_rmx_md,
                    sum_frais_fact_rpv_md,
                    sum_frais_fact_rps_md,
                    sum_frais_fact_fd_acte_md,
                    sum_frais_fact_fd_ligne_md,
                    sum_frais_fact_19680_md,

                    sum_rabais_amuq_md,
                    sum_rabais_annee_1_md,
                    sum_rabais_annee_2_md,
                    sum_rabais_annee_3_md,
                    sum_rabais_referencement_md,
                    sum_rabais_clinique_md,
                    sum_ajust_credit_md,
                    sum_ajust_debit_md,
                    sum_logiciel_base_md,
                    sum_dme_kinlogix_md,
                    sum_dme_medfar_md,
                    sum_dme_omnimed_md,
                    sum_rabais_groupe_md,
                    sum_rabais_bienvenue_md,
                    sum_mandats_speciaux_md,
                    sum_rabais_participation_portail_md,
                    sum_enveloppe_md,

                    percentage_total_discount_on_total_sales_md,

                    percentage_total_software_on_total_sales_md,
                } = point;

                totalsArrayForSma.push(sum_subtotal_md + sum_subtotal_clinic + sum_tps + sum_tvq);

                return {
                    label: moment(label).format(SQL_DATE),

                    sum_subtotal,
                    sum_tps,
                    sum_tvq,
                    sum_total,

                    sum_subtotal_md,
                    sum_tps_md,
                    sum_tvq_md,
                    sum_total_md,

                    sum_subtotal_clinic,
                    sum_tps_clinic,
                    sum_tvq_clinic,
                    sum_total_clinic,

                    percentage_subtotal_md,
                    percentage_subtotal_clinic,

                    sum_frais_fact_acte_md,
                    sum_frais_fact_ligne_md,

                    percentage_frais_fact_acte_md,
                    percentage_frais_fact_ligne_md,

                    sum_frais_fact_rmx_md,
                    sum_frais_fact_rpv_md,
                    sum_frais_fact_rps_md,
                    sum_frais_fact_fd_acte_md,
                    sum_frais_fact_fd_ligne_md,
                    sum_frais_fact_19680_md,

                    sum_rabais_amuq_md,
                    sum_rabais_annee_1_md,
                    sum_rabais_annee_2_md,
                    sum_rabais_annee_3_md,
                    sum_rabais_referencement_md,
                    sum_rabais_clinique_md,
                    sum_ajust_credit_md,
                    sum_ajust_debit_md,
                    sum_logiciel_base_md,
                    sum_dme_kinlogix_md,
                    sum_dme_medfar_md,
                    sum_dme_omnimed_md,
                    sum_rabais_groupe_md,
                    sum_rabais_bienvenue_md,
                    sum_mandats_speciaux_md,
                    sum_rabais_participation_portail_md,
                    sum_enveloppe_md,

                    percentage_total_discount_on_total_sales_md,

                    percentage_total_software_on_total_sales_md,

                    sma: 0,
                };
            });

        // SMA
        // const smas = getSMA(
        //     graphData.map((p) => p.sum_subtotal_md + p.sum_subtotal_clinic + p.sum_tps + p.sum_tvq),
        //     5,
        // );
        // smas.forEach((val, idx) => {
        //     graphData[idx].sma = val;
        // });

        return [graphData];
    }, [data]);

    return (
        <Grid item xs={12} lg={12}>
            <Paper
                sx={{
                    px: 2,
                    py: 2,
                }}
                elevation={1}
            >
                <Typography variant="h6" mb={4}>
                    [MD] - Revenus par licence de logiciel
                </Typography>

                <Box sx={{ width: '100%', height: 450 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={400}
                            data={graphData}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            // syncId="anyId"
                        >
                            <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                            <XAxis dataKey="label" scale="auto" />
                            <YAxis
                                yAxisId="left"
                                tickFormatter={(value) => `${value.toFixed(0)}%`}
                                label={{
                                    value: 'Pourcentage des ventes',
                                    angle: -90,
                                    position: 'insideLeft',
                                    style: { textAnchor: 'middle', fill: 'white' },
                                }}
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                tickFormatter={(value) =>
                                    new Intl.NumberFormat('en', { notation: 'compact' }).format(value)
                                }
                                label={{
                                    value: '$ facturé',
                                    angle: 90,
                                    position: 'insideRight',
                                    style: { textAnchor: 'middle', fill: 'white' },
                                }}
                            />
                            <ReTooltip
                                labelStyle={{ color: 'black' }}
                                labelFormatter={(label) => capitalize(moment(label).format('dddd Do MMMM Y'))}
                                // formatter={(value) => `${new Intl.NumberFormat('en').format((new Decimal(value).div(2)) as any)} $`}
                            />
                            <Legend />

                            <Area
                                yAxisId="left"
                                name="Pourcentage des ventes"
                                type="monotone"
                                dataKey="percentage_total_software_on_total_sales_md"
                                stackId="1"
                                stroke={COLORS.ORANGE_RED}
                                fill={COLORS.ORANGE_RED}
                            />

                            <Bar
                                yAxisId="right"
                                name="Logiciel de base"
                                dataKey="sum_logiciel_base_md"
                                stackId="a"
                                fill={theme.palette.primary.main}
                                barSize={20}
                            />
                            <Bar
                                yAxisId="right"
                                name="Kinlogix"
                                dataKey="sum_dme_kinlogix_md"
                                stackId="a"
                                fill={theme.palette.pink1Light.main}
                                barSize={20}
                            />
                            <Bar
                                yAxisId="right"
                                name="Medfar"
                                dataKey="sum_dme_medfar_md"
                                stackId="a"
                                fill={theme.palette.pink1.main}
                                barSize={20}
                            />
                            <Bar
                                yAxisId="right"
                                name="Omnimed"
                                dataKey="sum_dme_omnimed_md"
                                stackId="a"
                                fill={theme.palette.pink1Dark.main}
                                barSize={20}
                            />

                            {/* <Line
                                yAxisId="right"
                                name="SMA 5"
                                type="monotone"
                                dataKey="sma"
                                stroke="red"
                                activeDot={{ r: 8 }}
                                strokeWidth={2}
                            /> */}
                        </ComposedChart>
                    </ResponsiveContainer>
                </Box>
            </Paper>
        </Grid>
    );
}
