import React from 'react';
import { useTheme } from '@mui/material';
import { Box, Paper, Typography, Container } from '@mui/material';

interface GuestLayoutProps {
    children: React.ReactNode;
}

export default function GuestLayout({ children }: GuestLayoutProps) {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={(theme) => ({ width: '100vw', height: '100vh', bgcolor: theme.palette.background.default })}
        >
            <Box
                sx={{
                    bgcolor: theme.palette.primary.main,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '60px',
                    px: 2,
                    py: 1,
                    boxSizing: 'border-box',
                }}
            >
                <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%">
                    <Typography variant="h6" color="white" fontSize={18}>
                        Facturation Médicale Paradis
                    </Typography>
                </Box>
            </Box>

            <Container maxWidth="sm">
                <Paper elevation={5} sx={{ p: 4, bgcolor: theme.palette.background.paper }}>
                    {children}
                </Paper>
            </Container>
        </Box>
    );
}
