import React from 'react';
import { TextField } from '@mui/material';
import Decimal from 'decimal.js';
import { useState, useEffect, useCallback } from 'react';
import { Control, FormState, useController } from 'react-hook-form';
import moment from 'moment';
import { SQL_DATE } from 'constants/Moment';
import { DatePicker } from '@mui/lab';

interface DateControlProps {
    name: string;
    control: Control<any, any>;
    errors: FormState<any>['errors'];
    options?: { label?: string; suffix?: string };
}

export function DateControl({ name, control, errors, options = {} }: DateControlProps) {
    const { label, suffix } = options;

    const {
        field: { onChange, value },
    } = useController({ name, control });

    const [formattedValue, setFormattedValue] = useState<Date | string | null>(moment(value, SQL_DATE).toISOString());

    useEffect(() => {
        setFormattedValue(moment(value, SQL_DATE).toISOString());
    }, [value]);

    return (
        <DatePicker
            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
            value={formattedValue}
            onChange={(newValue) => {
                onChange(moment(newValue)?.format(SQL_DATE) || null);
                setFormattedValue(newValue);
            }}
            {...options}
        />
    );
}
