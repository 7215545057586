import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';

// MUI Components
import { IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    POSTE_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getContactTableColumns = (): GridColDefPro[] => [
    {
        field: 'descriptif',
        headerName: 'Descriptif',
        width: 120,
        valueFormatter: (params) => {
            const descriptif = params.value;
            if (isNaN(descriptif as any)) return descriptif;
            else return `Groupe ${descriptif}`;
        },
    },
    {
        field: 'defaut',
        headerName: 'Défaut',
        width: BOOLEAN_COLUMN_WIDTH,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'courriel',
        headerName: 'Courriel',
        width: 250,
    },
    {
        field: 'adresse',
        headerName: 'Adresse',
        width: 250,
    },
    {
        field: 'ville',
        headerName: 'Ville',
        width: 150,
    },
    {
        field: 'province',
        headerName: 'Province',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'codepostal',
        headerName: 'Code Postal',
        width: 125,
    },
    {
        field: 'modulateur',
        headerName: 'Modulateur',
        width: MODULATEUR_COLUMN_WIDTH,
    },
    {
        field: 'telcell',
        headerName: 'Tel. cell',
        width: 160,
        renderCell: (params) => {
            const phone = params.value;

            return (
                <>
                    <NumberFormat
                        format="+1 (###) ###-####"
                        mask="_"
                        value={phone}
                        displayType="text"
                        renderText={(value) => <Typography variant="body2">{value}</Typography>}
                    />
                </>
            );
        },
    },
    {
        field: 'teldom',
        headerName: 'Tel. dom',
        width: 160,
        renderCell: (params) => {
            const phone = params.value;

            return (
                <>
                    <NumberFormat
                        format="+1 (###) ###-####"
                        mask="_"
                        value={phone}
                        displayType="text"
                        renderText={(value) => <Typography variant="body2">{value}</Typography>}
                    />
                </>
            );
        },
    },
    {
        field: 'teltra',
        headerName: 'Tel. tra',
        width: 160,
        renderCell: (params) => {
            const phone = params.value;

            return (
                <>
                    <NumberFormat
                        format="+1 (###) ###-####"
                        mask="_"
                        value={phone}
                        displayType="text"
                        renderText={(value) => <Typography variant="body2">{value}</Typography>}
                    />
                </>
            );
        },
    },
    {
        field: 'telfax',
        headerName: 'Fax',
        width: 160,
        renderCell: (params) => {
            const fax = params.value;

            return (
                <>
                    <NumberFormat
                        format="+1 (###) ###-####"
                        mask="_"
                        value={fax}
                        displayType="text"
                        renderText={(value) => <Typography variant="body2">{value}</Typography>}
                    />
                </>
            );
        },
    },
    {
        field: 'telpaget',
        headerName: 'Tel. paget',
        width: 160,
        renderCell: (params) => {
            const phone = params.value;

            return (
                <>
                    <NumberFormat
                        format="+1 (###) ###-####"
                        mask="_"
                        value={phone}
                        displayType="text"
                        renderText={(value) => <Typography variant="body2">{value}</Typography>}
                    />
                </>
            );
        },
    },
];
