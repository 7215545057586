import moment from 'moment';

// MUI Components
import { Box, IconButton, Tooltip, CircularProgress, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    MenuOpen as MenuOpenIcon,
    Download as DownloadIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    DATETIME_COL_DEFAULTS,
    DATE_COL_DEFAULTS,
    AMT_COL_DEFAULTS,
    ID_COL_DEFAULTS,
    LICENCE_COL_DEFAULTS,
    BOOLEAN_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';
import ColoredNumber from 'components/ColoredNumber';

export const getmdHorsRamqClaimsTableColumns = (
    handleDownloadMdBill,
    mdHorsRamqClaimIdsOfCurrentFileDownloads,
    handleOpenMdHorsRamqClaimEditDialog,
    handleOpenMdHorsRamqClaimDeleteDialog,
): GridColDefPro[] => [
    {
        ...DATE_COL_DEFAULTS,
        field: 'received_on',
        headerName: 'Reçu le',
    },
    {
        ...LICENCE_COL_DEFAULTS,
        field: 'md_id',
        headerName: 'ID médecin',
    },
    {
        field: 'md.first_name',
        headerName: 'Prénom',
        valueGetter: ({ row }) => row.md.first_name,
    },
    {
        field: 'md.last_name',
        headerName: 'Prénom',
        valueGetter: ({ row }) => row.md.last_name,
    },
    {
        field: 'patient_name',
        headerName: 'Nom patient',
        minWidth: 200,
        flex: 1,
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'consulted_on',
        headerName: 'Consulté le',
    },
    {
        field: 'horsRamqClaimType.name',
        headerName: 'Type',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueGetter: ({ row }) => row.horsRamqClaimType?.name,
    },
    {
        field: 'horsRamqClaimSubtype.name',
        headerName: 'Sous-type',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueGetter: ({ row }) => row.horsRamqClaimSubtype?.name,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'amt_asked',
        headerName: 'Demandé',
        width: 100,
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
        renderCell: ({ value, row }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'amt_paid',
        headerName: 'Payé',
        width: 100,
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        field: 'check_no',
        headerName: 'No chèque',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'check_date',
        headerName: 'Date chèque',
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'is_billed',
        headerName: 'Facturé',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'md_bill_id',
        headerName: 'Facture ID',
        width: 130,
    },
    {
        field: 'status',
        headerName: 'Statut',
        width: 160,
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'status_date',
        headerName: 'Date statut',
    },
    {
        ...BOOLEAN_COL_DEFAULTS,
        field: 'is_sipt',
        headerName: 'SIPT',
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'sipt_admin_name',
        headerName: 'Nom admin',
        valueFormatter: ({ value }) => capitalize(value),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { id, md_bill_id } = row;

            const isFileDownloadButtonLoading = mdHorsRamqClaimIdsOfCurrentFileDownloads.includes(id);

            return (
                <Box display="flex" alignItems="center">
                    {isFileDownloadButtonLoading ? (
                        <IconButton size="medium" color="primary" disabled={true}>
                            <CircularProgress size={14} />
                        </IconButton>
                    ) : (
                        md_bill_id && (
                            <Tooltip title="Télécharger la facture." placement="left" disableInteractive>
                                <IconButton
                                    size="medium"
                                    color="primary"
                                    onClick={() => {
                                        handleDownloadMdBill(id, md_bill_id);
                                    }}
                                >
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )
                    )}

                    <IconButton
                        size="medium"
                        color="primary"
                        onClick={() => {
                            handleOpenMdHorsRamqClaimEditDialog(id);
                        }}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                        size="medium"
                        color="error"
                        onClick={() => {
                            handleOpenMdHorsRamqClaimDeleteDialog(id);
                        }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Box>
            );
        },
    },
];
