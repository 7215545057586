import React, { useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as ReTooltip,
    Legend,
    ResponsiveContainer,
    Label,
} from 'recharts';

// MUI Components
import { Box, Grid, Paper, Switch, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// MUI Grid

// MUI Icons

// Components
import Loading from 'components/Loading';

// Apollo

// Redux

// Services

// Constants
import { SQL_DATE } from 'constants/Moment';

// Utils
import { capitalize, formatNumber, getSMA } from 'utils';
import Decimal from 'decimal.js';

interface DpCountGraphProps {
    data: {
        dpGraphPoints: {
            label: string;

            count: number;
            count_new: number;
            count_rev: number;

            count_new_paper: number;
            count_new_dme: number;

            count_new_sm: number;
            count_new_fd: number;
            count_new_rmx: number;
            count_new_vac: number;
            count_new_sal: number;

            total_prelim: number;
            total_final: number;
            total_diff: number;
        }[];
    };
    startDate: Moment | null;
    endDate: Moment | null;
}

export default function DpCountGraph(props: DpCountGraphProps) {
    const theme = useTheme();
    const {
        data: { dpGraphPoints },
        data,
        startDate,
        endDate,
    } = props;

    const [graphData] = useMemo(() => {
        const totalsArrayForSma: number[] = [];

        // Main
        const graphData: {
            label: string;

            count_new: number;
            count_rev: number;

            count_new_paper: number;
            count_new_dme: number;

            sma: number | null;

            paper_percentage: string;
            dme_percentage: string;
        }[] = [...data.dpGraphPoints]
            .sort((a, b) => moment(a.label).unix() - moment(b.label).unix())
            .map((point, pointIdx) => {
                const {
                    label,

                    count,
                    count_new,
                    count_rev,

                    count_new_paper,
                    count_new_dme,

                    count_new_sm,
                    count_new_fd,
                    count_new_rmx,
                    count_new_vac,
                    count_new_sal,

                    total_prelim,
                    total_final,
                    total_diff,
                } = point;

                totalsArrayForSma.push(count_new);

                const paperPercentage = new Decimal(count_new_paper).div(count_new_paper + count_new_dme).toFixed(2);
                const dmePercentage = new Decimal(1).minus(paperPercentage).toFixed(2);

                return {
                    label: moment(label).format(SQL_DATE),

                    count_new,
                    count_rev,

                    count_new_paper,
                    count_new_dme,

                    sma: 0,

                    paper_percentage: paperPercentage,
                    dme_percentage: dmePercentage,
                };
            });

        // SMA
        const smas = getSMA(
            graphData.map((p) => p.count_new),
            5,
        );
        smas.forEach((val, idx) => {
            graphData[idx].sma = val;
        });

        return [graphData];
    }, [data]);

    return (
        <Grid item xs={12} lg={12}>
            <Paper
                sx={{
                    px: 2,
                    py: 2,
                }}
                elevation={1}
            >
                <Typography variant="h6" mb={4}>
                    Nombre de demandes & révisions créées (RFP)
                </Typography>

                <Box sx={{ width: '100%', height: 450 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={400}
                            data={graphData}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            // syncId="anyId"
                        >
                            <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                            <XAxis dataKey="label" scale="auto" />
                            <YAxis
                                yAxisId="left"
                                tickFormatter={(value) =>
                                    new Intl.NumberFormat('en', { notation: 'compact' }).format(value)
                                }
                                label={{
                                    value: 'Nombre de demandes',
                                    angle: -90,
                                    position: 'insideLeft',
                                    style: { textAnchor: 'middle', fill: 'white' },
                                }}
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                tickFormatter={(value) => `${(value * 100).toFixed(0)}%`}
                                label={{
                                    value: "Pourcentage d'attribution (%)",
                                    angle: 90,
                                    position: 'insideRight',
                                    style: { textAnchor: 'middle', fill: 'white' },
                                }}
                            />
                            <ReTooltip
                                labelStyle={{ color: 'black' }}
                                labelFormatter={(label) => capitalize(moment(label).format('dddd Do MMMM Y'))}
                            />
                            <Legend />
                            <Area
                                yAxisId="right"
                                name="Pourcentage papier"
                                // type="step"
                                type="monotone"
                                dataKey="paper_percentage"
                                stackId="1"
                                stroke={theme.palette.primary.main}
                                fill={theme.palette.primary.main}
                            />
                            <Area
                                yAxisId="right"
                                name="Pourcentage DMÉ"
                                // type="step"
                                type="monotone"
                                dataKey="dme_percentage"
                                stackId="1"
                                stroke={theme.palette.info.main}
                                fill={theme.palette.info.main}
                            />
                            <Bar
                                yAxisId="left"
                                name="Nouvelles demandes papier"
                                dataKey="count_new_paper"
                                stackId="a"
                                fill={theme.palette.primary.main}
                                barSize={20}
                            />
                            <Bar
                                yAxisId="left"
                                name="Nouvelles demandes DMÉ"
                                dataKey="count_new_dme"
                                stackId="a"
                                fill={theme.palette.info.main}
                                barSize={20}
                            />
                            <Bar
                                yAxisId="left"
                                name="Révisions"
                                dataKey="count_rev"
                                stackId="a"
                                fill={theme.palette.pink1Dark.main}
                                barSize={20}
                            />
                            <Line
                                yAxisId="left"
                                name="SMA 5 (excluant révisions)"
                                type="monotone"
                                dataKey="sma"
                                stroke="red"
                                activeDot={{ r: 8 }}
                                strokeWidth={2}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Box>
            </Paper>
        </Grid>
    );
}
