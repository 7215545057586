import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import Decimal from 'decimal.js';
import { Controller, useForm } from 'react-hook-form';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_BILLING_SHEETS, UPDATE_BILLING_SHEET } from './apollo-queries';

// Redux
import {} from 'redux';

// Utils
import { capitalize, formatNumber } from 'utils';

// Constants
import { SQL_DATETIME } from 'constants/Moment';

export default function BracketSheetFunctionCalculator() {
    const breadcrumbs = [
        <Typography key="1" fontSize={15}>
            Utilitaires
        </Typography>,
        <Typography key="1" fontSize={15}>
            Calculateur de matrice
        </Typography>,
    ];

    const [fraisPal1, setFraisPal1] = useState('0.033');
    const [fraisPal2, setFraisPal2] = useState('0.060');
    const [yIncrement, setYIncrement] = useState('0.050');

    const rows = React.useMemo(() => {
        let brackets: any[] = [];
        for (let index = 0; index < 2000; index++) {
            const stepDelta = 5 * 100;

            const from = index === 0 ? 0 : index * stepDelta;
            const to = from + 4.99 * 100;

            let fee = new Decimal(fraisPal1);
            if (index === 0) {
            } else if (index === 1) {
                fee = new Decimal(fraisPal2);
            } else {
                fee = new Decimal(index).minus(1).mul(yIncrement).plus(fraisPal2);
            }

            brackets.push({
                id: index + 1,
                from: from,
                to: to,
                fee2: fee.toDecimalPlaces(2).toFixed(2),
                fee3: fee.toDecimalPlaces(3).toFixed(3),
            });
        }
        return brackets;
    }, [fraisPal1, fraisPal2, yIncrement]);

    // Bracket sheet edit Dialog
    const [isBracketSheetEditDialogOpen, setIsBracketSheetDialogOpen] = useState<boolean>(false);
    const handleOpenBracketSheetEditDialog = () => {
        setIsBracketSheetDialogOpen(true);
    };
    const handleCloseBracketSheetEditDialog = () => {
        setIsBracketSheetDialogOpen(false);
    };

    // Columns
    const columns = useMemo(() => getColumns(), []);
    const [pageSize, setPageSize] = React.useState<number>(50);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Calculateur de matrice
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container spacing={3} mb={2.5}>
                <Grid item xs={2.5}>
                    <TextField
                        onChange={(e) => setFraisPal1(e.target.value)}
                        value={fraisPal1}
                        label="Frais palier 1"
                        size="small"
                        sx={{ mb: 2 }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <TextField
                        onChange={(e) => setFraisPal2(e.target.value)}
                        value={fraisPal2}
                        label="Frais palier 2"
                        size="small"
                        sx={{ mb: 2 }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <TextField
                        onChange={(e) => setYIncrement(e.target.value)}
                        value={yIncrement}
                        label="Variation Y"
                        size="small"
                        sx={{ mb: 2 }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <Button variant="contained" onClick={handleOpenBracketSheetEditDialog}>
                        Assigner à une matrice
                    </Button>
                </Grid>
            </Grid>

            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={rows}
                columns={columns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 50, 75, 100, 500, 1000, 2000]}
                autoHeight
                disableSelectionOnClick
                density="compact"
            />

            <BracketSheetEditDialog
                isBracketSheetEditDialogOpen={isBracketSheetEditDialogOpen}
                handleCloseBracketSheetEditDialog={handleCloseBracketSheetEditDialog}
                fraisPal1={fraisPal1}
                fraisPal2={fraisPal2}
                yIncrement={yIncrement}
            />
        </>
    );
}

interface BracketSheetEditDialogProps {
    isBracketSheetEditDialogOpen: boolean;
    handleCloseBracketSheetEditDialog: () => void;
    fraisPal1: string;
    fraisPal2: string;
    yIncrement: string;
}

interface BracketSheetEditDialogContentProps {
    isBracketSheetEditDialogOpen: boolean;
    handleCloseBracketSheetEditDialog: () => void;
    fraisPal1: string;
    fraisPal2: string;
    yIncrement: string;
}

export function BracketSheetEditDialog({
    isBracketSheetEditDialogOpen,
    handleCloseBracketSheetEditDialog,
    fraisPal1,
    fraisPal2,
    yIncrement,
}: BracketSheetEditDialogProps) {
    const isDialogOpen = isBracketSheetEditDialogOpen ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={handleCloseBracketSheetEditDialog}>
            <BracketSheetEditDialogContent
                isBracketSheetEditDialogOpen={isBracketSheetEditDialogOpen}
                handleCloseBracketSheetEditDialog={handleCloseBracketSheetEditDialog}
                fraisPal1={fraisPal1}
                fraisPal2={fraisPal2}
                yIncrement={yIncrement}
            />
        </Dialog>
    );
}

const BracketSheetEditDialogContent = ({
    isBracketSheetEditDialogOpen,
    handleCloseBracketSheetEditDialog,
    fraisPal1,
    fraisPal2,
    yIncrement,
}: BracketSheetEditDialogContentProps) => {
    const theme = useTheme();

    const {
        loading: billingSheetsLoading,
        error: billingSheetsError,
        data: billingSheetsData,
        refetch: billingSheetsRefetch,
        networkStatus: billingSheetsNetworkStatus,
    } = useQuery(GET_BILLING_SHEETS, {
        skip: !isBracketSheetEditDialogOpen,
        notifyOnNetworkStatusChange: true,
    });

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
    } = useForm({
        defaultValues: {
            id: null,
            name: `Matrice ${fraisPal1}, ${fraisPal2} - (variation ${yIncrement})`,
            first_bracket_fee: new Decimal(fraisPal1).toDecimalPlaces(3).toNumber(),
            second_bracket_fee: new Decimal(fraisPal2).toDecimalPlaces(3).toNumber(),
            y_increment: new Decimal(yIncrement).toDecimalPlaces(3).toNumber(),
        },
    });

    const [
        updateBillingSheet,
        { loading: updateBillingSheetLoading, error: updateBillingSheetError, data: updateBillingSheetData },
    ] = useMutation(UPDATE_BILLING_SHEET, {});

    const onSubmit = async (data) => {
        console.log(data);

        await updateBillingSheet({
            variables: {
                id: data.id,
                name: data.name,
                first_bracket_fee: data.first_bracket_fee,
                second_bracket_fee: data.second_bracket_fee,
                y_increment: data.y_increment,
            },
        });

        billingSheetsRefetch();
        handleCloseBracketSheetEditDialog();
    };

    if (!isBracketSheetEditDialogOpen) return <></>;

    const renderError = (error) => {
        if (!error) return <></>;

        console.log(Object.keys(error));

        let jsxErrors: React.ReactNode;
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            jsxErrors = error.graphQLErrors.map((item, idx) => {
                return (
                    <Typography key={idx} color="red" textAlign="center">
                        {`${item.extensions.code}: ${item.message}`}
                    </Typography>
                );
            });
        } else {
            jsxErrors = (
                <Typography color="red" textAlign="center">
                    {error.message}
                </Typography>
            );
        }

        return (
            <Grid item xs={12} mt={2}>
                {jsxErrors}
            </Grid>
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <DialogTitle>Assigner à une matrice</DialogTitle>

                <DialogContent>
                    <Grid container rowSpacing={4} pt={1}>
                        <Grid item xs={12}>
                            <Controller
                                name="id"
                                control={control}
                                render={({ field }) => (
                                    <TextField label="Matrice de prix" size="small" fullWidth select {...field}>
                                        {[...(billingSheetsData?.billingSheets || [])]
                                            .sort((a, b) => a.name - b.name)
                                            .map((billingSheet) => (
                                                <MenuItem key={billingSheet.id} value={billingSheet.id}>
                                                    {billingSheet.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField label="Nom de la matrice" size="small" fullWidth {...field} />
                                )}
                            />
                        </Grid> */}

                        {renderError(updateBillingSheetError)}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {/* <Button onClick={handleCloseBracketSheetEditDialog}>Fermer</Button> */}
                    {/* <Button type="submit" variant="text" disabled={updateBillingSheetLoading}> */}
                    {/* ! Fix permanent delete of billing_sheet_brackets in resolver before reactivating this button */}
                    <Button type="submit" variant="text" disabled={true}>
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </>
    );
};

const getColumns = (): GridColDefPro[] => [
    {
        field: 'idx',
        headerName: 'Idx',
        width: 100,
        hide: true,
    },
    {
        field: 'from',
        headerName: 'De',
        width: 100,
        valueFormatter: ({ value }) => `${new Decimal(value / 100).toDP(2).toNumber()} $`,
    },
    {
        field: 'to',
        headerName: 'À',
        width: 100,
        valueFormatter: ({ value }) => `${new Decimal(value / 100).toDP(2).toNumber()} $`,
    },
    // {
    //     field: 'fee2',
    //     headerName: 'Frais 2 décimales',
    //     width: 150,
    //     headerAlign: 'right',
    //     align: 'right',
    //     valueFormatter: ({ value }) => `${value} $`,
    // },
    {
        field: 'fee3',
        headerName: 'Frais',
        width: 150,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: ({ value }) => `${value} $`,
    },
    {
        field: 'percentage2',
        headerName: '% (2 décimales)',
        width: 150,
        headerAlign: 'right',
        align: 'right',
        valueGetter: ({ row }) => ((row.fee3 / (row.from / 100)) * 100).toFixed(2),
        valueFormatter: ({ value }) => `${value} %`,
    },
    {
        field: 'percentage3',
        headerName: '% (3 décimales)',
        width: 150,
        headerAlign: 'right',
        align: 'right',
        valueGetter: ({ row }) => ((row.fee3 / (row.from / 100)) * 100).toFixed(3),
        valueFormatter: ({ value }) => `${value} %`,
    },
];
