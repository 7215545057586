import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams, useParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import FileSaver from 'file-saver';

// MUI Components
import { Autocomplete, Breadcrumbs, Button, Grid, TextField, Typography } from '@mui/material';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getBillsColumns } from './grid-columns';

// MUI Icons
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import EditDrawer from './EditDrawer';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_CLINIC_BILLS, GET_ECS, GET_CLINICS } from './apollo-queries';
import { Api } from 'services';

// Redux

// Utils

// Constants
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';
import { useDebounce } from 'use-debounce';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Gestion des cliniques
    </Typography>,
    <Typography key="1" fontSize={15}>
        Factures
    </Typography>,
];

type RouteParams = 'id';

const DEBOUNCE_DELAY = 500;

export default function ClinicBillList() {
    const { id } = useParams<RouteParams>();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter - ClinicBill ID
    const search_clinic_bill_id = searchParams.get('clinic_bill_id');
    const [clinicBillIdFilter, setClinicBillIdFilter] = useState<string>(
        search_clinic_bill_id ? search_clinic_bill_id : '',
    );
    const handleChangeClinicBillId = (e) => {
        setClinicBillIdFilter(e.target.value);
    };
    const [clinicBillIdFilterDebounced] = useDebounce(clinicBillIdFilter, DEBOUNCE_DELAY);

    // Filter - ClinicBill bill_no
    const search_clinic_bill_bill_no = searchParams.get('clinic_bill_bill_no');
    const [clinicBillBillNoFilter, setClinicBillBillNoFilter] = useState<string>(
        search_clinic_bill_bill_no ? search_clinic_bill_bill_no : '',
    );
    const handleChangeClinicBillBillNoFilter = (e) => {
        setClinicBillBillNoFilter(e.target.value);
    };
    const [clinicBillBillNoFilterDebounced] = useDebounce(clinicBillBillNoFilter, DEBOUNCE_DELAY);

    // Filter - Ec ID
    const search_ec_id = searchParams.get('ec_id');
    const [ecIdFilter, setEcIdFilter] = useState<number | null>(search_ec_id ? parseInt(search_ec_id) : null);
    const handleChangeEcId = (e, value: any) => {
        const ecId = value?.id;
        setEcIdFilter(ecId || null);
    };

    // Filter - Clinic ID
    const search_clinic_id = searchParams.get('clinic_id');
    const [clinicIdFilter, setClinicIdFilter] = useState<number | null>(
        search_clinic_id ? parseInt(search_clinic_id) : null,
    );
    const handleChangeClinicId = (e, value: any) => {
        const clinicId = value?.id;
        setClinicIdFilter(clinicId || null);
    };

    // Apollo queries
    const { loading: ecsLoading, error: ecsError, data: ecsData } = useQuery(GET_ECS);
    const { loading: clinicsLoading, error: clinicsError, data: clinicsData } = useQuery(GET_CLINICS);
    const {
        loading: clinicBillsLoading,
        error: clinicBillsError,
        data: clinicBillsData,
    } = useQuery(GET_CLINIC_BILLS, {
        variables: {
            id: parseInt(clinicBillIdFilterDebounced),
            bill_no: clinicBillBillNoFilterDebounced,
            clinic_id: clinicIdFilter,
            ec_id: ecIdFilter,
        },
    });

    // EditDrawer
    const [selectedBillId, setSelectedBillId] = useState<number | null>(id ? parseInt(id) : null);

    const handleOpenEditDrawer = useCallback((id: number) => {
        setSelectedBillId(id);
    }, []);
    const handleCloseEditDrawer = useCallback(() => {
        setSelectedBillId(null);
    }, []);

    // Clinic bill file download
    const [clinicBillIdsOfCurrentFileDownloads, setClinicBillIdsOfCurrentFileDownloads] = useState<number[]>([]);
    const handleDownloadClinicBill = useCallback(
        async (clinic_bill_id: number, ec_id: number, clinic_id: number) => {
            setClinicBillIdsOfCurrentFileDownloads([...clinicBillIdsOfCurrentFileDownloads, clinic_bill_id]);

            try {
                const res = await Api.get('storage/clinic-bill', {
                    responseType: 'blob',
                    timeout: 1000 * 20, // 20 seconds
                    params: {
                        clinic_bill_id: clinic_bill_id,
                    },
                });

                const filename = `${ec_id}-${clinic_id}__${clinic_bill_id}.pdf`;
                FileSaver.saveAs(res.data, filename);
            } catch (e) {
            } finally {
                setClinicBillIdsOfCurrentFileDownloads(
                    clinicBillIdsOfCurrentFileDownloads.filter((item) => item === clinic_bill_id),
                );
            }
        },
        [setClinicBillIdsOfCurrentFileDownloads, clinicBillIdsOfCurrentFileDownloads],
    );

    // Columns
    const billsColumns = useMemo(
        () => getBillsColumns(handleOpenEditDrawer, handleDownloadClinicBill, clinicBillIdsOfCurrentFileDownloads),
        [handleOpenEditDrawer, handleDownloadClinicBill, clinicBillIdsOfCurrentFileDownloads],
    );
    const [pageSize, setPageSize] = React.useState<number>(15);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Factures
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container spacing={3} mb={2.5}>
                <Grid item xs={2.5}>
                    <TextField
                        label="ID facture"
                        value={clinicBillIdFilter}
                        onChange={handleChangeClinicBillId}
                        type="number"
                        size="small"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <TextField
                        label="No. facture"
                        value={clinicBillBillNoFilter}
                        onChange={handleChangeClinicBillBillNoFilter}
                        size="small"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <Autocomplete
                        // disablePortal
                        options={ecsData?.ecs || []}
                        loading={ecsLoading}
                        getOptionLabel={(option: any) => {
                            const { id, no_paiement, date_ec, date_coupure } = option;

                            if (option === ecIdFilter) {
                                const selectedEc = (ecsData?.ecs || []).find((ec) => ec.id === option);

                                return selectedEc
                                    ? `${moment(selectedEc.date_ec).format(SQL_DATE)}  (OR-${selectedEc.no_paiement})`
                                    : '';
                            } else {
                                return `${moment(date_ec).format(SQL_DATE)}  (OR-${no_paiement})`;
                            }
                        }}
                        value={ecIdFilter}
                        onChange={handleChangeEcId}
                        isOptionEqualToValue={(option: any, value) => option?.id === value}
                        renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth label="État de compte" />
                        )}
                        groupBy={(option) => `Année ${moment(option.date_ec).format('YYYY')}`}
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <Autocomplete
                        // disablePortal
                        options={clinicsData?.clinics || []}
                        loading={clinicsLoading}
                        getOptionLabel={(option: any) => {
                            const { id, pst, name } = option;

                            if (option === clinicIdFilter) {
                                const selectedClinic = (clinicsData?.clinics || []).find(
                                    (clinic) => clinic.id === option,
                                );

                                return selectedClinic ? `${selectedClinic.name} (${selectedClinic.pst})` : '';
                            } else {
                                return `${name} (${pst})`;
                            }
                        }}
                        value={clinicIdFilter}
                        onChange={handleChangeClinicId}
                        isOptionEqualToValue={(option: any, value) => option?.id === value}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth label="Clinique" />}
                    />
                </Grid>
            </Grid>

            <DataGridPro
                getRowId={(row) => row.id}
                rows={clinicBillsData?.clinicBills || []}
                columns={billsColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: {
                        left: ['id', 'bill_no', 'name'],
                        right: ['actions'],
                    },
                    sorting: {
                        sortModel: [{ field: 'date_ec', sort: 'desc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            created_at: false,
                            updated_at: false,
                            clinic_id: false,
                            ec_id: false,
                        },
                    },
                }}
                loading={clinicBillsLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />

            <EditDrawer
                clinicBillId={selectedBillId}
                handleCloseEditDrawer={handleCloseEditDrawer}
                handleDownloadClinicBill={handleDownloadClinicBill}
            />
        </>
    );
}
