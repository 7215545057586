import React from 'react';
import { ApolloError, FetchResult } from '@apollo/client';

import { Admin } from 'types';

interface AuthContextType {
    admin: Admin | null;
    error: ApolloError | undefined;
    isLoading: boolean;
    login: (token: string) => Promise<boolean>;
    logout: () => Promise<boolean>;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
    return React.useContext(AuthContext);
}
