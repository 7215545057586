import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    POSTE_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
    ACTIVE_COLUMN_WIDTH,
    LONG_STRING_COLUMN_WIDTH,
    ADDRESS_COLUMN_WIDTH,
    CITY_COLUMN_WIDTH,
    DESCRIPTION_COLUMN_WIDTH,
    REGION_NAME_COLUMN_WIDTH,
    ZIP_CODE_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getActiviteCodeDialogTableColumns = (): GridColDefPro[] => [
    {
        field: 'code',
        headerName: '# Code',
        width: ID_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.code,
    },
    {
        field: 'description',
        headerName: 'Description',
        width: DESCRIPTION_COLUMN_WIDTH,
        flex: 1,
        valueGetter: ({ row }) => row.description,
    },
    {
        field: 'omni',
        headerName: 'Omnipraticien',
        width: 130,
        valueGetter: ({ row }) => row.omni,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'spec',
        headerName: 'Spécialiste',
        width: 130,
        valueGetter: ({ row }) => row.spec,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'codecontexte',
        headerName: 'Code contexte',
        width: 140,
        valueGetter: ({ row }) => row.codecontexte,
    },
    {
        field: 'rmx',
        headerName: 'RMX',
        width: BOOLEAN_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.rmx,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'sal',
        headerName: 'SAL',
        width: BOOLEAN_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.sal,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'vac',
        headerName: 'VAC',
        width: BOOLEAN_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.vac,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
];
