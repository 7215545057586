import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { BankAccountFields, MdFields, MdPaymentFields } from 'apollo/fragments';

export const GET_BANK_ACCOUNTS = gql`
    ${BankAccountFields}

    query GetBankAccounts {
        bankAccounts {
            ...BankAccountFields
        }
    }
`;

export const GET_MDS = gql`
    ${MdFields}

    query GetMds {
        mds {
            ...MdFields
        }
    }
`;

export const GET_MD_PAYMENT = gql`
    ${MdPaymentFields}

    query GetMdPayment($id: Int) {
        mdPayment(id: $id) {
            ...MdPaymentFields
        }
    }
`;

export const UPDATE_MD_PAYMENT = gql`
    mutation UpdateMdPayment(
        $id: Int
        $md_id: Int
        $method: String
        $ref_no: String
        $amt: Int
        $bank_account_id: Int
        $payment_date: Date
        $private_note: String
    ) {
        updateMdPayment(
            id: $id
            md_id: $md_id
            method: $method
            ref_no: $ref_no
            amt: $amt
            bank_account_id: $bank_account_id
            payment_date: $payment_date
            private_note: $private_note
        ) {
            id
            md_id
            method
            ref_no
            amt
            bank_account_id
            payment_date
            deleted_at
            new_md_payment_id
            private_note
        }
    }
`;
