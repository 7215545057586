import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    POSTE_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    MODULATEUR_COLUMN_WIDTH,
    ACTIVE_COLUMN_WIDTH,
    LONG_STRING_COLUMN_WIDTH,
    ADDRESS_COLUMN_WIDTH,
    CITY_COLUMN_WIDTH,
    DESCRIPTION_COLUMN_WIDTH,
    REGION_NAME_COLUMN_WIDTH,
    ZIP_CODE_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getEtablissementDialogTableColumns = (): GridColDefPro[] => [
    {
        field: 'numero',
        headerName: '# Établissement',
        width: 150,
    },
    {
        field: 'description',
        headerName: 'Description',
        width: DESCRIPTION_COLUMN_WIDTH,
    },
    {
        field: 'adresse',
        headerName: 'Adresse',
        width: ADDRESS_COLUMN_WIDTH,
    },
    {
        field: 'ville',
        headerName: 'Ville',
        width: CITY_COLUMN_WIDTH,
    },
    {
        field: 'cp',
        headerName: 'Code postal',
        width: ZIP_CODE_COLUMN_WIDTH,
    },
    {
        field: 'regionNom',
        headerName: 'Nom région',
        width: REGION_NAME_COLUMN_WIDTH,
        valueGetter: ({ row }) => row.Region.nom,
    },
    {
        field: 'ext1',
        headerName: 'ext1',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'ext2',
        headerName: 'ext2',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'ext3',
        headerName: 'ext3',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'ext4',
        headerName: 'ext4',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'ext5',
        headerName: 'ext5',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'ext6',
        headerName: 'ext6',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'ext7',
        headerName: 'ext7',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
    {
        field: 'ext8',
        headerName: 'ext8',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: ({ value }) => <BooleanIcon value={value} />,
    },
];
