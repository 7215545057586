import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams, useParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import FileSaver from 'file-saver';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import { Autocomplete, Breadcrumbs, Button, Grid, TextField, Typography, IconButton, Stack } from '@mui/material';
import { DatePicker } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getClinicTransactionsTableColumns } from './grid-columns';

// MUI Icons
import { NavigateNext as NavigateNextIcon, Clear as ClearIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import DataGridLoader from 'components/DataGridLoader';
import { ClinicPaymentCreateDialog } from 'components/ClinicPaymentCreateDialog';
import { ClinicPaymentEditDialog } from 'components/ClinicPaymentEditDialog';
import { ClinicPaymentDeleteDialog } from 'components/ClinicPaymentDeleteDialog';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_CLINIC_TRANSACTIONS, GET_CLINICS } from './apollo-queries';
import { Api } from 'services';

// Redux

// Utils

// Constants
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Gestion des cliniques
    </Typography>,
    <Typography key="1" fontSize={15}>
        Grand livre
    </Typography>,
];

type RouteParams = 'id';

const DEBOUNCE_DELAY = 500;

export default function ClinicTransactionList() {
    const { id } = useParams<RouteParams>();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter - Start date
    const search_start_date = searchParams.get('start_date');
    const [startDateFilter, setStartDateFilter] = useState<Moment | null>(
        search_start_date ? moment(search_start_date) : null,
    );
    const handleChangeStartDate = (value) => {
        setStartDateFilter(value);
    };
    const handleClearStartDate = () => {
        setStartDateFilter(null);
    };

    // Filter - End date
    const search_end_date = searchParams.get('end_date');
    const [endDateFilter, setEndDateFilter] = useState<Moment | null>(search_end_date ? moment(search_end_date) : null);
    const handleChangeEndDate = (value) => {
        setEndDateFilter(value);
    };
    const handleClearEndDate = () => {
        setEndDateFilter(null);
    };

    // Filter - Clinic ID
    const search_clinic_id = searchParams.get('clinic_id');
    const [clinicIdFilter, setClinicIdFilter] = useState<number | null>(
        search_clinic_id ? parseInt(search_clinic_id) : null,
    );
    const handleChangeClinicId = (e, value: any) => {
        const clinicId = value?.id;
        setClinicIdFilter(clinicId || null);
    };

    // Apollo queries
    const { loading: clinicsLoading, error: clinicsError, data: clinicsData } = useQuery(GET_CLINICS);
    const {
        loading: clinicTransactionsLoading,
        error: clinicTransactionsError,
        data: clinicTransactionsData,
    } = useQuery(GET_CLINIC_TRANSACTIONS, {
        variables: {
            clinic_id: clinicIdFilter,
            start_date: startDateFilter,
            end_date: endDateFilter,
        },
    });

    // Clinic bill file download
    const [clinicTransactionIdsOfCurrentFileDownloads, setClinicBillIdsOfCurrentFileDownloads] = useState<number[]>([]);
    const handleDownloadClinicBill = useCallback(
        async (clinic_bill_id: number) => {
            setClinicBillIdsOfCurrentFileDownloads([...clinicTransactionIdsOfCurrentFileDownloads, clinic_bill_id]);

            try {
                const res = await Api.get('storage/clinic-bill', {
                    responseType: 'blob',
                    timeout: 1000 * 20, // 20 seconds
                    params: {
                        clinic_bill_id: clinic_bill_id,
                    },
                });

                const filename = `${clinic_bill_id}.pdf`;
                FileSaver.saveAs(res.data, filename);
            } catch (e) {
            } finally {
                setClinicBillIdsOfCurrentFileDownloads(
                    clinicTransactionIdsOfCurrentFileDownloads.filter((item) => item === clinic_bill_id),
                );
            }
        },
        [setClinicBillIdsOfCurrentFileDownloads, clinicTransactionIdsOfCurrentFileDownloads],
    );

    // ClinicPayment Dialog
    const [isClinicPaymentCreateDialogOpen, setIsClinicPaymentDialogOpen] = useState<boolean>(false);
    const handleOpenClinicPaymentDialog = useCallback(() => {
        setIsClinicPaymentDialogOpen(true);
    }, [setIsClinicPaymentDialogOpen]);
    const handleCloseClinicPaymentCreateDialog = useCallback(() => {
        setIsClinicPaymentDialogOpen(false);
    }, [setIsClinicPaymentDialogOpen]);

    // Selected payment id
    const [selectedPaymentId, setSelectedPaymentId] = useState<number | null>(null);

    // ClinicPaymentEdit Dialog
    const [isClinicPaymentEditDialogOpen, setIsClinicPaymentEditDialogOpen] = useState<boolean>(false);
    const handleOpenClinicPaymentEditDialog = useCallback(
        (paymentId) => {
            setIsClinicPaymentEditDialogOpen(true);
            setSelectedPaymentId(paymentId);
        },
        [setIsClinicPaymentEditDialogOpen],
    );
    const handleCloseClinicPaymentEditDialog = useCallback(() => {
        setIsClinicPaymentEditDialogOpen(false);
    }, [setIsClinicPaymentEditDialogOpen]);

    // ClinicPaymentDelete Dialog
    const [isClinicPaymentDeleteDialogOpen, setIsClinicPaymentDeleteDialogOpen] = useState<boolean>(false);
    const handleOpenClinicPaymentDeleteDialog = useCallback(
        (paymentId) => {
            setIsClinicPaymentDeleteDialogOpen(true);
            setSelectedPaymentId(paymentId);
        },
        [setIsClinicPaymentDeleteDialogOpen],
    );
    const handleCloseClinicPaymentDeleteDialog = useCallback(() => {
        setIsClinicPaymentDeleteDialogOpen(false);
    }, [setIsClinicPaymentDeleteDialogOpen]);

    // Columns
    const clinicTransactionsColumns = useMemo(
        () =>
            getClinicTransactionsTableColumns(
                handleDownloadClinicBill,
                clinicTransactionIdsOfCurrentFileDownloads,
                handleOpenClinicPaymentEditDialog,
                handleOpenClinicPaymentDeleteDialog,
            ),
        [
            handleDownloadClinicBill,
            clinicTransactionIdsOfCurrentFileDownloads,
            handleOpenClinicPaymentEditDialog,
            handleOpenClinicPaymentDeleteDialog,
        ],
    );
    const [pageSize, setPageSize] = React.useState<number>(25);

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                    <Typography variant="h5" fontWeight="600">
                        Grand livre
                    </Typography>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        sx={{ mt: 1, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>

                <Stack direction="column">
                    <Button variant="contained" onClick={handleOpenClinicPaymentDialog}>
                        Enregistrer un paiement
                    </Button>
                </Stack>
            </Stack>

            <Grid container spacing={3} mb={2.5}>
                <Grid item xs={2.5}>
                    <Autocomplete
                        // disablePortal
                        options={clinicsData?.clinics || []}
                        loading={clinicsLoading}
                        getOptionLabel={(option: any) => {
                            const { id, pst, name } = option;

                            if (option === clinicIdFilter) {
                                const selectedClinic = (clinicsData?.clinics || []).find(
                                    (clinic) => clinic.id === option,
                                );

                                return selectedClinic ? `${selectedClinic.name} (${selectedClinic.pst})` : '';
                            } else {
                                return `${name} (${pst})`;
                            }
                        }}
                        value={clinicIdFilter}
                        onChange={handleChangeClinicId}
                        isOptionEqualToValue={(option: any, value) => option?.id === value}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth label="Clinique" />}
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <DatePicker
                        label={'Date de début'}
                        value={startDateFilter}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: startDateFilter && (
                                <IconButton onClick={handleClearStartDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>

                <Grid item xs={2.5}>
                    <DatePicker
                        label={'Date de fin'}
                        value={endDateFilter}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: endDateFilter && (
                                <IconButton onClick={handleClearEndDate} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        clearable
                    />
                </Grid>
            </Grid>

            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={clinicTransactionsData?.clinicTransactions || []}
                columns={clinicTransactionsColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: {
                        right: ['actions'],
                    },
                    sorting: {
                        // sortModel: [{ field: 'txn_recorded_at', sort: 'desc' }],
                    },
                }}
                loading={clinicTransactionsLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />

            <ClinicPaymentCreateDialog
                isClinicPaymentCreateDialogOpen={isClinicPaymentCreateDialogOpen}
                handleCloseClinicPaymentCreateDialog={handleCloseClinicPaymentCreateDialog}
            />

            <ClinicPaymentEditDialog
                isClinicPaymentEditDialogOpen={isClinicPaymentEditDialogOpen}
                handleCloseClinicPaymentEditDialog={handleCloseClinicPaymentEditDialog}
                selectedPaymentId={selectedPaymentId}
            />

            <ClinicPaymentDeleteDialog
                isClinicPaymentDeleteDialogOpen={isClinicPaymentDeleteDialogOpen}
                handleCloseClinicPaymentDeleteDialog={handleCloseClinicPaymentDeleteDialog}
                selectedPaymentId={selectedPaymentId}
            />
        </>
    );
}
